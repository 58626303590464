import { Component, ElementRef, Input, OnInit, ViewChild,OnChanges,SimpleChanges,ChangeDetectionStrategy } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import {PavilionHistoryService} from '../../../../../core/production';
import {HistorycData} from './../../../../../core/production/_models/HistorycData.model';
import { isThisSecond } from 'date-fns';
import { formatDate } from '@angular/common';

@Component({
  selector: 'kt-deadly-acc-historyc-pavilion',
  templateUrl: './deadly-acc-historyc-pavilion.component.html',
  styleUrls: ['./deadly-acc-historyc-pavilion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeadlyAccHistorycPavilionComponent implements OnInit, OnChanges {

  	// Public properties
	@Input() public historyc: HistorycData;
	@Input() data: { labels: string[], datasets: any[] };
	@Input() type = 'line';
	@ViewChild('chart', { static: true }) chart: ElementRef;
	@Input() parentData: any;
	//@Input() datafecha: {labels: string[]};
	fechaString: string = '';
	chart1: any;

	@Input() public changeFromPavilion(id, fechadesde, fechahasta) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_acc_harvest_day(id, fechadesde, fechahasta).subscribe(res => {
			var yourArray = new Array();
			var maxYcolumn = 0;
			var dateReplica = '';
			var datasetArray = new Array();
			var tmp: any = [];

			res.forEach(function (value) {
				var cutfecha = value.write_date.slice(0,10);
				dateReplica = formatDate(cutfecha, 'dd-MMM', 'en-US');
				yourArray.push(dateReplica);//fecha

				if (maxYcolumn < value.harvest) {
					maxYcolumn = value.harvest;
				};
				var tmp2 = tmp.find(el => el.floor_id == value.floor_id)
				if (tmp2) {
					tmp2.data.push(value.harvest)
				} else {
					var tmp3 = {
						floor_id: value.floor_id,
						data: [value.harvest]
					};
					tmp.push(tmp3)
				}
			});
			console.log(tmp);

			tmp.forEach(function (ArrDash) {
				datasetArray.push({
					fill: false,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 12,
					pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
					label: 'floor: ' + ArrDash.floor_id,
					data: ArrDash.data
				});
			});
			function onlyUnique(value, index, self) {
				return self.indexOf(value) === index;
			};

			var uniqueDate = yourArray.filter(onlyUnique);


			const color = Chart.helpers.color;
			this.data = {
				labels: uniqueDate,
				datasets: datasetArray
			};
			//	this.removeData(chart1)
			this.initChart(maxYcolumn, this.data, 1);
		});


	}

	@Input() public changeFromDay(id, fechadesde, fechahasta) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_acc_harvest_day(id, fechadesde, fechahasta).subscribe(res => {
			var yourArray = new Array();
			var maxYcolumn = 0;
			var dateReplica = '';
			var datasetArray = new Array();
			var tmp: any = [];

			res.forEach(function (value) {
				var cutfecha = value.write_date.slice(0,10);
				dateReplica = formatDate(cutfecha, 'dd-MMM', 'en-US');
				yourArray.push(dateReplica);//fecha

				if (maxYcolumn < value.harvest) {
					maxYcolumn = value.harvest;
				};
				var tmp2 = tmp.find(el => el.floor_id == value.floor_id)
				if (tmp2) {
					tmp2.data.push(value.harvest)
				} else {
					var tmp3 = {
						floor_id: value.floor_id,
						data: [value.harvest]
					};
					tmp.push(tmp3)
				}
			});
			console.log(tmp);

			tmp.forEach(function (ArrDash) {
				datasetArray.push({
					fill: false,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 12,
					pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
					label: 'floor: ' + ArrDash.floor_id,
					data: ArrDash.data
				});
			});
			function onlyUnique(value, index, self) {
				return self.indexOf(value) === index;
			};

			var uniqueDate = yourArray.filter(onlyUnique);


			const color = Chart.helpers.color;
			this.data = {
				labels: uniqueDate,
				datasets: datasetArray
			};
			//	this.removeData(chart1)
			this.initChart(maxYcolumn, this.data, 1);
		});


	}

	@Input() public changeFromWeek(id, fechadesde, fechahasta) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_acc_harvest_week(id, fechadesde, fechahasta).subscribe(res => {
			var yourArray = new Array();
			var maxYcolumn = 0;
			var dateReplica = '';
			var datasetArray = new Array();
			var tmp: any = [];

			res.forEach(function (value) {
				var cutfecha = value.write_date.slice(0,10);
				dateReplica = formatDate(cutfecha, 'dd-MMM', 'en-US');
				yourArray.push(dateReplica);//fecha

				if (maxYcolumn < value.harvest) {
					maxYcolumn = value.harvest;
				};
				var tmp2 = tmp.find(el => el.floor_id == value.floor_id)
				if (tmp2) {
					tmp2.data.push(value.harvest)
				} else {
					var tmp3 = {
						floor_id: value.floor_id,
						data: [value.harvest]
					};
					tmp.push(tmp3)
				}
			});
			console.log(tmp);

			tmp.forEach(function (ArrDash) {
				datasetArray.push({
					fill: false,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 12,
					pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
					label: 'floor: ' + ArrDash.floor_id,
					data: ArrDash.data
				});
			});
			function onlyUnique(value, index, self) {
				return self.indexOf(value) === index;
			};

			var uniqueDate = yourArray.filter(onlyUnique);


			const color = Chart.helpers.color;
			this.data = {
				labels: uniqueDate,
				datasets: datasetArray
			};
			//	this.removeData(chart1)
			this.initChart(maxYcolumn, this.data, 1);
		});


	}

	@Input() public changeFromCycle(id, fechadesde, fechahasta) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_acc_harvest_generation(id, fechadesde, fechahasta,1).subscribe(res => {
			var yourArray = new Array();
			var maxYcolumn = 0;
			var dateReplica = '';
			var datasetArray = new Array();
			var tmp: any = [];

			res.forEach(function (value) {
				var cutfecha = value.write_date.slice(0,10);
				dateReplica = formatDate(cutfecha, 'dd-MMM', 'en-US');
				yourArray.push(dateReplica);//fecha

				if (maxYcolumn < value.harvest) {
					maxYcolumn = value.harvest;
				};
				var tmp2 = tmp.find(el => el.floor_id == value.floor_id)
				if (tmp2) {
					tmp2.data.push(value.harvest)
				} else {
					var tmp3 = {
						floor_id: value.floor_id,
						data: [value.harvest]
					};
					tmp.push(tmp3)
				}
			});
			console.log(tmp);

			tmp.forEach(function (ArrDash) {
				datasetArray.push({
					fill: false,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 12,
					pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
					label: 'floor: ' + ArrDash.floor_id,
					data: ArrDash.data
				});
			});
			function onlyUnique(value, index, self) {
				return self.indexOf(value) === index;
			};

			var uniqueDate = yourArray.filter(onlyUnique);


			const color = Chart.helpers.color;
			this.data = {
				labels: uniqueDate,
				datasets: datasetArray
			};
			//	this.removeData(chart1)
			this.initChart(maxYcolumn, this.data, 1);
		});


	}


	myDate = new Date();
	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private pavilionHistoryService: PavilionHistoryService
	) {
		//this.historyc = new HistorycData();
		//this.historyc.clear();
	}


	ngOnChanges(changes: SimpleChanges) {
		//console.log(changes);
		//console.log('HERE I AM');
	}


	/*ngOnChanges(changes: SimpleChanges) {
		console.log('Entering Widget12Component ngOnChanges');
		console.table(this.historyc);
		console.log('Ending Widget12Component ngOnChanges');
		if (changes)
			console.table(changes)
		const valueChange = changes['historyc'];
		console.log(valueChange);
		this.pavilionHistoryService.getPerformanceByDay(1,'2020-04-02','2020-04-03').subscribe(res => {
			console.log('HERE I AM')
			console.table(res);	
		});
	}*/

	ngOnInit(): void {

	}
public inicializarData(id){
	this.pavilionHistoryService.get_history_pavilion_acc_harvest_day(id, formatDate(this.myDate.setDate(this.myDate.getDate() - 7), 'yyyy-MM-dd', 'en'), formatDate(new Date(), 'yyyy-MM-dd', 'en')).subscribe(res => {
		var yourArray = new Array();
		var maxYcolumn = 0;
		var dateReplica = '';
		var datasetArray = new Array();
		var tmp: any = [];

		res.forEach(function (value) {
			var cutfecha = value.write_date.slice(0,10);
			dateReplica = formatDate(cutfecha, 'dd-MMM', 'en-US');
			yourArray.push(dateReplica);//fecha

			if (maxYcolumn < value.harvest) {
				maxYcolumn = value.harvest;
			};
			var tmp2 = tmp.find(el => el.floor_id == value.floor_id)
			if (tmp2) {
				tmp2.data.push(value.harvest)
			} else {
				var tmp3 = {
					floor_id: value.floor_id,
					data: [value.harvest]
				};
				tmp.push(tmp3)
			}
		});
		console.log(tmp);

		tmp.forEach(function (ArrDash) {
			datasetArray.push({
				fill: false,
				pointHoverRadius: 4,
				pointHoverBorderWidth: 12,
				pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
				label: 'floor: ' + ArrDash.floor_id,
				data: ArrDash.data
			});
		});
		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		};

		var uniqueDate = yourArray.filter(onlyUnique);


		const color = Chart.helpers.color;
		this.data = {
			labels: uniqueDate,
			datasets: datasetArray
		};
		//	this.removeData(chart1)
		this.initChart(maxYcolumn, this.data, 0);
	});



}

	private initChart(maxYcolumn, tmpreal, numaux) {
		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html

		if (numaux == 0) {
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});

		}

		if (numaux == 1) {
			this.chart1.destroy();
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});
		}

	}

}
