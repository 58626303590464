import {BasicService} from './basic.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_MANUAL_URL } from '../../connection.data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ManualService extends BasicService{
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_MANUAL_URL;
    }

    edit(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/${item.id}`, item).pipe(
            map((response)=>{
                if(response.status){
                    return response.status;
                }
                if(response.id){
                    return 'success';
                }else{
                    return 'error';
                }
            })
        );
    }
}