import { BaseModel } from '../../_base/crud';

export class AlarmModel extends BaseModel {
	id: number;
    write_date : any;
    write_uid : number;
    company_id : number;
    pavilion_id : number;
    pavilion_name : number;
    temp : number;
    harvest : number;
    manual_input : string;
    vel_mode_id : number;
    noursishment_level : number;
    alarm_type_id : number;
    white_eggs : number;
    color_eggs : number;
    statud_id : number;
    status : number;

	clear() {
        this.id = 0;
        this.write_uid;
        this.write_date = '';
        this.pavilion_id = 0;
        this.alarm_type_id = 0;
        this.statud_id = 0;
        this.company_id = 0;
	}
}

export class Alarm extends BaseModel{
    id: number;
    create_date: any;
    write_date: any;
    create_uid: number;
    write_uid: number;
    company_id: number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type_id: number;
    pavilion_type_name:string;
    msg: string;
    value: string;
    status: boolean;
    alarm_status_id: number;
    alarm_status_name: string;
    alarm_type_id: number;
    alarm_type_name: string;
    cfg_info: string;
    floor_id: number;
    floor_name: string;

    clear() {
        this.id                 = undefined;
        this.write_uid          = undefined;
        this.write_date         = undefined;
        this.pavilion_id        = undefined;
        this.alarm_type_id      = undefined;
        this.alarm_status_id    = undefined;
        this.company_id         = undefined;
        this.create_date        = undefined;
        this.alarm_status_id    = undefined;
        this.alarm_status_name  = "";
        this.alarm_type_name    = "";
        this.cfg_info           = "";
        this.status             = false;
        this.msg                = "";
        this.pavilion_name      = "";
        this.pavilion_type_id   = undefined;
        this.pavilion_type_name = "";
        this.value              = "";
        this.floor_id           = undefined;
        this.floor_name         = "";
	}
}

export class AlarmType{
    id: number;
    name: string;
    clear(){
        this.id = 0;
        this.name = undefined;
    }
}

export class AlarmStatus{
    id: number;
    name: string;
    clear(){
        this.id = 0;
        this.name = undefined;
    }
}