// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { WaterConsumption} from '../_models/water-consumption.model';
import { WaterConsumptionState } from '../_reducers/water-consumption.reducer';

// State
export const selectWaterConsumptionsState = createFeatureSelector<WaterConsumptionState>('waterConsumptions');

export const selectWaterConsumptionById = (waterConsumptionId: number) => createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.entities[waterConsumptionId]
);

export const selectWaterConsumptionsPageLoading = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectWaterConsumptionsState,
//     customersState => customersState.actionsloading
// );

export const selectWaterConsumptionsPageLastQuery = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.lastQuery
);

export const selectLastCreatedWaterConsumptionId = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.lastCreatedWaterConsumptionId
);

export const selectLastUpdatedWaterConsumptionId = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.lastUpdatedWaterConsumptionId
);

export const selectLastDeletedWaterConsumptionId = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.lastDeletedWaterConsumptionId
);

export const selectWaterConsumptionsInitWaitingMessage = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => waterConsumptionsState.showInitWaitingMessage
);

export const selectWaterConsumptionsInStore = createSelector(
    selectWaterConsumptionsState,
    waterConsumptionsState => {
        const items: WaterConsumption[] = [];
        each(waterConsumptionsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: WaterConsumption[] = httpExtension.sortArray(items, waterConsumptionsState.lastQuery.sortField, waterConsumptionsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, waterConsumptionsState.totalCount, '');
    }
);

export const selectHasWaterConsumptionsInStore = createSelector(
    selectWaterConsumptionsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);