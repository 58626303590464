import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_DEADS_URL} from '../../connection.data';
import { QueryParamsModel } from '../../_base/crud';
import { Deads } from '../_models/deads.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class DeadsService{
    constructor(private http:HttpClient){

    }

    add(deads: Deads): Observable<any>{
        const url = `${API_DEADS_URL}/`
        return this.http.post<any>(url, deads).pipe(
            map(response=>{return{id:response.id, added:response.status=="success"}})
        );
    }

    edit(deads: Deads): Observable<any>{
        const url = `${API_DEADS_URL}/${deads.id}`
        return this.http.put<any>(url, deads).pipe(
            map(response=>response.status=="success")
        );
    }

    delete(deads: Deads): Observable<any>{
        const url = `${API_DEADS_URL}/${deads.id}`
        return this.http.delete<any>(url).pipe(
            map(response=>response.status=="success")
        );
    }

    getPage(queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_DEADS_URL}/page`
        return this.http.post<any>(url, queryParams);
    }  
    
    getById(itemId: number):Observable<any>{
        const url = `${API_DEADS_URL}/${itemId}`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows[0];
            })
        )
    }
}