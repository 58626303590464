// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { BirdStockActions, BirdStockActionTypes } from '../_actions/bird-stock.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { BirdStockMovement } from '../_models/bird-stock-mov.model';
import { BirdStock, BreedingStock } from '../_models/bird-stock.model';
import { BirdGeneration, BirdStatusType, BirdType } from '../_models/bird.model';

// tslint:disable-next-line:no-empty-interface
export interface BSMState extends EntityState<BirdStockMovement> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedBSMId: number;
    lastUpdatedBSMId: number;
    lastDeletedBSMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const bsmAdapter: EntityAdapter<BirdStockMovement> = createEntityAdapter<BirdStockMovement>();

export const initialBSMState: BSMState = bsmAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedBSMId: undefined,
    lastUpdatedBSMId: undefined,
    lastDeletedBSMId: undefined,
    showInitWaitingMessage: true
});

export interface BSBreState extends EntityState<BreedingStock> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedBSId: number;
    lastUpdatedBSId: number;
    lastDeletedBSId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
}

export const bsBreAdapter: EntityAdapter<BreedingStock> = createEntityAdapter<BreedingStock>();

export const initialBSBreState: BSBreState = bsBreAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedBSId: undefined,
    lastUpdatedBSId: undefined,
    lastDeletedBSId: undefined,
    showInitWaitingMessage: true,
    error: null
});

export interface BSTransferState extends EntityState<BirdStockMovement> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedBSTId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const bsTransferAdapter: EntityAdapter<BirdStockMovement> = createEntityAdapter<BirdStockMovement>();

export const initialBSTransferState: BSTransferState = bsTransferAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedBSTId: undefined,
    showInitWaitingMessage: true
});

export function stockMovReducer(state = initialBSMState, action: BirdStockActions): BSMState {
    switch  (action.type) {
        case BirdStockActionTypes.BS_MOVS_PAGE_LOADED:
            return bsmAdapter.addMany(action.payload.birdStockMovs, {
                    ...initialBSMState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        case BirdStockActionTypes.BS_MOV_ADDED:
            if(action.payload.birdStockMov.id){
                console.log("bird stock mov reducer... bs mov added.. action: "+JSON.stringify(action));
                return bsmAdapter.addOne(action.payload.birdStockMov, {
                        ...state, lastCreatedBSMId: action.payload.birdStockMov.id
                    });
            }
            return {...state, lastCreatedBSMId: -1}
        case BirdStockActionTypes.BS_MOV_EDITED:
            console.log("reducre stock mov")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return bsmAdapter.updateOne(action.payload.partialBirdStockMov, 
                    {...state, lastUpdatedBSMId: action.payload.birdStockMov.id});
            return {...state, lastUpdatedBSMId: -1}
        case BirdStockActionTypes.BS_MOV_DELETED:
            if(action.payload.deleted){
                return bsmAdapter.removeOne(action.payload.birdStockMov.id, 
                    {...state, lastDeletedBSMId: action.payload.birdStockMov.id});
            }
            return {...state, lastDeletedBSMId: -1}
        default: return state;
    }
}

export function breedingStockReducer(state = initialBSBreState, action: BirdStockActions): BSBreState {
    switch  (action.type) {
        case BirdStockActionTypes.BS_BREEDING_PAGE_LOADED:
            return bsBreAdapter.addMany(action.payload.breedingStocks, {
                    ...initialBSBreState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        case BirdStockActionTypes.BS_BREEDING_LOADED:
            if(action.payload.breedingStock.id)
                return bsBreAdapter.addOne(action.payload.breedingStock, {
                        ...state, lastCreatedBSId: action.payload.breedingStock.id
                    });
            return {...state, lastCreatedBSId: -1}
        case BirdStockActionTypes.BS_BREEDING_UPDATED:
            if(action.payload.updated)
                return bsBreAdapter.updateOne(action.payload.partialBreedingStock, 
                    {...state, lastUpdatedBSId: action.payload.breedingStock.id});
            return {...state, lastUpdatedBSId: -1}
        case BirdStockActionTypes.BS_BREEDING_DELETED:
            if(action.payload.deleted){
                return bsBreAdapter.removeOne(action.payload.breedingStock.id, 
                    {...state, lastDeletedBSId: action.payload.breedingStock.id});
            }
            return {...state, lastDeletedBSId: -1}
        case BirdStockActionTypes.BS_FAILURE:
            if(action.payload){
                console.log("bird stock reducer - error: ");console.dir(action);
                return {...state, error: action.payload};
            }
            return {...state, error: null}
        default: return state;
    }
}

//Hay que revisar que se hara finalmente con esta informacion
export function birdStockTransReducer(state = initialBSTransferState, action: BirdStockActions): BSTransferState {
    switch  (action.type) {
        // case BirdStockActionTypes.BS_TRANSFER_ADDED: return bsTransferAdapter.addOne(action.payload.silo, {
        //     ...state, lastCreatedBSTId: action.payload.birdStockMovement.id
        // });
        // case BirdStockActionTypes.BS_TRANSFER_EDITED: return bsTransferAdapter.updateOne(action.payload.partialBST, state);
        // case BirdStockActionTypes.BS_TRANSFER_PAGE_LOADED: return bsTransferAdapter.addMany(action.payload.birdStockMovement, {
        //     ...initialBSTransferState,
        //     totalCount: action.payload.totalCount,
        //     lastQuery: action.payload.page,
        //     listLoading: false,
        //     showInitWaitingMessage: false
        // });
        // case BirdStockActionTypes.BS_TRANSFER_DELETED: return bsTransferAdapter.removeOne(action.payload.id, state);
        default: return state;
    }
}

export const getBirdStockMovState = createFeatureSelector<BSMState>('birdStockMovs');
export const getBreedingStockState = createFeatureSelector<BSBreState>('breedingStocks');
export const getBirdStockTransferState = createFeatureSelector<BSTransferState>('birdStockTransfers');

export const birdStockMovSelectors = bsmAdapter.getSelectors();
export const breedingStockSelectors = bsBreAdapter.getSelectors();
export const birdStockTransSelectors = bsTransferAdapter.getSelectors();

//BIRD TYPES
export interface BirdTypeState{
    birdTypes:BirdType[],
    birdTypesLoaded:boolean;
}

export const initialBirdTypeState: BirdTypeState = {
    birdTypes: undefined,
    birdTypesLoaded: false
}

export function birdTypesReducer(state = initialBirdTypeState, action: BirdStockActions):BirdTypeState{
    switch(action.type){
        case BirdStockActionTypes.BS_BIRD_TYPES_LOADED:
            return {
                birdTypesLoaded: true,
                birdTypes: action.payload.birdTypes
            };
        default:
            return state;
    }
}

//BIRD TYPES
export interface BirdStatusTypeState{
    birdStatusTypes:BirdStatusType[],
    birdStatusTypesLoaded:boolean;
}

export const initialBirdStatusTypeState: BirdStatusTypeState = {
    birdStatusTypes: undefined,
    birdStatusTypesLoaded: false
}

export function birdStatusTypesReducer(state = initialBirdStatusTypeState, action: BirdStockActions):BirdStatusTypeState{
    switch(action.type){
        case BirdStockActionTypes.BS_BIRD_STATUS_TYPES_LOADED:
            return {
                birdStatusTypesLoaded: true,
                birdStatusTypes: action.payload.birdStatusTypes
            };
        default:
            return state;
    }
}

//BIRD GENERATIONS
export interface BirdGenState{
    birdGens:BirdGeneration[],
    birdGensLoaded:boolean;
}

export const initialBirdGenState: BirdGenState = {
    birdGens: undefined,
    birdGensLoaded: false
}

export function birdGensReducer(state = initialBirdGenState, action: BirdStockActions):BirdGenState{
    switch(action.type){
        case BirdStockActionTypes.BS_BIRD_GENS_LOADED:
            return {
                birdGensLoaded: true,
                birdGens: action.payload.birdGens
            };
        default:
            return state;
    }
}