// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, filter } from 'rxjs/operators';
import { combineLatest, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  selectHylineTypes, 
  HylineType, 
  HylineGetTypes,
  BirdType,
  birdTypesLoaded, 
  selectBirdTypes, 
  selectHylinesPageLastQuery, 
  BSGetBirdTypes,
  hylineTypesLoaded,
  HylineGetPage,
  HylineDelete,
  HylinesDataSource,
  Hyline,
  HylineEdit,
  HylineDatasDataSource,
  HylineData,
  HylineLoadDataPage,
  HylineBreDatasDataSource,
  HylineHavDatasDataSource,
  HylineLoadBreDataPage,
  HylineLoadHavDataPage,
  HylineLMoltDatasDataSource,
  HylineEMoltDatasDataSource,
  HylineLoadEMoltDataPage,
  HylineLoadLMoltDataPage
} from '../../../../../core/plant-configuration';
import { Update } from '@ngrx/entity';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { User } from '../../../../../core/auth';


// Table with EDIT item in new page
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
  selector: 'kt-hyline-list',
  templateUrl: './hyline-list.component.html',
  styleUrls: ['./hyline-list.component.scss']
})
export class HylineListComponent implements OnInit, OnDestroy, AfterViewInit {

  // Table fields
dataSource: HylinesDataSource;
hylineBreDataDataSource: HylineBreDatasDataSource;
hylineHavDataDataSource: HylineHavDatasDataSource;
hylineEMoltDataDataSource: HylineEMoltDatasDataSource;
hylineLMoltDataDataSource: HylineLMoltDatasDataSource;
displayedColumns = ['id', 'name', 'type', 'bird type', 'active', 'actions'];
breedingDisplayedColumns = ['id', 'age', 'mort acum', 'min weight', 'max weight', 'weight uni', 
  'c nourishment', 'c water'];
haverstDisplayedColumns = ['id', 'age', 'haverst', 'haverst acum', 'mort acum', 'c nourishment',
  'c water'];
earlyMoltDisplayedColumns = ['id', 'age', 'haverst', 'haverst acum', 'mort acum', 'c nourishment',
  'c water'];
lateMoltDisplayedColumns = ['id', 'age', 'haverst', 'haverst acum', 'mort acum', 'c nourishment',
  'c water'];
@ViewChild('hylinePaginator', {static: true}) paginator: MatPaginator;
@ViewChild('breedingPaginator', {static: true}) breedingPaginator: MatPaginator;
@ViewChild('haverstPaginator', {static: true}) haverstPaginator: MatPaginator;
@ViewChild('eMoltPaginator', {static: true}) earlyMoltPaginator: MatPaginator;
@ViewChild('lMoltPaginator', {static: true}) lateMoltPaginator: MatPaginator;
@ViewChild('sort1', {static: true}) sort: MatSort;
@ViewChild('breedingSort', {static: false}) breedingSort: MatSort;
@ViewChild('haverstSort', {static: true}) haverstSort: MatSort;
@ViewChild('earlyMoltSort', {static: true}) earlyMoltSort: MatSort;
@ViewChild('lateMoltSort', {static: true}) lateMoltSort: MatSort;
// Filter fields
@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
filterBirdType = '';
filterHylineType = '';
lastQuery: QueryParamsModel;
lastQuery2: QueryParamsModel;
// Selection
selection = new SelectionModel<Hyline>(false, []);
hylinesResult: Hyline[] = [];
hylineBreDatasResult: HylineData[] = [];
hylineHavDatasResult: HylineData[] = [];
hylineEMoltDatasResult: HylineData[] = [];
hylineLMoltDatasResult: HylineData[] = [];
birdTypesResult: BirdType[] = [];
hylineTypesResult: HylineType[] = [];
selectedHyline: Hyline = null;
private subscriptions: Subscription[] = [];
private user: User = new User();
/**
 * Component constructor
 *
 * @param dialog: MatDialog
 * @param activatedRoute: ActivatedRoute
 * @param router: Router
 * @param subheaderService: SubheaderService
 * @param layoutUtilsService: LayoutUtilsService
 * @param store: Store<AppState>
 */
constructor(public dialog: MatDialog,
             private activatedRoute: ActivatedRoute,
             private router: Router,
             private subheaderService: SubheaderService,
             private layoutUtilsService: LayoutUtilsService,
             private store: Store<AppState>,
             private cdr: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    //INICIALIZACION DE PAGINADORES PARA TABLAS DE DATA DE HYLINES
    const breedingSortSub = this.breedingSort.sortChange.subscribe(() => (this.breedingPaginator.pageIndex = 0));
    this.subscriptions.push(breedingSortSub); 
    const breedingPaginatorSubscriptions = merge(this.breedingSort.sortChange, this.breedingPaginator.page).pipe(
      tap(() => {
        this.loadHylineBreDataList();
      })
    )
    .subscribe();
    this.subscriptions.push(breedingPaginatorSubscriptions);

    const haverstSortSub = this.haverstSort.sortChange.subscribe(() => (this.haverstPaginator.pageIndex = 0));
    this.subscriptions.push(haverstSortSub);
    const haverstPaginatorSubscriptions = merge(this.haverstSort.sortChange, this.haverstPaginator.page).pipe(
      tap(() => this.loadHylineHavDataList())
    )
    .subscribe();
    this.subscriptions.push(haverstPaginatorSubscriptions);

    const earlyMoltSortSub = this.earlyMoltSort.sortChange.subscribe(() => (this.earlyMoltPaginator.pageIndex = 0));
    this.subscriptions.push(earlyMoltSortSub);
    const earlyMoltPaginatorSubscriptions = merge(this.earlyMoltSort.sortChange, this.earlyMoltPaginator.page).pipe(
      tap(() => this.loadHylineEMoltDataList())
    )
    .subscribe();
    this.subscriptions.push(earlyMoltPaginatorSubscriptions);
  
    const lateMoltSortSub = this.lateMoltSort.sortChange.subscribe(() => (this.lateMoltPaginator.pageIndex = 0));
    this.subscriptions.push(lateMoltSortSub);
    const lateMoltPaginatorSubscriptions = merge(this.lateMoltSort.sortChange, this.lateMoltPaginator.page).pipe(
      tap(() => this.loadHylineLMoltDataList())
    )
    .subscribe();
    this.subscriptions.push(lateMoltPaginatorSubscriptions);

    this.loadHylinesList();

  }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
  // If the user changes the sort order, reset back to the first page.
  const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  this.subscriptions.push(sortSubscription);

  this.user.clear();

  const authSubscription = this.store.pipe(
    select(selectAuthState),
  ).subscribe((auth) => {
    // console.log("auth state: "+JSON.stringify(auth));
    if(auth.isUserLoaded)
      this.user = auth.user;
    else
      console.log("hyline list component: usuario no logueado");
  });

  this.subscriptions.push(authSubscription);

  /* Data load will be triggered in two cases:
  - when a pagination event occurs => this.paginator.page
  - when a sort event occurs => this.sort.sortChange
  **/
  const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
    tap(() => this.loadHylinesList())
  )
  .subscribe();
  this.subscriptions.push(paginatorSubscriptions);

  // Filtration, bind to searchInput
  const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    debounceTime(150),
    distinctUntilChanged(),
    tap(() => {
      this.paginator.pageIndex = 0;
      this.breedingPaginator.pageIndex = 0;
      this.haverstPaginator.pageIndex = 0;
      this.earlyMoltPaginator.pageIndex = 0;
      this.lateMoltPaginator.pageIndex = 0;
      this.loadHylinesList();
    })
  )
  .subscribe();
  this.subscriptions.push(searchSubscription);

  //Bird types observers
  const birdTypesLoaded$ = this.store.pipe(
    select(birdTypesLoaded),
    filter((loaded)=>loaded)
  );
  const birdTypes$ = this.store.pipe(
    select(selectBirdTypes)
  );

  const birdTypeSub = combineLatest([birdTypesLoaded$, birdTypes$]).subscribe(
    ([birdTypesLoaded, birdTypes])=>{
      console.log("bird types loaded!");
      if(birdTypesLoaded && birdTypes){
        this.birdTypesResult = birdTypes;
      }
    }
  );

  //Hyline types observers
  const hylineTypesLoaded$ = this.store.pipe(
    select(hylineTypesLoaded),
    filter((loaded)=>loaded)
  );
  const hylineTypes$ = this.store.pipe(
    select(selectHylineTypes)
  );

  const hylineTypeSub = combineLatest([hylineTypesLoaded$, hylineTypes$]).subscribe(
    ([hylineTypesLoaded, hylineTypes])=>{
      console.log("hyline types loaded!");
      if(hylineTypesLoaded && hylineTypes){
        this.hylineTypesResult = hylineTypes;
      }
    }
  );

  // Set title to page breadCrumbs
  this.subheaderService.setTitle('Hyline Movements');

  // Init DataSource
  this.dataSource = new HylinesDataSource(this.store);
  const entitiesSubscription = this.dataSource.entitySubject.subscribe(res => {
    console.log("carga hylines... "+JSON.stringify(res));
    this.hylinesResult = res.map(hyline=>{return {...hyline, writable:true}});
    console.log("almacenados: "+JSON.stringify(this.hylinesResult));
  });
  this.hylineBreDataDataSource = new HylineBreDatasDataSource(this.store);
  
  const hylineBreDataSubscription = this.hylineBreDataDataSource.entitySubject.subscribe(res=>{
    if(!this.hylineBreDatasResult){
      this.hylineBreDatasResult = [];
     
    }else{
      this.hylineBreDatasResult = res;
      
    }    
  });
  this.hylineHavDataDataSource = new HylineHavDatasDataSource(this.store);
  const hylineHavDataSubscription = this.hylineHavDataDataSource.entitySubject.subscribe(res=>{
    if(!this.hylineHavDatasResult){
      this.hylineHavDatasResult = [];      
    }else{
      this.hylineHavDatasResult = res;
    }    
  });
  this.hylineEMoltDataDataSource = new HylineEMoltDatasDataSource(this.store);
  const hylineEMoltDataSubscription = this.hylineEMoltDataDataSource.entitySubject.subscribe(res=>{
    if(!this.hylineEMoltDatasResult){
      this.hylineEMoltDatasResult = [];      
    }else{
      this.hylineEMoltDatasResult = res;
    }    
  });
  this.hylineLMoltDataDataSource = new HylineLMoltDatasDataSource(this.store);
  const hylineLMoltDataSubscription = this.hylineLMoltDataDataSource.entitySubject.subscribe(res=>{
    if(!this.hylineLMoltDatasResult){
      this.hylineLMoltDatasResult = [];      
    }else{
      this.hylineLMoltDatasResult = res;
    }    
  });
  
  const lastQuerySubscription = this.store.pipe(select(selectHylinesPageLastQuery)).subscribe(res => this.lastQuery = res);
  // Load last query from store
  this.subscriptions.push(lastQuerySubscription);

  // Read from URL itemId, for restore previous state
  const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
    if (params.id) {
      this.restoreState(this.lastQuery, +params.id);
    }

    // First load
      // const firstLoadSub = of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      //   this.loadHylinesList();
      // }); // Remove this line, just loading imitation
    // this.subscriptions.push(firstLoadSub);
  });

  this.subscriptions.push(entitiesSubscription);
  this.subscriptions.push(routeSubscription);
  this.subscriptions.push(birdTypeSub);
  this.subscriptions.push(hylineTypeSub);
  this.subscriptions.push(hylineBreDataSubscription);
  this.subscriptions.push(hylineHavDataSubscription);
  this.subscriptions.push(hylineEMoltDataSubscription);
  this.subscriptions.push(hylineLMoltDataSubscription);

  this.loadBirdTypes();
  this.loadHylineTypes();
}

/**
 * On Destroy
 */
ngOnDestroy() {
  this.subscriptions.forEach(el => el.unsubscribe());
}

loadBirdTypes(){
  this.store.dispatch(new BSGetBirdTypes({}));
}

loadHylineTypes(){
  this.store.dispatch(new HylineGetTypes({}));
}

/**
 * Load Hyline List
 */
loadHylinesList() {
  this.selection.clear();
  const queryParams = new QueryParamsModel(
    this.filterConfiguration(),
    this.sort.direction,
    this.sort.active,
    this.paginator.pageIndex,
    this.paginator.pageSize
  );
  // Call request from server
  console.log("loadHylinesList");
  this.store.dispatch(new HylineGetPage({ page: queryParams }));
  this.selection.clear();
}

/**
 * Load Hyline Breeding Data List
 */
loadHylineBreDataList() {
  
  if(this.selectedHyline){
    const queryParams = new QueryParamsModel(
      {},
      this.breedingSort.direction,
      this.breedingSort.active,
      this.breedingPaginator.pageIndex,
      this.breedingPaginator.pageSize
    );
    // Call request from server
    console.log("loadHylineBreDataList");
    this.store.dispatch(new HylineLoadBreDataPage({hyline:this.selectedHyline, page: queryParams }));
  }
}

/**
 * Load Hyline Haverst Data List
 */
loadHylineHavDataList() {
  if(this.selectedHyline){
    const queryParams = new QueryParamsModel(
      {},
      this.haverstSort.direction,
      this.haverstSort.active,
      this.haverstPaginator.pageIndex,
      this.haverstPaginator.pageSize
    );
    // Call request from server
    console.log("loadHylineHavDataList");
    this.store.dispatch(new HylineLoadHavDataPage({hyline:this.selectedHyline, page: queryParams }));
  }
}

/**
 * Load Hyline Early Molt Data List
 */
loadHylineEMoltDataList() {
  if(this.selectedHyline){
    const queryParams = new QueryParamsModel(
      {},
      this.earlyMoltSort.direction,
      this.earlyMoltSort.active,
      this.earlyMoltPaginator.pageIndex,
      this.earlyMoltPaginator.pageSize
    );
    // Call request from server
    console.log("loadHylineEMoltDataList");
    this.store.dispatch(new HylineLoadEMoltDataPage({hyline:this.selectedHyline, page: queryParams }));
  }
}

/**
 * Load Hyline Late Molt Data List
 */
loadHylineLMoltDataList() {
  if(this.selectedHyline){
    const queryParams = new QueryParamsModel(
      {},
      this.lateMoltSort.direction,
      this.lateMoltSort.active,
      this.lateMoltPaginator.pageIndex,
      this.lateMoltPaginator.pageSize
    );
    // Call request from server
    console.log("loadHylineLMoltDataList");
    this.store.dispatch(new HylineLoadLMoltDataPage({hyline:this.selectedHyline, page: queryParams }));
  }
}

/**
 * Returns object for filter
 */
filterConfiguration(): any {
  const filter: any = {};
  const searchText: string = this.searchInput.nativeElement.value;

  if(searchText != ''){
    filter.hyline = searchText;
  }

  if(this.filterBirdType!=''){
    filter.bird_type_id = this.filterBirdType;
  }

  if(this.filterHylineType!=''){
    filter.hyline_type_id = this.filterHylineType;
  }

  return filter;
}

/**
 * Restore state
 *
 * @param queryParams: QueryParamsModel
 * @param id: number
 */
restoreState(queryParams: QueryParamsModel, id: number) {

  if (!queryParams.filter) {
    return;
  }

  if ('birdType' in queryParams.filter) {
    this.filterBirdType = queryParams.filter.bird_type_id.toString();
  }

  if ('hylineType' in queryParams.filter) {
    this.filterHylineType = queryParams.filter.hyline_type_id.toString();
  }
}

/** ACTIONS */
/**
 * Delete hyline register
 *
 * @param _item: Hyline
 */
deleteHyline(_item: Hyline) {
  const _title = 'Eliminar hyline';
  const _description = 'Esta usted seguro de que desea eliminar permanentemente esta hyline?';
  const _waitDesciption = 'La hyline se esta eliminando...';
  const _deleteMessage = `La hyline ha sido eliminada`;

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  const dialogSub = dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    this.store.dispatch(new HylineDelete({ hyline: _item}));
    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    this.loadHylinesList();
  });
  this.subscriptions.push(dialogSub);
}

/**
 * Delete pavilions
 */
deleteHylines() {
  // const _title = 'Products Delete';
  // const _description = 'Are you sure to permanently delete selected products?';
  // const _waitDesciption = 'Products are deleting...';
  // const _deleteMessage = 'Selected products have been deleted';

  // const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  // dialogRef.afterClosed().subscribe(res => {
  // 	if (!res) {
  // 		return;
  // 	}

  // 	const idsForDeletion: number[] = [];
  // 	// tslint:disable-next-line:prefer-for-of
  // 	for (let i = 0; i < this.selection.selected.length; i++) {
  // 		idsForDeletion.push(this.selection.selected[i].id);
  // 	}
  // 	this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
  // 	this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
  // 	this.selection.clear();
  // });
}

/**
 * Fetch selected products
 */
fetchHylines() {
  // tslint:disable-next-line:prefer-const
  let messages = [];
  this.selection.selected.forEach(elem => {
    messages.push({
      text: `${elem.name} ${elem.is_active?"Activo":"Inactivo"} de tipo ${elem.hyline_type_name} con aves ${elem.bird_type_name}`,
      id: elem.id,
    });
  });
  this.layoutUtilsService.fetchElements(messages);
}

/**
 * Update status dialog
 */
updateStatusForHylines() {
  // const _title = 'Update status for selected bird stocks';
  // const _updateMessage = 'Status has been updated for selected bird stocks';
  // const _statuses = [{ value: 0, text: 'Selling' }, { value: 1, text: 'Sold' }];
  // const _messages = [];

  // this.selection.selected.forEach(elem => {
  //   _messages.push({
  //     text: `${elem.name} ${elem.is_active?"Active":"Inactive"} of type ${elem.hyline_type_name} with ${elem.bird_type_name} birds`,
  //     id: elem.id,
  //   });
  // });

  // const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
  // const dialogSub = dialogRef.afterClosed().subscribe(res => {
  //   if (!res) {
  //     this.selection.clear();
  //     return;
  //   }

  //   // this.store.dispatch(new BSEdit({
  //   //   birdStocks: this.selection.selected
  //   // }));

  //   this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
  //   this.selection.clear();
  // });
  // this.subscriptions.push(dialogSub);
}

updateHylineStatus(hyline:Hyline){
  console.log(JSON.stringify(hyline));
  console.log("changed status");
  const chyline = {...this.hylinesResult.find((h)=>h.id == hyline.id)};
  console.log("found hyline: "+JSON.stringify(chyline));
  
  chyline.is_active = !chyline.is_active;
  chyline.write_uid = this.user.id;
  const updateHyline: Update<Hyline> = {
    id: hyline.id,
    changes: chyline
  };
  this.store.dispatch(new HylineEdit({hyline:chyline, partialHyline:updateHyline}));
}

/**
 * Redirect to edit page
 *
 * @param id: any
 */
editHyline(id) {
  this.router.navigate(['../hyline/edit', id], { relativeTo: this.activatedRoute });
}

createHyline() {
  this.router.navigate(['../hyline/add'], { relativeTo: this.activatedRoute });
}

selectHyline(row, hyline:Hyline){
  // console.log(JSON.stringify(row));
  // console.log();
  // console.log("hyline selected");
  if(this.selection.isSelected(row)){
    this.selectedHyline = hyline;
    this.loadHylineBreDataList();
    this.loadHylineHavDataList();
    this.loadHylineEMoltDataList();
    this.loadHylineLMoltDataList();
  }else if(this.selectedHyline.id == hyline.id){
    this.selectedHyline = null;
  }
}

/**
 * Check all rows are selected
 */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.hylinesResult.length;
  return numSelected === numRows;
}

/**
 * Selects all rows if they are not all selected; otherwise clear selection
 */
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
  } else {
    this.hylinesResult.forEach(row => this.selection.select(row));
  }
}

/* UI */
/**
 * Returns status string
 *
 * @param status: number
 */
getItemStatusString(status: number = 0): string {
  switch (status) {
    case 0:
      return 'Selling';
    case 1:
      return 'Sold';
  }
  return '';
}

/**
 * Returns CSS Class by status
 *
 * @param status: number
 */
getItemCssClassByStatus(status: number = 0): string {
  switch (status) {
    case 0:
      return 'success';
    case 1:
      return 'metal';
  }
  return '';
}

/**
 * Rerurns condition string
 *
 * @param condition: number
 */
getItemConditionString(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'New';
    case 1:
      return 'Used';
  }
  return '';
}

/**
 * Returns CSS Class by condition
 *
 * @param condition: number
 */
getItemCssClassByCondition(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'accent';
    case 1:
      return 'primary';
  }
  return '';
}

}