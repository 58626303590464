import { Component, OnInit,OnDestroy,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { EventMqttService } from '../../../../../core/production/_services/EventMqttService';
import { IMqttMessage } from "ngx-mqtt";
import {PavilionHistoryService} from '../../../../../core/production/_services/pavilionHistory.service';
import {formatDate } from '@angular/common';
import {PavilionRealtimeService} from '../../../../../core/production/_services/pavilionRealtime.service';
import { filter, map, startWith, tap } from 'rxjs/operators';
@Component({
  selector: 'kt-realtime-dashboard',
  templateUrl: './realtime-dashboard.component.html',
  styleUrls: ['./realtime-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealtimeDashboardComponent implements OnInit, OnDestroy{
  pavilionDatas : pavilionData [] = [];
  pavilionTotal : pavilionData ;
  private tempID: string;
  private acumID: string;
  private velID: string;
  private totID: string;
  subscriptions: Subscription[] = [];
  tmp1 : number = 0;
  tmp2 : number = 0
  tmp3 : number = 0;
  tro_harvest : number = 0;
  trp_harvest :  number = 0;
  total_harvest : number = 0;
  total_birds : number = 0;
  total_deads : number = 0;
  vel_prom : number = 0;
  birds_by_type;
  prom_age = 0;
  prom_temp = 0;
  prom_performance = 0;
  prom_hyline_performance = 0;
  prom_dead_bird = 0;
  t_bird_by_class = [
    {
      bird_type_id : 1,
      bird_type_name : 'Brown',
      t_bird_amount : 0
    },
    {
      bird_type_id : 2,
      bird_type_name : 'Libre',
      t_bird_amount : 0
    },
    {
      bird_type_id : 3,
      bird_type_name : 'Omega',
      t_bird_amount : 0
    },
    {
      bird_type_id : 4,
      bird_type_name : 'W36',
      t_bird_amount : 0
    },
];
  t_bird_by_pavilion = [];
  hyline_by_floor : any = [];
  constructor(
    private readonly eventMqtt: EventMqttService,
    private cd: ChangeDetectorRef,
    private pavilionHistoryService : PavilionHistoryService,
    private pavilionRealtimeService: PavilionRealtimeService
    ) {
    var iiot = -1;
    for (let index = 8; index < 22; index++) {
      this.pavilionDatas.push({
        pavilion_id : index,
        iiot_code : iiot++,
        age : 0,
        bird_status_id : 2,
        b_amount_pavilion : 0,
        c_dead_bird_daily : 0,
        categories : '',
        p_dead_bird_daily : 0.0,
        p_performance : 0.0,
        p_hyline_performance_comp : 0.0,
        p_hyline_teo : 0.0,
        pavilion_harvest_mode : 'A',
        pavilion_harvest_state : 'R',
        pavilion_t : 0,
        harvest : 0
            })      
          
      if (iiot == 0)
        this.pavilionDatas[0].iiot_code = 14;
      else if (iiot == 1)
        this.pavilionDatas[1].iiot_code = 13;
    }
    this.tempID = 'TEMP'
    this.acumID = 'ACUM';
    this.velID = 'VEL';
    this.totID = 'TOT';
    this.subscribeToTopic();
    this.getHarvestByClass();
   }
  teo_sum = 0;
  age_sum = 0;
  ngOnInit() {
    this.getHarvestByClass();
    this.pavilionRealtimeService.production_realtime_prom_age_pavilion().subscribe(res => {
      var rsp :  any; rsp = res;
      if (res){
        //console.log('HERE WE GO',res);
        this.prom_age = rsp[0].prom_avg;
        this.prom_temp = rsp[0].temp_avg;
        //this.prom_dead_bird = rsp[0].deads_avg;
      }
    })
    this.pavilionHistoryService.get_production_realtime_total_bird_by_pavilion().pipe(
      tap((resp:any)=>{console.log("response realtime total bird: ");console.dir(resp);}),
      filter((resp:any)=>resp.length>0),
      map(rows=>{ //Agregado por piso, se preocupa de agrupar multiples generaciones por piso
        const birdPerFloorObj = {};
        rows.forEach(row=>{
          if(birdPerFloorObj[row.iiot_code]==undefined){
            birdPerFloorObj[row.iiot_code] = {};
          }
          if(birdPerFloorObj[row.iiot_code][row.floor_id]==undefined){
            birdPerFloorObj[row.iiot_code][row.floor_id] = {
              age_in_weeks: 0,
              c_bird_amount: 0,
              category_name: row.category_name,
              iiot_code: row.iiot_code,
              bird_status_id: row.bird_status_id
            }
          }
          if(birdPerFloorObj[row.iiot_code][row.floor_id].c_bird_amount < Number(row.c_bird_amount)){
            birdPerFloorObj[row.iiot_code][row.floor_id].age_in_weeks = row.age_in_weeks;
            birdPerFloorObj[row.iiot_code][row.floor_id].category_name = row.category_name
          }
          birdPerFloorObj[row.iiot_code][row.floor_id].c_bird_amount += Number(row.c_bird_amount);
        });
        const birdPerIiotCode = Object.keys(birdPerFloorObj)
          .sort((a,b)=>Number(a)-Number(b))
          .map(key=>birdPerFloorObj[key]);
        const birdPerFloor = birdPerIiotCode.map(row=>
          Object.keys(row)
          .sort((a,b)=>Number(a)-Number(b))
          .map(key=>row[key])
        ).reduce((prev, curr)=>prev.concat(curr));
        console.log("bird per floor: ");console.dir(birdPerFloor);
        return birdPerFloor
      }),
      map(rows=>{//Agregado por pabellón
        const birdPerPavObj = {};
        rows.forEach(row=>{
          if(birdPerPavObj[row.iiot_code]==undefined){
            birdPerPavObj[row.iiot_code] = {
              age_in_weeks: 0,
              c_bird_amount: 0,
              floor_id: row.floor_id,
              category_name: row.category_name,
              iiot_code: row.iiot_code,
              bird_status_id: row.bird_status_id
            }
          }
          if(birdPerPavObj[row.iiot_code].c_bird_amount < Number(row.c_bird_amount)){
            birdPerPavObj[row.iiot_code].age_in_weeks = row.age_in_weeks;
            birdPerPavObj[row.iiot_code].category_name = row.category_name
          }
          birdPerPavObj[row.iiot_code].c_bird_amount += Number(row.c_bird_amount);
        });
        const birdPerPav = Object.keys(birdPerPavObj).sort((a,b)=>Number(a)-Number(b)).map(key=>birdPerPavObj[key]);
        birdPerPav.forEach(pav=>{
          pav['categories'] = rows.filter(row=>row.iiot_code == pav.iiot_code).map(row=>row.category_name).join(', ');
        })
        console.log("bird per pav: ");console.dir(birdPerPav);
        return birdPerPav
      })
    ).subscribe((rows:any) => {
      console.log("production realtime total bird by pavilion aftere aggregations rows: ");console.dir(rows);
      if (rows.length > 0){
        rows.forEach(row => {
          var pavData = this.pavilionDatas.find(e => e.iiot_code == row.iiot_code)  
          if (pavData){
            pavData.b_amount_pavilion = Number(row.c_bird_amount)
            pavData.age = row.age_in_weeks
            pavData.bird_status_id = row.bird_status_id
            //if (pavData.age > 0)
            //this.prom_age = Number(Number(this.prom_age) + Number(pavData.age)/14)
            pavData.categories = row.categories;
            this.total_birds = Number(this.total_birds) + Number(row.c_bird_amount);
            this.cd.markForCheck();
            this.fetchData();
          }
          // if (pavData){
          //   pavData.b_amount_pavilion = Number(pavData.b_amount_pavilion) + Number(element.c_bird_amount)
          //   pavData.age = element.age_in_weeks
          //   pavData.bird_status_id = element.bird_status_id
          //   //if (pavData.age > 0)
          //   //this.prom_age = Number(Number(this.prom_age) + Number(pavData.age)/14)
          //   pavData.categories = pavData.categories + element.category_name + ', '
          //   this.total_birds = Number(this.total_birds) + Number(element.c_bird_amount);
          //   this.cd.markForCheck();
          // }
        });                
      }
      console.log("pavdata: ");console.dir(this.pavilionDatas);
    })
    
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
  }

  fetchData(){
    this.pavilionRealtimeService.pavilion_last_realtime_temperature().subscribe(res => {
      var rsp :  any; rsp = res;
      if (rsp.rows.length >0){        
        //console.table(rsp)
        rsp.rows.forEach(element => {
          var tmp = this.pavilionDatas.find(e => e.iiot_code == element.iiot_code)  
          if (tmp){
            tmp.pavilion_t = element.temp
            this.cd.markForCheck();
          }
        });
      }
    })
    
    this.pavilionRealtimeService.pavilion_realtime_deads().subscribe(res => {
      var rsp :  any; rsp = res;
      if (rsp.rows.length >0){        
        //console.table(rsp.rows)
        // console.log("TMP TABLE DAILY DEADS: ");console.dir(this.pavilionDatas);
        // console.dir(rsp.rows);
        rsp.rows.forEach(element => {
          var tmp = this.pavilionDatas.find(e => e.iiot_code == element.iiot_code)  
          if (tmp){
            tmp.c_dead_bird_daily = element.c_dead_bird
            this.total_deads = Number(this.total_deads) + Number(element.c_dead_bird)
            // console.log(`${element.c_dead_bird} * 100 / (${tmp.b_amount_pavilion} + ${element.c_dead_bird})`)
            if (!isNaN(element.c_dead_bird) && !isNaN(tmp.b_amount_pavilion))
              tmp.p_dead_bird_daily = (Number(element.c_dead_bird)*100/(Number(tmp.b_amount_pavilion)+Number(element.c_dead_bird)))
            
            //if (element.c_dead_bird > 0)
            //  this.prom_dead_bird = Number(this.prom_dead_bird) + Number(Number(element.c_dead_bird)/this.total_birds)
            //if ((this.total_deads) > 0 && (this.total_birds > 0))
            //  this.prom_dead_bird = this.total_deads/(this.total_deads+this.total_birds)
            //console.log(`TOTAL DEADS: ${this.total_deads},${this.prom_dead_bird}`);
            if (tmp.iiot_code == 12)
              this.prom_dead_bird = (this.total_deads/(this.total_deads+this.total_birds))*100
            this.cd.markForCheck();
          }            
        });
      }
    })
    this.pavilionRealtimeService.pavilion_hyline_performance().subscribe(res => {
      this.hyline_by_floor = res;
      //console.table(this.hyline_by_floor);
      this.hyline_by_floor.forEach(element => {
        var pav_data = this.pavilionDatas.find(e => e.iiot_code == element.iiot_code)
        if (pav_data)
          pav_data.p_hyline_teo = element.sum
          if (element.sum > 0)
            this.teo_sum = Number(this.teo_sum) + Number(element.sum)
      });
      
    })
    
    
    this.get_prom_velocity();
    this.getPavilionsLatestHarvest();
  }
  get_prom_velocity(){
    this.pavilionRealtimeService.get_prom_vel().subscribe(res => {
      var result: any; result = res;
      this.vel_prom = res[0].gral_vel_prom
    });
    this.pavilionRealtimeService.pavilion_last_realtime_vel_mode().subscribe(res => {
      var result: any; result = res;
      if (result.rows.length >0){       
        result.rows.forEach(element => {
          var pav_data = this.pavilionDatas.find(e => e.iiot_code == element.iiot_code) 
          if (pav_data){
            if (element.modulation == "1")
              pav_data.pavilion_harvest_mode = 'A'
            else
              pav_data.pavilion_harvest_mode = 'M'
            this.cd.markForCheck();
          }
        })
       }
    });
  }

  async getHarvestByClass(){
    this.pavilionHistoryService.get_production_realtime_total_bird_by_type().subscribe(res => {
      this.t_bird_by_class.forEach(element => {
        element.t_bird_amount = 0;
      })
      var rsp :  any; rsp = res;
      console.table(rsp);
      rsp.forEach(element => {
        var tmp = this.t_bird_by_class.find(e => e.bird_type_id == element.bird_type_id)  
        if (tmp)
          tmp.t_bird_amount = Number(tmp.t_bird_amount) + Number(element.harvest)      
      });
      this.cd.markForCheck();
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }  
  private subscribeToTopic() {
    //if (this.total_deads > 0)
    //  this.prom_dead_bird = this.total_deads/this.total_birds
    this.subscriptions.push(this.eventMqtt.topic(this.tempID).subscribe((data: IMqttMessage) => {
            let item = JSON.parse(data.payload.toString());
            var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)  
            if (pav_data){
              pav_data.pavilion_t = item.Temperatura
              this.cd.markForCheck();
            }
    }));
    
    this.subscriptions.push(this.eventMqtt.topic(this.totID)
    .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        //console.log(item)
        console.log("mqtt tot - item: ");console.dir(item);
        var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon) 
        if (pav_data){
          if (item.Total > 0){
            pav_data.pavilion_harvest_state = 'Y'
            if (pav_data.harvest == item.Total)
              pav_data.pavilion_harvest_state = 'G'
            pav_data.harvest = item.Total;                 
          }
          // pav_data.p_performance = (item.Total/pav_data.b_amount_pavilion)*100;
          //console.log('HERE I AM',pav_data.harvest,pav_data.p_hyline_teo);
          // if (pav_data.harvest > 0)
          //   pav_data.p_hyline_performance_comp = (pav_data.harvest / pav_data.p_hyline_teo)*100
        }
        this.calculatePerformancePerPavilion();
        this.calculateHylinePerformancePerPavilion();

        this.calculateTroHarvest();
        this.calculateTrpHarvest();
        this.calculateTotalHarvest();
        this.calculatePromPerformance();
        this.calculatePromHylinePerformance();
        // if (item.Galpon == "1"){
        //   this.tmp1 = 0, this.tmp2 = 0;
        // }
        // if (item.Galpon == "1" || item.Galpon == "2" || item.Galpon == "6" || item.Galpon == "7" || item.Galpon == "11" || item.Galpon == "12")
        //   this.tmp1 = Number(this.tmp1) + Number(item.Total);
        // if (item.Galpon == "3" || item.Galpon == "4" || item.Galpon == "5" || item.Galpon == "8" || item.Galpon == "9" || item.Galpon == "10"|| item.Galpon == "13"|| item.Galpon == "14")
        //   this.tmp2 = Number(this.tmp2) + Number(item.Total);
        // if (item.Galpon == "14"){
        //   this.tro_harvest = this.tmp1;
        //   this.trp_harvest = this.tmp2;
        //   this.total_harvest = this.tmp1 + this.tmp2;
        //   this.prom_performance = Number(this.total_harvest/this.total_birds)*100
        //   this.prom_hyline_performance = Number(Number(this.total_harvest)/Number(this.teo_sum))*100
        // }
        this.cd.markForCheck();
        this.getHarvestByClass();
    }));
    this.subscriptions.push(this.eventMqtt.topic(this.velID)
    .subscribe((data: IMqttMessage) => {
        
        let item = JSON.parse(data.payload.toString());
        var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)  
        if (pav_data){
          if (item.Modulador == "1")
            pav_data.pavilion_harvest_mode = 'A'
          else
            pav_data.pavilion_harvest_mode = 'M'
          this.cd.markForCheck();
        }
    }));
  }

  calculatePerformancePerPavilion(){
    this.pavilionDatas.forEach(pav=>{
      pav.p_performance = (Number(pav.harvest)/Number(pav.b_amount_pavilion))*100;
    })    
  }

  calculateHylinePerformancePerPavilion(){
    this.pavilionDatas.forEach(pav=>{
      if (pav.harvest > 0 && pav.p_hyline_teo != 0 && pav.p_hyline_teo!=null){
        pav.p_hyline_performance_comp = (Number(pav.harvest) / pav.p_hyline_teo)*100
      }
    }) 
    // console.log("pavilion datas: ");console.dir(this.pavilionDatas);  
  }

  getPavilionsLatestHarvest(){
    this.subscriptions.push(
      this.pavilionRealtimeService.latestHarvestPerPavilion().subscribe(res => {
        console.log("last pavilion harvest response:");console.dir(res);
        var rsp:any;rsp=res;
        console.log("listado de pabellones: ");console.dir(this.pavilionDatas)
        rsp.forEach(element => {
          var pav_data = this.pavilionDatas.find(e => e.iiot_code == element.iiot_code)
          if(pav_data!=undefined){
            pav_data.harvest = Number(element.harvest)
          }else{
            console.log("no se encuentra pabellon ", element.iiot_code);
            console.dir(element);
          }
        });
        const perPavilion = {};
        rsp.forEach(row=>{
          if(perPavilion[row.iiot_code] == undefined)perPavilion[`${row.iiot_code}`] = 0;
          console.log("acum harvest ", row.harvest, " for pavilion ", row.iiot_code)
          perPavilion[row.iiot_code] += Number(row.harvest);
        })
        console.log("harveset per pavilion http req: ");console.dir(perPavilion);
        this.calculatePerformancePerPavilion();
        this.calculateHylinePerformancePerPavilion();
        this.calculateTroHarvest();
        this.calculateTrpHarvest();
        this.calculateTotalHarvest();
        this.calculatePromPerformance();
        this.calculatePromHylinePerformance();
        this.cd.detectChanges();
        console.log("total harvest by http req: ", this.total_harvest);
      })
    );
  }

  calculateTroHarvest = () => {
    const troGalponList = ["1", "2", "6", "7", "11","12"];
    this.tro_harvest = this.pavilionDatas.reduce((prev, curr, idx)=>{
        if(troGalponList.indexOf(`${curr.iiot_code}`)!=-1) return prev+Number(curr.harvest);
        return prev;
      }, 0);
    return this.tro_harvest;
  }
  calculateTrpHarvest = () => {
    const trpGalponList = ["3", "4", "5", "8", "9", "10", "13", "14"];
    this.trp_harvest = this.pavilionDatas.reduce((prev, curr, idx)=>{
      if(trpGalponList.indexOf(`${curr.iiot_code}`)!=-1) return prev+Number(curr.harvest);
      return prev;
    }, 0);
    return this.trp_harvest;
  }

  calculateTotalHarvest(){
    this.total_harvest = this.trp_harvest + this.tro_harvest;
  }

  calculatePromPerformance(){
    this.prom_performance = Number(this.total_harvest/this.total_birds)*100
  }

  calculatePromHylinePerformance(){
    this.prom_hyline_performance = Number(Number(this.total_harvest)/Number(this.teo_sum))*100;
  }

  getItemCssClassBySign(item): string {
    var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.iiot_code) 
    if (pav_data){
      if (pav_data.pavilion_harvest_state == 'R')
        return 'red-sign'
      else if (pav_data.pavilion_harvest_state == 'Y')
        return 'yellow-sign'
      else if (pav_data.pavilion_harvest_state == 'G')
        return 'green-sign'
      else
        return 'red-sign'
    }else
      return 'red-sign'
  }
  getItemCssBirdClass(item): string {
    if (item.bird_status_id == 2)
      return ''
    else if (item.bird_status_id == 3)
      return 'yellow-sign'
    else if (item.bird_status_id == 4)
      return 'red-sign'
    else 
      return ''
  }

}
interface pavilionData {
  pavilion_id ?: number,
  iiot_code ?: number,
  age ?: number,                    //one read
  bird_status_id ?: number,
  categories ?: string,                 //one read
  b_amount_pavilion ?: number,   // online
  c_dead_bird_daily ?: number,      // online
  p_dead_bird_daily ?: number,      // check how to calculate
  pavilion_t ?: number,             // online
  pavilion_harvest_mode ?: string,  // online
  pavilion_harvest_state ?: string, /// check how to calculate
  p_performance ?: number,     // check how to calculate
  p_hyline_performance_comp ?: number,     // check how to calculate
  harvest ?: number,
  p_hyline_teo ?:number,
}
