import { BaseModel } from '../../_base/crud';

export class VelocityData extends BaseModel {
    pavilion_id : number;
    pavilion_name: string;
    iiot_code : number;
    pavilion_harvest_mode :  string;
    pavilion_velocity_mode :  string;
    harvest : number;
    velocity : number;
    working_floor : string;

    clear(){
        this.pavilion_id = 0;
        this.iiot_code = 0;
        this.harvest = 0;
        this.pavilion_harvest_mode = 'A';
        this.pavilion_velocity_mode = 'M';
        this.velocity = 0;
        this.working_floor = '';
        this.pavilion_name = '';
    } 
}