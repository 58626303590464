import { BaseModel } from '../../_base/crud';

export class WaterConsumption extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type_id: number;
    pavilion_type_name: string;
    water_measured_level: number;
    water_level: number;
    clear(){
        this.id                     = undefined;
        this.create_uid             = undefined;
        this.write_date             = undefined;
        this.write_uid              = undefined;
        this.create_date            = undefined;
        this.water_measured_level   = undefined;
        this.company_id             = undefined;
        this.pavilion_id            = undefined;
        this.pavilion_name          = "";
        this.pavilion_type_id       = undefined;
        this.pavilion_type_name     = "";
        this.water_level            = undefined
    }
}