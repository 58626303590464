// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, withLatestFrom, filter, tap } from 'rxjs/operators';
import { Observable, defer, of, forkJoin } from 'rxjs';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, select, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { AuthService } from '../../../core/auth/_services';
// State
import { AppState } from '../../../core/reducers';
import {    
    AllProfilesLoaded,
    AllProfilesRequested,
    ProfileActionTypes,
    ProfilesPageRequested,
    ProfilesPageLoaded,
    //ProfileCreated,
    //ProfileDeleted,
    ProfileUpdated,
    //ProfileOnServerCreated,
    ProfilesActionToggleLoading,
    ProfilesPageToggleLoading
} from '../_actions/profile.actions';
import { allProfilesLoaded } from '../_selectors/profile.selectors';

@Injectable()
export class ProfileEffects {
    showPageLoadingDistpatcher = new ProfilesPageToggleLoading({ isLoading: true });
    hidePageLoadingDistpatcher = new ProfilesPageToggleLoading({ isLoading: false });

    showActionLoadingDistpatcher = new ProfilesActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ProfilesActionToggleLoading({ isLoading: false });

    @Effect()
    loadAllProfiles$ = this.actions$
        .pipe(
            ofType<AllProfilesRequested>(ProfileActionTypes.AllProfilesRequested),
            withLatestFrom(this.store.pipe(select(allProfilesLoaded))),
            filter(([action, isAllProfilesLoaded]) => !isAllProfilesLoaded),
            mergeMap(() => this.auth.getAllProfiles()),
            map(profiles => {
                return new AllProfilesLoaded({profiles});
            })
          );

    @Effect()
    loadProfilesPage$ = this.actions$
        .pipe(
            ofType<ProfilesPageRequested>(ProfileActionTypes.ProfilesPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.auth.findProfiles(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ProfilesPageLoaded({
                    profiles: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );

    /*@Effect()
    deleteProfile$ = this.actions$
        .pipe(
            ofType<ProfileDeleted>(ProfileActionTypes.ProfileDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showActionLoadingDistpatcher);
                    return this.auth.deleteProfile(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
*/
    @Effect()
    updateProfile$ = this.actions$
        .pipe(
            ofType<ProfileUpdated>(ProfileActionTypes.ProfileUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.auth.updateProfile(payload.profile);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
/*
    @Effect()
    createProfile$ = this.actions$
        .pipe(
            ofType<ProfileOnServerCreated>(ProfileActionTypes.ProfileOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.auth.createProfile(payload.Profile).pipe(
                    tap(res => {
                        this.store.dispatch(new ProfileCreated({ Profile: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );*/

    constructor(private actions$: Actions, private auth: AuthService, private store: Store<AppState>) { }
}
