// Angular
import { Component, HostListener, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';

@Component({
	selector: 'kt-user-profile3',
	templateUrl: './user-profile3.component.html',
})
export class UserProfile3Component implements OnInit {
	// Public properties
	user$: Observable<User>;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */

	// Listen to scroll event to change logout button style
	scrolledH = 1;
	@HostListener('window:scroll', ['$event'])
	onWindowScroll($event) {
		const numb = window.scrollY;
		if (numb >= 75){
		this.scrolledH = 0;
		}
		else {
		this.scrolledH = 1;
		}
	}

	constructor(private store: Store<AppState>) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.store.pipe(select(currentUser));
	}

	/**
	 * Log out
	 */
	logout() {
		//console.log(`Leavin SITE`);
		var galpones = [];
		for (let index = 0; index < 15; index++) {
			galpones.push({
				id : String(index),
				globlal_count : 0,
				reset_count : 0,
				old_global_count : 0,
				is_reseted : 0,
			})
			}
		localStorage.setItem("galpones", JSON.stringify(galpones));
		this.store.dispatch(new Logout());
	}
}
