// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { Alarm, AlarmType } from '../_models/alarm.model';

//const API_AlarmS_URL = 'api/alarms';

import {BASE_URL,API_PERMISSION_URL,API_ALARMS_URL} from '../../connection.data';
import { map } from 'rxjs/operators';
// Real REST API
@Injectable()
export class AlarmService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new Alarm to the server
	createAlarm(alarm): Observable<Alarm> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<Alarm>(API_ALARMS_URL, alarm, { headers: httpHeaders });
	}

	// READ
	getAllAlarms(): Observable<Alarm[]> {
		return this.http.get<Alarm[]>(API_ALARMS_URL);
	}

	getPage(query:QueryParamsModel): Observable<QueryResultsModel>{
		return this.http.post<QueryResultsModel>(API_ALARMS_URL+ `/page`, query);
	}

	getAlarmById(alarmId: number): Observable<Alarm> {
		return this.http.get<Alarm>(API_ALARMS_URL + `/${alarmId}`);
	}

	getAlarmTypes(): Observable<AlarmType[]> {
		return this.http.get<any>(API_ALARMS_URL + `/types`).pipe(
			map((resp)=>resp.rows)
		);
	}

	getAlarmStatusTypes(): Observable<AlarmType[]> {
		return this.http.get<any>(API_ALARMS_URL + `/status/types`).pipe(
			map((resp)=>resp.rows)
		);
	}

	// Server should return filtered/sorted result
	findAlarms(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
			// Note: Add headers if needed (tokens/bearer)
			const httpHeaders = this.httpUtils.getHTTPHeaders();
			const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

			const url = API_ALARMS_URL + '/findAlarms';
			return this.http.post<QueryResultsModel>(url, {
				headers: httpHeaders,
				params:  httpParams
			});
	}

	// UPDATE => PUT: update the Alarm on the server
	updateAlarm(alarm: Alarm): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_ALARMS_URL, alarm, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForAlarm(alarms: Alarm[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			AlarmsForUpdate: alarms,
			newStatus: status
		};
		const url = API_ALARMS_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the Alarm from the server
	deleteAlarm(alarmId: number): Observable<Alarm> {
		const url = `${API_ALARMS_URL}/${alarmId}`;
		return this.http.delete<Alarm>(url);
	}

	deleteAlarms(ids: number[] = []): Observable<any> {
		const url = API_ALARMS_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { alarmIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
