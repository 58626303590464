import { BaseModel } from '../../_base/crud';

export class Hyline extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    name: string;
    hyline_type_id: number;
    hyline_type_name: string;
    bird_type_id: number;
    bird_type_name: string;
    is_active: boolean;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.company_id         = undefined;
        this.name               = "";
        this.hyline_type_id     = undefined;
        this.hyline_type_name   = "";
        this.bird_type_id       = undefined;
        this.bird_type_name     = "";
        this.is_active          = undefined;
    }
}

export class HylineType extends BaseModel{
    id: number;
    name: string;
    clear(){
        this.id     = undefined;
        this.name   = "";
    }
}

export class HylineDataType extends BaseModel{
    id: number;
    name: string;
    clear(){
        this.id     = undefined;
        this.name   = "";
    }
}

export class HylineData extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    name: string;
    hyline_type_id: number;
    hyline_type_name: string;
    hyline_data_type_id: number;
    hyline_data_type_name: string;
    bird_type_id: number;
    bird_type_name: string;
    age_in_weeks: number;
    weight_min: number;
    weight_max: number;
    p_weight_uni: number;
    p_acc_mortality: number;
    c_nourishment_daily: number;
    c_water_daily: number;
    p_harvest: number;
    p_acc_harvest:number;

    clear(){
        this.id                     = undefined;
        this.create_uid             = undefined;
        this.write_date             = undefined;
        this.write_uid              = undefined;
        this.create_date            = undefined;
        this.company_id             = undefined;
        this.name                   = "";
        this.hyline_type_id         = undefined;
        this.hyline_type_name       = "";
        this.bird_type_id           = undefined;
        this.bird_type_name         = "";
        this.hyline_data_type_id    = undefined;
        this.hyline_data_type_name  = "";
        this.age_in_weeks           = undefined;
        this.weight_max             = undefined;
        this.weight_min             = undefined;
        this.p_weight_uni           = undefined;
        this.p_acc_harvest          = undefined;
        this.p_acc_mortality        = undefined;
        this.c_nourishment_daily    = undefined;
        this.c_water_daily          = undefined;
        this.p_harvest              = undefined;
    }
}