// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, concat, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, tap, debounceTime, filter } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	GrainType,
	GrainTypeGetAll,
	GrainTypesDataSource,
	Pavilion,
	PavilionGetAll,
	PavilionsDataSource,
	selectLastCreatedSiloId, 
	selectSiloById, 
	Silo, 
	SiloAdd, 
	SiloEdit
} from '../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-silos-edit',
  templateUrl: './silos-edit.component.html',
  styleUrls: ['./silos-edit.component.scss']
})
export class SilosEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('wizard', {static: true}) el: ElementRef;
	// Public properties
	silo: Silo;
	siloId$: Observable<number>;
	oldSilo: Silo;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	siloForm: FormGroup;
	hasFormErrors = false;
  	filteredGrainTypes: Observable<GrainType[]>;
  	filteredPavilions: Observable<Pavilion[]>;
  	grainTypesDataSource: GrainTypesDataSource;
  	pavilionsDataSource: PavilionsDataSource;
	grainTypesResult: GrainType[];
	pavilionsResult: Pavilion[];
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	_pavilionInstance: NgbTypeahead;
	@ViewChild('pavilionTA', {static: false}) set pavilionInstance(content: NgbTypeahead){
		if(content){
			this._pavilionInstance = content;
		}
	};
	pavilionFocus$ = new Subject<string>();
	pavilionClick$ = new Subject<string>();

	searchPavilion = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.pavilionClick$.pipe(filter(() => !this._pavilionInstance.isPopupOpen()));
		const inputFocus$ = this.pavilionFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching pavilion with "+text);
				this.selectedPavilion = this.findPavilion(text)}),
			map(text => this.filterPavilion(text)),
			tap(pavilions=>console.log("pavilions found: "+JSON.stringify(pavilions)))
		);
	}
	pavilionFormatter = (pavilion: Pavilion) => pavilion.name;

	_grainTypeInstance: NgbTypeahead;
	@ViewChild('grainTypeTA', {static: false}) set grainTypeInstance(content: NgbTypeahead){
		if(content){
			this._grainTypeInstance = content;
		}
	};
	grainTypeFocus$ = new Subject<string>();
	grainTypeClick$ = new Subject<string>();

	searchGrainType = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.grainTypeClick$.pipe(filter(() => !this._grainTypeInstance.isPopupOpen()));
		const inputFocus$ = this.grainTypeFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching grainType with "+text);
				this.selectedGrainType = this.findGrainType(text)}),
			map(text => this.filterGrainType(text)),
			tap(grainTypes=>console.log("grainTypes found: "+JSON.stringify(grainTypes)))
		);
	}
	grainTypeFormatter = (grainType: GrainType) => grainType.name;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;
	private selectedGrainType: GrainType = null;
	private selectedPavilion: Pavilion = null;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	ngAfterViewInit(): void {
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	/**
	 * On init
	 */
	ngOnInit() {

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("silos edit component: usuario no logueado");
		});
		    
    	// Init DataSource
		this.grainTypesDataSource = new GrainTypesDataSource(this.store);
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const pavilionSub = this.pavilionsDataSource.entitySubject.subscribe(pavilions=>{
			this.pavilionsResult = pavilions;
			this.cdr.detectChanges();
		})
		const entitiesSubscription = this.grainTypesDataSource.entitySubject.pipe(
			distinctUntilChanged()
		).subscribe(res => {
			this.grainTypesResult = res;
			this.activatedRoute.params.subscribe(params => {
				const id = params.id;
				if (id && id > 0) {
	
					const sub = this.store.pipe(
						select(selectSiloById(id))
					).subscribe(result => {
						// if (!result) {
						// 	this.loadProducFromService(id);
						// 	return;
						// }
						console.log("Silo retrieved from store");
						this.loadSilo(result);
						this.loadWizzard();
						this.siloForm.controls.pavilion.patchValue(result.pavilion_name);
						this.cdr.detectChanges();
					});
					this.subscriptions.push(sub);
				} else {
					console.log("default silo");
					const newSilo = new Silo();
					newSilo.clear();
					this.loadSilo(newSilo);
					this.loadWizzard();
				}
			});
		});
		this.subscriptions.push(entitiesSubscription);
		this.subscriptions.push(authSubscription);
		this.subscriptions.push(pavilionSub);
		this.store.dispatch(new GrainTypeGetAll({}));

		

		this.loadPavilions();
		this.cdr.detectChanges();
	}

	loadWizzard(){
		// sticky portlet header
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	loadPavilions(){
		this.store.dispatch(new PavilionGetAll({}));
	}

	loadSilo(silo, fromService: boolean = false) {
		if (!silo) {
			this.goBack('');
		}
		this.silo = {...silo};
		this.selectedGrainType = new GrainType();
		this.selectedGrainType.clear();
		this.selectedGrainType.id = silo.grain_type_id;
		this.selectedGrainType.name = silo.grain_type;

		this.selectedPavilion = new Pavilion();
		this.selectedPavilion.clear();
		this.selectedPavilion.id = silo.pavilion_id;
		this.selectedPavilion.name = silo.pavilion_name;
		this.selectedPavilion.type = silo.pavilion_type_name;

		this.siloId$ = of(silo.id);
		this.oldSilo = Object.assign({}, silo);
		this.initSilo();
		// if (fromService) {
			this.cdr.detectChanges();
		// }
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init silo
	 */
	initSilo() {
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.silo.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de silos', page: `/plant-configuration/silos` },
				{ title: 'Crear silo', page: `/plant-configuration/silos/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Edit silo');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de silos', page: `/plant-configuration/silos` },
			{ title: 'Editar silo', page: `/plant-configuration/silos/edit`, queryParams: { id: this.silo.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.siloForm = this.formBuilder.group({
			name: [this.silo.name, Validators.required],
			capacity: [this.silo.capacity, [Validators.required, Validators.min(0)]],
			cGrainAmount: [this.silo.c_grain_amount, [Validators.required, Validators.min(0)]],
			pReorder: [this.silo.p_reorder, [Validators.required, Validators.min(0), Validators.max(100)]],
			grainType: [{name:this.silo.grain_type}, Validators.required],
			pavilion: [this.silo.pavilion_name, Validators.required],
		});

		const controls = this.siloForm.controls;
		const nameSub = controls.name.valueChanges.pipe(
			startWith(this.silo.name),
			debounceTime(250)
		).subscribe(x=>{
			this.silo.name = x;
		});
		const capacitySub = controls.capacity.valueChanges.pipe(
			startWith(this.silo.capacity),
			debounceTime(250)
		).subscribe(x=>{
			this.silo.capacity = x;
		});
		const pReorderSub = controls.pReorder.valueChanges.pipe(
			startWith(this.silo.p_reorder),
			debounceTime(250)
		).subscribe(x=>{
			this.silo.p_reorder = x;
		});
		const cGrainAmountSub = controls.cGrainAmount.valueChanges.pipe(
			startWith(this.silo.c_grain_amount),
			debounceTime(250)
		).subscribe(x=>{
			this.silo.c_grain_amount = x;
		});
		this.subscriptions.push(nameSub);
		this.subscriptions.push(capacitySub);
		this.subscriptions.push(pReorderSub);
		this.subscriptions.push(cGrainAmountSub);
		this.cdr.detectChanges();
		// this.filteredGrainTypes = concat(
		// 	of(this.filterGrainType("")),
		// 	this.siloForm.controls.grainType.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterGrainType(val.toString())),
		// 		tap(grainTypes => {
		// 			this.selectedGrainType = null;
		// 			if(grainTypes && grainTypes.length>0){
		// 				let val = this.siloForm.controls.grainType.value;
		// 				let grainTypeCoincidences = grainTypes.filter((grainType)=>grainType.name.toLowerCase()==val.toLowerCase());
		// 				if(grainTypeCoincidences.length>0){
		// 					this.selectedGrainType = grainTypeCoincidences[0];
		// 				}
		// 			}
		// 		})
		// 	)
		// );	
		
		// this.filteredPavilions = concat(
		// 	of(this.filterPavilion("")),
		// 	this.siloForm.controls.pavilion.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterPavilion(val.toString())),
		// 		tap(pavilions => {
		// 			this.selectedPavilion = null;
		// 			if(pavilions && pavilions.length>0){
		// 				let val = this.siloForm.controls.pavilion.value;
		// 				let pavilionCoincidences = pavilions.filter((pavilion)=>pavilion.name.toLowerCase()==val.toLowerCase());
		// 				if(pavilionCoincidences.length>0){
		// 					this.selectedPavilion = pavilionCoincidences[0];
		// 				}
		// 			}
		// 		})
		// 	)
		// );	
	}

	/**
	 * Filter grain type
	 *
	 * @param val: string
	 */
	filterGrainType(val: string): GrainType[] {
		if (this.grainTypesResult){
			return this.grainTypesResult.filter(grainType =>
				grainType.name.toString().toLowerCase().includes(val.toLowerCase()));
		}
    	return [];
	}

	/**
	 * Filter pavilion
	 *
	 * @param val: string
	 */
	filterPavilion(val: string): Pavilion[] {
		if (this.pavilionsResult){
			return this.pavilionsResult.filter(pavilion =>
				pavilion.name.toString().toLowerCase().includes(val.toLowerCase()));
		}
    	return [];
	}

	findPavilion(name:String):Pavilion{
		return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	}

	findGrainType(name:String):GrainType{
		return this.grainTypesResult.filter(grainType=>grainType.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/silos?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/silos', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh silo
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshSilo(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/silos/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.silo = Object.assign({}, this.oldSilo);
		this.createForm();
		this.hasFormErrors = false;
		this.siloForm.markAsPristine();
		this.siloForm.markAsUntouched();
		this.siloForm.updateValueAndValidity();
		this.selectedPavilion = null;
		this.selectedGrainType = null;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.siloForm.controls;
		/** check form */
		if (this.siloForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let editedSilo = this.prepareSilo();

		if (editedSilo.id > 0) {
			this.updateSilo(editedSilo, withBack);
			return;
		}

		this.addSilo(editedSilo, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareSilo(): Silo {
		let pavilion = this.selectedPavilion;
		let grainType = this.selectedGrainType;
		const controls = this.siloForm.controls;
		const silo = new Silo();
		silo.id = this.silo.id;
		silo.name = controls.name.value;
		silo.capacity = controls.capacity.value;
		silo.p_reorder = controls.pReorder.value;
		silo.grain_type_id = grainType.id;
		silo.grain_type = grainType.name;
		silo.pavilion_id = pavilion.id;
		silo.pavilion_name = pavilion.name;
		silo.pavilion_type_name = pavilion.type;
		silo.c_grain_amount = controls.cGrainAmount.value;
		silo._createdDate = this.silo._createdDate;
		silo._updatedDate = this.silo._updatedDate;
		silo._updatedDate = this.typesUtilsService.getDateStringFromDate();
		silo._createdDate = this.silo.id > 0 ? silo._createdDate : silo._updatedDate;
		silo.write_uid = this.user.id;
		silo.company_id = this.user.company_id;
		console.log(JSON.stringify(silo))
		return silo;
	}

	selectPavilion(pavilion:Pavilion){
		this.selectedPavilion = pavilion;
	}

	/**
	 * Add Silo
	 *
	 * @param silo: Silo
	 * @param withBack: boolean
	 */
	addSilo(silo: Silo, withBack: boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new SiloAdd({silo: silo}));
		this.componentSubscriptions = this.store.pipe(
			delay(1000),
			select(selectLastCreatedSiloId)
		).subscribe(newId => {
			if (!newId) {
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Silo ha sido creado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshSilo(true, newId);
			}
		});
	}

	/**
	 * Update silo
	 *
	 * @param silo: Silo
	 * @param withBack: boolean
	 */
	updateSilo(silo: Silo, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateSilo: Update<Silo> = {
			id: silo.id,
			changes: silo
		};

		this.store.dispatch(new SiloEdit({
			partialSilo: updateSilo,
			silo: silo
		}));

		of(undefined).pipe(delay(3000)).subscribe(() => { // Remove this line
			if (withBack) {
				this.goBack(silo.id);
			} else {
				const message = `Silo ha sido actualizado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshSilo(false);
			}
		}); // Remove this line
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear silo';
		if (!this.silo || !this.silo.id) {
			return result;
		}

		result = `Editar silo - ${this.silo.name} ${this.silo.grain_type}, ${this.silo.capacity}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	selectGrainType(grainType:GrainType){
		this.selectedGrainType = grainType;
	}
}
