
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AlarmCfgActions, AlarmCfgActionTypes } from '../_actions/alarmscfg.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AlarmCfgModel } from '../_models/alarmcfg.model';

export interface AlarmCfgsState extends EntityState<AlarmCfgModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    lastCreatedAlarmCfgId: number;
    lastUpdatedAlarmCfgId: number;
    lastDeletedAlarmCfgId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AlarmCfgModel> = createEntityAdapter<AlarmCfgModel>();

export const initialAlarmCfgsState: AlarmCfgsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedAlarmCfgId: undefined,
    lastUpdatedAlarmCfgId: undefined,
    lastDeletedAlarmCfgId: undefined,
    showInitWaitingMessage: true
});

export function AlarmCfgsReducer(state = initialAlarmCfgsState, action: AlarmCfgActions): AlarmCfgsState {
    switch  (action.type) {
        case AlarmCfgActionTypes.AlarmCfgsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedAlarmCfgId: undefined
        };
        break;
        case AlarmCfgActionTypes.AlarmCfgsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        break;
        case AlarmCfgActionTypes.AlarmCfgOnServerCreated: return {
            ...state
        };
        case AlarmCfgActionTypes.AlarmCfgCreated: return adapter.addOne(action.payload.alarmCfg, {
             ...state, lastCreatedAlarmCfgId: action.payload.alarmCfg.id
        });
        break;
        case AlarmCfgActionTypes.AlarmCfgUpdated: return adapter.updateOne(action.payload.partialAlarmCfg, 
            {...state, lastUpdatedAlarmCfgId: action.payload.id});
        // case AlarmCfgActionTypes.AlarmCfgsStatusUpdated: {
        //     const _partialAlarmCfgs: Update<AlarmCfgModel>[] = [];
        //     for (let i = 0; i < action.payload.alarmCfgs.length; i++) {
        //         _partialAlarmCfgs.push({
		// 		    id: action.payload.alarmCfgs[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialAlarmCfgs, state);
        // }
        break;
        case AlarmCfgActionTypes.OneAlarmCfgDeleted: return adapter.removeOne(action.payload.id, 
            {...state, lastDeletedAlarmCfgId:action.payload.id});
        break;
        case AlarmCfgActionTypes.ManyAlarmCfgsDeleted: return adapter.removeMany(action.payload.ids, state);
        break;
        case AlarmCfgActionTypes.AlarmCfgsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        break;
        case AlarmCfgActionTypes.AlarmCfgsPageLoaded:
            if(action.payload==undefined){
                return state;
            }
            try{
                return adapter.addMany(action.payload.alarmCfgs, {
                    ...initialAlarmCfgsState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
        break;
        case AlarmCfgActionTypes.AlarmCfgsAllLoaded:
            if(action.payload==undefined){
                return state;
            }
            console.log("reducer: all alarm cfgs loaded: "+JSON.stringify(action));
            try{
                return adapter.addMany(action.payload.alarmCfgs, {
                    ...initialAlarmCfgsState,
                    totalCount: action.payload.alarmCfgs.length,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
        break;
        default: return state;
        break;
    }
}

export const getAlarmCfgState = createFeatureSelector<AlarmCfgModel>('alarmcfgs');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
