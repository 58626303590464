import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../auth';
import {BASE_URL, API_PRODUCTION_URL,API_VELOCITY_URL} from '../../connection.data';
import {QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {HttpClientModule} from '@angular/common/http';
//import { Pavilion, Pavilioner } from '../_models/pavilion.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class PavilionRealtimeService{

    constructor(private http: HttpClient){

    }
    get_history_realtime_pavilion_temperature(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_realtime_paviltion_temperature?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    production_last_pavilion_harvest(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_last_pavilion_harvest`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_velocity(): Observable<any[]>{
        const url = `${API_VELOCITY_URL}/get_velocity`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    get_velocity_brief(): Observable<any[]>{
        const url = `${API_VELOCITY_URL}/velocity`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    set_velocity(uid,gral_velo): Observable<any[]>{
        const url = `${API_VELOCITY_URL}/velocity`;
        return this.http.put<any>(url, {uid:uid,vel_value:gral_velo}).pipe(
            map((response)=>response.status)
        );
    }
    get_iiot_velocity(): Observable<any[]>{
        const url = `${API_VELOCITY_URL}/get_iiot_velocity`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    get_prom_vel(): Observable<any[]>{
        const url = `${API_VELOCITY_URL}/get_prom_vel`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_realtime_bird_by_floor(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_realtime_bird_by_floor?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_realtime_silo_by_pavilion(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_realtime_silo_by_pavilion?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_manual_daily_data(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_manual_daily_data?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_tot_deads(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_tot_deads?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }

    pavilion_realtime_temperature(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_realtime_temperature?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_realtime_last_temp_per_hour(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_realtime_last_temp_per_hour?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    production_last_pavilion_harvest_by_id(pavilion_id): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_last_pavilion_harvest_by_id?pavilion_id=${pavilion_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    
    pavilion_last_realtime_temperature(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_last_realtime_temperature`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_realtime_deads(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_realtime_deads`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_last_realtime_vel_mode(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_last_realtime_vel_mode`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
    pavilion_hyline_performance(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/pavilion_hyline_performance`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    production_realtime_prom_age_pavilion(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_prom_age_pavilion`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    production_guano_control(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_guano_control`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    production_manual_data(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_manual_data`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    production_manual_data_proms(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_manual_data_proms`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    production_breading_proms(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_breading_proms`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    latestHarvestPerPavilion(){
        const url = `${API_PRODUCTION_URL}/harvest/latest/pavilion/all`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
   /* get_history_pavilion_harvest_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_day?pavilion_id=${pavilion_id}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }*/
}