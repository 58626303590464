import { Component, ElementRef, Input, OnInit, ViewChild,OnChanges,SimpleChanges,ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import {PavilionHistoryService} from '../../../../../core/production';
import {HistorycData} from './../../../../../core/production/_models/HistorycData.model';
import { isThisSecond } from 'date-fns';
import { formatDate } from '@angular/common';

@Component({
  selector: 'kt-temperature-historyc-pavilion',
  templateUrl: './temperature-historyc-pavilion.component.html',
  styleUrls: ['./temperature-historyc-pavilion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemperatureHistorycPavilionComponent implements OnInit, OnChanges {

 	// Public properties
   @Input() public historyc: HistorycData;
   @Input() data: { labels: string[], datasets: any[] };
   @Input() type = 'line';
   @ViewChild('chart2', { static: true }) chart: ElementRef;
   @Input() parentData: any;
   fechaString: string = '';
   chart1: any;

   @Input() public changeFromPavilion(id, fechadesde, fechahasta) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_temperature_day(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});
	}

	@Input() public changeFromDay(id, fechadesde, fechahasta) {
		this.pavilionHistoryService.get_history_pavilion_temperature_day(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});
	}

	@Input() public changeFromWeek(id, fechadesde, fechahasta) {
		this.pavilionHistoryService.get_history_pavilion_temperature_week(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});
	}
	plot(id, res){
		var dateList = new Array();
		var maxYcolumn = 0;
		var datasetArray = new Array();
		// console.log("temperature res: "+JSON.stringify(res));
		dateList = res.map(value => formatDate(value.write_date.slice(0,10), 'dd-MMM', 'en-US'));

		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		};

		var uniqueDate = dateList.filter(onlyUnique);

		var series = [];
		series.push(new Array(uniqueDate.length).fill(0.0));//avg
		series.push(new Array(uniqueDate.length).fill(-1000.0));//max
		series.push(new Array(uniqueDate.length).fill(1000.0));//min
		// console.log("series before: "+JSON.stringify(series));
		var nvalues = new Array(uniqueDate.length).fill(0);
		res.forEach(row=>{
			const date = formatDate(row.write_date.slice(0,10), 'dd-MMM', 'en-US');			 	
			const dateIdx = uniqueDate.findIndex(d=>d==date);
			// console.log(dateIdx);
			// console.log(JSON.stringify(row));
			const avg = parseFloat(row.avg);
			const max = parseFloat(row.max);
			const min = parseFloat(row.min);
			if(max > maxYcolumn){
				maxYcolumn = max;
			}
			nvalues[dateIdx] += 1;
			series[0][dateIdx] += avg;
			if(series[1][dateIdx] < max)
				series[1][dateIdx] = max;
			if(series[2][dateIdx] > min)
				series[2][dateIdx] = min;
		});
		nvalues.forEach((value, idx)=>{
			series[0][idx] /= value;
		});
		// console.log("series: "+JSON.stringify(series));
		const datasetLabels = ["Promedio", "Máxima", "Mínima"];
		series.forEach((serie, idx)=> {
			datasetArray.push({
				fill: false,
				pointHoverRadius: 4,
				pointHoverBorderWidth: 12,
				pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
				label: `${datasetLabels[idx]}`,
				data: serie
			});
		});
		const color = Chart.helpers.color;
		this.data = {
			labels: uniqueDate,
			datasets: datasetArray
		};
		//	this.removeData(chart1)
		this.initChart(maxYcolumn, this.data, 1);
		this.cdr.detectChanges();
	}

	@Input() public changeFromCycle(id, fechadesde, fechahasta, generation_id) {
		console.log(id,fechadesde,fechahasta);
		this.pavilionHistoryService.get_history_pavilion_temperature_generation(id, fechadesde, fechahasta,generation_id).subscribe(res => {
			this.plot(id, res);
		});
	}

 
   myDate = new Date();
   /**
    * Component constructor
    * @param layoutConfigService
    */
   constructor(
     private layoutConfigService: LayoutConfigService,
	 private pavilionHistoryService: PavilionHistoryService,
	 private cdr: ChangeDetectorRef
   ) {
     //this.historyc = new HistorycData();
     //this.historyc.clear();
   }
 
 
   ngOnChanges(changes: SimpleChanges) {

   }

   	ngOnInit(): void {
	}
		 
	public inicializarData(id){
		this.pavilionHistoryService.get_history_pavilion_temperature_day(id, formatDate(this.myDate.setDate(this.myDate.getDate() - 7), 'yyyy-MM-dd', 'en'), formatDate(new Date(), 'yyyy-MM-dd', 'en')).subscribe(res => {
			this.plot(id, res);
		});
	}
   private initChart(maxYcolumn, tmpreal, numaux) {
     // For more information about the chartjs, visit this link
     // https://www.chartjs.org/docs/latest/getting-started/usage.html
 
     if (numaux == 0) {
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});

		}

		if (numaux == 1) {
			try{
				this.chart1.destroy();
			}catch(error){

			}
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});
		}

	}

}
 