import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../reducers';

import {
    PavilionActionTypes,
    PavilionAdd,
    PavilionAdded,
    PavilionAssign,
    PavilionAssigned,
    PavilionDelete,
    PavilionDeleted,
    PavilionEdit,
    PavilionEdited,
    PavilionGetPage,
    PavilionPageLoaded,
    PavilionGetTypes,
    PavilionTypesLoaded,
    PavilionerGetPage,
    PavilionerPageLoaded,
    PavilionUnlink,
    PavilionUnlinked,
    PavilionerGetAll,
    PavilionerAllLoaded,
    PavilionGetAll,
    PavilionAllLoaded,
    PavilionGetFloors,
    PavilionFloorsLoaded, PavilionGetSPDashPage, PavilionSPDashPageLoaded
} from '../_actions/pavilion.actions';
import { PavilionService } from '../_services/pavilion.service';


@Injectable()
export class PavilionEffects {

    constructor(
        private actions: Actions,
        private pavilionService: PavilionService
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_ADD),
        map((action: PavilionAdd) => action.payload),
        switchMap(payload => {
            return this.pavilionService.add(payload.pavilion).pipe(
                map((id)=>{ //Adecuarse a la response recibida de api real
                    //console.log("add pavilion");
                    //console.log(JSON.stringify(payload.pavilion));
                    return new PavilionAdded({pavilion: {...payload.pavilion, id:id}});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_EDIT),
        map((action: PavilionEdit) => action.payload),
        switchMap(payload => {
            return this.pavilionService.edit(payload.pavilion).pipe(
                map((isEdited)=>{ //Adecuarse a la response recibida de api real
                    //console.log("edit pavilion");
                    if(isEdited){
                        //console.log(JSON.stringify(payload.pavilion));
                        return new PavilionEdited({partialPavilion: payload.pavilion});
                    }else{
                        //console.log("could not edit pavilion "+payload.pavilion.name);
                        return new PavilionEdited({partialPavilion: undefined});
                    }
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_DELETE),
        map((action: PavilionDelete) => action.payload),
        switchMap(payload => {
            return this.pavilionService.delete(payload.pavilion).pipe(
                map((isDeleted)=>{ //Adecuarse a la response recibida de api real
                    //console.log("delete pavilion");
                    if(isDeleted){                        
                        //console.log(JSON.stringify(payload.pavilion));
                        return new PavilionDeleted({id: payload.pavilion.id});
                    }else{
                        //console.log("Could not delete pavilion "+payload.pavilion.name);
                        return new PavilionDeleted({id: undefined});
                    }
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType<PavilionGetPage>(PavilionActionTypes.PAV_GET_PAGE),
        map((action: PavilionGetPage) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getPage(payload.page).pipe(
                map(response=>{
                    //console.log("get page pavilion");
                    //console.log(JSON.stringify(payload));
                    return {pavilions: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new PavilionPageLoaded(response);
        })
    );

    @Effect()
    getSpecificDashboardPage: Observable<any> = this.actions.pipe(
        ofType<PavilionGetSPDashPage>(PavilionActionTypes.PAV_GET_SPDASH_PAGE),
        map((action: PavilionGetSPDashPage) => action.payload),
        switchMap(payload => {
            console.log("pavilion effect get specific dashboard page with payload: ");
            console.dir(payload);
            return this.pavilionService.getSpecificDashboardPage(payload.pavilion, payload.page).pipe(
                map(response=>{
                    //console.log("get page pavilion");
                    //console.log(JSON.stringify(payload));
                    const items = this.processSpecificDashboards(response.items);
                    const pageSize = payload.page.pageSize;
                    const pageNumber = payload.page.pageNumber;
                    const offset = pageNumber*pageSize;
                    const limit = (pageNumber+1)*pageSize;
                    return {specificDashboards: items.slice(offset, limit), page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new PavilionSPDashPageLoaded(response);
        })
    );

    processSpecificDashboards(data){
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        const uniqueAges = data.map(row=>row.age_in_weeks).filter(onlyUnique);
        console.log(`unique ages: ${JSON.stringify(uniqueAges)}`);
        console.log(`ages: ${JSON.stringify(data.map(row=>row.age_in_weeks))}`);
        var specificDashboardArray = [];
        uniqueAges.forEach(age=>{
          const sdAtAge = data.filter(row=>row.age_in_weeks==age);
          var specificDashboardItem = {
            id: sdAtAge[0].id,
            weight_uniformity: 0,
            weight: 0,
            weight_min: 0,
            weight_max: 0,
            grain: 0,
            grain_tb: 49,
            diff_grain: 0,
            water: 0,
            water_tb: 0,
            diff_water: 0,
            mortality: 0,
            mortality_tb: 0,
            diff_mortality: 0,
            t_min: 0,
            t_max: 0,
            age_in_weeks: age,
            bird_type_id: sdAtAge[0].bird_type_id,
            bird_type_name: sdAtAge[0].bird_type_name
          };
          sdAtAge.forEach(sd=>{
            specificDashboardItem.weight_uniformity += parseFloat(sd.weight_uniformity);
            specificDashboardItem.weight += parseFloat(sd.weight);
            specificDashboardItem.weight_min += parseFloat(sd.weight_min);
            specificDashboardItem.weight_max += parseFloat(sd.weight_max);
            specificDashboardItem.grain += parseFloat(sd.grain);
            specificDashboardItem.grain_tb += parseFloat(sd.grain_tb);
            specificDashboardItem.diff_grain += parseFloat(sd.diff_grain);
            specificDashboardItem.water += parseFloat(sd.water);
            specificDashboardItem.water_tb += parseFloat(sd.water_tb);
            specificDashboardItem.diff_water += parseFloat(sd.diff_water);
            specificDashboardItem.mortality += parseFloat(sd.mortality);
            specificDashboardItem.mortality_tb += parseFloat(sd.mortality_tb);
            specificDashboardItem.diff_mortality += parseFloat(sd.diff_mortality);
            specificDashboardItem.t_min += parseFloat(sd.t_min);
            specificDashboardItem.t_max += parseFloat(sd.t_max);
          });
    
          specificDashboardItem.weight_uniformity /= sdAtAge.length;
          specificDashboardItem.weight /= sdAtAge.length;
          specificDashboardItem.weight_min /= sdAtAge.length;
          specificDashboardItem.weight_max /= sdAtAge.length;
          specificDashboardItem.grain /= sdAtAge.length;
          specificDashboardItem.grain_tb /= sdAtAge.length;
          specificDashboardItem.diff_grain /= sdAtAge.length;
          specificDashboardItem.water /= sdAtAge.length;
          specificDashboardItem.water_tb /= sdAtAge.length;
          specificDashboardItem.diff_water /= sdAtAge.length;
          specificDashboardItem.mortality /= sdAtAge.length;
          specificDashboardItem.mortality_tb /= sdAtAge.length;
          specificDashboardItem.diff_mortality /= sdAtAge.length;
          specificDashboardItem.t_min /= sdAtAge.length;
          specificDashboardItem.t_max /= sdAtAge.length;
    
          specificDashboardArray.push(specificDashboardItem);
        });
    
        return specificDashboardArray;
    }

    @Effect()
    getAll: Observable<any> = this.actions.pipe(
        ofType<PavilionGetAll>(PavilionActionTypes.PAV_GET_ALL),
        map((action: PavilionGetAll) => action.payload),
        switchMap(payload => {
            console.log("effect get all pavilions")
            return this.pavilionService.getAll();
        }),
        map((pavilions)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(pavilions));
            return new PavilionAllLoaded({pavilions:pavilions});
        })
    );

    @Effect()
    getPavilionersPage: Observable<any> = this.actions.pipe(
        ofType<PavilionerGetPage>(PavilionActionTypes.PAV_PAVILIONER_GET_PAGE),
        map((action: PavilionerGetPage) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getPavilionersPage(payload.page).pipe(
                map(response=>{
                    //console.log("get page pavilioner");
                    //console.log(JSON.stringify(payload));
                    return {pavilioners: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new PavilionerPageLoaded(response);
        })
    );

    @Effect()
    getPavilionFloors: Observable<any> = this.actions.pipe(
        ofType<PavilionGetFloors>(PavilionActionTypes.PAV_GET_FLOORS),
        map((action: PavilionGetFloors) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getPavilionFloors(payload.pavilion);
        }),
        map((floors)=>{
            //console.log("get pavilion floors") 
            //console.log(JSON.stringify(floors));
            return new PavilionFloorsLoaded({floors: floors});
        })
    );

    @Effect()
    getAllPavilioners: Observable<any> = this.actions.pipe(
        ofType<PavilionerGetAll>(PavilionActionTypes.PAV_PAVILIONER_GET_ALL),
        map((action: PavilionerGetAll) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getAllPavilioners().pipe(
                map(pavilioners=>{
                    //console.log("get all pavilioners");
                    //console.log(JSON.stringify(pavilioners));
                    return {pavilioners: pavilioners};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new PavilionerAllLoaded(response);
        })
    );

    @Effect()
    getTypes: Observable<any> = this.actions.pipe(
        ofType<PavilionGetTypes>(PavilionActionTypes.PAV_GET_TYPES),
        map((action: PavilionGetTypes) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getTypes().pipe(
                map(pavilionTypes=>{
                    //console.log("get types pavilion");
                    //console.log(JSON.stringify(payload));
                    return {pavilionTypes: pavilionTypes};
                })
            );
        }),
        map((newPayload)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(newPayload));
            return new PavilionTypesLoaded(newPayload);
        })
    );

    @Effect()
    assignPavilioner: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_ASSIGN),
        map((action:PavilionAssign)=>action.payload),
        switchMap(payload=>{
            return this.pavilionService.assignPavilioner(payload.pavilioner, payload.pavilion).pipe(
                map((response)=>{
                    //console.log("asign pavilioner to pavilion");
                    //console.log(JSON.stringify(payload));
                    if(response.status=="success")
                        return new PavilionAssigned({pavilionerAssigned: payload.pavilioner});
                    return new PavilionAssigned({});
                })
            )
        })
    );

    @Effect()
    unlinkPavilioner: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_UNLINK),
        map((action:PavilionUnlink)=>action.payload),
        switchMap(payload=>{
            return this.pavilionService.unlinkPavilioner(payload.pavilioner, payload.pavilion).pipe(
                map((unlinked)=>{
                    //console.log("unlink pavilioner from pavilion");
                    //console.log(JSON.stringify(payload));
                    if(unlinked)
                        return new PavilionUnlinked({pavilionerAssigned: payload.pavilioner});
                    return new PavilionUnlinked({});
                })
            )
        })
    );

    @Effect({dispatch:false})
    pavilionerAssigned: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_ASSIGNED),
        //tap((action:PavilionAssigned)=>console.log("pavilion pavilioner assigned"))
    );

    @Effect({dispatch:false})
    pavilionerUnlinked: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_UNLINKED),
        //tap((action:PavilionUnlinked)=>console.log("pavilion pavilioner unlinked"))
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_PAGE_LOADED),
        //tap((action:PavilionPageLoaded)=>console.log("pavilion page loaded"))
    );

    @Effect({dispatch:false})
    specificDashboardPageLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_SPDASH_PAGE_LOADED),
        //tap((action:PavilionPageLoaded)=>console.log("pavilion page loaded"))
    );

    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_ALL_LOADED),
        //tap((action:PavilionAllLoaded)=>console.log("pavilion all loaded"))
    );

    @Effect({dispatch:false})
    pavilionersPageLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_PAVILIONER_PAGE_LOADED),
        //tap((action:PavilionerPageLoaded)=>console.log("pavilioner page loaded"))
    );

    @Effect({dispatch:false})
    pavilionersAllLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_PAVILIONER_ALL_LOADED),
        //tap((action:PavilionerAllLoaded)=>console.log("pavilioner all loaded"))
    );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_ADDED),
        //tap((action:PavilionAdded)=>console.log("pavilion added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_EDITED),
        //tap((action:PavilionEdited)=>console.log("pavilion edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_DELETED),
        //tap((action:PavilionDeleted)=>console.log("pavilion deleted"))
    );

    @Effect({dispatch:false})
    typesLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_TYPES_LOADED),
        //tap((action:PavilionTypesLoaded)=>console.log("pavilion types loaded"))
    );

    @Effect({dispatch:false})
    floorsLoaded: Observable<any> = this.actions.pipe(
        ofType(PavilionActionTypes.PAV_FLOORS_LOADED),
        //tap((action:PavilionFloorsLoaded)=>console.log("pavilion floors loaded"))
        //tap((action:PavilionFloorsLoaded) => undefined
    );
}