// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, tap, debounceTime } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	Pavilion, 
	Hyline,
	PavilionsDataSource,
	selectHylineById,
	HylinesDataSource,
	HylineGetAll,
  HylineType,
  BirdType,
  selectBirdTypes,
  selectHylineTypes,
  BSGetBirdTypes,
  HylineGetTypes,
  HylineAdd,
  selectLastCreatedHylineId,
  HylineEdit,
  selectLastUpdatedHylineId
} from '../../../../../core/plant-configuration';
import { User, UsersDataSource } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-hyline-edit',
  templateUrl: './hyline-edit.component.html',
  styleUrls: ['./hyline-edit.component.scss']
})
export class HylineEditComponent implements OnInit, OnDestroy {
	@ViewChild('wizard', {static: true}) el: ElementRef;
	// Public properties
	hyline: Hyline;
	hylineId$: Observable<number>;
	oldHyline: Hyline;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	hylineForm: FormGroup;
	hasFormErrors = false;
	filteredBirdTypes: Observable<BirdType[]>;
	filteredHylineTypes: Observable<HylineType[]>;
	hylinesDataSource: HylinesDataSource;
	birdTypesResult: BirdType[] = [];
	hylineTypesResult: HylineType[] = [];
	hylinesResult: Hyline[] = [];
	selectedBirdType: BirdType = null;
	selectedHylineType: HylineType = null;
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	@ViewChild('hylineTypeTA', {static: true}) hylineTypeInstance: NgbTypeahead;
	@ViewChild('birdTypeTA', {static: true}) birdTypeInstance: NgbTypeahead;
	birdTypeFocus$ = new Subject<string>();
	birdTypeClick$ = new Subject<string>();
	hylineTypeFocus$ = new Subject<string>();
	hylineTypeClick$ = new Subject<string>();

	searchBirdType = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.birdTypeClick$.pipe(filter(() => !this.birdTypeInstance.isPopupOpen()));
		const inputFocus$ = this.birdTypeFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap((text:string)=>{
				console.log("searching birdType with "+text);
				this.selectBirdType(this.findBirdType(text))}),
			map((text:string) => this.filterBirdType(text)),
			tap(birdTypes=>console.log("birdTypes found: "+JSON.stringify(birdTypes)))

		);
	};
	birdTypeFormatter = (birdType: BirdType) => birdType.name;

	searchHylineType = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.hylineTypeClick$.pipe(filter(() => !this.hylineTypeInstance.isPopupOpen()));
		const inputFocus$ = this.hylineTypeFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap((text:string)=>{
				console.log("searching hylineType with "+text);
				this.selectHylineType(this.findHylineType(text))}),
			map((text:string) => this.filterHylineType(text)),
			tap(hylineTypes=>console.log("hylineTypes found: "+JSON.stringify(hylineTypes)))

		);
	};
	hylineTypeFormatter = (hylineType: HylineType) => hylineType.name;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("hyline edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
    
    	// Init DataSource
		this.hylinesDataSource = new HylinesDataSource(this.store);
		const hylines$ = this.hylinesDataSource.entitySubject.pipe(distinctUntilChanged());
		
		const birdTypes$ = this.store.pipe(
		select(selectBirdTypes)
		);
		const hylineTypes$ = this.store.pipe(
		select(selectHylineTypes)
		);

		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				const hyline$ = this.store.pipe(
					select(selectHylineById(id))
				);
				const sub = combineLatest([hyline$,birdTypes$, hylineTypes$]).subscribe(
					([hyline, birdTypes, hylineTypes])=>{
						if(birdTypes && birdTypes.length>0 && hyline && hylineTypes && hylineTypes.length>0){
							this.birdTypesResult = birdTypes;
							this.hylineTypesResult = hylineTypes;
							this.loadHyline(hyline);
						}							
					}
				)
				this.subscriptions.push(sub);
			} else {
				const sub = combineLatest([birdTypes$, hylineTypes$]).subscribe(
					([birdTypes, hylineTypes])=>{
						if(birdTypes && birdTypes.length>0 && hylineTypes && hylineTypes.length>0){
							this.birdTypesResult = birdTypes;
							this.hylineTypesResult = hylineTypes;
							var hyline:Hyline = new Hyline();
							hyline.clear();
							this.loadHyline(hyline);
						}							
					}
				)
				this.subscriptions.push(sub);				
			}
		});
		this.loadHylines();
		this.loadBirdTypes();
		this.loadHylineTypes();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
		this.subscriptions.push(authSubscription);
	}

	loadBirdTypes(){
		this.store.dispatch(new BSGetBirdTypes({}));
	}
	
	loadHylineTypes(){
		this.store.dispatch(new HylineGetTypes({}));
	}

	loadHylines(){
		this.store.dispatch(new HylineGetAll({}));
	}

	loadHyline(hyline: Hyline, fromService: boolean = false) {
		if (!hyline) {
			this.goBack('');
		}
		this.hyline = Object.assign(new Hyline(), hyline);
		this.hylineId$ = of(hyline.id);
		this.oldHyline = Object.assign({}, hyline);
		
		this.selectedBirdType = new BirdType();
		this.selectedBirdType.clear();
		this.selectedBirdType.id = hyline.bird_type_id;
		this.selectedBirdType.name = hyline.bird_type_name;

		this.selectedHylineType = new HylineType();
		this.selectedHylineType.clear();
		this.selectedHylineType.id = hyline.hyline_type_id;
		this.selectedHylineType.name = hyline.hyline_type_name;

		this.initHyline();
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init hyline
	 */
	initHyline() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.hyline.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de hylines', page: `/plant-configuration/hyline` },
				{ title: 'Crear de hyline', page: `/plant-configuration/hyline/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Crear de hyline');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de hylines', page: `/plant-configuration/hyline` },
			{ title: 'Editar hyline', page: `/plant-configuration/hyline/edit`, queryParams: { id: this.hyline.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// console.log("creating hyline form");
		// console.log(JSON.stringify(this.hyline));
		this.hylineForm = this.formBuilder.group({
			name: [this.hyline.name, Validators.required],
			birdType: [{name:this.selectedBirdType.name}, Validators.required],
			hylineType: [{name:this.selectedHylineType.name}, Validators.required],
		});
		const controls = this.hylineForm.controls;
		const nameSub = controls.name.valueChanges.subscribe(x=>{
			this.hyline.name = x;
		});
		this.subscriptions.push(nameSub);
		// console.log("hyline form created");
		// console.log(this.hylineForm);
		// console.log(typeof(this.hylineForm));
		// console.log(JSON.stringify(this.hylineForm));

		// this.filteredBirdTypes = this.hylineForm.controls.birdType.valueChanges
		// 	.pipe(
		// 		startWith(''),
		// 		map(val => this.filterBirdType(val.toString())),
		// 		tap(birdTypes => {
		// 			this.selectedBirdType = null;
		// 			if(birdTypes && birdTypes.length>0){
		// 				let val = this.hylineForm.controls.birdType.value;
		// 				let birdTypeCoincidences = birdTypes.filter((birdType)=>birdType.name.toLowerCase()==val.toLowerCase());
		// 				if(birdTypeCoincidences.length>0){
		// 					this.selectedBirdType = birdTypeCoincidences[0];
		// 				}
		// 			}
		// 		})
		// 	);
		// this.filteredHylineTypes = this.hylineForm.controls.hylineType.valueChanges
		// 	.pipe(
		// 		startWith(''),
		// 		map(val => this.filterHylineType(val.toString())),
		// 		tap(hylineTypes => {
		// 			this.selectedHylineType = null;
		// 			if(hylineTypes && hylineTypes.length>0){
		// 				let val = this.hylineForm.controls.hylineType.value;
		// 				let hylineTypeCoincidences = hylineTypes.filter((hylineType)=>hylineType.name.toLowerCase()==val.toLowerCase());
		// 				if(hylineTypeCoincidences.length>0){
		// 					this.selectedHylineType = hylineTypeCoincidences[0];
		// 				}
		// 			}
		// 		})
		// 	);
		this.cdr.detectChanges();
	}

	/**
	 * Filter bird type
	 *
	 * @param val: string
	 */
	filterBirdType(val: string): BirdType[] {
		if (this.birdTypesResult){
      return this.birdTypesResult.filter(birdType =>
        birdType.name.toLowerCase().includes(val.toLowerCase()));
		}
		return [];
	}

	/**
	 * Filter hyline type
	 *
	 * @param val: string
	 */
	filterHylineType(val: string): HylineType[] {
		if (this.hylineTypesResult){
      return this.hylineTypesResult.filter(hylineType =>
        hylineType.name.toLowerCase().includes(val.toLowerCase()));
		}
		return [];
	}

	findBirdType(name:String):BirdType{
		return this.birdTypesResult.filter(birdType=>birdType.name.toLowerCase() == name.toLowerCase())[0];
	}

	findHylineType(name:String):HylineType{
		return this.hylineTypesResult.filter(hylineType=>hylineType.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/hyline?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/hyline', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh hyline
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshHyline(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/hyline/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.hyline = Object.assign({}, this.oldHyline);
		this.createForm();
		this.hasFormErrors = false;
		this.hylineForm.markAsPristine();
		this.hylineForm.markAsUntouched();
		this.hylineForm.updateValueAndValidity();
	}

	selectBirdType(birdType: BirdType){
		this.selectedBirdType = birdType;
	}

	selectHylineType(hylineType:HylineType){
		this.selectedHylineType = hylineType;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.hylineForm.controls;
		/** check form */
		if (this.hylineForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let hyline = this.prepareData();

		if (hyline.id > 0) {
			this.updateHyline(hyline, withBack);
			return;
		}

		this.addHyline(hyline, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareData(): Hyline {
		const controls 			= this.hylineForm.controls;
		let hyline: Hyline = new Hyline();
		hyline.clear();
		hyline.id = this.hyline.id;
		hyline.bird_type_id = this.selectedBirdType.id;
		hyline.bird_type_name = this.selectedBirdType.name;
		hyline.hyline_type_id = this.selectedHylineType.id;
		hyline.hyline_type_name = this.selectedHylineType.name;
		hyline.name = controls.name.value;
		hyline.company_id = this.user.company_id;
		hyline.write_uid = this.user.id;
		hyline.is_active = this.hyline.is_active;
		return hyline;
	}

	/**
	 * Add Hyline
	 *
	 * @param hyline: Hyline
	 * @param withBack: boolean
	 */
	addHyline(hyline:Hyline, withBack:boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new HylineAdd({hyline: hyline}));
		const addBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastCreatedHylineId)
		).subscribe(newId => {
			if (!newId || newId==-1) {
				// addBreedingSub.unsubscribe();
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Hyline ha sido creada exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshHyline(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update hyline
	 *
	 * @param hyline: Hyline
	 * @param withBack: boolean
	 */
	updateHyline(hyline: Hyline, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateHyline: Update<Hyline> = {
			id: hyline.id,
			changes: hyline
		};

		this.store.dispatch(new HylineEdit({
			partialHyline: updateHyline,
			hyline:hyline
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedHylineId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
			}
			if (withBack) {
				this.goBack(hyline.id);
			} else {
				const message = `Hyline ha sido actualizada exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshHyline(false);
			}
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear hyline';
		if (!this.hyline || !this.hyline.id) {
			return result;
		}

		result = `Editar Hyline - ${this.hyline.name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}