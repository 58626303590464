import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_GRAIN_TYPE_URL, API_SILOS_URL} from '../../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { GrainStock, GrainType } from '../_models/grain-stock.model';

@Injectable()
export class GrainTypeService{
    constructor(private http: HttpClient){

    }

    getAll():Observable<GrainType[]>{
        const url = `${API_GRAIN_TYPE_URL}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_GRAIN_TYPE_URL}/page`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    add(grainType:GrainType):Observable<number>{
        return this.http.post<any>(API_GRAIN_TYPE_URL, grainType).pipe(
            map((response)=> response.id)
        );
    }

    edit(grainType:GrainType):Observable<string>{
        return this.http.put<any>(API_GRAIN_TYPE_URL, grainType).pipe(
            map((response)=>response.status)
        );
    }

    delete(grainType:GrainType):Observable<string>{
        return this.http.delete<any>(`${API_GRAIN_TYPE_URL}/${grainType.id}`).pipe(
            map((response)=>response.status)
        );
    }
}

@Injectable()
export class GrainStockService{
    constructor(private http: HttpClient){

    }

    getInventory(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/inventory/page`;
        return this.http.post<QueryResultsModel>(url, queryParams);
    }

    getLoads(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/stock/page`;
        return this.http.post<QueryResultsModel>(url, queryParams);
    }

    getConsumptions(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/consumption/page`;
        return this.http.post<QueryResultsModel>(url, queryParams);
    }

    load(grainStock: GrainStock):Observable<any>{
        const url = `${API_SILOS_URL}/stock`
        return this.http.post<any>(url, grainStock).pipe(
            map((response)=> {return {added: response.status=="success", id:response.id}})
        );
    }

    consume(grainStock: GrainStock):Observable<any>{
        const url = `${API_SILOS_URL}/consumption`
        return this.http.post<any>(url, grainStock).pipe(
            map((response)=> {return {added: response.status=="success", id:response.id}})
        );
    }

    updateLoad(grainStock: GrainStock):Observable<boolean>{
        const url = `${API_SILOS_URL}/stock`
        return this.http.put<any>(url, grainStock).pipe(
            map((response)=>response.status == "success")
        );
    }

    updateConsumption(grainStock: GrainStock):Observable<boolean>{
        const url = `${API_SILOS_URL}/consumption`
        return this.http.put<any>(url, grainStock).pipe(
            map((response)=>response.status == "success")
        );
    }

    deleteLoad(grainStock:GrainStock):Observable<boolean>{
        const url = `${API_SILOS_URL}/stock/${grainStock.id}`
        return this.http.delete<any>(url).pipe(
            map((response)=>response.status=="success")
        );
    }

    deleteConsumption(grainStock:GrainStock):Observable<boolean>{
        const url = `${API_SILOS_URL}/consumption/${grainStock.id}`
        return this.http.delete<any>(url).pipe(
            map((response)=>response.status=="success")
        );
    }

    findStockLoad(grainStock: GrainStock):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/stock/${grainStock.id}`
        return this.http.get<QueryResultsModel>(url);
    }

    findStockConsumption(grainStock: GrainStock):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/consumption/${grainStock.id}`
        return this.http.get<QueryResultsModel>(url);
    }
}