// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AlarmCfgsState } from '../_reducers/alarmcfg.reducers';
import { AlarmCfgModel } from '../_models/alarmcfg.model';

export const selectAlarmCfgsState = createFeatureSelector<AlarmCfgsState>('alarmcfgs');

export const selectAlarmCfgById = (alarmCfgId: number) => createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.entities[alarmCfgId]
);

export const selectAlarmCfgsPageLoading = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.listLoading
);

export const selectAlarmCfgsActionLoading = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.actionsloading
);

export const selectAlarmCfgsPageLastQuery = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.lastQuery
);

export const selectLastCreatedAlarmCfgId = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.lastCreatedAlarmCfgId
);

export const selectLastUpdatedAlarmCfgId = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.lastUpdatedAlarmCfgId
);

export const selectLastDeletedAlarmCfgId = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.lastDeletedAlarmCfgId
);

export const selectAlarmCfgsInitWaitingMessage = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => alarmCfgsState.showInitWaitingMessage
);

export const selectAlarmCfgsInStore = createSelector(
    selectAlarmCfgsState,
    alarmCfgsState => {
        const items: AlarmCfgModel[] = [];
        each(alarmCfgsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: AlarmCfgModel[] = httpExtension.sortArray(items, alarmCfgsState.lastQuery.sortField, alarmCfgsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, alarmCfgsState.totalCount, '');
    }
);

export const selectHasAlarmCfgsInStore = createSelector(
    selectAlarmCfgsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
