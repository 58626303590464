//export const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//export const BASE_URL = "http://192.168.2.16:3000"                //INTERNAL DEV

//export const BASE_URL = "http://asml.ir4hub.cl:30030"                //LAB DEV
//export const BASE_URL = "http://asml.ir4hub.cl:30030"                //PRODUCTION
export const BASE_URL = "https://asml.ir4hub.cl:30031"                //PRODUCTION + SSL

export const API_USERS_URL = BASE_URL+'/user';
export const API_PAVILIONS_URL = BASE_URL+'/pavilion';
export const API_SILOS_URL = BASE_URL + '/silo';
export const API_BIRDS_URL = BASE_URL + '/bird';
export const API_WATER_CONSUMPTION_URL = BASE_URL + '/water-consumption';
export const API_DEADS_URL = BASE_URL + '/deads';
export const API_APK_URL = BASE_URL + '/apk';
export const API_MANUAL_URL = BASE_URL + '/manual';
export const API_GRAIN_TYPE_URL = BASE_URL + '/grain-type'
export const API_ALARMS_URL = BASE_URL+'/alarms';
export const API_PERMISSION_URL = 'api/permissions';
export const API_ROLES_URL = 'api/roles';
export const API_PRODUCTION_URL = BASE_URL + '/production';
export const API_VELOCITY_URL = BASE_URL + '/velocity';
