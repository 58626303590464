// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import { PavilionHistoryService } from '../../../../../core/production';
import { HistorycData } from './../../../../../core/production/_models/HistorycData.model';
import { formatDate } from '@angular/common';

/**
 * Sample components with sample data
 */
@Component({
	selector: 'kt-widget12',
	templateUrl: './widget12.component.html',
	styleUrls: ['./widget12.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})

export class Widget12Component implements OnInit, OnChanges {

	// Public properties
	@Input() public historyc: HistorycData;
	@Input() data: { labels: string[], datasets: any[], maxY:number};
	@Input() maxYColumn: number;
	@Input() type = 'line';
	@ViewChild('chart', { static: true }) chart: ElementRef;
	@Input() parentData: any;
	//@Input() datafecha: {labels: string[]};
	fechaString: string = '';
	//const chart;
	//chart1:any;
	chart1: any;
	@Input() public changeFromPavilion(id, fechadesde, fechahasta) {
		//console.log(id,fechadesde,fechahasta,'Rendimiento');
		//asdf1.destroy();
		//console.log('aca');
		//var aa = document.getElementById(asdf1.id);
		//aa.parentNode.removeChild(aa);
		//asdf1.destroy;
		//asdf1.clear;

		this.pavilionHistoryService.get_history_pavilion_harvest_day(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});


	}
	@Input() public changeFromDay(id, fechadesde, fechahasta) {

		this.pavilionHistoryService.get_history_pavilion_harvest_day(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});
	}

	plot(id, res){
		var dateList = new Array();
		var maxYcolumn = 0;
		var datasetArray = new Array();
		// console.log("temperature res: "+JSON.stringify(res));
		dateList = res.map(value => formatDate(value.write_date.slice(0,10), 'dd-MMM', 'en-US'));

		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		};

		var uniqueDate = dateList.filter(onlyUnique);
		var field = 'pavilion_id';
		var fieldLabel = 'Pabellón'
		if(id){
			field = 'floor_id';
			fieldLabel = 'Piso';
		}
		const fieldList = res.map(row => row[field]).filter(onlyUnique);

		var series = [];
		var nvalues = [];
		for(let i = 0; i<fieldList.length; i++){
			series.push(new Array(uniqueDate.length).fill(0.0));
			nvalues.push( new Array(uniqueDate.length).fill(0));
		}		

		res.forEach(row=>{
			const field_id = row[field];
			const date = formatDate(row.write_date.slice(0,10), 'dd-MMM', 'en-US');			 	
			const dateIdx = uniqueDate.findIndex(d=>d==date);
			const fieldIdx = fieldList.findIndex(d=>d==field_id);
			// console.log(dateIdx);
			// console.log(JSON.stringify(row));
			const harvest = parseFloat(row.harvest);
			
			series[fieldIdx][dateIdx] += harvest;
			nvalues[fieldIdx][dateIdx] += 1;

			if(series[fieldIdx][dateIdx] > maxYcolumn){
				maxYcolumn = series[fieldIdx][dateIdx];
			}
		});
		// nvalues.forEach((row, idx)=>{
		// 	row.forEach((value, idx2) => {
		// 		series[idx][idx2] /= value;
				// if(series[idx][idx2] > maxYcolumn){
				// 	maxYcolumn = series[idx][idx2];
				// }
		// 	});
		// });
		// console.log("series: "+JSON.stringify(series));
		const datasetLabels = fieldList.map(floor_id=>`${fieldLabel} ${floor_id}`);
		console.log("field list: "+JSON.stringify(fieldList));
		console.log("series: "+JSON.stringify(series));
		series.forEach((serie, idx)=> {
			datasetArray.push({
				fill: false,
				pointHoverRadius: 4,
				pointHoverBorderWidth: 12,
				pointBorderColor: Chart.helpers.color('#000000').alpha(0).rgbString(),
				label: `${datasetLabels[idx]}`,
				data: serie
			});
		});
		console.log("datsets: "+JSON.stringify(datasetArray));
		console.log("unique dates: "+JSON.stringify(uniqueDate));
		const color = Chart.helpers.color;
		this.data = {
			labels: uniqueDate,
			datasets: datasetArray,
			maxY:maxYcolumn
		};
		//	this.removeData(chart1)
		this.initChart(maxYcolumn, this.data, 1);
		this.cdr.detectChanges();
	}

	@Input() public changeFromWeek(id, fechadesde, fechahasta) {
		console.log(id, fechadesde, fechahasta);
		this.pavilionHistoryService.get_history_pavilion_harvest_week(id, fechadesde, fechahasta).subscribe(res => {
			this.plot(id, res);
		});
	}
	@Input() public changeFromCycle(id, fechadesde, fechahasta, generation_id) {
		console.log(id, fechadesde, fechahasta);
		this.pavilionHistoryService.get_history_pavilion_harvest_generation(id, fechadesde, fechahasta, generation_id).subscribe(res => {
			this.plot(id, res);
		});
	}
	myDate = new Date();
	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private pavilionHistoryService: PavilionHistoryService,
	 	private cdr: ChangeDetectorRef
	 ) {
		//this.historyc = new HistorycData();
		//this.historyc.clear();
	}


	ngOnChanges(changes: SimpleChanges) {
		this.initChart(this.data.maxY, this.data, 1);
		this.cdr.detectChanges();
	}

	ngOnInit(): void {
	}

	public inicializarData(id){
		this.pavilionHistoryService.get_history_pavilion_harvest_day(id, formatDate(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd', 'en'), formatDate(new Date(), 'yyyy-MM-dd', 'en')).subscribe(res => {
			this.plot(id, res);
		});
	}
	
	public removeData(chart) {
		chart.data.labels.pop();
		chart.data.datasets.forEach((dataset) => {
			dataset.data.pop();
		});
		chart.update();
	}

	public initChart(maxYcolumn, tmpreal, numaux) {

		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html
		//asdf2.destroy();
		if (numaux == 0) {
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});

		}

		if (numaux == 1) {
			try{
				this.chart1.destroy();
			}catch(error){
				
			}
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 13,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});
		}
	}
}