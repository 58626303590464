import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-velocity-edit',
  templateUrl: './velocity-edit.component.html',
  styleUrls: ['./velocity-edit.component.scss']
})
export class VelocityEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
