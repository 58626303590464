import { Component, OnInit,ViewChild,OnDestroy,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import {realTimeTemperaturecomponent} from '../../../../partials/content/widgets/realtimeTemperature/realTimeTemperature.component';
import {formatDate } from '@angular/common';
import {OnLinePavilionData} from '../../../../../core/production/_models/OnLinePavliionData'
import {BirdService} from '../../../../../core/plant-configuration/_services/bird.service'
import {PavilionRealtimeService} from '../../../../../core/production/_services/pavilionRealtime.service';
import { interval, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'kt-realtime-dashboard',
  templateUrl: './realtime-dashboard.component.html',
  styleUrls: ['./realtime-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealtimeDashboardComponent implements OnInit, OnDestroy {
  onLinePavilionData :OnLinePavilionData[] = [];
  breeding_totals : any;
  @ViewChild(realTimeTemperaturecomponent, { static: true }) remoteHistory: realTimeTemperaturecomponent;
  private subscriptions: Subscription[] = [];
  constructor(
    private birdService :BirdService,
    private cd: ChangeDetectorRef,
    private pavilionRealtimeService: PavilionRealtimeService
  ) {
    for (let index = 1; index < 8; index++) {
      var pavilion : OnLinePavilionData =  new OnLinePavilionData();
      pavilion.clear();
      pavilion.age_in_weeks = '0';
      pavilion.id = index;
      pavilion.c_bird_type_name = ''
      pavilion.c_bird_amount = 0;
      pavilion.water_amount = 0;
      this.onLinePavilionData.push(pavilion);
    }
    this.breeding_totals = {
      prom_age : 0,
      c_bird_total : 0,
      prom_uniformity : 0.0,
      prom_weight : 0.0,
      prom_nourishment : 0.0,
      prom_water : 0.0,
      prom_tmin : 0.0,
      prom_tmax : 0.0,
      prom_c_dead_bird : 0.0

    }
  }
  ngOnInit() {
    // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    this.birdService.getBirdBreedingDashboardData().subscribe(res => {
      //console.table(res)
      if (res){
        res.forEach(element => {
          var tmp = this.onLinePavilionData.find(e => e.id == element.pavilion_id)          
          if (tmp){
            tmp.age_in_weeks = element.age_in_weeks;
            tmp.c_bird_amount = element.c_bird_amount;
            tmp.c_bird_type_name = element.bird_type_name;
            this.cd.markForCheck();
          }
        });
      }
    })
    this.pavilionRealtimeService.production_breading_proms().subscribe(res => {
      if (res){
        this.breeding_totals.c_bird_total = res[0].sum;
        this.breeding_totals.prom_age = res[0].avg;
        this.cd.markForCheck();
      }
    })
    this.pavilionRealtimeService.production_manual_data().subscribe(res => {
      if (res){
        console.table(res);
        res.forEach(element => {
          var tmp = this.onLinePavilionData.find(e => e.id == element.pavilion_id)
          if (tmp){
            tmp.c_dead_bird = element.c_dead_bird;    
            //console.log(`tmp.c_grain_amount = ${element.c_grain}/${tmp.c_bird_amount}*1000`);        
            tmp.c_grain_amount = (element.c_grain/Number(tmp.c_bird_amount))*1000
            tmp.t_min = element.t_min;
            tmp.t_max = element.t_max;
            if (element.water_amount > 0 && tmp.c_bird_amount > 0){
              tmp.water_amount = Number(element.water_amount*10000000)/Number(tmp.c_bird_amount);
              //console.log(`tmp.water_amount = ${element.water_amount}*10000000/${tmp.c_bird_amount}`);        
            }
              
            tmp.weight_prom = element.avg_weight;
            tmp.p_uniformity = element.p_uni_weight;
            this.cd.markForCheck();
          }
        });
      }
    })
    this.pavilionRealtimeService.production_manual_data_proms().subscribe(res => {
      if (res){
        //console.table(res)
        this.breeding_totals.prom_c_dead_bird = res[0].c_dead_bird_sum;
        this.breeding_totals.prom_tmin = res[0].t_min_avg;
        this.breeding_totals.prom_tmax = res[0].t_max_avg;
        //console.log(`this.breeding_totals.prom_water = ${res[0].water_amount_sum}*10000000/${this.breeding_totals.c_bird_total}`);
        this.breeding_totals.prom_water = res[0].water_amount_sum*10000000/this.breeding_totals.c_bird_total;
        this.breeding_totals.prom_nourishment = (res[0].sum_c_grain/this.breeding_totals.c_bird_total)*1000;
        //console.log(`this.breeding_totals.prom_nourishment = (${res[0].sum_c_grain}/${this.breeding_totals.c_bird_total})*1000`);
        this.breeding_totals.prom_uniformity = res[0].p_uniformity_avg;
        this.breeding_totals.prom_weight = res[0].weight_prom_avg;
        this.cd.markForCheck();
      }
    })
  }
 
  ngOnDestroy(){
    this.subscriptions.map(sub=>sub.unsubscribe());
  }
}
