import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-breeding-list',
  templateUrl: './breeding-list.component.html',
  styleUrls: ['./breeding-list.component.scss']
})
export class BreedingListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
