import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import { EventMqttService } from '../../../../core/production/_services/EventMqttService';
import { Subscription,Observable, interval } from 'rxjs';
import { IMqttMessage } from "ngx-mqtt";
@Component({
  selector: 'kt-online-harvest-dashboard',
  templateUrl: './online-harvest-dashboard.component.html',
  styleUrls: ['./online-harvest-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnlineHarvestDashboardComponent implements OnInit {
  subscription: Subscription;
  private subscriptions: Subscription[] = [];
  contaID = 'CONTA';
  galpones = [];
  constructor(
    private readonly eventMqtt: EventMqttService,
    private cd: ChangeDetectorRef,
  ) { 
    console.log(`Entering OnlineHarvestDashboardComponent::constructor `);
    this.galpones = JSON.parse(localStorage.getItem("galpones")) 
    if (this.galpones.length == 0){
      for (let index = 0; index < 15; index++) {
      this.galpones.push({
        id : String(index),
        globlal_count : 0,
        reset_count : 0,
        old_global_count : 0,
        is_reseted : 0,
      })
      }
    }
    console.log(`GETTING INFO ${JSON.stringify(this.galpones)}`);
    this.subscribeToTopic();
  }

  ngOnInit() {
  }
  private subscribeToTopic() {
    this.subscription = this.eventMqtt.topic(this.contaID).subscribe((data: IMqttMessage) => {
      let item = JSON.parse(data.payload.toString());
      console.log(item)
      var tmp = this.galpones.find(el => el.id == item.Galpon)
      tmp.globlal_count = Number(item.Contador)
      if (tmp.is_reseted){
        tmp.reset_count =  tmp.globlal_count - tmp.old_global_count;
      }else{
        tmp.reset_count = Number(item.Contador)
      }        
      //{Contador: '94455', Galpon: '1', Timestamp: '2022-03-31 11:41:40'}
      this.cd.markForCheck();
    })
  }
  reset_counter(pavilion_name_id){
    var tmp = this.galpones.find(el => el.id == pavilion_name_id)
    tmp.old_global_count = tmp.globlal_count
    tmp.is_reseted = 1;
    tmp.reset_count = tmp.globlal_count - tmp.old_global_count;
    localStorage.setItem("galpones", JSON.stringify(this.galpones));
  }

}
