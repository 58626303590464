// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { BirdStockMovement } from '../_models/bird-stock-mov.model';
import { BirdStock, BreedingStock } from '../_models/bird-stock.model';
import { BirdGeneration, BirdStatusType, BirdType } from '../_models/bird.model';
import { BSBreState, BSMState } from '../_reducers/bird-stock.reducers';
// State
export const selectBreedingStocksState = createFeatureSelector<BSBreState>('breedingStocks');

export const selectBreedingStockById = (breedingStockId: number) => createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.entities[breedingStockId]
);

export const selectBreedingStocksPageLoading = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectBreedingStocksState,
//     customersState => customersState.actionsloading
// );

export const selectBreedingStocksPageLastQuery = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.lastQuery
);

export const selectBreedingStocksError = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.error
);

export const selectLastCreatedBreedingStockId = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.lastCreatedBSId
);

export const selectLastUpdatedBreedingStockId = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.lastUpdatedBSId
);

export const selectLastDeletedBreedingStockId = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.lastDeletedBSId
);

export const selectBreedingStocksInitWaitingMessage = createSelector(
    selectBreedingStocksState,
    breedingStocksState => breedingStocksState.showInitWaitingMessage
);

export const selectBreedingStocksInStore = createSelector(
    selectBreedingStocksState,
    breedingStocksState => {
        const items: BreedingStock[] = [];
        each(breedingStocksState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BreedingStock[] = httpExtension.sortArray(items, breedingStocksState.lastQuery.sortField, breedingStocksState.lastQuery.sortOrder);
        return new QueryResultsModel(result, breedingStocksState.totalCount, '');
    }
);

export const selectHasBreedingStocksInStore = createSelector(
    selectBreedingStocksInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//BIRD STOCK MOVS
export const selectBirdStockMovsState = createFeatureSelector<BSMState>('birdStockMovs');

export const selectBirdStockMovById = (birdStockMovId: number) => createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.entities[birdStockMovId]
);

export const selectBirdStockMovsPageLoading = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectBirdStockMovsState,
//     customersState => customersState.actionsloading
// );

export const selectBirdStockMovsPageLastQuery = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.lastQuery
);

export const selectLastCreatedBirdStockMovId = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.lastCreatedBSMId
);

export const selectLastUpdatedBirdStockMovId = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.lastUpdatedBSMId
);

export const selectLastDeletedBirdStockMovId = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.lastDeletedBSMId
);

export const selectBirdStockMovsInitWaitingMessage = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => birdStockMovsState.showInitWaitingMessage
);

export const selectBirdStockMovsInStore = createSelector(
    selectBirdStockMovsState,
    birdStockMovsState => {
        const items: BirdStockMovement[] = [];
        each(birdStockMovsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BirdStockMovement[] = httpExtension.sortArray(items, birdStockMovsState.lastQuery.sortField, birdStockMovsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, birdStockMovsState.totalCount, '');
    }
);

export const selectHasBirdStockMovsInStore = createSelector(
    selectBreedingStocksInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//BIRD TYPES
export const selectBirdTypesState = state => state.birdTypes;

export const birdTypesLoaded = createSelector(
    selectBirdTypesState,
    birdTypesState => birdTypesState.birdTypesLoaded
)

export const selectBirdTypes = createSelector(
    selectBirdTypesState,
    birdTypesState => {
        const items: BirdType[] = [];
        each(birdTypesState.birdTypes, element => {
            items.push(element);
        });
        return items;
    }
)

//BIRD STATUS TYPES
export const selectBirdStatusTypesState = state => state.birdStatusTypes;

export const birdStatusTypesLoaded = createSelector(
    selectBirdStatusTypesState,
    birdStatusTypesState => birdStatusTypesState.birdStatusTypesLoaded
)

export const selectBirdStatusTypes = createSelector(
    selectBirdStatusTypesState,
    birdStatusTypesState => {
        const items: BirdStatusType[] = [];
        each(birdStatusTypesState.birdStatusTypes, element => {
            items.push(element);
        });
        return items;
    }
)

//BIRD GENS
export const selectBirdGensState = state => state.birdGens;

export const birdGensLoaded = createSelector(
    selectBirdGensState,
    birdGensState => birdGensState.birdGensLoaded
)

export const selectBirdGens = createSelector(
    selectBirdGensState,
    birdGensState => {
        const items: BirdGeneration[] = [];
        each(birdGensState.birdGens, element => {
            items.push(element);
        });
        return items;
    }
)