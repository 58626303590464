// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';


 // Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
} from '@angular/material';

//import { RealtimeDashboardBreedComponent } from './realtime/realtime-dashboard-breed/realtime-dashboard-breed.component';
import { RealtimeDashboardComponent } from './realtime/realtime-dashboard/realtime-dashboard.component'
import { BreedingComponent } from './breeding.component';
import { HistorycDashboardComponent } from './historyc/historyc-dashboard/historyc-dashboard.component';
/*import { RealtimeDashboardC1Component } from './realtime/realtime-dashboard-c1/realtime-dashboard-c1.component';
import { RealtimeDashboardC1cComponent } from './realtime/realtime-dashboard-c1c/realtime-dashboard-c1c.component';
import { RealtimeDashboardC2Component } from './realtime/realtime-dashboard-c2/realtime-dashboard-c2.component';
import { RealtimeDashboardC2cComponent } from './realtime/realtime-dashboard-c2c/realtime-dashboard-c2c.component';
import { RealtimeDashboardC3Component } from './realtime/realtime-dashboard-c3/realtime-dashboard-c3.component';
import { RealtimeDashboardC3cComponent } from './realtime/realtime-dashboard-c3c/realtime-dashboard-c3c.component';
import { RealtimeDashboardC4Component } from './realtime/realtime-dashboard-c4/realtime-dashboard-c4.component';*/

import { 
	birdGensReducer, 
	birdStatusTypesReducer, 
	BirdStockEffects, 
	birdTypesReducer, 
	breedingStockReducer, 
	hylineBreDataReducer, 
	hylineDataReducer, 
	hylineDataTypeReducer, 
	HylineEffects, 
	hylineEMoltDataReducer, 
	hylineHavDataReducer, 
	hylineLMoltDataReducer, 
	hylineReducer, 
	hylineTypesReducer, 
	PavilionEffects,
	pavilionersReducer, 
	pavilionFloorsReducer, 
	pavilionsReducer, 
	pavilionTypesReducer, 
	specificDashboardsReducer, 
	stockMovReducer 
} from '../../../core/plant-configuration';

const routes: Routes = [
	{
		path: '',
		component: BreedingComponent,
		children: [
			{
				path: '',
				redirectTo: 'realtime-dashboard',
				pathMatch: 'full'
			},
			{
				path: 'realtime',
				component: RealtimeDashboardComponent
			},
			/*{
				path: 'realtime-c1',
				component: RealtimeDashboardC1Component
			},
			{
				path: 'realtime-c1c',
				component: RealtimeDashboardC1cComponent
			},
			{
				path: 'realtime-c2',
				component: RealtimeDashboardC2Component
			},
			{
				path: 'realtime-c2c',
				component: RealtimeDashboardC2cComponent
			},
			{
				path: 'realtime-c3',
				component: RealtimeDashboardC3Component
			},
			{
				path: 'realtime-c3c',
				component: RealtimeDashboardC3cComponent
			},
			{
				path: 'realtime-c4',
				component: RealtimeDashboardC4Component
			},*/
      		{
				path: 'specific',
				component: HistorycDashboardComponent
	    	}
		]
	}
];


@NgModule({
  
  imports: [
    CommonModule,
    HttpClientModule,
		PartialsModule,
		RouterModule.forChild(routes),
		//StoreModule.forFeature('alarms', AlarmsReducer),
		//EffectsModule.forFeature([AlarmEffects]),
		//StoreModule.forFeature('alarmscfg', AlarmCfgsReducer),
		//EffectsModule.forFeature([AlarmCfgEffects]),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		StoreModule.forFeature('pavilions', pavilionsReducer),
		StoreModule.forFeature('pavilionTypes', pavilionTypesReducer),
		StoreModule.forFeature('pavilioners', pavilionersReducer),
		StoreModule.forFeature('pavilionFloors', pavilionFloorsReducer),
		StoreModule.forFeature('specificDashboards', specificDashboardsReducer),
		EffectsModule.forFeature([PavilionEffects]),
		StoreModule.forFeature('breedingStocks', breedingStockReducer),
		StoreModule.forFeature('birdStockMovs', stockMovReducer),
		StoreModule.forFeature('birdTypes', birdTypesReducer),
		StoreModule.forFeature('birdStatusTypes', birdStatusTypesReducer),
		StoreModule.forFeature('birdGens', birdGensReducer),
		EffectsModule.forFeature([BirdStockEffects]),	
		StoreModule.forFeature('hylines', hylineReducer),
		StoreModule.forFeature('hylineDatas', hylineDataReducer),
		StoreModule.forFeature('hylineBreDatas', hylineBreDataReducer),
		StoreModule.forFeature('hylineHavDatas', hylineHavDataReducer),
		StoreModule.forFeature('hylineEMoltDatas', hylineEMoltDataReducer),
		StoreModule.forFeature('hylineLMoltDatas', hylineLMoltDataReducer),
		StoreModule.forFeature('hylineTypes', hylineTypesReducer),
		StoreModule.forFeature('hylineDataTypes', hylineDataTypeReducer),
		EffectsModule.forFeature([HylineEffects]),
		//realTimeTemperaturecomponent
		//RealtimeDashboardC1Component
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
  ],
  entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
  declarations: [
    HistorycDashboardComponent,
	BreedingComponent,
	//RealtimeDashboardC1Component,
	//RealtimeDashboardC1cComponent,
	//RealtimeDashboardC2Component,
	//RealtimeDashboardC2cComponent,
	//RealtimeDashboardC3Component,
	//RealtimeDashboardC3cComponent,
	//RealtimeDashboardC4Component,
	RealtimeDashboardComponent
  ]
})
export class BreedingModule { }