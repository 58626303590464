import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineHarvestComponent } from './online-harvest.component'
import { OnlineHarvestDashboardComponent } from './online-harvest-dashboard/online-harvest-dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
import { EventMqttService } from '../../../core/production/_services/EventMqttService'
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';

 import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';

const routes: Routes = [
	{
		path: '',
		component: OnlineHarvestComponent,
		children: [
			{
				path: '',
				redirectTo: 'online-harvest',
				pathMatch: 'full'
			},
			{
				path: 'online-harvest',
				component: OnlineHarvestDashboardComponent
			},			
		]
	}
];

@NgModule({
  declarations: [
    OnlineHarvestComponent,
    OnlineHarvestDashboardComponent
  ],
  imports: [
    HttpClientModule,
      CommonModule,
      PartialsModule,
      RouterModule.forChild(routes),
      //StoreModule.forFeature('alarms', AlarmsReducer),
      //EffectsModule.forFeature([AlarmEffects]),
      //StoreModule.forFeature('alarmscfg', AlarmCfgsReducer),
      //EffectsModule.forFeature([AlarmCfgEffects]),
      FormsModule,
      ReactiveFormsModule,
      TranslateModule.forChild(),
      MatButtonModule,
      MatMenuModule,
      MatSelectModule,
        MatInputModule,
      MatTableModule,
      MatAutocompleteModule,
      MatRadioModule,
      MatIconModule,
      MatNativeDateModule,
      MatProgressBarModule,
      MatDatepickerModule,
      MatCardModule,
      MatPaginatorModule,
      MatSortModule,
      MatCheckboxModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatExpansionModule,
      MatTabsModule,
      MatTooltipModule,
      MatDialogModule
    ],
    providers: [
      InterceptService,
      {
            provide: HTTP_INTERCEPTORS,
              useClass: InterceptService,
        multi: true
      },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
          hasBackdrop: true,
          panelClass: 'kt-mat-dialog-container__wrapper',
          height: 'auto',
          width: '900px'
        }
      },
      HttpUtilsService,
      TypesUtilsService,
      LayoutUtilsService,
      EventMqttService
    ],
    entryComponents: [
      ActionNotificationComponent,
      DeleteEntityDialogComponent,
      FetchEntityDialogComponent,      
    ],
    
})

export class OnlineHarvestModule { }
