// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';

// Components
import { AlarmsComponent } from './alarms.component';
import { AlarmsListComponent } from './alarms/alarms-list/alarms-list.component';
import { AlarmsCalendarComponent } from './alarms/alarms-calendar/alarms-calendar.component';
import { AlarmscfgListComponent } from './alarms-cfg/alarmscfg-list/alarmscfg-list.component';
import { AlarmscfgEditComponent } from './alarms-cfg/alarmscfg-edit/alarmscfg-edit.component';
import { AlarmsDashboardComponent } from './alarms/alarms-dashboard/alarms-dashboard.component';

import {
	AlarmsReducer,
	AlarmService,
	AlarmEffects,
	AlarmCfgsReducer,
	AlarmCfgsService,
	AlarmCfgEffects,
	alarmTypesReducer,
	alarmStatusTypesReducer
} from '../../../core/alarms'

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';


const routes: Routes = [
	{
		path: '',
		component: AlarmsComponent,
		children: [
			{
				path: '',
				redirectTo: 'alarmsdashboard',
				pathMatch: 'full'
			},
			{
				path: 'alarmsdashboard',
				component: AlarmsDashboardComponent
			},
			{
				path: 'alarms',
				component: AlarmsListComponent
			},			
			{
				path: 'alarmscalendar',
				component: AlarmsCalendarComponent
			},
			{
				path: 'alarmscfg',
				component: AlarmscfgListComponent
			},
			{
				path: 'alarmscfg/add',
				component: AlarmscfgEditComponent
			},
			{
				path: 'alarmscfg/add/:id',
				component: AlarmscfgEditComponent
			},
			{
				path: 'alarmscfg/edit',
				component: AlarmscfgEditComponent
			},
			{
				path: 'alarmscfg/edit/:id',
				component: AlarmscfgEditComponent
			},
			{
				path: 'alarmscfg/:id',
				component: AlarmscfgListComponent
			},			
		]
	}
];

@NgModule({
  
  imports: [
    CommonModule,
	HttpClientModule,
	NgbModule,
		PartialsModule,
		RouterModule.forChild(routes),
		StoreModule.forFeature('alarms', AlarmsReducer),
		StoreModule.forFeature('alarmTypes', alarmTypesReducer),
		StoreModule.forFeature('alarmStatusTypes', alarmStatusTypesReducer),
		EffectsModule.forFeature([AlarmEffects]),
		StoreModule.forFeature('alarmcfgs', AlarmCfgsReducer),
		EffectsModule.forFeature([AlarmCfgEffects]),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		AlarmService,
		AlarmCfgsService
  ],
  entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlarmscfgEditComponent
	],
  declarations: [
    AlarmsComponent,
    AlarmsListComponent, 
    AlarmscfgListComponent, 
    AlarmscfgEditComponent, 
	AlarmsCalendarComponent,
	AlarmsDashboardComponent
  ],    
})
export class AlarmsModule { }
