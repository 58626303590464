import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-guano-list',
  templateUrl: './guano-list.component.html',
  styleUrls: ['./guano-list.component.scss']
})
export class GuanoListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
