// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, concat, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, debounceTime, tap, filter } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import {
	BSLoadBreeding,
	BSUpdateBreeding,
	Floor,
	Pavilion,
	PavilionGetAll,
	PavilionGetFloors,
	PavilionsDataSource,
	selectPavilionFloors
} from '../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AlarmCfgModel, selectLastUpdatedAlarmCfgId, AlarmCfgOnServerCreated, AlarmCfgUpdated, AlarmOnServerCreated, AlarmsGetTypes, AlarmType, selectAlarmCfgById, selectAlarmTypes, selectLastCreatedAlarmCfgId, AlarmCfgUpdate } from '../../../../../core/alarms';

@Component({
  selector: 'kt-alarmscfg-edit',
  templateUrl: './alarmscfg-edit.component.html',
  styleUrls: ['./alarmscfg-edit.component.scss']
})
export class AlarmscfgEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('wizard', {static: true}) el: ElementRef;
	// Public properties
	alarmCfg: AlarmCfgModel;
	alarmCfgId$: Observable<number>;
	oldAlarmCfg: AlarmCfgModel;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	alarmCfgForm: FormGroup;
	hasFormErrors = false;
	filteredPavilions: Observable<Pavilion[]>
	filteredAlarmTypes: Observable<AlarmType[]>
	time = {hour: 0, minute: 0};

	assignedPavilionFloors: {pavilion:Pavilion, floor:Floor}[] = [];
	
  	pavilionsDataSource: PavilionsDataSource;
	pavilionsResult: Pavilion[];
	floorsResult: Floor[];
	alarmTypesResult: AlarmType[];
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;
	private selectedPavilion: Pavilion = null;
	private selectedFloor: Floor = null;
	private selectedAlarmType: AlarmType = null;
	public editing:boolean = false;
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	ngAfterViewInit(): void {
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("loadbreeding edit component: usuario no logueado");
		});
		    
    	// Init DataSource
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const pavilions$ = this.pavilionsDataSource.entitySubject.pipe(
			distinctUntilChanged()
		);
		const acceptedAlarmTypes = ["PABELLONES-TEMPERATURA", "PABELLONES-COSECHA",
			"PABELLONES-INVENTARIO-ALIMENTO", "CRIANZA-INGRESO-DATA", 
			"COSECHA-HUEVOS"];
		const alarmTypes$ = this.store.pipe(
			select(selectAlarmTypes),
			map(alarmTypes=>alarmTypes.filter(alarmType=>acceptedAlarmTypes.includes(alarmType.name)))
		);
		
		const floors$ = this.store.pipe(
			select(selectPavilionFloors)
		);
		const floorsSub = floors$.subscribe((floors)=>{
			console.log("floors sub")
			// console.log("floors: "+JSON.stringify(floors));
			this.floorsResult = floors;
			this.selectedFloor = null;
			try{
				// this.alarmCfgForm.get("floor").patchValue(floors[0].name);
				this.selectFloor(floors[0]);
				this.cdr.detectChanges();
			}catch(error){

			}
		});
		const routeSub = this.activatedRoute.params.subscribe(params => {
			let urlSplited = this.router.url.split("/");
			let relUrl = urlSplited[urlSplited.length-2];
			if (relUrl == "edit"){
				this.editing = true;
			}
			const id = params.id;
			if (id && id > 0) {

				const alarmCfg$ = this.store.pipe(
					select(selectAlarmCfgById(id))
				);
				
				const sub = combineLatest([pavilions$,  alarmTypes$, alarmCfg$])
					.subscribe(([pavilions, alarmTypes, alarmCfg]) => {
						console.log("alarm cfg retrieved from store");
						if(pavilions && alarmTypes && alarmCfg){				
							this.pavilionsResult = pavilions;
							this.alarmTypesResult = alarmTypes;
							try{
								this.selectAlarmType(this.findAlarmType(alarmCfg.alarm_type_name));
								console.log("selected alarm type: "+JSON.stringify(this.selectedAlarmType));
							}catch(error){
								console.log(error);
							}
							try{
								this.selectPavilion(this.findPavilion(alarmCfg.pavilion_name));
							}catch(error){
								console.log(error);
							}
							this.loadAlarmCfg(alarmCfg);
						}
					}
				);
				this.subscriptions.push(sub);
			} else {				
				const sub = combineLatest([pavilions$, alarmTypes$])
					.subscribe(([pavilions, alarmTypes]) => {
						if(pavilions && alarmTypes && pavilions.length>0 && alarmTypes.length>0){				
							this.pavilionsResult = pavilions;
							this.alarmTypesResult = alarmTypes;
							console.log("default alarm cfg");
							const newAlarmCfg = new AlarmCfgModel();
							newAlarmCfg.clear();
							try{
								this.selectAlarmType(this.alarmTypesResult[0]);
								console.log("selected alarm type: "+JSON.stringify(this.selectedAlarmType));
							}catch(error){
								console.log(error);
							}
							try{
								this.selectPavilion(this.pavilionsResult[0]);
							}catch(error){
								console.log(error);
							}
							this.loadAlarmCfg(newAlarmCfg);

						}
					}
				);
				this.subscriptions.push(sub);				
			}
		});

		this.subscriptions.push(authSubscription);
		this.subscriptions.push(routeSub);
		this.subscriptions.push(floorsSub);

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		this.loadPavilions();
		this.loadAlarmTypes();
	}

	loadPavilions(){
		this.store.dispatch(new PavilionGetAll({}));
	}

	loadAlarmTypes(){
		this.store.dispatch(new AlarmsGetTypes({}));
	}

	loadPavilionFloors(pavilion:Pavilion){
		this.store.dispatch(new PavilionGetFloors({pavilion: pavilion}));
	}

	loadAlarmCfg(alarmCfg, fromService: boolean = false) {
		if (!alarmCfg) {
			this.goBack('');
		}
		this.alarmCfg = {...alarmCfg};

		this.selectedFloor = new Floor();
		this.selectedFloor.id = alarmCfg.floor_id;
		this.selectedFloor.name = alarmCfg.floor_name;
		
		// this.selectedPavilion = new Pavilion();
		// this.selectedPavilion.clear();
		// this.selectedPavilion.name = alarmCfg.pavilion_name;
		// this.selectedPavilion.id = alarmCfg.pavilion_id;
		// this.selectedPavilion.type_id = alarmCfg.pavilion_type_id;
		// this.selectedPavilion.type = alarmCfg.pavilion_type;

		// this.selectedAlarmType = new AlarmType();
		// this.selectedAlarmType.name = alarmCfg.alarm_type_name;
		// this.selectedAlarmType.id = alarmCfg.alarm_type_id;

		this.alarmCfgId$ = of(alarmCfg.id);
		this.oldAlarmCfg = Object.assign({}, alarmCfg);
		this.initAlarmCfg();
		// if (fromService) {
		this.cdr.detectChanges();
		// }
  }

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init alarmCfg
	 */
	initAlarmCfg() {
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.alarmCfg.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de configuraciones de alarma', page: `/alarms/alarmscfg` },
				{ title: 'Nueva configuración de alarma', page: `/alarms/alarmscfg/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar configuración de alarma');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de configuraciones de alarma', page: `/alarms/alarmscfg` },
			{ title: 'Editar configuración de alarma', page: `/alarms/alarmscfg/edit`, queryParams: { id: this.alarmCfg.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
    	console.log("creating alarm cfg form");
		console.log("selected pavilion: "+JSON.stringify(this.selectedPavilion));
		this.alarmCfgForm = this.formBuilder.group({
			pavilion: [{value:this.selectedPavilion.name, disabled: false}, Validators.required],
			floor: [{value:this.selectedFloor.name, disabled: false}],
			alarmType: [{value: this.selectedAlarmType.name, disabled:this.editing},
				Validators.required],
			minTemp: [this.alarmCfg.temp_low_value,
				[Validators.min(-100), Validators.max(130)]],
			mediumTemp: [this.alarmCfg.temp_medium_value,
				[Validators.min(-100), Validators.max(130)]],
			maxTemp: [this.alarmCfg.temp_high_value,
				[Validators.min(-100), Validators.max(130)]],
			minHarvest: [this.alarmCfg.harvest_data_low,
				[Validators.min(0)]],
			mediumHarvest: [this.alarmCfg.harvest_data_medium,
				[Validators.min(0)]],
			maxHarvest: [this.alarmCfg.harvest_data_high,
				[Validators.min(0)]],
			minNourishment: [this.alarmCfg.grain_low,
				[Validators.min(0)]],
			mediumNourishment: [this.alarmCfg.grain_medium,
				[Validators.min(0)]],
			maxNourishment: [this.alarmCfg.grain_high,
				[Validators.min(0)]],
			whiteEggs: [this.alarmCfg.white_egg_weekly_harvest_setup,
				[Validators.min(0)]],
			colourEggs: [this.alarmCfg.color_egg_weekly_harvest_setup,
				[Validators.min(0)]]
		});
		const controls = this.alarmCfgForm.controls;
		const tempSub = combineLatest([controls.minTemp.valueChanges, controls.mediumTemp.valueChanges, controls.maxTemp.valueChanges]).subscribe(
			([minTemp, mediumTemp, maxTemp])=>{
				this.alarmCfg.info = `Min: ${minTemp}°C. Medio: ${mediumTemp}°C. Max: ${maxTemp}°C.`;
			}
		);
		const harvestSub = combineLatest([controls.minHarvest.valueChanges, controls.mediumHarvest.valueChanges, controls.maxHarvest.valueChanges]).subscribe(
			([minHarvest, mediumHarvest, maxHarvest])=>{
				this.alarmCfg.info = `Min: ${minHarvest} hv. Medio: ${mediumHarvest} hv. Max: ${maxHarvest} hv.`;
			}
		);
		const nourishmentSub = combineLatest([controls.minNourishment.valueChanges, controls.mediumNourishment.valueChanges, controls.maxNourishment.valueChanges]).subscribe(
			([minNourishment, mediumNourishment, maxNourishment])=>{
				this.alarmCfg.info = `Min: ${minNourishment} kg. Medio: ${mediumNourishment} kg. Max: ${maxNourishment} kg.`;
			}
		);
		const eggsSub = combineLatest([controls.whiteEggs.valueChanges, controls.colourEggs.valueChanges]).subscribe(
			([whiteEggs, colourEggs])=>{
				this.alarmCfg.info = `Huevos blancos: ${whiteEggs}. Huevos de color: ${colourEggs}`;
			}
		);
		this.subscriptions.push(tempSub);
		this.subscriptions.push(harvestSub);
		this.subscriptions.push(nourishmentSub);
		this.subscriptions.push(eggsSub);

		// const ageSub = controls.age.valueChanges.pipe(
		// 	startWith(age)
		// ).subscribe(x=>{
		// 	this.alarmCfg.age_in_weeks = x;
		// });
		// const generationSub = controls.generation.valueChanges.pipe(
		// 	startWith(generation)
		// ).subscribe(x=>{
		// 	this.alarmCfg.generation_name = x;
		// });
		// const amountSub = controls.amount.valueChanges.pipe(
		// 	startWith(amount)
		// ).subscribe(x=>{
		// 	this.alarmCfg.bird_amount = x;
		// });
		// this.subscriptions.push(ageSub);
		// this.subscriptions.push(generationSub);
		// this.subscriptions.push(amountSub);
		// console.log(this.alarmCfgForm);

		console.log("alarm cfg form created");
		this.cdr.detectChanges();
	}

	assignPavilionFloor(){
		const pavilionFloorListFound = this.assignedPavilionFloors.filter(
			pavFloor=>pavFloor.pavilion.id == this.selectedPavilion.id && 
				((this.selectedFloor && pavFloor.floor.id == this.selectedFloor.id) || this.selectedPavilion.type == "CRIANZA")
		);
		if(this.selectedPavilion && pavilionFloorListFound.length == 0){
			this.assignedPavilionFloors.push({pavilion: this.selectedPavilion, floor:this.selectedFloor});
		}
	}

	unassignPavilionFloor(pavilionFloor:{pavilion:Pavilion, floor:Floor}){
		if(pavilionFloor){
			this.assignedPavilionFloors = this.assignedPavilionFloors.filter(
				pavFloor=>!(pavFloor.pavilion.id==pavilionFloor.pavilion.id &&
					((pavFloor.floor && pavilionFloor.floor && pavFloor.floor.id == pavilionFloor.floor.id) || this.selectedPavilion.type == "CRIANZA"))
			);
		}
	}

	/**
	 * Filter pavilion
	 *
	 * @param val: string
	 */
	filterPavilion(val: string): Pavilion[] {
		this.selectedPavilion = null;
		if (this.pavilionsResult){
			let pavilionList = this.pavilionsResult.filter(pavilion =>
				pavilion.name.toString().toLowerCase().includes(val.toLowerCase()));
			if(pavilionList.length>=1){
				let pavilionCoincidences = pavilionList.filter(pavilion=>pavilion.name.toLowerCase() == val.toLowerCase());
				if(pavilionCoincidences.length>0){
					this.selectedPavilion = pavilionCoincidences[0];
				}
			}
			return pavilionList;
		}
    	return [];
	}

	/**
	 * Filter bird type
	 *
	 * @param val: string
	 */
	filterAlarmType(val: string): AlarmType[] {
		this.selectedAlarmType = null;
		if (this.alarmTypesResult){
			let alarmTypeList = this.alarmTypesResult.filter(alarmType =>
				alarmType.name.toString().toLowerCase().includes(val.toLowerCase()));
			if(alarmTypeList.length>=1){
				let alarmTypeCoincidences = alarmTypeList.filter(alarmType=>alarmType.name.toLowerCase() == val.toLowerCase());
				if(alarmTypeCoincidences.length>0){
					this.selectedAlarmType = alarmTypeCoincidences[0];
				}
			}
			return alarmTypeList;
		}
    	return [];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/alarms/alarmscfg?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/alarms/alarmscfg', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh alarm cfg
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshAlarmCfg(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/loadbreading/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.alarmCfg = Object.assign({}, this.oldAlarmCfg);
		this.createForm();
		this.hasFormErrors = false;
		this.alarmCfgForm.markAsPristine();
		this.alarmCfgForm.markAsUntouched();
		this.alarmCfgForm.updateValueAndValidity();
		this.selectedAlarmType = null;
		this.selectedPavilion = null;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.alarmCfgForm.controls;
		/** check form */
		if (this.alarmCfgForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let editedAlarmCfg = this.prepareAlarmCfg();

		if (editedAlarmCfg.id > 0) {
			this.updateAlarmCfg(editedAlarmCfg, withBack);
			return;
		}

		this.addAlarmCfg(editedAlarmCfg, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareAlarmCfg(): AlarmCfgModel {
		const controls = this.alarmCfgForm.controls;
		const alarmCfg = Object.assign(new AlarmCfgModel(), this.alarmCfg);
		alarmCfg.id = this.alarmCfg.id;
		alarmCfg.pavilion_id = this.selectedPavilion.id;
		alarmCfg.pavilion_name = this.selectedPavilion.name;
		if(this.selectedFloor){
			alarmCfg.floor_id = this.selectedFloor.id;
			alarmCfg.floor_name = this.selectedFloor.name;
		}
		alarmCfg.grain_high = controls.maxNourishment.value;
		alarmCfg.grain_medium = controls.mediumNourishment.value;
		alarmCfg.grain_low = controls.minNourishment.value;
		alarmCfg.harvest_data_high = controls.maxHarvest.value;
		alarmCfg.harvest_data_medium = controls.mediumHarvest.value;
		alarmCfg.harvest_data_low = controls.minHarvest.value;
		alarmCfg.input_manual_data_hour = this.time.hour;
		alarmCfg.input_manual_data_min = this.time.minute;
		alarmCfg.is_harvest = true;
		alarmCfg.is_vel_manual = true;
		alarmCfg.guano_control = true;
		alarmCfg.white_egg_weekly_harvest_setup = controls.whiteEggs.value;
		alarmCfg.color_egg_weekly_harvest_setup = controls.colourEggs.value;
		alarmCfg.temp_high_value = controls.maxTemp.value;
		alarmCfg.temp_medium_value = controls.mediumTemp.value;
		alarmCfg.temp_low_value = controls.minTemp.value;
		alarmCfg.alarm_type_id = this.selectedAlarmType.id;

		alarmCfg._createdDate = this.alarmCfg._createdDate;
		alarmCfg._updatedDate = this.alarmCfg._updatedDate;
		alarmCfg._updatedDate = this.typesUtilsService.getDateStringFromDate();
		alarmCfg._createdDate = this.alarmCfg.id > 0 ? alarmCfg._createdDate : alarmCfg._updatedDate;
		alarmCfg.write_uid = this.user.id;
		alarmCfg.company_id = this.user.company_id;
		console.log(JSON.stringify(alarmCfg))
		return alarmCfg;
	}

	findPavilion(name:String):Pavilion{
		console.log(JSON.stringify(this.pavilionsResult));
		return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	}

	findFloor(name:String):Floor{
		console.log(JSON.stringify(this.floorsResult));
		return this.floorsResult.filter(floor=>floor.name.toLowerCase() == name.toLowerCase())[0];
	}

	findAlarmType(name:String):AlarmType{
		console.log(JSON.stringify(this.alarmTypesResult));
		return this.alarmTypesResult.filter(alarmType=>alarmType.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Add AlarmCfg
	 *
	 * @param alarmCfg: AlarmCfg
	 * @param withBack: boolean
	 */
	addAlarmCfg(alarmCfg: AlarmCfgModel, withBack: boolean = false) {
		this.loadingSubject.next(true);
		let ids = this.assignedPavilionFloors.map((pavFloor)=>pavFloor.pavilion.id);
		for(let pavilionFloor of this.assignedPavilionFloors){
			let pavilion = pavilionFloor.pavilion;
			let floor = pavilionFloor.floor;
			let alarmCfgCopy = {...alarmCfg};
			alarmCfgCopy.pavilion_id = pavilion.id;
			alarmCfgCopy.pavilion_name = pavilion.name;
			alarmCfgCopy.floor_id = floor?floor.id:null;
			alarmCfgCopy.floor_name = floor?floor.name:null;
			this.store.dispatch(new AlarmCfgOnServerCreated({alarmCfg: alarmCfgCopy}));
		}
		console.log("IDS: "+JSON.stringify(ids));
		const addAlarmCfgSub = this.store.pipe(
			delay(1000),
			select(selectLastCreatedAlarmCfgId)
		).subscribe(newId => {
			console.log("IDS: "+JSON.stringify(ids));
			console.log("new id: "+newId);
			if (!newId || newId==-1) {
				// addAlarmCfgSub.unsubscribe();
				return;
			}
			if (newId && newId!=-1 && ids.length>0){
				ids.pop();
			}
			if(ids.length==0){
				this.loadingSubject.next(false);
				if (withBack) {
					this.goBack(newId);
				} else {
					const message = `Nueva configuración de alarma ha sido añadida.`;
					this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
					this.refreshAlarmCfg(true, newId);
				}
			}
			// addAlarmCfgSub.unsubscribe();
		});
		this.subscriptions.push(addAlarmCfgSub);
	}

	/**
	 * Update alarmCfg
	 *
	 * @param alarmCfg: AlarmCfg
	 * @param withBack: boolean
	 */
	updateAlarmCfg(alarmCfg: AlarmCfgModel, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateAlarmCfg: Update<AlarmCfgModel> = {
			id: alarmCfg.id,
			changes: alarmCfg
		};

		this.store.dispatch(new AlarmCfgUpdate({
			partialAlarmCfg: updateAlarmCfg,
			alarmCfg:alarmCfg
		}));

		const updateAlarmCfgSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedAlarmCfgId)
		).subscribe((id) => { // Remove this line
			console.log("last updated alarm cfg id: "+id);
			if (!id || id==-1) {
				// updateAlarmCfgSub.unsubscribe();
				return;
			}
			// if(id==alarmCfg.id){
			if (withBack) {
				this.goBack(alarmCfg.id);
			} else {
				const message = `Configuración de alarma ha sido actualizada.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshAlarmCfg(false);
			}
			// }
		}); 
		this.subscriptions.push(updateAlarmCfgSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Nueva configuración de alarma';
		if (!this.alarmCfg || !this.alarmCfg.id) {
			return result;
		}

		result = `Editar configuración de alarma - ${this.alarmCfg.alarm_type_name} de ${this.alarmCfg.pavilion_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	selectPavilion(pavilion:Pavilion){
		this.selectedPavilion = pavilion;
		this.loadPavilionFloors(pavilion);
	}

	selectFloor(floor:Floor){
		this.selectedFloor = floor;
	}

	selectAlarmType(alarmType:AlarmType){
		this.selectedAlarmType = alarmType;
	}
}