// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { ProfileActions, ProfileActionTypes } from '../_actions/profile.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Profiles } from '../_models/profile.model';

// tslint:disable-next-line:no-empty-interface
export interface ProfilesState extends EntityState<Profiles> {
    isAllProfilesLoaded: boolean;
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedProfileId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Profiles> = createEntityAdapter<Profiles>();

export const initialProfilesState: ProfilesState = adapter.getInitialState({
    isAllProfilesLoaded : false,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedProfileId: undefined,
    showInitWaitingMessage: true
});

export function ProfilesReducer(state = initialProfilesState, action: ProfileActions): ProfilesState {
    switch  (action.type) {
        case ProfileActionTypes.ProfilesPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedProfileId: undefined
        };
        case ProfileActionTypes.ProfilesActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        //case ProfileActionTypes.ProfileOnServerCreated: return {
        //    ...state
        //};
        //case ProfileActionTypes.ProfileCreated: return adapter.addOne(action.payload.Profile, {
        //    ...state, lastCreatedProfileId: action.payload.Profile.id
        //});
        case ProfileActionTypes.ProfileUpdated: return adapter.updateOne(action.payload.partialProfile, state);
        case ProfileActionTypes.AllProfilesLoaded: return adapter.addAll(action.payload.profiles, {
            ...state, isAllRolesLoaded: true
        });
        //case ProfileActionTypes.ProfileDeleted: return adapter.removeOne(action.payload.id, state);
        case ProfileActionTypes.ProfilesPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        case ProfileActionTypes.ProfilesPageLoaded: {
            return adapter.addMany(action.payload.profiles, {
                ...initialProfilesState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getProfileState = createFeatureSelector<ProfilesState>('profiles');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
