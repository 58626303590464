
import { BaseModel } from '../../_base/crud';

export class Manual extends BaseModel {
    id:                         number;
    create_date:                any;
    create_uid:                 number;
    write_uid:                  number;
    write_date:                 any;
    company_id:                 number;
    pavilion_id:                number;
    pavilion_name:              string;
    pavilion_type_id:           number;
    pavilion_type_name:         string;
    c_dead_bird:                number;
    m_harvest:                  number;
    water_amount:               number;
    c_grain:                    number;
    c_grain_omega:              number;
    c_grain_regular:            number;
    grain_amount:               number;
    r_grain_omega:              number;
    r_grain_regular:            number;
    weight_prom:                number;
    p_uniformity:               number;
    t_min:                      number;
    status_id:                  number;
    status_name:                string;
    t_max:                      number;
    c_dead_bird_b:              number;
    c_dead_bird_w:              number;
    manual_weekly_data_id:      number;
    weekly_avg_weight:          number;
    weekly_p_uni_weight:        number;
    weekly_status_id:           number;
    weekly_avg_weight_lock:     number;
    weekly_p_uni_weight_lock:   number;
    weekly_n_week:              number;
    weekly_status_name:         string;
    write_user_name:            string;

    clear(){
        this.id                         = undefined;                         
        this.create_date                = undefined;                
        this.create_uid                 = undefined;                 
        this.write_uid                  = undefined;                  
        this.write_date                 = undefined;                 
        this.company_id                 = undefined;                 
        this.pavilion_id                = undefined;                
        this.pavilion_name              = undefined;              
        this.pavilion_type_id           = undefined;           
        this.pavilion_type_name         = undefined;         
        this.c_dead_bird                = undefined;                
        this.m_harvest                  = undefined;                  
        this.water_amount               = undefined;               
        this.c_grain                    = undefined;                    
        this.c_grain_omega              = undefined;              
        this.c_grain_regular            = undefined;            
        this.grain_amount               = undefined;               
        this.r_grain_omega              = undefined;              
        this.r_grain_regular            = undefined;            
        this.weight_prom                = undefined;                
        this.p_uniformity               = undefined;               
        this.t_min                      = undefined;                      
        this.status_id                  = undefined;                  
        this.status_name                = undefined;                
        this.t_max                      = undefined;                      
        this.c_dead_bird_b              = undefined;              
        this.c_dead_bird_w              = undefined;              
        this.manual_weekly_data_id      = undefined;      
        this.weekly_avg_weight          = undefined;          
        this.weekly_p_uni_weight        = undefined;        
        this.weekly_status_id           = undefined;           
        this.weekly_avg_weight_lock     = undefined;     
        this.weekly_p_uni_weight_lock   = undefined;   
        this.weekly_n_week              = undefined;              
        this.weekly_status_name         = undefined;  
        this.write_user_name            = undefined;
    }
}