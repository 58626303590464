import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-loadbirds-edit',
  templateUrl: './loadbirds-edit.component.html',
  styleUrls: ['./loadbirds-edit.component.scss']
})
export class LoadbirdsEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
