// Angular
import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
// Layout config
import { LayoutConfigService } from '../../../../../core/_base/layout';
import { PavilionRealtimeService } from '../../../../../core/production';
import { HistorycData } from './../../../../../core/production/_models/HistorycData.model';
import { intervalToDuration, isThisSecond } from 'date-fns';
import { AnyARecord } from 'dns';
import { formatDate } from '@angular/common';
import { forEach, update } from 'lodash';
import { SSL_OP_NO_TLSv1_1 } from 'constants';
import { AlarmCfgModel, AlarmCfgsService } from './../../../../../core/alarms';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Sample components with sample data
 */
@Component({
	selector: 'kt-realTimeTemperature',
	templateUrl: './realTimeTemperature.component.html',
	styleUrls: ['./realTimeTemperature.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class realTimeTemperaturecomponent implements OnInit, OnChanges, OnDestroy {

	// Public properties
	@Input() public historyc: HistorycData;
	@Input() data: { labels: string[], datasets: any[] };
	@Input() type = 'line';
	@ViewChild('chart', { static: true }) chart: ElementRef;
	@Input() parentData: any;
	//@Input() datafecha: {labels: string[]};
	fechaString: string = '';
	//const chart;
	//chart1:any;
	chart1: any;
	myDate = new Date();
	private subscriptions: Subscription[] = [];
	temperatureAlarm: AlarmCfgModel;
	/**
	 * Component constructor
	 * @param layoutConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private pavilionRealtimeService: PavilionRealtimeService,
		private alarmCfgsService: AlarmCfgsService
	) {
		//this.historyc = new HistorycData();
		//this.historyc.clear();
	}


	ngOnChanges(changes: SimpleChanges) {

	}

	ngOnDestroy(){
		this.subscriptions.forEach(sub=>sub.unsubscribe());
	}

	ngOnInit(): void {

	}

	public inicializarConID(id){
		this.subscriptions.push(
			of(null).pipe(
				switchMap(()=>this.alarmCfgsService.getAllAlarmCfgs()),
				switchMap((alarmCfgList)=>{
					this.temperatureAlarm = alarmCfgList.find(alarm=>alarm.alarm_type_name == "PABELLONES-TEMPERATURA" && alarm.pavilion_id==id);
					console.log(`temperature alarm: ${JSON.stringify(this.temperatureAlarm)}`)
					return this.pavilionRealtimeService.pavilion_realtime_last_temp_per_hour(id);
				})
			).subscribe(rsp => {
				console.table(rsp);
				var res : any; res = rsp;
				var yourArray = new Array();
				var maxYcolumn = 0;
				var dateReplica = '';
				var datasetArray = new Array();
				var tmp: any = [];
				var num_min: any =[];
				var num_max: any =[];
				var num_hyl: any =[];
				
				const temperatureAlarm = this.temperatureAlarm;
	
				res.rows.forEach(function (value) {
					//var cutfecha = value.write_date.slice(0,10);
					dateReplica = formatDate(value.write_date, 'HH:MM', 'en-US');
					yourArray.push(dateReplica);//fecha
					
					maxYcolumn = 40;
	
					var p = value.t_min
					var o: number = +p;
	
					var q = value.t_max
					var w: number = +q;
	
					var s = value.t_hyline
					var d: number = +s;
					
					if(temperatureAlarm){
						num_min.push(temperatureAlarm.temp_low_value);
						num_max.push(temperatureAlarm.temp_high_value);
					}
					num_hyl.push(d);
	
				});

				var tmp1 = {
					pavilion_id: 'Minima',
					data: num_min
				};
				var tmp2 = {
					pavilion_id: 'Máxima',
					data: num_max
				};
				var tmp3 = {
					pavilion_id: 'Pabellón',
					data: num_hyl
				};
				if(temperatureAlarm){
					tmp.push(tmp1);
					tmp.push(tmp2);
				}
				tmp.push(tmp3);
				console.log(tmp);
				//console.log(tmax,tmin,thyl);
				//console.log(numero);
				tmp.forEach(function (ArrDash) {
					datasetArray.push({
						fill: false,
						pointHoverRadius: 4,
						pointHoverBorderWidth: 12,
						pointBorderColor: Chart.helpers.color('#8DB580').alpha(1).rgbString(),
						borderColor: Chart.helpers.color('#8DB580').alpha(1).rgbString(),
						borderWidth: 3,
						showLine: true,
						label: ArrDash.pavilion_id,
						data: ArrDash.data
					});
				});
				if(temperatureAlarm){
					datasetArray[0].pointBorderColor = Chart.helpers.color('#d1542a').alpha(1).rgbString();
					datasetArray[0].borderColor = Chart.helpers.color('#d1542a').alpha(1).rgbString();
					datasetArray[1].pointBorderColor = Chart.helpers.color('#d1542a').alpha(1).rgbString();
					datasetArray[1].borderColor = Chart.helpers.color('#d1542a').alpha(1).rgbString();
				}
				function onlyUnique(value, index, self) {
					return self.indexOf(value) === index;
				};
	
				var uniqueDate = yourArray.filter(onlyUnique);
	
				const color = Chart.helpers.color;
				this.data = {
					labels: uniqueDate,
					datasets: datasetArray
				};
				this.initChart(maxYcolumn, this.data, 0);
			})
		)
	}

	public initChart(maxYcolumn, tmpreal, numaux) {

		// For more information about the chartjs, visit this link
		// https://www.chartjs.org/docs/latest/getting-started/usage.html
		//asdf2.destroy();
		if (numaux == 0) {
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Horas [HH:MM:SS]'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 20,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Temperatura [C°]'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 5,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 20,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0,
						bodyFontSize: 24,
						titleFontSize: 24
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});

		}

		if (numaux == 1) {
			this.chart1.destroy();
			this.chart1 = new Chart(this.chart.nativeElement, {
				type: this.type,
				data: tmpreal,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: false,
					scales: {
						xAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							},
							gridLines: false,
							ticks: {
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 20,
								padding: 10
							}
						}],
						yAxes: [{
							categoryPercentage: 0.35,
							barPercentage: 0.70,
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							},
							gridLines: {
								color: this.layoutConfigService.getConfig('colors.base.shape.2'),
								drawBorder: false,
								offsetGridLines: false,
								drawTicks: false,
								borderDash: [3, 4],
								zeroLineWidth: 1,
								zeroLineColor: this.layoutConfigService.getConfig('colors.base.shape.2'),
								zeroLineBorderDash: [3, 4]
							},
							ticks: {
								max: maxYcolumn,
								stepSize: 1000,
								display: true,
								beginAtZero: true,
								fontColor: this.layoutConfigService.getConfig('colors.base.shape.3'),
								fontSize: 20,
								padding: 10
							}
						}]
					},
					title: {
						display: false
					},
					hover: {
						mode: 'index'
					},
					tooltips: {
						enabled: true,
						intersect: false,
						mode: 'nearest',
						bodySpacing: 5,
						yPadding: 10,
						xPadding: 10,
						caretPadding: 0,
						displayColors: false,
						backgroundColor: this.layoutConfigService.getConfig('colors.state.brand'),
						titleFontColor: '#ffffff',
						cornerRadius: 4,
						footerSpacing: 0,
						titleSpacing: 0,
						bodyFontSize: 24,
						titleFontSize: 24
					},
					layout: {
						padding: {
							left: 0,
							right: 0,
							top: 5,
							bottom: 5
						}
					}
				}
			});
		}

	}

}
