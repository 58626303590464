import { Component, OnInit,ViewChild,OnDestroy,ChangeDetectorRef } from '@angular/core';
import {realTimeTemperaturecomponent} from '../../../../partials/content/widgets/realtimeTemperature/realTimeTemperature.component';
import {OnLinePavilionData} from '../../../../../core/production/_models/OnLinePavliionData'
import { BehaviorSubject, combineLatest, interval, merge, of, Subscription } from 'rxjs';
import { EventMqttService } from '../../../../../core/production/_services/EventMqttService';
import { IMqttMessage } from "ngx-mqtt";
import {formatDate } from '@angular/common';
import {PavilionRealtimeService} from '../../../../../core/production/_services/pavilionRealtime.service';
import {PavilionService} from '../../../../../core/plant-configuration/_services/pavilion.service'
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'kt-realtime-dashboard-pav3',
  templateUrl: './realtime-dashboard-pav3.component.html',
  styleUrls: ['./realtime-dashboard-pav3.component.scss']
})
export class RealtimeDashboardPav3Component implements OnInit {
  @ViewChild(realTimeTemperaturecomponent, { static: true }) remoteHistory: realTimeTemperaturecomponent;
  private subscriptions: Subscription[] = [];
  c_pavilion : OnLinePavilionData;
  private acumID: string = 'ACUM';
  private totID: string = 'TOT';
  pavilionName = '';

  private floorsLoaded$ = new BehaviorSubject<boolean>(false);
  private iiotCode$ = new BehaviorSubject<number>(null);
  constructor(
    private readonly eventMqtt: EventMqttService,
    private pavilionRealtimeService: PavilionRealtimeService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
    private pavilionService : PavilionService,
    private cd: ChangeDetectorRef,
  ) {
    
    this.c_pavilion = new OnLinePavilionData();
    this.c_pavilion.clear();
    this.c_pavilion.pavilion_id = 12;
    this.c_pavilion.iiot_code = '3';
    
   }

   ngOnInit() {
    
    this.keepOnDate();//Mantiene boton con fecha actualizada

    const routeSub = this.activatedRoute.params.subscribe(params => {
			let urlSplited = this.router.url.split("/");
			let lastUrlFragment = urlSplited[urlSplited.length-1];
      let iiotCode = -1;
      if(lastUrlFragment.includes('realtime-dashboard-pav')){
        //pabellon
        iiotCode = Number(lastUrlFragment.replace('realtime-dashboard-pav', ''));

      }else{
        //Ave libre
        const num = Number(lastUrlFragment.replace('realtime-dashboard-av', ''));
        iiotCode = 13;
        if(num == 1) iiotCode = 14;
      }
			this.iiotCode$.next(iiotCode);
    });
    this.subscriptions.push(routeSub);
    
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=> combineLatest([this.pavilionService.getAll(), this.iiotCode$])),
        filter(([pavilionList, iiotCode])=>pavilionList!=undefined && pavilionList.length>0 && iiotCode!=null && iiotCode != -1),
        switchMap(([pavilionList, iiotCode])=>{
          const pavilion = pavilionList.find(pav=>pav.iiot_code == `${iiotCode}`);
          this.c_pavilion.pavilion_id = pavilion.id;
          this.c_pavilion.iiot_code = `${iiotCode}`;
          this.pavilionName = `Pabellón ${iiotCode}`;
          if(iiotCode>=13){
            if(iiotCode == 14) this.pavilionName = 'Pabellón GL1';
            if(iiotCode == 13) this.pavilionName = 'Pabellón GL2';
          }
          this.loadFloors();
          return this.floorsLoaded$
        }),
        filter(loaded=>loaded==true),
        take(1)
      ).subscribe(()=>{
        this.fetchData();
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  keepOnDate(){
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
  }

  loadFloors(){
    this.subscriptions.push(
      this.pavilionService.getPavilionFloors(undefined,this.c_pavilion.pavilion_id).subscribe(res => {
        var result: any; result = res;
        result.forEach(element => {
          this.c_pavilion.floor_data.push({
            floor_id : element.id,
            c_bird_type_name : '',
            pavilion_harvest_state : 'R',
            totharvest : 0
          })
        });
        this.floorsLoaded$.next(true);
      })
    );
  }

  //Esta funcion obtiene toda la data necesaria a mostrar
  //Se llama una vez que se obtiene ya el pabellon y sus pisos
  fetchData(){
    this.remoteHistory.inicializarConID(this.c_pavilion.pavilion_id);
    this.subscribeToTopic();
    this.getBirdsPerFloor();//Cantidad y edad de aves por piso
    //GLOBAL DATA
    this.getPavilionGrain();//cantidad y tipo de granos por el pabellon
    this.getPavilionManualData();//alimentacion y agua por el pabellon
    this.getPavilionDeads();//cantidad de muertes del pabellon
    this.getPavilionTemperatureStats();//temperaturas min, max y avg del pabellon
    this.getHarvestPerFloor();//cocecha por piso
  }

  private getHarvestPerFloor(){
    const pavilionId = this.c_pavilion.pavilion_id;
    this.subscriptions.push(
      this.pavilionRealtimeService.production_last_pavilion_harvest_by_id(pavilionId).pipe(
        filter((result:any)=>result!=undefined && result.rows!=undefined && result.rows.length>0),
        map((result:any)=>result.rows),
        map(rows=>{
          console.log("retrieved harvest per pavilion id ", pavilionId);console.dir(rows);
          const birdPerFloorObj = {};
          rows.forEach(row=>{
            if(birdPerFloorObj[row.floor_id]==undefined){
              birdPerFloorObj[row.floor_id] = {
                harvest_tot: row.harvest_tot,
                harvest: 0,
                floor_id: row.floor_id
              }
            }
            birdPerFloorObj[row.floor_id].harvest += Number(row.harvest);
          });
          const birdPerFloor = Object.keys(birdPerFloorObj).sort((a,b)=>Number(a)-Number(b)).map(key=>birdPerFloorObj[key]);
          return birdPerFloor
        })
      ).subscribe((birdPerFloor:any) => {
        this.c_pavilion.totharvest = birdPerFloor[0].harvest_tot;
        birdPerFloor.forEach(row => {
          var floor = this.c_pavilion.floor_data.find(e => e.floor_id == row.floor_id)  
            if (row.harvest > 0){
              floor.totharvest = row.harvest;
              floor.pavilion_harvest_state = 'Y'
            }
        });
        console.dir(this.c_pavilion);
        this.cd.markForCheck();
      })
    )    
  }

  private getPavilionTemperatureStats(){
    this.subscriptions.push(
      this.pavilionRealtimeService.pavilion_realtime_temperature(this.c_pavilion.pavilion_id).subscribe((result:any) => {
        if (result.rows.length > 0){
          this.c_pavilion.t_min = result.rows[0].temp_min;
          this.c_pavilion.t_max = result.rows[0].temp_max;
          this.c_pavilion.t_curr = result.rows[0].temp_curr;
        }
      })
    )    
  }

  private getPavilionDeads(){
    this.subscriptions.push(
      this.pavilionRealtimeService.pavilion_tot_deads(this.c_pavilion.pavilion_id).subscribe((result:any) => {
        if (result.rows.length > 0){
          this.c_pavilion.c_dead_bird = result.rows[0].c_dead_bird;
              if (result.rows[0].c_dead_bird > 0 && this.c_pavilion.c_bird_amount)
                this.c_pavilion.p_dead_bird = (result.rows[0].c_dead_bird/this.c_pavilion.c_bird_amount)*100;
        }
      })
    )
  }

  private getPavilionManualData(){
    this.subscriptions.push(
      this.pavilionRealtimeService.pavilion_manual_daily_data(this.c_pavilion.pavilion_id).subscribe((result:any) => {
        if (result.rows.length > 0){
          if (result.rows[0].pavilion_type_id = 1){
            this.c_pavilion.water_amount = (result.rows[0].water_amount/this.c_pavilion.c_bird_amount)*1000000;
            this.c_pavilion.nourishment_total = Number(result.rows[0].c_grain_regular);this.c_pavilion.nourishment_omega = Number(result.rows[0].c_grain_omega)
            this.c_pavilion.nourishment = Number(Number(result.rows[0].c_grain_regular+result.rows[0].c_grain_omega)/this.c_pavilion.c_bird_amount)*1000;
          }
        }
      })
    )
  }

  private getBirdsPerFloor(){
    this.subscriptions.push(
      this.pavilionRealtimeService.pavilion_realtime_bird_by_floor(this.c_pavilion.pavilion_id).pipe(
        filter((result:any)=>result!=undefined && result.rows!=undefined && result.rows.length>0),
        map((result:any)=>result.rows),
        map(rows=>{
          const birdPerFloorObj = {};
          rows.forEach(row=>{
            if(birdPerFloorObj[row.floor_id]==undefined){
              birdPerFloorObj[row.floor_id] = {
                age_in_weeks: 0,
                c_bird_amount: 0,
                floor_id: row.floor_id,
                bird_type_name: row.bird_type_name
              }
            }
            if(birdPerFloorObj[row.floor_id].c_bird_amount < Number(row.c_bird_amount)){
              birdPerFloorObj[row.floor_id].age_in_weeks = row.age_in_weeks;
              birdPerFloorObj[row.floor_id].bird_type_name = row.bird_type_name
            }
            birdPerFloorObj[row.floor_id].c_bird_amount += Number(row.c_bird_amount);
          });
          const birdPerFloor = Object.keys(birdPerFloorObj).sort((a,b)=>Number(a)-Number(b)).map(key=>birdPerFloorObj[key]);
          return birdPerFloor
        })
      ).subscribe((birdPerFloor:any) => {          
        this.c_pavilion.age_in_weeks = birdPerFloor.map(row=>`${row.age_in_weeks}`).join(', ');

        birdPerFloor.forEach(row => {
          // this.c_pavilion.age_in_weeks = this.c_pavilion.age_in_weeks + row.age_in_weeks + ', '
          this.c_pavilion.c_bird_amount = this.c_pavilion.c_bird_amount + row.c_bird_amount;
          var pav_data = this.c_pavilion.floor_data.find(e => e.floor_id == row.floor_id) 
          if (pav_data){
            pav_data.c_bird_type_name = row.bird_type_name;
            pav_data.bird_amount = row.c_bird_amount;
          }                                 
          this.cd.markForCheck();
        });
      })
    )    
  }

  private getPavilionGrain(){
    this.subscriptions.push(
      this.pavilionRealtimeService.pavilion_realtime_silo_by_pavilion(this.c_pavilion.pavilion_id).subscribe((result:any) => {
        if (result.rows.length > 0){        
          result.rows.forEach(element => {
            this.c_pavilion.grain_name = this.c_pavilion.grain_name + element.grain_name + ', '
            this.c_pavilion.c_grain_amount = Number(this.c_pavilion.c_grain_amount) + Number(element.c_grain_amount)
          });
        }
      })
    )    
  }

  private subscribeToTopic() {
    this.subscriptions.push(this.eventMqtt.topic(this.acumID)
    .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        if (this.c_pavilion.iiot_code == item.Galpon){
           var floor = this.c_pavilion.floor_data.find(e => e.floor_id == Number(item.Piso))
           if (floor){
            if (item.Acumulado > 0){
              floor.pavilion_harvest_state = 'Y';
              if (floor.totharvest == item.Acumulado)
                floor.pavilion_harvest_state = 'G';     //THIS NEEDS TO BE COMPARED WITH THE PROM FROM DB
            }
            else
              floor.pavilion_harvest_state = 'R'
            floor.totharvest = item.Acumulado
            this.cd.markForCheck();
           }
        }
        document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    this.subscriptions.push(this.eventMqtt.topic(this.totID)
    .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());        
        if (item.Galpon == this.c_pavilion.iiot_code){          
          this.c_pavilion.totharvest = Number(item.Total);
        }        
    }));
  }
  
  getItemCssClassBySign(floor_id): string {
    var pav_data = this.c_pavilion.floor_data.find(e => e.floor_id == floor_id) 
    if (pav_data){
      if (pav_data.pavilion_harvest_state == 'R')
        return 'red-sign'
      else if (pav_data.pavilion_harvest_state == 'Y')
        return 'yellow-sign'
      else if (pav_data.pavilion_harvest_state == 'G')
        return 'green-sign'
      else
        return 'red-sign'
    }else
      return 'red-sign'
  }
 
}
