// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { ECommerceModule } from './apps/e-commerce/e-commerce.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
//import { ReportsModule } from './reports/reports.module';

import {PlantConfigurationModule} from './plant-configuration/plant-configuration.module'
import {NourishmentModule}from './nourishment/nourishment.module'
import {ProductionModule} from './production/production.module'
import {BirdTypeModule} from './bird-type/bird-type.module'
import {BreedingModule} from './breeding/breeding.module'
//import {HistorydataModule} from './historydata/historydata.module'
import {VelocityModule} from './velocity/velocity.module'
import {AlarmsModule} from './alarms/alarms.module';
import {GuanocontrolModule} from './guanocontrol/guanocontrol.module';
import {ReportsModule} from './reports/reports.module';
import {OnlineHarvestModule} from './online-harvest/online-harvest.module'
//import { OnlineHarvestComponent } from './online-harvest/online-harvest.component'
//import { ReportsComponent } from './reports/reports.component';

@NgModule({
	declarations: [
		MyPageComponent,
		//OnlineHarvestComponent,
		//ReportsComponent,
		],
	exports: [],
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		CoreModule,
		PartialsModule,
		MailModule,
		ECommerceModule,
		UserManagementModule,
		//ReportsModule,
		PlantConfigurationModule,
		NourishmentModule,
		ProductionModule,
		BirdTypeModule,
		BreedingModule,
		//HistorydataModule,
		VelocityModule,
		AlarmsModule,
		GuanocontrolModule,
		ReportsModule,
		OnlineHarvestModule
		//ReportsComponent
	],
	providers: []
})
export class PagesModule {
}
