// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef,ElementRef,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter } from 'rxjs/operators';
import { each, find, remove } from 'lodash';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	Pavilion, 
	PavilionAdd, 
	PavilionEdit, 
	selectLastCreatedPavilionId,
	selectPavilionById, 
	Silo, 
	SilosDataSource, 
	SiloGetAll, 
	PavilionType, 
	pavilionTypesLoaded, 
	selectPavilionTypes, 
	PavilionGetTypes,
	PavilionService 
} from '../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';

@Component({
  selector: 'kt-pavilion-edit',
  templateUrl: './pavilion-edit.component.html',
  styleUrls: ['./pavilion-edit.component.scss']
})
export class PavilionEditComponent implements OnInit, OnDestroy {

	@ViewChild('wizard', {static: true}) el: ElementRef;

	// Public properties
	pavilion: Pavilion;
	pavilionId$: Observable<number>;
	oldPavilion: Pavilion;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	pavilionForm: FormGroup;
	hasFormErrors = false;
	filteredSilos: Observable<Silo[]>;
	filteredTypes: Observable<PavilionType[]>;
	siloDataSource: SilosDataSource;
	silosResult: Silo[] = [];
	pavilionTypesResult: PavilionType[] = [];
	selectedSilo: Silo = null;
	selectedPavilionType: PavilionType = null;
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;


	//allContractSystems$: Observable<SystemModel[]>;
	floorSubject = new BehaviorSubject<number[]>([]);
  	allFloor = [];
	unassignedFloor = [];
	assignedFloor = [];
	floorIdForAdding: number;

	siloSubject = new BehaviorSubject<number[]>([]);
  	allSilo = [];
	unassignedSilo = [];
	assignedSilo = [];
	siloIdForAdding: number;
	  


	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private pavilionService: PavilionService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("pavilion edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
    
    	// Init DataSource
		/*this.siloDataSource = new SilosDataSource(this.store);
		
		const silos$ = this.siloDataSource.entitySubject.pipe(distinctUntilChanged());*/
		const pavTypesLoaded$ = this.store.pipe(
			select(pavilionTypesLoaded),
			filter((pavTypesLoaded)=>pavTypesLoaded)
		);
		const pavTypes$ = this.store.pipe(
			select(selectPavilionTypes)
		);
		

		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				//const pavilion$ = this.store.pipe(select(selectPavilionById(id)));
				this.store.pipe(select(selectPavilionById(id))).subscribe(res => {
					if (res){
						this.pavilion = res;
						//console.log('WTF');
						//console.table(this.pavilion);
						this.oldPavilion = Object.assign({}, this.pavilion);
						this.initPavilion();
						const sub = combineLatest([pavTypesLoaded$, pavTypes$]).subscribe(
							([pavTypesLoaded, pavTypes])=>{
								if(pavTypesLoaded && pavTypes){
									this.pavilionTypesResult = pavTypes;
									//this.silosResult = silos;
									//this.loadPavilion(pavilion);
								}							
							}
						)
					}
				})
				
				//this.subscriptions.push(sub);
			} else {
				// console.log("default pavilion");
				//const newPavilion = new Pavilion();
				//newPavilion.clear();
				this.pavilion = new Pavilion();
				this.pavilion.clear();
				this.oldPavilion = Object.assign({}, this.pavilion);
				this.initPavilion();

				/*const sub = combineLatest([pavTypesLoaded$, pavTypes$, silos$]).subscribe(
					([pavTypesLoaded, pavTypes, silos])=>{
						if(silos && pavTypesLoaded && pavTypes){
							this.pavilionTypesResult = pavTypes;
							this.silosResult = silos;
							this.loadPavilion(newPavilion);
						}							
					}
				)
				this.subscriptions.push(sub);*/
			}
		});
		this.getCombos();
		//this.loadSilos();
		this.loadPavilionTypes();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		this.subscriptions.push(authSubscription);
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		
		

	}
	getCombos(){
		this.pavilionService.getAllFloors().subscribe(res => {
			this.allFloor = res;
			this.unassignedFloor = res;
			if (this.pavilion.id > 0){				
				if (this.pavilion.floor_ids){
					
					var f_ids : any; f_ids = this.pavilion.floor_ids 
					f_ids.forEach(element => {
						this.floorIdForAdding = element;
						this.assignFloor();
					});
					//console.table(this.assignedFloor);
				}
			}
		});
		this.pavilionService.getAllSilos().subscribe(res => {
			this.allSilo = res;
			this.unassignedSilo = res;
			if (this.pavilion.id > 0){				
				if (this.pavilion.silo_ids){
					//console.table(this.pavilion.silo_ids)
					var s_ids : any; s_ids = this.pavilion.silo_ids 
					s_ids.forEach(element => {
						this.siloIdForAdding = element;
						this.assignSilo();
					});
				}
			}
		});
	}


	loadPavilionTypes(){
		this.store.dispatch(new PavilionGetTypes({}));
	}

	/*loadPavilion(_pavilion, fromService: boolean = false) {
		if (!_pavilion) {
			this.goBack('');
		}
		//console.table(_pavilion)
		this.pavilion = _pavilion;
		this.pavilionId$ = of(_pavilion.id);
		this.oldPavilion = Object.assign({}, _pavilion);

		this.initPavilion();

		//this.selectedPavilionType = new PavilionType();
		//this.selectedPavilionType.clear();
		//this.selectedPavilionType.id = this.pavilion.type_id;
		//this.selectedPavilionType.name = this.pavilion.type;
		//console.log("selected pavilion type: "+JSON.stringify(this.selectedPavilionType));

		//this.selectedSilo = new Silo();
		//this.selectedSilo.clear();
		//this.selectedSilo.name = this.pavilion.silo_name;
		//this.selectedSilo.id = this.pavilion.silo_id;
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}*/
  
	loadSilos(){
		this.store.dispatch(new SiloGetAll({}));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init pavilion
	 */
	initPavilion() {
		//console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.pavilion.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de pabellones', page: `/plant-configuration/pavilion` },
				{ title: 'Crear Pabellón', page: `/plant-configuration/pavilion/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar pabellón');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de pabellones', page: `/plant-configuration/pavilion` },
			{ title: 'Editar pabellón', page: `/plant-configuration/pavilion/edit`, queryParams: { id: this.pavilion.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		this.pavilionForm = this.formBuilder.group({
			name: [this.pavilion.name, Validators.required],
			iotCode: [this.pavilion.iiot_code, Validators.required],
			pav_group_id: [this.pavilion.pav_group_id, ],
			floor_ids: [this.pavilion.floor_ids,],
			silo_ids: [this.pavilion.silo_ids, ],
			type: [this.pavilion.type_id, Validators.required],
		});
		
		this.cdr.detectChanges();
	}

	/**
	 * Filter manufacture
	 *
	 * @param val: string
	 */
	filterSilo(val: string): Silo[] {
		if (this.silosResult){
		return this.silosResult.filter(silo =>
			silo.name.toLowerCase().includes(val.toLowerCase()));
		}
		return [];
	}

	/**
	 * Filter color
	 *
	 * @param val: string
	 */
	filterType(val: string): PavilionType[] {
		return this.pavilionTypesResult.filter(pavType =>
			pavType.name.toLowerCase().includes(val.toLowerCase()));
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/pavilion', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh pavilion
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshPavilion(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/pavilion/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}
	assignFloor() {
		//var floor_id = this.pavilionForm.get('floor_ids').value
		if (this.floorIdForAdding === 0)
			return;	
		const floor = find(this.allFloor, (_floor: FloorModel) => {
		  return _floor.id === (+this.floorIdForAdding);
		});
		if (floor){
			this.assignedFloor.push(floor);
			remove(this.unassignedFloor, (el) => el.id === floor.id);
			this.floorIdForAdding = 0;
			this.updateFloors();
		}
	}
	unassignFloor(floor: FloorModel) {
		this.floorIdForAdding = 0;
		this.unassignedFloor.push(floor);
		remove(this.assignedFloor, el => el.id === floor.id);
		this.updateFloors();
	}
	updateFloors() {
		const _floors = [];
		each(this.assignedFloor, elem => _floors.push(elem.id));
		this.floorSubject.next(_floors);
	}

	assignSilo() {		
		if (this.siloIdForAdding === 0) {
		  return;
		}
		const silo = find(this.allSilo, (_silo: SiloModel) => {
		  return _silo.id === (+this.siloIdForAdding);
		});		
		if (silo){
			this.assignedSilo.push(silo);
			remove(this.unassignedSilo, (el) => el.id === silo.id);
			this.siloIdForAdding = 0;
			this.updateSilos();
		} 
			
	}
	unassignSilo(silo: SiloModel) {
		this.siloIdForAdding = 0;
		this.unassignedSilo.push(silo);
		remove(this.assignedSilo, el => el.id === silo.id);
		this.updateSilos();
	}
	updateSilos() {
		const _silos = [];
		each(this.assignedSilo, elem => _silos.push(elem.id));
		this.siloSubject.next(_silos);
	}

	/**
	 * Reset
	 */
	reset() {
		this.pavilion = Object.assign({}, this.oldPavilion);
		this.createForm();
		this.hasFormErrors = false;
		this.pavilionForm.markAsPristine();
		this.pavilionForm.markAsUntouched();
		this.pavilionForm.updateValueAndValidity();
	}

	selectPavilionType(pavilionType:PavilionType){
		console.log("select pavilion type: "+JSON.stringify(pavilionType));
		this.selectedPavilionType = pavilionType;
	}

	selectSilo(silo: Silo){
		this.selectedSilo = silo;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.pavilionForm.controls;
		/** check form */
		if (this.pavilionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let editedPavilion = this.preparePavilion();

		if (editedPavilion.id > 0) {
			//console.table(editedPavilion);
			this.updatePavilion(editedPavilion, withBack);
			return;
		}

		this.addPavilion(editedPavilion, withBack);
	}

	/**
	 * Returns object for saving
	 */
	preparePavilion(): Pavilion {
		const controls 			= this.pavilionForm.controls;
		const pavilion 			= new Pavilion();
		pavilion.id 			= this.pavilion.id;
		pavilion.name 			= controls.name.value;
		pavilion.iiot_code 		= controls.iotCode.value;
		pavilion.silo_ids 		= this.assignedSilo;
		pavilion.floor_ids 		= this.assignedFloor;
		pavilion.type_id 		= controls.type.value;
		pavilion.write_uid		= this.user.id;
		return pavilion;
	}

	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/pavilion?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Add pavilion
	 *
	 * @param _pavilion: Pavilion
	 * @param withBack: boolean
	 */
	addPavilion(pavilion: Pavilion, withBack: boolean = false) {
		this.loadingSubject.next(true);
		// this.store.dispatch(new ProductOnServerCreated({ product: _product }));
		this.store.dispatch(new PavilionAdd({pavilion: pavilion}));
		this.componentSubscriptions = this.store.pipe(
			delay(1000),
			select(selectLastCreatedPavilionId)
		).subscribe(newId => {
			if (!newId) {
				return;
			}

			this.loadingSubject.next(false);
			//if (withBack) {
				this.goBack(newId);
			/*} else {
				const message = `New pavilion successfully has been added.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshPavilion(true, newId);
			}*/
		});
	}

	/**
	 * Update pavilion
	 *
	 * @param pavilion: Pavilion
	 * @param withBack: boolean
	 */
	goBackWithId() {
		const url = `/plant-configuration/pavilion`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	  }

	updatePavilion(pavilion: Pavilion, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updatePavilion: Update<Pavilion> = {
			id: pavilion.id,
			changes: pavilion
		};

		this.store.dispatch(new PavilionEdit({
			partialPavilion: updatePavilion,
			pavilion: pavilion
		}));

		of(undefined).pipe(delay(3000)).subscribe(() => { // Remove this line
			//if (withBack) {
				//this.goBack(pavilion.id);
				const message = `El pabellón fue exitosamente guardado.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshPavilion(false);
				this.goBackWithId();
			/*} else {
				const message = `Pavilion successfully has been saved.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshPavilion(false);
			}*/
		}); // Remove this line
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear pabellón';
		if (!this.pavilion || !this.pavilion.id) {
			return result;
		}

		result = `Editar ${this.pavilion.type} - ${this.pavilion.name} `;
		return result;
	}
	getSelectedFloors(){
		if (this.assignedFloor.length > 0){
			var floors = '';
			this.assignedFloor.forEach(element => {
				floors = floors + element.name + ', '
			});
			return floors;
		}
	}
	getSelectedSilos(){
		if (this.assignedSilo.length > 0){
			var silos = '';
			this.assignedSilo.forEach(element => {
				silos = silos + element.name + ', '
			});
			return silos;
		}
	}
	getName(){
		//this.pavilionForm.get('name').value
		return this.pavilion.name
	}
	getType(){
		//this.pavilionForm.get('type').value
		return this.pavilion.type
	}
	getIIOTCode(){
		//this.pavilionForm.get('iotCode').value
		return this.pavilion.iiot_code
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}
interface FloorModel {
	id : number,
	name : string,
}
interface SiloModel {
	id : number,
	name : string,
}