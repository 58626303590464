import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
    SiloActionTypes,
    SiloAdd,
    SiloAdded,
    SiloAllLoaded,
    SiloDelete,
    SiloDeleted,
    SiloEdit,
    SiloEdited,
    SiloGetAll,
    SiloGetPage,
    SiloPageLoaded
} from '../_actions/silo.actions';
import { SiloService } from '../_services/silo.service';


@Injectable()
export class SiloEffects {

    constructor(
        private actions: Actions,
        private siloService: SiloService,
        private router: Router,
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_ADD),
        map((action: SiloAdd) => action.payload),
        switchMap(payload => {
            return this.siloService.add(payload.silo).pipe(
                map((siloId)=>{ //Adecuarse a la response recibida de api real
                    //console.log("add silo");
                    //console.log(JSON.stringify(payload.silo));
                    let silo = {
                        ...payload.silo,
                        id: siloId
                    };
                    return new SiloAdded({silo: silo});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_EDIT),
        map((action: SiloEdit) => action.payload),
        switchMap(payload => {
            return this.siloService.edit(payload.silo).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("edit silo");
                    //console.log(JSON.stringify(payload.silo));
                    return new SiloEdited({partialSilo: payload.silo});
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_DELETE),
        map((action: SiloDelete) => action.payload),
        switchMap(payload => {
            return this.siloService.delete(payload.silo).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("delete silo");
                    //console.log(JSON.stringify(payload.silo));
                    return new SiloDeleted({id: payload.silo.id});
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_GET_PAGE),
        map((action: SiloGetPage) => action.payload),
        switchMap(payload => {
            return this.siloService.getPage(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    //console.log("get page silo");
                    //console.log(JSON.stringify(payload.silo));
                    return {silos: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new SiloPageLoaded(response);
        })
    );

    @Effect()
    getAll: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_GET_ALL),
        map((action: SiloGetAll) => action.payload),
        switchMap(payload => {            
            return this.siloService.getAll().pipe(
                map((silos)=>{ //Adecuarse a la response recibida de api real
                    //console.log("get all silos");
                    //console.log(JSON.stringify(silos));
                    return silos
                })
            );
        }),
        map((silos)=>{
            return new SiloAllLoaded({silos: silos});
        })
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_PAGE_LOADED),
        //tap((action:SiloPageLoaded)=>console.log("silo page loaded"))
    );

    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_ALL_LOADED),
        //tap((action:SiloAllLoaded)=>console.log("silo all loaded"))
    );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_ADDED),
        //tap((action:SiloAdded)=>console.log("silo added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_EDITED),
        //tap((action:SiloEdited)=>console.log("silo edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(SiloActionTypes.SILO_DELETED),
        //tap((action:SiloDeleted)=>console.log("silo deleted"))
    );
}