import { BaseModel } from '../../_base/crud';

export class Bird extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name:string;
    bird_type_id: number;
    bird_type: string;
    egg_type_id: number;
    egg_type: string;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.name               = "";
        this.bird_type_id       = undefined;
        this.bird_type          = "";
        this.egg_type_id        = undefined;
        this.egg_type           = "";
    }
}

export class BirdType{
    id: number;
    name: string;
    clear(){
        this.id     = undefined;
        this.name   = "";
    }
}

export class BirdStatusType{
    id: number;
    name: string;
    clear(){
        this.id     = undefined;
        this.name   = "";
    }
}

export class BirdGeneration{
    id: number;
    name: string;
    clear(){
        this.id     = undefined;
        this.name   = "";
    }
}