import { Action } from '@ngrx/store';

export enum BirdStockActionTypes {
    BS_GET_MOVS_PAGE = '[BS] BSGetMovsPage',
    BS_MOVS_PAGE_LOADED = '[BS] BSMovPageLoaded',
    BS_DELETE_MOV = '[BS] BSMovDelete',
    BS_MOV_DELETED = '[BS] BSMovDeleted',
    BS_EDIT_MOV = '[BS] BSEditMov',
    BS_MOV_EDITED = '[BS] BSMovEdited',
    BS_ADD_MOV = '[BS] BSAddMov',
    BS_MOV_ADDED = '[BS] BSMovAdded',
    BS_GET_BREEDING_PAGE = '[BS] BSGetBreedingPage',
    BS_BREEDING_PAGE_LOADED = '[BS] BSPageLoaded',
    BS_LOAD_BREEDING = '[BS] BSLoadBreeding',
    BS_BREEDING_LOADED = '[BS] BSBreedingLoaded',
    BS_UPDATE_BREEDING  = '[BS] BSUpdateBreeding',
    BS_BREEDING_UPDATED = '[BS] BSBreedingUpdated',
    BS_DELETE_BREEDING  = '[BS] BSDeleteBreeding',
    BS_BREEDING_DELETED = '[BS] BSBreedingDeleted',
    BS_GET_BIRD_TYPES = '[BS] BSGetBirdTypes',
    BS_BIRD_TYPES_LOADED = '[BS] BSBirdTypesLoaded',
    BS_GET_BIRD_STATUS_TYPES = '[BS] BSGetBirdStatusTypes',
    BS_BIRD_STATUS_TYPES_LOADED = '[BS] BSBirdStatusTypesLoaded',
    BS_GET_BIRD_GENS = '[BS] BSGetBirdGens',
    BS_SEARCH_BIRD_GENS = '[BS] BSSearchBirdGens',
    BS_BIRD_GENS_LOADED = '[BS] BSBirdGensLoaded',
    BS_GET_PAV_GENS = '[BS] BSGetPavGens',
    BS_PAV_GENS_LOADED = '[BS] BSPavGensLoaded',
    BS_GET_PAVILION_GENS = '[BS] BSGetPavilionGens',
    BS_PAVILION_GENS_LOADED = '[BS] BSPavilionGensLoaded',
    // BS_PAV_LOAD_BIRDS = '[BS] BSPavLoadBirds',
    // BS_PAV_EDIT_LOAD = '[BS] BSPavEditLoad',
    // BS_PAV_DELETE_LOAD = '[BS] BSPavDeleteLoad',
    // BS_PAV_BIRDS_LOADED = '[BS] BSPavBirdsLoaded',
    // BS_PAV_LOAD_EDITED = '[BS] BSPavLoadEdited',
    // BS_PAV_LOAD_DELETED = '[BS] BSPavLoadDeleted',
    // BS_PAV_LOAD_LOAD_PAGE = '[BS] BSPavLoadLoadPage',
    // BS_PAV_LOAD_PAGE_LOADED = '[BS] BSPavLoadPageLoaded',
    // BS_BRE_LOAD_BIRDS = '[BS] BSBreLoadBirds',
    // BS_BRE_EDIT_LOAD = '[BS] BSBreEditLoad',
    // BS_BRE_DELETE_LOAD = '[BS] BSBreDeleteLoad',
    // BS_BRE_BIRDS_LOADED = '[BS] BSBreBirdsLoaded',
    // BS_BRE_LOAD_EDITED = '[BS] BSBreLoadEdited',
    // BS_BRE_LOAD_DELETED = '[BS] BSBreLoadDeleted',
    // BS_BRE_LOAD_LOAD_PAGE = '[BS] BSBreLoadLoadPage',
    // BS_BRE_LOAD_PAGE_LOADED = '[BS] BSBreLoadPageLoaded',
    BS_ADD_TRANSFER = '[BS] BSAddTransfer',
    BS_TRANSFER_ADDED = '[BS] BSTransferAdded',
    BS_EDIT_TRANSFER = '[BS] BSEditTransfer',
    BS_TRANSFER_EDITED = '[BS] BSTransferEdited',
    BS_DELETE_TRANSFER = '[BS] BSDeleteTransfer',
    BS_TRANSFER_DELETED = '[BS] BSTransferDeleted',
    BS_TRANSFER_LOAD_PAGE = '[BS] BSTransferLoadPage',
    BS_TRANSFER_PAGE_LOADED = '[BS] BSTransferPageLoaded',
    BS_FAILURE = '[BS] BSFailure'
}

export class BSGetMovsPage implements Action {
    readonly type = BirdStockActionTypes.BS_GET_MOVS_PAGE;
    constructor(public payload: any) {}
}

export class BSMovsPageLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_MOVS_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class BSAddMov implements Action {
    readonly type = BirdStockActionTypes.BS_ADD_MOV;
    constructor(public payload: any) {}
}

export class BSMovAdded implements Action {
    readonly type = BirdStockActionTypes.BS_MOV_ADDED;
    constructor(public payload: any) {}
}

export class BSDeleteMov implements Action {
    readonly type = BirdStockActionTypes.BS_DELETE_MOV;
    constructor(public payload: any) {}
}

export class BSMovDeleted implements Action {
    readonly type = BirdStockActionTypes.BS_MOV_DELETED;
    constructor(public payload: any) {}
}

export class BSEditMov implements Action {
    readonly type = BirdStockActionTypes.BS_EDIT_MOV;
    constructor(public payload: any) {}
}

export class BSMovEdited implements Action {
    readonly type = BirdStockActionTypes.BS_MOV_EDITED;
    constructor(public payload: any) {}
}

export class BSGetBreedingPage implements Action {
    readonly type = BirdStockActionTypes.BS_GET_BREEDING_PAGE;
    constructor(public payload: any) {}
}

export class BSBreedingPageLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_BREEDING_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class BSGetBirdTypes implements Action {
    readonly type = BirdStockActionTypes.BS_GET_BIRD_TYPES;
    constructor(public payload: any) {}
}

export class BSBirdTypesLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_BIRD_TYPES_LOADED;
    constructor(public payload: any) {}
}

export class BSGetBirdStatusTypes implements Action {
    readonly type = BirdStockActionTypes.BS_GET_BIRD_STATUS_TYPES;
    constructor(public payload: any) {}
}

export class BSBirdStatusTypesLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_BIRD_STATUS_TYPES_LOADED;
    constructor(public payload: any) {}
}

export class BSBirdGensLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_BIRD_GENS_LOADED;
    constructor(public payload: any) {}
}

export class BSGetBirdGens implements Action {
    readonly type = BirdStockActionTypes.BS_GET_BIRD_GENS;
    constructor(public payload: any) {}
}

export class BSSearchBirdGens implements Action {
    readonly type = BirdStockActionTypes.BS_SEARCH_BIRD_GENS;
    constructor(public payload: any) {}
}

// export class BSPavLoadBirds implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_LOAD_BIRDS;
//     constructor(public payload: any) {}
// }

// export class BSPavEditLoad implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_EDIT_LOAD;
//     constructor(public payload: any) {}
// }

// export class BSPavDeleteLoad implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_DELETE_LOAD;
//     constructor(public payload: any) {}
// }

// export class BSPavBirdsLoaded implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_BIRDS_LOADED;
//     constructor(public payload: any) {}
// }

// export class BSPavLoadEdited implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_LOAD_EDITED;
//     constructor(public payload: any) {}
// }

// export class BSPavLoadDeleted implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_LOAD_DELETED;
//     constructor(public payload: any) {}
// }

// export class BSPavLoadLoadPage implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_LOAD_LOAD_PAGE;
//     constructor(public payload: any) {}
// }

// export class BSPavLoadPageLoaded implements Action {
//     readonly type = BirdStockActionTypes.BS_PAV_LOAD_PAGE_LOADED;
//     constructor(public payload: any) {}
// }

// export class BSBreLoadBirds implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_LOAD_BIRDS;
//     constructor(public payload: any) {}
// }

// export class BSBreEditLoad implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_EDIT_LOAD;
//     constructor(public payload: any) {}
// }

// export class BSBreDeleteLoad implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_DELETE_LOAD;
//     constructor(public payload: any) {}
// }

// export class BSBreBirdsLoaded implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_BIRDS_LOADED;
//     constructor(public payload: any) {}
// }

// export class BSBreLoadEdited implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_LOAD_EDITED;
//     constructor(public payload: any) {}
// }

// export class BSBreLoadDeleted implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_LOAD_DELETED;
//     constructor(public payload: any) {}
// }

// export class BSBreLoadLoadPage implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_LOAD_LOAD_PAGE;
//     constructor(public payload: any) {}
// }

// export class BSBreLoadPageLoaded implements Action {
//     readonly type = BirdStockActionTypes.BS_BRE_LOAD_PAGE_LOADED;
//     constructor(public payload: any) {}
// }

export class BSAddTransfer implements Action {
    readonly type = BirdStockActionTypes.BS_ADD_TRANSFER;
    constructor(public payload: any) {}
}

export class BSTransferAdded implements Action {
    readonly type = BirdStockActionTypes.BS_TRANSFER_ADDED;
    constructor(public payload: any) {}
}

export class BSEditTransfer implements Action {
    readonly type = BirdStockActionTypes.BS_EDIT_TRANSFER;
    constructor(public payload: any) {}
}

export class BSTransferEdited implements Action {
    readonly type = BirdStockActionTypes.BS_TRANSFER_EDITED;
    constructor(public payload: any) {}
}

export class BSDeleteTransfer implements Action {
    readonly type = BirdStockActionTypes.BS_DELETE_TRANSFER;
    constructor(public payload: any) {}
}

export class BSTransferDeleted implements Action {
    readonly type = BirdStockActionTypes.BS_TRANSFER_DELETED;
    constructor(public payload: any) {}
}

export class BSTransferLoadPage implements Action {
    readonly type = BirdStockActionTypes.BS_TRANSFER_LOAD_PAGE;
    constructor(public payload: any) {}
}

export class BSTransferPageLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_TRANSFER_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class BSLoadBreeding implements Action {
    readonly type = BirdStockActionTypes.BS_LOAD_BREEDING;
    constructor(public payload: any) {}
}

export class BSBreedingLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_BREEDING_LOADED;
    constructor(public payload: any) {}
}

export class BSBreedingUpdated implements Action {
    readonly type = BirdStockActionTypes.BS_BREEDING_UPDATED;
    constructor(public payload: any) {}
}

export class BSUpdateBreeding implements Action {
    readonly type = BirdStockActionTypes.BS_UPDATE_BREEDING;
    constructor(public payload: any) {}
}

export class BSGetPavGens implements Action {
    readonly type = BirdStockActionTypes.BS_GET_PAV_GENS;
    constructor(public payload: any) {}
}

export class BSPavGensLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_PAV_GENS_LOADED;
    constructor(public payload: any) {}
}

export class BSGetPavilionGens implements Action {
    readonly type = BirdStockActionTypes.BS_GET_PAVILION_GENS;
    constructor(public payload: any) {}
}

export class BSPavilionGensLoaded implements Action {
    readonly type = BirdStockActionTypes.BS_PAVILION_GENS_LOADED;
    constructor(public payload: any) {}
}

export class BSDeleteBreeding implements Action {
    readonly type = BirdStockActionTypes.BS_DELETE_BREEDING;
    constructor(public payload: any) {}
}

export class BSBreedingDeleted implements Action {
    readonly type = BirdStockActionTypes.BS_BREEDING_DELETED;
    constructor(public payload: any) {}
}

export class BSFailure implements Action {
    readonly type = BirdStockActionTypes.BS_FAILURE;
    constructor(public payload: any) {}
}

export type BirdStockActions =
                // | BSBreBirdsLoaded
                // | BSBreDeleteLoad
                // | BSBreLoadBirds
                // | BSPavBirdsLoaded
                // | BSPavLoadDeleted
                // | BSPavDeleteLoad
                // | BSPavLoadBirds
                // | BSPavLoadLoadPage
                // | BSPavLoadPageLoaded
                // | BSPavEditLoad
                // | BSPavLoadEdited
                // | BSBreLoadDeleted
                // | BSBreEditLoad
                // | BSBreLoadEdited
                // | BSBreLoadLoadPage
                // | BSBreLoadPageLoaded
                | BSGetMovsPage
                | BSMovsPageLoaded
                | BSEditMov
                | BSMovEdited
                | BSDeleteMov
                | BSMovDeleted
                | BSAddMov
                | BSMovAdded
                | BSGetBreedingPage
                | BSBreedingPageLoaded
                | BSAddTransfer
                | BSTransferAdded
                | BSEditTransfer
                | BSTransferEdited
                | BSDeleteTransfer
                | BSTransferDeleted
                | BSTransferLoadPage
                | BSTransferPageLoaded
                | BSGetBirdTypes
                | BSBirdTypesLoaded
                | BSGetBirdStatusTypes
                | BSBirdStatusTypesLoaded
                | BSGetBirdGens
                | BSBirdGensLoaded
                | BSSearchBirdGens
                | BSBreedingLoaded
                | BSLoadBreeding
                | BSUpdateBreeding
                | BSBreedingUpdated
                | BSGetPavGens
                | BSPavGensLoaded
                | BSBreedingDeleted
                | BSDeleteBreeding
                | BSGetPavilionGens
                | BSPavilionGensLoaded
                | BSFailure;