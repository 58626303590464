import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { Profiles } from '../_models/profile.model'
import { catchError, map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import {BASE_URL,API_PERMISSION_URL,API_ROLES_URL,API_USERS_URL} from '../../connection.data';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}
    // Authentication/Authorization
    /*login(email: string, password: string): Observable<User> {
        return this.http.post<User>(API_USERS_URL, { email, password });
    }*/
    login(email: string, password: string): Observable<{user:User, token:string}> {        
        const url = `${API_USERS_URL}/login`;
        return this.http.post<{user: User, token:string}>(url, { email, password }).pipe(
            map((rsp)=>{
                if(rsp["status"]=="success"){
                    let user:User = new User();
                    const data = rsp["rows"][0];
                    user.id = data.uid;
                    user.active = data.active;
                    user.login = data.login;
                    user.refreshToken = data.token;
                    user.group_id = data.res_group;
                    user.group_name = data.group_name;
                    user.username = data.partner_name;
                    user.partner_id = data.partner_id;
                    user.fullname = user.username;
                    user.company_id = data.company_id;
                    return {user:user, token:rsp["token"]};
                }
                return null;
            })
        );
    }

    getUserByToken(): Observable<User> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + userToken);
        return this.http.get<User>(`${API_USERS_URL}/token-login`, { headers: httpHeaders }).pipe(
            map((rsp)=>{
                if(rsp["status"]=="success"){
                    let user:User = new User();
                    const data = rsp["rows"][0];
                    user.id = data.uid;
                    user.active = data.active;
                    user.login = data.login;
                    user.refreshToken = data.token;
                    user.group_id = data.res_group;
                    user.group_name = data.group_name;
                    user.username = data.partner_name;
                    user.partner_id = data.partner_id;
                    user.fullname = user.username;
                    user.company_id = data.company_id;
                    return user;
                }
                return null;
            })
        );
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
    	return this.http.get(API_USERS_URL + '/forgot?=' + email)
    		.pipe(catchError(this.handleError('forgot-password', []))
	    );
    }


    /*getAllUsers(): Observable<User[]> {
		return this.http.get<User[]>(API_USERS_URL);
    }*/
    getAllUsers(): Observable<User[]> {
        const url = `${API_USERS_URL}/findUsers`;
        return this.http.get<User[]>(url);        
    }
    getUsers(): Observable<any[]> {
        const url = `${API_USERS_URL}/users`;
        return this.http.get<any>(url);        
    }

    getUserById(userId: number): Observable<User> {
        const url = `${API_USERS_URL}/users/${userId}`;
		return this.http.get<User>(url);
    }
    toggleUsers(login: string): Observable<any> {
        const url = `${API_USERS_URL}/toggleUsers?login=${login}`;
		return this.http.get<any>(url);
	}
    // DELETE => delete the user from the server
	deleteUser(userId: number) {
        const url = `${API_USERS_URL}/${userId}`;
        return this.http.delete(url);
        
    }
    
    // UPDATE => PUT: update the user on the server
	updateUser(_user: User): Observable<any> {
        const url = `${API_USERS_URL}/`;
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.put(url, _user, { headers: httpHeaders });
	}

    // CREATE =>  POST: add a new user to the server
	createUser(user: User): Observable<User> {
        const url = `${API_USERS_URL}/`;
    	const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<User>(url, user, { headers: httpHeaders});
    }


    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const url = `${API_USERS_URL}/findUsers`;
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(url, queryParams, { headers: httpHeaders});
    }
    updateStatusForUser(user: User, status: boolean): Observable<any> {
		const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		/*const body = {
			userForUpdate: users,
			status: Boolean
        };*/
        const url = `${API_USERS_URL}/userStatusUpdate`;
		//const url = API_PRODUCTS_URL + '/updateStatus';
		return this.http.put(url, user, { headers: httpHeaders });
	}

    // Permission
    getAllPermissions(): Observable<Permission[]> {
		return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }
    getAllProfiles():Observable<Profiles[]> {
        const url = `${API_USERS_URL}/getAllProfiles`;
        return this.http.get<Profiles[]>(url);
    }

    getRoleById(roleId: number): Observable<Role> {
		return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }
    getProfileById(profileId:number):Observable<Profiles[]> {
        const url = `${API_USERS_URL}/getProfileById`;
        return this.http.get<Profiles[]>(url+ `/${profileId}`);
    }

    // CREATE =>  POST: add a new role to the server
	createRole(role: Role): Observable<Role> {
		// Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders});
	}

    // UPDATE => PUT: update the role on the server
	updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
    }
    
    // UPDATE => PUT: update the role on the server
	updateProfile(profile: Profiles): Observable<any> {
        const url = `${API_USERS_URL}/updateProfile`;
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.put(url, profile, { headers: httpHeaders });
    }

	// DELETE => delete the role from the server
	deleteRole(roleId: number): Observable<Role> {
		const url = `${API_ROLES_URL}/${roleId}`;
		return this.http.delete<Role>(url);
	}

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders});
    }
    findProfiles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
		      return this.http.post<QueryResultsModel>(API_USERS_URL + '/findProfiles', queryParams, { headers: httpHeaders});
    }

 	/*
 	 * Handle Http operation that failed.
 	 * Let the app continue.
     *
	 * @param operation - name of the operation that failed
 	 * @param result - optional value to return as the observable result
 	 */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
