import { Action } from '@ngrx/store';

export enum DeadsActionTypes {
    DEADS_EDIT = '[DEADS] DeadsEdit',
    DEADS_EDITED = '[DEADS] DeadsEdited',
    DEADS_ADD = '[DEADS] DeadsAdd',
    DEADS_ADDED = '[DEADS] DeadsAdded',
    DEADS_DELETE = '[DEADS] DeadsDelete',
    DEADS_DELETED = '[DEADS] DeadsDeleted',
    DEADS_GET_PAGE = '[DEADS] DeadsGetPage',
    DEADS_PAGE_LOADED = '[DEADS] DeadsPageLoaded'
}

export class DeadsAdd implements Action {
    readonly type = DeadsActionTypes.DEADS_ADD;
    constructor(public payload: any) {}
}

export class DeadsEdit implements Action {
    readonly type = DeadsActionTypes.DEADS_EDIT;
    constructor(public payload: any) {}
}

export class DeadsGetPage implements Action {
    readonly type = DeadsActionTypes.DEADS_GET_PAGE;
    constructor(public payload: any) {}
}

export class DeadsPageLoaded implements Action {
    readonly type = DeadsActionTypes.DEADS_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class DeadsAdded implements Action {
    readonly type = DeadsActionTypes.DEADS_ADDED;
    constructor(public payload: any) {}
}

export class DeadsEdited implements Action {
    readonly type = DeadsActionTypes.DEADS_EDITED;
    constructor(public payload: any) {}
}

export class DeadsDelete implements Action {
    readonly type = DeadsActionTypes.DEADS_DELETE;
    constructor(public payload: any) {}
}

export class DeadsDeleted implements Action {
    readonly type = DeadsActionTypes.DEADS_DELETED;
    constructor(public payload: any) {}
}

export type DeadsActions =
                | DeadsAdd
                | DeadsEdit
                | DeadsGetPage
                | DeadsPageLoaded
                | DeadsAdded
                | DeadsDeleted
                | DeadsDelete 
                | DeadsEdited;