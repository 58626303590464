// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Alarm } from '../_models/alarm.model';
import { Update } from '@ngrx/entity';

export enum AlarmActionTypes {
    AlarmOnServerCreated = '[Edit Alarm Component] Alarm On Server Created',
    AlarmCreated = '[Edit Alarm Component] Alarm Created',
    AlarmUpdated = '[Edit Alarm Component] Alarm Updated',
    AlarmsStatusUpdated = '[Alarms List Page] Alarms Status Updated',
    OneAlarmDeleted = '[Alarms List Page] One Alarm Deleted',
    ManyAlarmsDeleted = '[Alarms List Page] Many Selected Alarms Deleted',
    AlarmsPageRequested = '[Alarms List Page] Alarms Page Requested',
    AlarmsPageLoaded = '[Alarms API] Alarms Page Loaded',
    AlarmsPageCancelled = '[Alarms API] Alarms Page Cancelled',
    AlarmsPageToggleLoading = '[Alarms] Alarms Page Toggle Loading',
    AlarmsActionToggleLoading = '[Alarms] Alarms Action Toggle Loading',
    AlarmsGetTypes = '[Alarms] Alarms get types',
    AlarmsTypesLoaded = '[Alarms] Alarms types loaded',
    AlarmsGetStatusTypes = '[Alarms] Alarms get status types',
    AlarmsStatusTypesLoaded = '[Alarms] Alarms status types loaded'
}

export class AlarmOnServerCreated implements Action {
    readonly type = AlarmActionTypes.AlarmOnServerCreated;
    constructor(public payload: { alarm: Alarm }) { }
}

export class AlarmCreated implements Action {
    readonly type = AlarmActionTypes.AlarmCreated;
    constructor(public payload: { alarm: Alarm }) { }
}

export class AlarmUpdated implements Action {
    readonly type = AlarmActionTypes.AlarmUpdated;
    constructor(public payload: {
        partialAlarm: Update<Alarm>, // For State update
        alarm: Alarm // For Server update (through service)
    }) { }
}

export class AlarmsStatusUpdated implements Action {
    readonly type = AlarmActionTypes.AlarmsStatusUpdated;
    constructor(public payload: {
        alarms: Alarm[],
        status: number
    }) { }
}

export class OneAlarmDeleted implements Action {
    readonly type = AlarmActionTypes.OneAlarmDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAlarmsDeleted implements Action {
    readonly type = AlarmActionTypes.ManyAlarmsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AlarmsPageRequested implements Action {
    readonly type = AlarmActionTypes.AlarmsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AlarmsPageLoaded implements Action {
    readonly type = AlarmActionTypes.AlarmsPageLoaded;
    constructor(public payload: { alarms: Alarm[], totalCount: number, page: QueryParamsModel }) { }
}

export class AlarmsPageCancelled implements Action {
    readonly type = AlarmActionTypes.AlarmsPageCancelled;
}

export class AlarmsPageToggleLoading implements Action {
    readonly type = AlarmActionTypes.AlarmsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AlarmsActionToggleLoading implements Action {
    readonly type = AlarmActionTypes.AlarmsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AlarmsGetTypes implements Action {
    readonly type = AlarmActionTypes.AlarmsGetTypes;
    constructor(public payload: any) { }
}

export class AlarmsTypesLoaded implements Action {
    readonly type = AlarmActionTypes.AlarmsTypesLoaded;
    constructor(public payload: any) { }
}

export class AlarmsGetStatusTypes implements Action {
    readonly type = AlarmActionTypes.AlarmsGetStatusTypes;
    constructor(public payload: any) { }
}

export class AlarmsStatusTypesLoaded implements Action {
    readonly type = AlarmActionTypes.AlarmsStatusTypesLoaded;
    constructor(public payload: any) { }
}

export type AlarmActions = AlarmOnServerCreated
| AlarmCreated
| AlarmUpdated
| AlarmsStatusUpdated
| OneAlarmDeleted
| ManyAlarmsDeleted
| AlarmsPageRequested
| AlarmsPageLoaded
| AlarmsPageCancelled
| AlarmsPageToggleLoading
| AlarmsActionToggleLoading
| AlarmsGetTypes
| AlarmsTypesLoaded
| AlarmsGetStatusTypes
| AlarmsStatusTypesLoaded;