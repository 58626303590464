// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { PavilionerState, PavilionState, SpecificDashboardState } from '../_reducers/pavilion.reducers';
import { Floor, Pavilion, Pavilioner, PavilionType, SpecificDashboard } from '../_models/pavilion.model';

export const selectPavilionsState = createFeatureSelector<PavilionState>('pavilions');

export const selectPavilionById = (pavilionId: number) => createSelector(
    selectPavilionsState,
    pavilionsState => pavilionsState.entities[pavilionId]
);

export const selectPavilionsPageLoading = createSelector(
    selectPavilionsState,
    pavilionsState => pavilionsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectPavilionsState,
//     customersState => customersState.actionsloading
// );

export const selectPavilionsPageLastQuery = createSelector(
    selectPavilionsState,
    pavilionsState => pavilionsState.lastQuery
);

export const selectLastCreatedPavilionId = createSelector(
    selectPavilionsState,
    pavilionsState => pavilionsState.lastCreatedPavId
);

export const selectPavilionsInitWaitingMessage = createSelector(
    selectPavilionsState,
    pavilionsState => pavilionsState.showInitWaitingMessage
);

export const selectPavilionsInStore = createSelector(
    selectPavilionsState,
    pavilionsState => {
        const items: Pavilion[] = [];
        each(pavilionsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Pavilion[] = httpExtension.sortArray(items, pavilionsState.lastQuery.sortField, pavilionsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, pavilionsState.totalCount, '');
    }
);

export const selectHasPavilionsInStore = createSelector(
    selectPavilionsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//PAVILION TYPES
export const selectPavilionTypesState = state => state.pavilionTypes;

export const pavilionTypesLoaded = createSelector(
    selectPavilionTypesState,
    pavilionTypesState => pavilionTypesState.pavilionTypesLoaded
)

export const selectPavilionTypes = createSelector(
    selectPavilionTypesState,
    pavilionTypesState => {
        const items: PavilionType[] = [];
        each(pavilionTypesState.pavilionTypes, element => {
            items.push(element);
        });
        return items;
    }
)

//PAVILION FLOORS
export const selectFloorsState = state => state.pavilionFloors;

export const pavilionFloorsLoaded = createSelector(
    selectFloorsState,
    pavilionFloorsState => pavilionFloorsState.floorsLoaded
)

export const selectPavilionFloors = createSelector(
    selectFloorsState,
    pavilionFloorsState => {
        const items: Floor[] = [];
        each(pavilionFloorsState.floors, element => {
            items.push(element);
        });
        return items;
    }
)

//PAVILIONERS
export const selectPavilionersState = createFeatureSelector<PavilionerState>('pavilioners');

export const selectPavilionerById = (partnerId: number) => createSelector(
    selectPavilionersState,
    pavilionersState => pavilionersState.entities[partnerId]
);

export const selectPavilionersPageLoading = createSelector(
    selectPavilionersState,
    pavilionersState => pavilionersState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectPavilionersState,
//     customersState => customersState.actionsloading
// );

export const selectPavilionersPageLastQuery = createSelector(
    selectPavilionersState,
    pavilionersState => pavilionersState.lastQuery
);

export const selectLastCreatedPavilionerId = createSelector(
    selectPavilionersState,
    pavilionersState => pavilionersState.lastCreatedPartnerId
);

export const selectPavilionersInitWaitingMessage = createSelector(
    selectPavilionersState,
    pavilionersState => pavilionersState.showInitWaitingMessage
);

export const selectPavilionersInStore = createSelector(
    selectPavilionersState,
    pavilionersState => {
        const items: Pavilioner[] = [];
        each(pavilionersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Pavilioner[] = httpExtension.sortArray(items, pavilionersState.lastQuery.sortField, pavilionersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, pavilionersState.totalCount, '');
    }
);

export const selectHasPavilionersInStore = createSelector(
    selectPavilionersInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//Specific dashboard
export const selectSpecificDashboardsState = createFeatureSelector<SpecificDashboardState>('specificDashboards');

export const selectSpecificDashboardById = (partnerId: number) => createSelector(
    selectSpecificDashboardsState,
    specificDashboardsState => specificDashboardsState.entities[partnerId]
);

export const selectSpecificDashboardsPageLoading = createSelector(
    selectSpecificDashboardsState,
    specificDashboardsState => specificDashboardsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectSpecificDashboardsState,
//     customersState => customersState.actionsloading
// );

export const selectSpecificDashboardsPageLastQuery = createSelector(
    selectSpecificDashboardsState,
    specificDashboardsState => specificDashboardsState.lastQuery
);

export const selectSpecificDashboardsInitWaitingMessage = createSelector(
    selectSpecificDashboardsState,
    specificDashboardsState => specificDashboardsState.showInitWaitingMessage
);

export const selectSpecificDashboardsInStore = createSelector(
    selectSpecificDashboardsState,
    specificDashboardsState => {
        const items: SpecificDashboard[] = [];
        each(specificDashboardsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SpecificDashboard[] = httpExtension.sortArray(items, specificDashboardsState.lastQuery.sortField, specificDashboardsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, specificDashboardsState.totalCount, '');
    }
);

export const selectHasSpecificDashboardsInStore = createSelector(
    selectSpecificDashboardsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);