import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { of, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../../../../core/_base/crud';
import { ManualService, PavilionService } from '../../../../../core/plant-configuration';

@Component({
  selector: 'kt-manual-list',
  templateUrl: './manual-list.component.html',
  styleUrls: ['./manual-list.component.scss']
})
export class ManualListComponent implements OnInit, OnDestroy, AfterViewInit {

  filterList = [
    {name: 'pavilion_type_id', label: 'Tipo de pabellón', value: '-1', optionList: [], type: 'list'},
    {name: 'pavilion_id', label: 'Pabellón', value: '-1', optionList: [], type: 'list'},
    {name: 'st', label: 'Desde', value: null, type: 'date'},
    {name: 'et', label: 'Hasta', value: null, type: 'date'}
  ];
  actions = {edit:true, delete: false, add: false};
  columnList = [
    // {name: 'id' , displayName: 'ID', def: 'id'},
    // {name: 'name' , displayName: 'Nombre', def: 'name'},
    // {name: 'id', displayName: '', def: 'id' },
    {name: 'create_date', displayName: 'Fecha', def: 'create_date', isDate: true},
    // {name: 'create_uid', displayName: '', def: 'create_uid' },
    {name:'pavilion_name', displayName: 'Pabellón', def: 'pavilion_name' },
    // {name:'pavilion_type_id', displayName: '', def: 'pavilion_type_id' },
    // {name:'pavilion_type_name', displayName: 'Tipo pabellón', def: 'pavilion_type_name' },
    {name: 'write_user_name', displayName: 'Autor última modificación', def: 'write_user_name' },
    {name: 'write_date', displayName: 'Última modificación', def: 'write_date', isDate: true },
    // {name: 'company_id', displayName: '', def: 'company_id' },
    // {name: 'pavilion_id', displayName: '', def: 'pavilion_id' },
    
    {name:'c_dead_bird', displayName: 'Total muertes', def: 'c_dead_bird' },
    // {name:'m_harvest', displayName: '', def: 'm_harvest' },
    {name:'water_amount', displayName: 'Medidor de agua', def: 'water_amount' },
    {name:'c_grain', displayName: 'Consumo granos (crianza)', def: 'c_grain' },
    {name:'c_grain_omega', displayName: 'Consumo granos omega', def: 'c_grain_omega' },
    {name:'c_grain_regular', displayName: 'Consumo granos regulares', def: 'c_grain_regular' },
    // {name:'grain_amount', displayName: '', def: 'grain_amount' },
    {name:'r_grain_omega', displayName: 'Recepción granos omega', def: 'r_grain_omega' },
    {name:'r_grain_regular', displayName: 'Recepción granos regulares', def: 'r_grain_regular' },
    // {name:'weight_prom', displayName: '', def: 'weight_prom' },
    // {name:'p_uniformity', displayName: '', def: 'p_uniformity' },
    // {name:'t_min', displayName: '', def: 't_min' },
    // {name:'status_id', displayName: '', def: 'status_id' },
    {name:'status_name', displayName: 'Estado', def: 'status_name' },
    // {name:'t_max', displayName: '', def: 't_max' },
    {name:'c_dead_bird_b', displayName: 'Muertes brown', def: 'c_dead_bird_b' },
    {name:'c_dead_bird_w', displayName: 'Muertes white', def: 'c_dead_bird_w' },
    // {name:'manual_weekly_data_id', displayName: '', def: 'manual_weekly_data_id' },
    {name:'weekly_avg_weight', displayName: 'Peso promedio semanal', def: 'weekly_avg_weight' },
    {name:'weekly_p_uni_weight', displayName: 'Uniformidad de peso semanal', def: 'weekly_p_uni_weight' },
    // {name:'weekly_status_id', displayName: '', def: 'weekly_status_id' },
    // {name:'weekly_avg_weight_lock', displayName: '', def: 'weekly_avg_weight_lock' },
    // {name:'weekly_p_uni_weight_lock', displayName: '', def: 'weekly_p_uni_weight_lock' },
    // {name:'weekly_n_week', displayName: '', def: 'weekly_n_week' },
    // {name:'weekly_status_name', displayName: '', def: 'weekly_status_name' },
  ];
  inited = false;
  dataObs:Subject<QueryResultsModel> = new Subject<QueryResultsModel>();
  deleteObs:Subject<string> = new Subject<string>();
  subscriptions:Subscription[] = [];
  
  constructor(
    public dialog: MatDialog,
    private manualService: ManualService,
    private pavilionService: PavilionService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.inited = true;

    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionService.getTypes())
      ).subscribe(pavilionTypes=>{
        this.filterList[0].optionList = pavilionTypes.map(ptype=>{
          return {value: ptype.id, text: ptype.name};
        })
      })
    );

    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionService.getAll())
      ).subscribe(pavilionList=>{
        this.filterList[1].optionList = pavilionList.map(pav=>{
          return {value: pav.id, text: pav.name};
        })
      })
    );
  }

  deleteOne(manualId:number){
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.manualService.delete({id: manualId}))
      ).subscribe(status=>{
        this.deleteObs.next(status);
      })
    );    
  }

  getPage(query: QueryParamsModel){
    // console.log(`query get page: ${JSON.stringify(query)}`);
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.manualService.getPage(query))
      ).subscribe(res=>{
        // console.log(`query result: ${JSON.stringify(res)}`);
        this.dataObs.next(res);
      })
    ); 
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  ngAfterViewInit() {
  }
}