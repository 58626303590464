// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';
import { BirdTypeDashboardComponent } from './bird-type-dashboard/bird-type-dashboard.component';
import { BirdTypeComponent } from './bird-type.component'
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';
import { 
	pavilionsReducer, 
	pavilionTypesReducer, 
	pavilionersReducer, 
	pavilionFloorsReducer, 
	PavilionEffects,
	breedingStockReducer, 
	stockMovReducer, 
	birdTypesReducer, 
	birdStatusTypesReducer, 
	birdGensReducer, 
	BirdStockEffects, 
	hylineReducer, 
	hylineDataReducer, 
	hylineBreDataReducer, 
	hylineHavDataReducer, 
	hylineEMoltDataReducer, 
	hylineLMoltDataReducer, 
	hylineTypesReducer, 
	hylineDataTypeReducer, 
	HylineEffects 
} from '../../../core/plant-configuration';

const routes: Routes = [
	{
		path: '',
		component: BirdTypeComponent,
		children: [
			{
				path: '',
				redirectTo: 'bird-type-dashboard',
				pathMatch: 'full'
			},
			{
				path: 'bird-type-dashboard',
				component: BirdTypeDashboardComponent
			}
		]
	}
];

@NgModule({
  
  imports: [
	  	CommonModule,
    	HttpClientModule,
		PartialsModule,
		RouterModule.forChild(routes),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		StoreModule.forFeature('pavilions', pavilionsReducer),
		StoreModule.forFeature('pavilionTypes', pavilionTypesReducer),
		StoreModule.forFeature('pavilioners', pavilionersReducer),
		StoreModule.forFeature('pavilionFloors', pavilionFloorsReducer),
		EffectsModule.forFeature([PavilionEffects]),
		StoreModule.forFeature('breedingStocks', breedingStockReducer),
		StoreModule.forFeature('birdStockMovs', stockMovReducer),
		StoreModule.forFeature('birdTypes', birdTypesReducer),
		StoreModule.forFeature('birdStatusTypes', birdStatusTypesReducer),
		StoreModule.forFeature('birdGens', birdGensReducer),
		EffectsModule.forFeature([BirdStockEffects]),	
		StoreModule.forFeature('hylines', hylineReducer),
		StoreModule.forFeature('hylineDatas', hylineDataReducer),
		StoreModule.forFeature('hylineBreDatas', hylineBreDataReducer),
		StoreModule.forFeature('hylineHavDatas', hylineHavDataReducer),
		StoreModule.forFeature('hylineEMoltDatas', hylineEMoltDataReducer),
		StoreModule.forFeature('hylineLMoltDatas', hylineLMoltDataReducer),
		StoreModule.forFeature('hylineTypes', hylineTypesReducer),
		StoreModule.forFeature('hylineDataTypes', hylineDataTypeReducer),
		EffectsModule.forFeature([HylineEffects]),
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
  ],
  entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
  declarations: [
    BirdTypeDashboardComponent,
    BirdTypeComponent
  ],
})
export class BirdTypeModule { }
