// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, debounceTime, switchMapTo, tap, switchMap } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
	GrainStock,
  SilosDataSource,
  Silo,
  selectLastCreatedGrainStockConsumptionId,
  selectLastUpdatedGrainStockConsumptionId,
  GrainStockConsumptionDataSource,
  selectGrainStockConsumptionById,
  SiloGetPage,
  GrainEditStockConsumption, 
  GrainFindStockConsumption, 
  GrainConsumeStock,
  SiloGetAll,
  GrainStockService
} from '../../../../../core/plant-configuration';
import { User, UsersDataSource } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';


@Component({
  selector: 'kt-consumption-edit',
  templateUrl: './consumption-edit.component.html',
  styleUrls: ['./consumption-edit.component.scss']
})
export class ConsumptionEditComponent implements OnInit, OnDestroy {
	@ViewChild('wizard', {static: true}) el: ElementRef;

	// Public properties
	grainStock: GrainStock;
	grainStockId$: Observable<number>;
	oldGrainStock: GrainStock;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	grainStockForm: FormGroup;
	hasFormErrors = false;
	filteredSilos: Observable<Silo[]>;
	grainStocksDataSource: GrainStockConsumptionDataSource;
	grainStocksResult: GrainStock[] = [];
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;
	silosDataSource: SilosDataSource;
	silosResult: Silo[];
	selectedSilo: Silo;
	public model: any;
	search = (text$: Observable<string>)=>
		text$.pipe(
			debounceTime(200),
			tap((val)=>this.loadSilos()),
			switchMapTo(this.filteredSilos)
		);
	// search = (text$: Observable<string>) =>
	// 	text$.pipe(
	// 		debounceTime(200),
	// 		map(term => term === '' ? []
	// 		: this.filterSilo(term))
	// 	);
	formatter = (silo: Silo) => silo.name;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef,
		private grainStockService: GrainStockService
		) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("stock movement edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
    
    	// Init DataSource
		this.grainStocksDataSource = new GrainStockConsumptionDataSource(this.store);
		const grainStocks$ = this.grainStocksDataSource.entitySubject.pipe(distinctUntilChanged());
		this.silosDataSource = new SilosDataSource(this.store);

		const silos$ = this.silosDataSource.entitySubject.pipe(
			distinctUntilChanged()
		);
		const silosSub = silos$.subscribe((silos)=>{
			this.silosResult = silos;
			console.log("silos retrieved: "+JSON.stringify(silos));
		});
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.subscriptions.push(
					of(null).pipe(
						switchMap(()=>this.grainStockService.findStockConsumption(Object.assign(new GrainStock(), {id:id})))
					).subscribe((result)=>{
						const grainConsumption = result.items[0];
						this.loadGrainStock(grainConsumption);
						this.loadWizzard();
					})
				)
				

				// const grainStock$ = this.store.pipe(
				// 	select(selectGrainStockConsumptionById(id))
				// );
				// const sub = grainStock$.subscribe(
				// 	(grainStock)=>{
				// 		if(grainStock){
				// 			console.log("grainstock found: "+JSON.stringify(grainStock));
				// 			this.loadGrainStock(grainStock);
				// 		}							
				// 	}
				// );
				// this.subscriptions.push(sub);
				// let grainStock = new GrainStock();
				// grainStock.id = id;
				// this.findGrainStock(grainStock);
			} else {
				var grainStock:GrainStock = new GrainStock();
				grainStock.clear();
				this.loadGrainStock(grainStock);
				this.loadWizzard();
			}
		});
		console.log("load silos")
		this.loadSilos();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		this.subscriptions.push(authSubscription);
		this.subscriptions.push(silosSub);
		
		this.subscriptions.push(authSubscription);
	}

	loadWizzard(){
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}
	loadSilos(){
    // const queryParams = new QueryParamsModel(
    //   {"name": this.grainStockForm.controls.silo.value},
    //   "asc",
    //   "id",
    //   0,
    //   5
    // );
	// 	this.store.dispatch(new SiloGetPage({
    //   page: queryParams
    // }));
		this.store.dispatch(new SiloGetAll({}));
	}

	findGrainStock(grainStock:GrainStock){
    	console.log("se despacha grain find stock load con objeto: "+JSON.stringify({grainStock:grainStock}));
		this.store.dispatch(new GrainFindStockConsumption({grainStock:grainStock}));
	}

	loadGrainStock(grainStock: GrainStock, fromService: boolean = false) {
		if (!grainStock) {
			this.goBack('');
		}
		this.grainStock = Object.assign({}, grainStock);
		this.grainStockId$ = of(grainStock.id);
		this.oldGrainStock = Object.assign({}, grainStock);
		
		this.selectedSilo = new Silo();
		this.selectedSilo.clear();
		this.selectedSilo.id = grainStock.silo_id;
		this.selectedSilo.name = grainStock.silo_name;

		this.initGrainStock();
		
		// if (fromService) {
		this.cdr.detectChanges();
		// }
  }

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init grainStock
	 */
	initGrainStock() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.grainStock.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de consumos', page: `/nourishment/consumption` },
				{ title: 'Crear consumo', page: `/nourishment/consumption/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Assign consumption');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de consumos', page: `/nourishment/consumption` },
			{ title: 'Editar consumo', page: `/nourishment/consumption/edit`, queryParams: { id: this.grainStock.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		console.log("creating grainStock form");
		console.log(JSON.stringify(this.grainStock));
		this.grainStockForm = this.formBuilder.group({
			silo: [{value: this.grainStock.silo_name?this.grainStock.silo_name:'none', disabled:false}, Validators.required],
			grainAmount: [this.grainStock.grain_amount, Validators.required],
		});
		// console.log("grainStock form created");
		// console.log(this.grainStockForm);
		// console.log(typeof(this.grainStockForm));
		// console.log(JSON.stringify(this.grainStockForm));
		this.grainStockForm.get("grainAmount").valueChanges.subscribe(val=>{
			this.grainStock = this.prepareData();
		})

		this.cdr.detectChanges();
  	}
  
	siloKeyup(event:KeyboardEvent){
		// console.log(JSON.stringify(event));
		// console.log(JSON.stringify(event.keyCode));
		// if(event.keyCode>31 && event.keyCode<127){
		this.loadSilos();
		// }
	}

	/**
	 * Filter silo
	 *
	 * @param val: string
	 */
	filterSilo(val: string): Silo[] {
		if (this.silosResult){
			let filteredSilos = this.silosResult.filter(silo =>
			  silo.name.toLowerCase().includes(val.toLowerCase()));
		  this.selectedSilo = null;
		  if(filteredSilos && filteredSilos.length>0){
			  let siloCoincidences = filteredSilos.filter((silo)=>silo.name.toLowerCase() == val.toLowerCase());
			  if(siloCoincidences && siloCoincidences.length>0){
				  this.selectSilo(siloCoincidences[0]);
			  }
		  }
		  return filteredSilos;
	  }
	  return [];
	}

	findSilo(name:String):Silo{
		console.log(JSON.stringify(this.silosResult));
		return this.silosResult.filter(silo=>silo.name.toLowerCase() == name.toLowerCase())[0];
	}
	
	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/nourishment/consumption?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/nourishment/consumption', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh grainStock
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshGrainStock(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/nourishment/consumption/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.grainStock = Object.assign({}, this.oldGrainStock);
		this.createForm();
		this.hasFormErrors = false;
		this.grainStockForm.markAsPristine();
		this.grainStockForm.markAsUntouched();
		this.grainStockForm.updateValueAndValidity();
	}

	selectSilo(silo: Silo){
		this.selectedSilo = silo;
		console.log("select silo")
		this.grainStock = this.prepareData();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.grainStockForm.controls;
		/** check form */
		if (this.grainStockForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let grainStock = this.prepareData(true);

		if (grainStock.id > 0) {
			this.updateGrainStock(grainStock, withBack);
			return;
		}

		this.addGrainStock(grainStock, withBack);
	}

	/**
	 * Returns object for saving
	 */
	prepareData(updateCGrainAmount:boolean=false): GrainStock {
		const controls 			= this.grainStockForm.controls;
		let grainStock: GrainStock = Object.assign(new GrainStock(), this.grainStock);
		grainStock.grain_amount = parseInt(this.grainStockForm.get("grainAmount").value);
		grainStock.silo_id = this.selectedSilo.id;
		grainStock.company_id = this.user.company_id;
		grainStock.write_uid = this.user.id;
		grainStock.silo_name = this.selectedSilo.name;
		if(this.grainStock.id==undefined||(this.grainStock.id && this.oldGrainStock.silo_id != this.selectedSilo.id)){
			grainStock.capacity = this.selectedSilo.capacity;
			grainStock.pavilion_id = this.selectedSilo.pavilion_id;
			grainStock.pavilion_name = this.selectedSilo.pavilion_name;
			grainStock.pavilion_type_name = this.selectedSilo.pavilion_type_name;
			grainStock.pavilion_type_id = this.selectedSilo.pavilion_type_id;
			grainStock.p_reorder = this.selectedSilo.p_reorder;
			grainStock.c_grain_amount = this.selectedSilo.c_grain_amount;
			grainStock.grain_type = this.selectedSilo.grain_type;
			grainStock.grain_type_id = this.selectedSilo.grain_type_id;
		}
		if(updateCGrainAmount){
			if(this.oldGrainStock.grain_amount && this.oldGrainStock.silo_id==this.selectedSilo.id){
				grainStock.c_grain_amount = grainStock.c_grain_amount + this.oldGrainStock.grain_amount - grainStock.grain_amount;
			}else{
				grainStock.c_grain_amount = grainStock.c_grain_amount - grainStock.grain_amount;
			}
		}
		console.log("grain amount value: "+controls.grainAmount.value);
		console.log("selected silo: "+JSON.stringify(this.selectedSilo));
		console.log("prepared grain stock: "+JSON.stringify(grainStock));
		return grainStock;
	}

	/**
	 * Add GrainStock
	 *
	 * @param grainStock: GrainStock
	 * @param withBack: boolean
	 */
	addGrainStock(grainStock:GrainStock, withBack:boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new GrainConsumeStock({grainStock: grainStock}));
		const addBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastCreatedGrainStockConsumptionId)
		).subscribe(newId => {
			if (!newId || newId==-1) {
				// addBreedingSub.unsubscribe();
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Consumo ha sido registrado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshGrainStock(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update grainStock
	 *
	 * @param grainStock: GrainStock
	 * @param withBack: boolean
	 */
	updateGrainStock(grainStock: GrainStock, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateGrainStock: Update<GrainStock> = {
			id: grainStock.id,
			changes: grainStock
		};
		console.log("dispatching edit stock consumption.. payload: "+JSON.stringify({
			partialGrainStock: updateGrainStock,
			grainStock:grainStock
		}));
		this.store.dispatch(new GrainEditStockConsumption({
			partialGrainStock: updateGrainStock,
			grainStock:grainStock
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedGrainStockConsumptionId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
			}
			if (withBack) {
				this.goBack(grainStock.id);
			} else {
				const message = `Consumo ha sido actualizado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.grainStock = grainStock;
				this.refreshGrainStock(true, grainStock.id);
			}
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
  }

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear consumo';
		if (!this.grainStock || !this.grainStock.id) {
			return result;
		}
		//console.log("title... grainstock: "+JSON.stringify(this.grainStock));
		result = `Editar consumo - ${this.grainStock.grain_amount} de ${this.grainStock.silo_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}