import { BaseModel } from '../../_base/crud';

export class OnLinePavilionData extends BaseModel {
  id : number;
  totharvest ?: number;
  iiot_code ?: string;
  pavilion_harvest_state ?: string;
  pavilion_id ?: number;
  c_bird_amount ?: number;
  c_bird_type_name ?:string;
  age_in_weeks ?: string;
  c_grain_amount ?: number;
  grain_name ?: string;   
  nourishment ?: number;
  nourishment_total ?: number;
  nourishment_omega ?: number;
  c_dead_bird ?: number;
  p_dead_bird ?: number;
  water_amount ?: number;
  t_min ?: number;
  t_max ?: number;
  t_curr ?: number;
  floor_data : any;
  guano_control:string;
  p_uniformity ?: number;
  weight_prom ?: number;
  water ?: number;
  cnt ?: number;
  //nourishment : number;
  clear(){
    this.id = 0;
    this.iiot_code = '0';
    this.totharvest = 0;
    this.pavilion_harvest_state = 'R';
    this.pavilion_id = 0;
    this.c_bird_amount = 0;
    this.c_bird_type_name = '';
    this.age_in_weeks = '';
    this.nourishment_total=0;
    this.grain_name = '';
    this.c_dead_bird = 0;
    this.nourishment = 0;
    this.t_min = 0;
    this.t_max = 0;
    this.t_curr = 0;
    this.p_dead_bird = 0;
    this.floor_data = [];
    this.c_grain_amount = 0;
    this.guano_control = '';
    //Breeding
    this.p_uniformity = 0;
    this.weight_prom = 0;
    this.water = 0;
    this.cnt = 0;
    this.nourishment_omega = 0;
    //this.nourishment = 0;

  }
}