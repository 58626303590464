// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, tap, debounceTime } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	Pavilion, 
	WaterConsumption,
	PavilionsDataSource,
	selectWaterConsumptionById,
	WaterConsumptionsDataSource,
  selectLastCreatedWaterConsumptionId,
  WaterConsumptionEdit,
  selectLastUpdatedWaterConsumptionId,
  PavilionGetAll,
  WaterConsumptionAdd
} from '../../../../../core/plant-configuration';
import { User, UsersDataSource } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-water-consumption-edit',
  templateUrl: './water-consumption-edit.component.html',
  styleUrls: ['./water-consumption-edit.component.scss']
})
export class WaterConsumptionEditComponent implements OnInit, OnDestroy {
	@ViewChild('wizard', {static: true}) el: ElementRef;
  // Public properties
  
	waterConsumption: WaterConsumption;
	waterConsumptionId$: Observable<number>;
	oldWaterConsumption: WaterConsumption;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	waterConsumptionForm: FormGroup;
  hasFormErrors = false;
  pavilionsResult :Pavilion[] = [];
  selectedPavilion: Pavilion = new Pavilion();
	private subscriptions: Subscription[] = [];
	private user:User = new User();
  private pavilionsDataSource: PavilionsDataSource;
	private editing = false;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("waterConsumption edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
		// Init DataSource
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const entitiesSubscription = this.pavilionsDataSource.entitySubject.pipe(
			distinctUntilChanged()
		).subscribe(res => {
			this.pavilionsResult = res;
			if(res){
				if(!this.editing){
					this.selectPavilion(res[0]);
				}
				this.cdr.detectChanges();
			}
		});
		this.subscriptions.push(entitiesSubscription);
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.editing = true;
				const waterConsumption$ = this.store.pipe(
					select(selectWaterConsumptionById(id))
				);
				const sub = waterConsumption$.subscribe(
					(waterConsumption)=>{
						if(waterConsumption){
							this.loadWaterConsumption(waterConsumption);
						}							
					}
				)
				this.subscriptions.push(sub);
			} else {
				var waterConsumption:WaterConsumption = new WaterConsumption();
				waterConsumption.clear();
				this.loadWaterConsumption(waterConsumption);
			}
		});
		this.loadPavilions();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
		this.subscriptions.push(authSubscription);
  }

  loadPavilions(){
    this.store.dispatch(new PavilionGetAll({}));
  }
  
	loadWaterConsumption(waterConsumption: WaterConsumption, fromService: boolean = false) {
		if (!waterConsumption) {
			this.goBack('');
		}
		this.waterConsumption = Object.assign(new WaterConsumption(), waterConsumption);
		this.waterConsumptionId$ = of(waterConsumption.id);
		this.oldWaterConsumption = Object.assign({}, waterConsumption);
		
		this.selectedPavilion = new Pavilion();
		this.selectedPavilion.clear();
		this.selectedPavilion.id = waterConsumption.pavilion_id;
		this.selectedPavilion.name = waterConsumption.pavilion_name;

		this.initWaterConsumption();
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init waterConsumption
	 */
	initWaterConsumption() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.waterConsumption.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de consumos de agua', page: `/nourishment/water-movements` },
				{ title: 'Crear consumo de agua', page: `/nourishment/water-movements/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Crear consumo de agua');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de consumos de agua', page: `/nourishment/water-movements` },
			{ title: 'Editar consumo de agua', page: `/nourishment/water-movements/edit`, queryParams: { id: this.waterConsumption.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// console.log("creating waterConsumption form");
		// console.log(JSON.stringify(this.waterConsumption));
		this.waterConsumptionForm = this.formBuilder.group({
			pavilion: [this.selectedPavilion.id, Validators.required],
			water_measured_level: [this.waterConsumption.water_measured_level, Validators.required],
		});
		const controls = this.waterConsumptionForm.controls;
    const waterMeasuredLevelSub = controls.water_measured_level.valueChanges.subscribe(x=>{
			this.waterConsumption.water_measured_level = x;
		});
		this.subscriptions.push(waterMeasuredLevelSub);
		this.cdr.detectChanges();
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/nourishment/water-movements?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/nourishment/water-movements', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh waterConsumption
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshWaterConsumption(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/nourishment/water-movements/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.waterConsumption = Object.assign({}, this.oldWaterConsumption);
		this.createForm();
		this.hasFormErrors = false;
		this.waterConsumptionForm.markAsPristine();
		this.waterConsumptionForm.markAsUntouched();
		this.waterConsumptionForm.updateValueAndValidity();
	}

	selectPavilion(pavilion: Pavilion){
		this.selectedPavilion = pavilion;
  }
  
  findPavilion(id: number){
    return this.pavilionsResult.find(pav=>pav.id==id);
    //return this.pavilionsResult.find(pav=>pav.id=id);
  }

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.waterConsumptionForm.controls;
		/** check form */
		if (this.waterConsumptionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let waterConsumption = this.prepareData();

		if (waterConsumption.id > 0) {
			this.updateWaterConsumption(waterConsumption, withBack);
			return;
		}

		this.addWaterConsumption(waterConsumption, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareData(): WaterConsumption {
		const controls 			= this.waterConsumptionForm.controls;
		let waterConsumption: WaterConsumption = new WaterConsumption();
		waterConsumption.clear();
		waterConsumption.id = this.waterConsumption.id;
		waterConsumption.pavilion_id = this.selectedPavilion.id;
		waterConsumption.pavilion_name = this.selectedPavilion.name;
    	// waterConsumption.water_level = controls.water_level.value;
    	waterConsumption.water_measured_level = controls.water_measured_level.value;
		waterConsumption.company_id = this.user.company_id;
		waterConsumption.write_uid = this.user.id;
		return waterConsumption;
	}

	/**
	 * Add WaterConsumption
	 *
	 * @param waterConsumption: WaterConsumption
	 * @param withBack: boolean
	 */
	addWaterConsumption(waterConsumption:WaterConsumption, withBack:boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new WaterConsumptionAdd({waterConsumption: waterConsumption}));
		const addBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastCreatedWaterConsumptionId)
		).subscribe(newId => {
			if (!newId || newId==-1) {
				// addBreedingSub.unsubscribe();
				return;
			}
			console.log("new id: "+newId);

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Consumo de agua ha sido creado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshWaterConsumption(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update waterConsumption
	 *
	 * @param waterConsumption: WaterConsumption
	 * @param withBack: boolean
	 */
	updateWaterConsumption(waterConsumption: WaterConsumption, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateWaterConsumption: Update<WaterConsumption> = {
			id: waterConsumption.id,
			changes: waterConsumption
		};

		this.store.dispatch(new WaterConsumptionEdit({
			partialWaterConsumption: updateWaterConsumption,
			waterConsumption:waterConsumption
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedWaterConsumptionId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
      }
      if(id==waterConsumption.id){
        if (withBack) {
          this.goBack(waterConsumption.id);
        } else {
          const message = `Consumo de agua ha sido actualizado exitosamente.`;
          this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
          this.refreshWaterConsumption(false);
        }
        updateBreedingSub.unsubscribe();
      }
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear consumo de agua';
		if (!this.waterConsumption || !this.waterConsumption.id) {
			return result;
		}

		result = `Editar consumo de agua - ${this.waterConsumption.water_level} en ${this.waterConsumption.pavilion_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}