// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy,ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { combineLatest, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  Alarm,
  AlarmsDataSource,
  AlarmsPageRequested,
  OneAlarmDeleted,
  ManyAlarmsDeleted,
  AlarmsStatusUpdated,
  selectAlarmsPageLastQuery,
  AlarmType,
  AlarmStatus,
  selectAlarmTypes,
  selectAlarmStatusTypes,
  AlarmsGetTypes,
  AlarmsGetStatusTypes,
  AlarmsCfgDataSource,
  AlarmCfgModel,
  AlarmCfgsGetAll
} from '../../../../../core/alarms'
import { PavilionGetTypes, PavilionType, selectPavilionTypes } from '../../../../../core/plant-configuration';

@Component({
  selector: 'kt-alarms-list',
  templateUrl: './alarms-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
  //styleUrls: ['./alarms-list.component.scss']
})
export class AlarmsListComponent implements OnInit, OnDestroy, AfterViewInit {
// Table fields
dataSource: AlarmsDataSource;
alarmCfgsDataSource: AlarmsCfgDataSource;
displayedColumns = ['select', 'create_date', 'pavilion_name', 'floor_name', 'alarm_type', 'msg',
  'value', 'info', 'status', 'actions'];
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild('sort1', {static: true}) sort: MatSort;
// Filter fields
@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
filterPavilionType = '';
filterAlarmType = '';
filterStatus = '';
lastQuery: QueryParamsModel;
// Selection
selection = new SelectionModel<Alarm>(true, []);
alarmsResult: Alarm[] = [];
pavilionTypesResult: PavilionType[] = [];
alarmTypesResult: AlarmType[] = [];
alarmStatusResult: AlarmStatus[] = [];
alarmCfgsResult: AlarmCfgModel[] = [];
private subscriptions: Subscription[] = [];
page_length;
first_load = true;

/**
 * Component constructor
 *
 * @param dialog: MatDialog
 * @param activatedRoute: ActivatedRoute
 * @param router: Router
 * @param subheaderService: SubheaderService
 * @param layoutUtilsService: LayoutUtilsService
 * @param store: Store<AppState>
 */
constructor(public dialog: MatDialog,
             private activatedRoute: ActivatedRoute,
             private router: Router,
             private subheaderService: SubheaderService,
             private layoutUtilsService: LayoutUtilsService,
             private store: Store<AppState>,
             private cdr: ChangeDetectorRef
             ) { }
  ngAfterViewInit(): void {
    this.loadAlarmsList();
  }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
  // If the user changes the sort order, reset back to the first page.
  const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  this.subscriptions.push(sortSubscription);

  /* Data load will be triggered in two cases:
  - when a pagination event occurs => this.paginator.page
  - when a sort event occurs => this.sort.sortChange
  **/
  const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
    tap(() => this.loadAlarmsList())
  )
  .subscribe();
  this.subscriptions.push(paginatorSubscriptions);

  // Filtration, bind to searchInput
  const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    debounceTime(150),
    distinctUntilChanged(),
    tap(() => {
      this.paginator.pageIndex = 0;
      this.loadAlarmsList();
    })
  )
  .subscribe();
  this.subscriptions.push(searchSubscription);

  // Set title to page breadCrumbs
  this.subheaderService.setTitle('Alarmas');

  const pavTypes$ = this.store.pipe(
    select(selectPavilionTypes)
  );
  const alarmTypes$ = this.store.pipe(
    select(selectAlarmTypes)
  );
  const alarmStatusTypes$ = this.store.pipe(
    select(selectAlarmStatusTypes)
  );

  const pavTypesSub = pavTypes$.subscribe(pavilionTypes=>{
    this.pavilionTypesResult = pavilionTypes;
  });

  const alarmTypesSub = alarmTypes$.subscribe(alarmTypes=>{
    console.log("alarm types retrieved: "+JSON.stringify(alarmTypes));
    this.alarmTypesResult = alarmTypes;
  });

  const alarmStatusTypesSub = alarmStatusTypes$.subscribe(alarmStatusTypes=>{
    this.alarmStatusResult = alarmStatusTypes;
  });

  this.subscriptions.push(pavTypesSub);
  this.subscriptions.push(alarmTypesSub);
  this.subscriptions.push(alarmStatusTypesSub);

  // Init DataSource
  this.dataSource = new AlarmsDataSource(this.store);
  // const entitiesSubscription = this.dataSource.entitySubject.pipe(
  //   distinctUntilChanged()
  // ).subscribe(res => {
  //   this.alarmsResult = res;
  //   if (res){				
  //     if (res.length!=0){
  //       //load alarm cfg
  //       this.loadAlarmCfgs();
  //       console.log("loading alarm cfgs")
  //     }	
  //   }	
  // });
  // this.subscriptions.push(entitiesSubscription);

  this.alarmCfgsDataSource = new AlarmsCfgDataSource(this.store);
  const alarms$ = this.dataSource.entitySubject.pipe(distinctUntilChanged());
  const alarmCfgs$ = this.alarmCfgsDataSource.entitySubject;
  const combineSub = combineLatest([alarms$, alarmCfgs$]).subscribe(
    ([alarms, alarmCfgs]) => {
      this.alarmCfgsResult = alarmCfgs;
      // this.alarmsResult = JSON.parse(JSON.stringify(alarms));
      this.alarmsResult = alarms;
      console.log("alarm cfgs subscription: "+JSON.stringify(alarmCfgs));
      console.log("alarmsResult: "+JSON.stringify(this.alarmsResult));
      // if(alarmCfgs && this.alarmsResult){
      //   this.alarmsResult.forEach((part, index)=>{
      //     console.log("searching cfg for alarm with msg: "+this.alarmsResult[index].msg);
      //     const cfgs = this.alarmCfgsResult.filter((cfg)=>cfg.pavilion_id == this.alarmsResult[index].pavilion_id);
      //       this.alarmsResult[index] = Object.assign(new Alarm(), this.alarmsResult[index]);
      //       if(cfgs && cfgs.length>0){
      //         const cfg = cfgs[0];
      //         console.log("cfg found: "+JSON.stringify(cfg));
      //         switch(cfg.alarm_type_name){
      //           case "PABELLONES-TEMPERATURA":
      //             console.log("temperatura!")
      //             console.log("index: "+index)
      //             this.alarmsResult[index].cfg_info = `Min: ${cfg.temp_low_value}°C. Medio: ${cfg.temp_medium_value}°C. `
      //               + `Max: ${cfg.temp_high_value}°C.`;
      //             console.log("alarms after: "+JSON.stringify(this.alarmsResult));
      //             break
      //           case "PABELLONES-COSECHA":        
      //             //NO SE CUMPLE LA META
      //             this.alarmsResult[index].cfg_info = `Min: ${cfg.harvest_data_low}. Medio: ${cfg.harvest_data_medium}. `
      //               + `Max: ${cfg.harvest_data_high}`;
      //             break
      //           case "PABELLONES-MOD-VELOCIDAD":
      //             this.alarmsResult[index].cfg_info = `Velocidad manual: ${cfg.is_vel_manual}`;
      //             break
      //           case "PABELLONES-INVENTARIO-ALIMENTO":
      //             this.alarmsResult[index].cfg_info = `Min: ${cfg.grain_low} Kg. Medio: ${cfg.grain_medium} Kg. `
      //               + `Max: ${cfg.grain_high} Kg.`;
      //             break
      //           case "PABELLONES-CONTROL-GUANO":
      //             break
      //           case "CRIANZA-INGRESO-DATA":
      //             //Tiempo para ingresar la data manual ha sido superado
      //             this.alarmsResult[index].cfg_info = `Hora limite: ${cfg.input_manual_data_hour}:${cfg.input_manual_data_min}.`
      //             // this.alarmsResult[index].cfg_info = `Min: ${cfg.manual_data_breeding_low}. Medio: ${cfg.manual_data_breeding_medium}. `
      //             //   + `Max: ${cfg.manual_data_breeding_high}`;
      //             break
      //           case "COSECHA-HUEVOS":
      //             //Solo mostrar white y color
      //             this.alarmsResult[index].cfg_info = `Blancos esperados: ${cfg.white_egg_weekly_harvest_setup}. Color esperados: ${cfg.color_egg_weekly_harvest_setup}.`
      //             break
      //           case "PABELLONES-COSECHA-MOD":
      //             //Is harvest.. mostrar checkbox
      //             this.alarmsResult[index].cfg_info = cfg.is_harvest?'Cosecha automática':'Cosecha manual';
      //           default:
      //             break
      //         }
      //       }
      //   });
      // }
    }
  )
  this.subscriptions.push(combineSub);
  const lastQuerySubscription = this.store.pipe(select(selectAlarmsPageLastQuery)).subscribe(res => this.lastQuery = res);
  // Load last query from store
  this.subscriptions.push(lastQuerySubscription);

  // Read from URL itemId, for restore previous state
  const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
    if (params.id) {
      this.restoreState(this.lastQuery, +params.id);
    }

    // First load
    // of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
    // }); // Remove this line, just loading imitation
  });
  this.subscriptions.push(routeSubscription);
  this.loadAlarmTypes();
  this.loadAlarmStatusTypes();
  this.loadPavilionTypes();
  this.loadAlarmCfgs();
}

/**
 * On Destroy
 */
ngOnDestroy() {
  this.subscriptions.forEach(el => el.unsubscribe());
}

loadAlarmTypes(){
  this.store.dispatch(new AlarmsGetTypes({}));
}

loadAlarmStatusTypes(){
  this.store.dispatch(new AlarmsGetStatusTypes({}));
}

loadPavilionTypes(){
  this.store.dispatch(new PavilionGetTypes({}));
}

loadAlarmCfgs(){
  this.store.dispatch(new AlarmCfgsGetAll({}));
}
/**
 * Load Alarms List
 */
loadAlarmsList() {
  this.selection.clear();
  const queryParams = new QueryParamsModel(
    this.filterConfiguration(),
    this.sort.direction,
    this.sort.active,
    this.paginator.pageIndex,
    this.paginator.pageSize
  );
  // Call request from server
  this.store.dispatch(new AlarmsPageRequested({ page: queryParams }));
  this.selection.clear();
}

/**
 * Returns object for filter
 */
filterConfiguration(): any {
  const filter: any = {};
  const searchText: string = this.searchInput.nativeElement.value;

  if (this.filterStatus) {
    filter.status_id = this.filterStatus;
  }

  if (this.filterAlarmType) {
    filter.alarm_type_id = this.filterAlarmType;
  }

  if (this.filterPavilionType) {
    filter.pavilion_type_id = this.filterPavilionType;
  }

  filter.pavilion = searchText;
  filter.msg = searchText;
  return filter;
}

/**
 * Restore state
 *
 * @param queryParams: QueryParamsModel
 * @param id: number
 */
restoreState(queryParams: QueryParamsModel, id: number) {

  if (!queryParams.filter) {
    return;
  }

  if ('status_id' in queryParams.filter) {
    this.filterStatus = queryParams.filter.status_id.toString();
  }

  if ('alarm_type_id' in queryParams.filter) {
    this.filterAlarmType = queryParams.filter.alarm_type_id.toString();
  }

  if ('pavilion_type_id' in queryParams.filter) {
    this.filterPavilionType = queryParams.filter.pavilion_type_id.toString();
  }

  if (queryParams.filter.pavilion) {
    this.searchInput.nativeElement.value = queryParams.filter.pavilion;
  }
}

findCfgByPavilionFloor(pavilionId, floorId){
  try{
    const result = this.alarmCfgsResult.filter(cfg=>cfg.pavilion_id==pavilionId && cfg.floor_id == floorId);
    console.log("searching for cfg with pavilion_id = "+pavilionId+ " and floor_id = "+floorId);
    console.log("cfg founds: "+JSON.stringify(result));
    return result[0];
  }catch(error){
    console.log(error);
    return new AlarmCfgModel();
  }
}

/** ACTIONS */
/**
 * Delete Alarm
 *
 * @param _item: Alarm
 */
deleteAlarm(_item: Alarm) {
  const _title = 'Eliminar alarma';
  const _description = 'Está seguro de que desea eliminar permanentemente esta alarma?';
  const _waitDesciption = 'La alarma se está eliminando...';
  const _deleteMessage = `La alarma ha sido eliminada`;

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    this.store.dispatch(new OneAlarmDeleted({ id: _item.id }));
    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
  });
}

/**
 * Delete Alarms
 */
deleteAlarms() {
  const _title = 'Eliminar alarmas';
  const _description = 'Está usted seguro de que desea eliminar las alarmas seleccionadas?';
  const _waitDesciption = 'Las alarmas se están eliminando...';
  const _deleteMessage = 'Las alarmas seleccioandas han sido eliminadas';

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    const idsForDeletion: number[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selection.selected.length; i++) {
      idsForDeletion.push(this.selection.selected[i].id);
    }
    this.store.dispatch(new ManyAlarmsDeleted({ ids: idsForDeletion }));
    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    this.selection.clear();
  });
}

/**
 * Fetch selected Alarms
 */
fetchAlarms() {
  // tslint:disable-next-line:prefer-const
  let messages = [];
  this.selection.selected.forEach(elem => {
    messages.push({
      text: `${elem.pavilion_name} ${elem.status} ${elem.alarm_type_name}`,
      id: elem.id,
      status: elem.status
    });
  });
  this.layoutUtilsService.fetchElements(messages);
}

/**
 * Update status dialog
 */
updateStatusForAlarms() {
  const _title = 'Update status for selected Alarms';
  const _updateMessage = 'Status has been updated for selected Alarms';
  const _statuses = [{ value: 0, text: 'Selling' }, { value: 1, text: 'Sold' }];
  const _messages = [];

  this.selection.selected.forEach(elem => {
    _messages.push({
      text: `${elem.pavilion_name} ${elem.alarm_status_name} ${elem.alarm_type_name}`,
      id: elem.id,
      status: elem.alarm_status_id,
      statusTitle: elem.alarm_status_name,
      statusCssClass: this.getItemCssClassByStatus(elem.alarm_status_id)
    });
  });

  const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      this.selection.clear();
      return;
    }

    this.store.dispatch(new AlarmsStatusUpdated({
      status: +res,
      alarms: this.selection.selected
    }));

    this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
    this.selection.clear();
  });
}

/**
 * Redirect to edit page
 *
 * @param id: any
 */
editAlarm(id) {
  this.router.navigate(['../alarms/edit', id], { relativeTo: this.activatedRoute });
}

//createAlarm() {
//  this.router.navigateByUrl('/ecommerce/Alarms/add');
//}

/**
 * Check all rows are selected
 */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.alarmsResult.length;
  return numSelected === numRows;
}

/**
 * Selects all rows if they are not all selected; otherwise clear selection
 */
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
  } else {
    this.alarmsResult.forEach(row => this.selection.select(row));
  }
}

/* UI */
/**
 * Returns status string
 *
 * @param status: number
 */
getItemStatusString(status: number = 0): string {
  switch (status) {
    case 0:
      return 'Selling';
    case 1:
      return 'Sold';
  }
  return '';
}

/**
 * Returns CSS Class by status
 *
 * @param status: number
 */
getItemCssClassByStatus(status: number = 0): string {
  switch (status) {
    case 0:
      return 'success';
    case 1:
      return 'metal';
  }
  return '';
}

/**
 * Rerurns condition string
 *
 * @param condition: number
 */
getItemConditionString(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'New';
    case 1:
      return 'Used';
  }
  return '';
}

/**
 * Returns CSS Class by condition
 *
 * @param condition: number
 */
getItemCssClassByCondition(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'accent';
    case 1:
      return 'primary';
  }
  return '';
}
}
