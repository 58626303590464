import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { WaterConsumptionActionTypes, WaterConsumptionAdd, WaterConsumptionAdded, WaterConsumptionDelete, WaterConsumptionDeleted, WaterConsumptionEdit, WaterConsumptionEdited, WaterConsumptionGetPage, WaterConsumptionPageLoaded } from '../_actions/water-consumption.actions';
import { WaterConsumptionService } from '../_services/water-consumption.service';

@Injectable()
export class WaterConsumptionEffects {

    constructor(
        private actions: Actions,
        private waterConsumptionService: WaterConsumptionService
    ) {}

    @Effect()
    getWaterConsumptionPage: Observable<any> = this.actions.pipe(
        ofType<WaterConsumptionGetPage>(WaterConsumptionActionTypes.WATER_CONSUMPTION_GET_PAGE),
        map((action: WaterConsumptionGetPage) => action.payload),
        switchMap(payload => {
            return this.waterConsumptionService.getPage(payload.page).pipe(
                map(response=>{
                    console.log("get waterConsumption page");
                    console.log(JSON.stringify(payload));
                    console.log(JSON.stringify(response));
                    return {waterConsumptions: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new WaterConsumptionPageLoaded(response);
        })
    );

    @Effect()
    updateWaterConsumption: Observable<any> = this.actions.pipe(
        ofType<WaterConsumptionEdit>(WaterConsumptionActionTypes.WATER_CONSUMPTION_EDIT),
        map((action: WaterConsumptionEdit) => action.payload),
        switchMap(payload => {
            return this.waterConsumptionService.edit(payload.waterConsumption).pipe(
                map((updated)=> {return {updated: updated, waterConsumption: payload.waterConsumption, partialWaterConsumption:payload.partialWaterConsumption}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("edit waterConsumption");
            return new WaterConsumptionEdited(resp);
        })
    );

    @Effect()
    deleteWaterConsumption: Observable<any> = this.actions.pipe(
        ofType<WaterConsumptionDelete>(WaterConsumptionActionTypes.WATER_CONSUMPTION_DELETE),
        map((action: WaterConsumptionDelete) => action.payload),
        switchMap(payload => {
            return this.waterConsumptionService.delete(payload.waterConsumption).pipe(
                map(deleted=>{
                    console.log("delete waterConsumption");
                    console.log(JSON.stringify(payload));
                    return {waterConsumption: payload.waterConsumption, deleted:deleted};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new WaterConsumptionDeleted(response);
        })
    );

    @Effect()
    addWaterConsumption: Observable<any> = this.actions.pipe(
        ofType<WaterConsumptionAdd>(WaterConsumptionActionTypes.WATER_CONSUMPTION_ADD),
        map((action: WaterConsumptionAdd) => action.payload),
        switchMap(payload => {
            return this.waterConsumptionService.add(payload.waterConsumption).pipe(
                map((resp)=> {return {added: resp.added, waterConsumption: {...payload.waterConsumption, id:resp.id}}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("add waterConsumption");
            return new WaterConsumptionAdded(resp);
        })
    );

    @Effect({dispatch:false})
    waterConsumptionPageLoaded: Observable<any> = this.actions.pipe(
        ofType(WaterConsumptionActionTypes.WATER_CONSUMPTION_PAGE_LOADED),
        tap((action:WaterConsumptionPageLoaded)=>console.log("waterConsumption page loaded"))
    );

    @Effect({dispatch:false})
    waterConsumptionEdited: Observable<any> = this.actions.pipe(
        ofType(WaterConsumptionActionTypes.WATER_CONSUMPTION_EDITED),
        tap((action:WaterConsumptionEdited)=>console.log("waterConsumption edited"))
    );

    @Effect({dispatch:false})
    waterConsumptionDeleted: Observable<any> = this.actions.pipe(
        ofType(WaterConsumptionActionTypes.WATER_CONSUMPTION_DELETED),
        tap((action:WaterConsumptionDeleted)=>console.log("waterConsumption deleted"))
    );

    @Effect({dispatch:false})
    waterConsumptionAdded: Observable<any> = this.actions.pipe(
        ofType(WaterConsumptionActionTypes.WATER_CONSUMPTION_ADDED),
        tap((action:WaterConsumptionAdded)=>console.log("waterConsumption added"))
    );
}