import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { LayoutUtilsService, MessageType, QueryParamsModel, QueryResultsModel } from '../../../../../core/_base/crud';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../core/_base/layout';

@Component({
  selector: 'kt-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  datasource: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  @Input() columnList: any[];
  @Input() name: string;
  @Input() defaultSortActive: string;
  @Input() defaultSortDirection: 'asc' | 'desc' | '';
  @Input() pluralName: string;
  @Input() route: string;
  @Input() filterList: any[];
  @Input() actions: any = {edit: true, add: true, delete: true};
  @Input() dataObs: Subject<QueryResultsModel>;
  @Input() deleteObs: Subject<string>;

  // @Input() deleteOne: (item: any) => Observable<string>;
  // @Input() getPage: (query: QueryParamsModel) => Observable<QueryResultsModel>;

  @Output() getPageEvent = new EventEmitter<QueryParamsModel>();
  @Output() deleteOneEvent = new EventEmitter<number>();

  private subscriptions: Subscription[] = [];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

  itemList: any[] = [];
  itemCount = 0;
  isLoading = true;
  
  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private cdr: ChangeDetectorRef
  ) { }
  
	ngOnChanges(changes: SimpleChanges): void {
		this.displayedColumns = this.columnList.map(col=>col.def);
    this.displayedColumns.push("actions");
	}

  ngOnInit() {
		
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngAfterViewInit() {
    // console.log(`columnlist: ${JSON.stringify(this.columnList)}`);
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadItemList())
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadItemList();
      })
    )
    .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle(this.pluralName);

    this.sort.direction = this.defaultSortDirection;
    this.sort.active = this.defaultSortActive;

    this.loadItemList();
  }

  /**
   * Load Item List
   */
   loadItemList() {
     
    // this.selection.clear();
    // console.log(this.sort.active);
    const activeColumn = this.columnList.find(col=>col.def==this.sort.active);
    var sortActive = null;
    if(activeColumn) sortActive = activeColumn.def;
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      sortActive,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    this.isLoading = true;
    // console.log(JSON.stringify(queryParams));
    // console.log(this.sort.active);
    // console.log(this.defaultSortActive);
    // console.log(JSON.stringify(this.columnList));
    this.getPageEvent.emit(queryParams);
    this.subscriptions.push(
      this.dataObs.pipe(
        filter((res)=>res!=null),
        take(1)
      ).subscribe((res:any)=>{
        this.itemList = res.items;
        // this.itemList.length = res.totalCount;
				// console.log(`items: ${JSON.stringify(this.itemList)}`);
        // console.log(`total count: ${res.totalCount}`);
        this.paginator.length = res.totalCount;
        if(!this.datasource){
          this.datasource = new MatTableDataSource(this.itemList);        
          // this.datasource.paginator = this.paginator;
        }else{
          this.datasource.data = this.itemList;
          // this.datasource.paginator = this.paginator;
        }
        // this.datasource.sort = this.sort;
        this.datasource._updateChangeSubscription();
        this.paginator.length = res.totalCount;
        this.itemCount = res.totalCount;
        this.cdr.detectChanges();
        this.isLoading = false;
        // this.paginator.pageIndex = 0;
        
      })
    );
    // Call request from server
    // console.log("loadMachineList");
    // this.store.dispatch(new MachineGetPage({ page: queryParams }));
    // this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    // if(this.filterProductType!="-1"){
    //   filter.product_type_id = this.filterProductType;
    // }
    // if(this.filterProductVariety!="-1"){
    //   filter.product_variety_id = this.filterProductVariety;
    // }
    // if(this.filterSpecie!="-1"){
    //   filter.product_specie_id = this.filterSpecie;
    // }
    // if(this.filterPackaging!="-1"){
    //   filter.product_packaging_id = this.filterPackaging;
    // }
    // if(this.filterPackagingWeight!="-1"){
    //   filter.product_packaging_weight_id = this.filterPackagingWeight;
    // }
    // if(this.filterColour!="-1"){
    //   filter.product_colour_id = this.filterColour;
    // }
    // if(this.filterItem!="-1"){
    //   filter.product_item_id = this.filterItem;
    // }
    // if(this.filterProcess!="-1"){
    //   filter.product_process_id = this.filterProcess;
    // }
    // if(this.filterStatus!="-1"){
    //   filter.status_id = this.filterStatus;
    // }
    filter.name = searchText;
    this.filterList.forEach(filterElement=>{
      if(filterElement.value!="-1") filter[filterElement.name] = filterElement.value;
    })
    return filter;
  }

  isNumber(value){
    return value?typeof(value).toLocaleString() == 'number':false;
  }

  clearDateFilter(filter){
    filter.value = null;
    this.loadItemList();
  }

  editItem(itemId: number){
    this.router.navigateByUrl(`${this.route}/edit/${itemId}`);
  }

  createItem() {
    this.router.navigateByUrl(`${this.route}/add`);
  }

  deleteItem(itemId: number){
    const _title = `Eliminar registro de ${this.name}`;
    const _description = `Está usted seguro de que desea eliminar permanentemente este registro de ${this.name}?`;
    const _waitDesciption = `El registro de ${this.name} se está eliminando...`;
    const _deleteMessage = `El registro de ${this.name} ha sido eliminada`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.deleteOneEvent.emit(itemId);
      this.subscriptions.push(
        this.deleteObs.pipe(
          filter(res=>res!=null),
          take(1)
        ).subscribe((status)=>{
          if(status && status == "success"){
            this.loadItemList();
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
          }
        })
      )      
    });
  }
}