// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, concat, combineLatest, Subject, merge, interval } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, tap, debounceTime } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	BirdStockMovement,
	Pavilion,
	PavilionGetAll,
	PavilionsDataSource,
	selectBirdStockMovById,
	Floor,
	BirdGeneration,
	selectBirdGens,
	selectPavilionFloors,
	BSAddMov,
	selectLastCreatedBirdStockMovId,
	selectLastUpdatedBirdStockMovId,
	BSGetBirdGens,
	PavilionGetFloors,
	BSGetPavGens,
	BSEditMov
} from '../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-movements-edit',
  templateUrl: './movements-edit.component.html',
  styleUrls: ['./movements-edit.component.scss']
})
export class MovementsEditComponent implements OnInit, OnDestroy, AfterViewInit {
	_el: ElementRef;
	@ViewChild('wizard', {static: true}) el: ElementRef;
	// Public properties
	birdStockMov: BirdStockMovement;
	birdStockMovId$: Observable<number>;
	oldBirdStockMov: BirdStockMovement;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	birdStockMovForm: FormGroup;
	hasFormErrors = false;
  	filteredPavilions: Observable<Pavilion[]>
  	filteredBrePavilions: Observable<Pavilion[]>
  	filteredFloors: Observable<Floor[]>
  	filteredGenerationsSrc: Observable<BirdGeneration[]>

  	pavilionsDataSource: PavilionsDataSource;
  	pavilionsResult: Pavilion[];
  	floorsResult: Floor[];
  	generationsResult: BirdGeneration[];
	private subscriptions: Subscription[] = [];
	private user:User = new User();
	_pavilionSrcInstance: NgbTypeahead;
	@ViewChild('pavilionSrcTA', {static: false}) set pavilionSrcInstance(content: NgbTypeahead){
		if(content){
			this._pavilionSrcInstance = content;
		}
	};
	pavilionSrcFocus$ = new Subject<string>();
	pavilionSrcClick$ = new Subject<string>();

	_pavilionDstInstance: NgbTypeahead;
	@ViewChild('pavilionDstTA', {static: false}) set pavilionDstInstance(content: NgbTypeahead){
		if(content){
			this._pavilionDstInstance = content;
		}
	};
	pavilionDstFocus$ = new Subject<string>();
	pavilionDstClick$ = new Subject<string>();

	_generationSrcInstance: NgbTypeahead;
	@ViewChild('generationSrcTA', {static: false}) set generationSrcInstance(content: NgbTypeahead){
		if(content){
			this._generationSrcInstance = content;
		}
	};
	generationSrcFocus$ = new Subject<string>();
	generationSrcClick$ = new Subject<string>();

	_floorInstance: NgbTypeahead;
	@ViewChild('floorTA', {static: false}) set floorInstance(content: NgbTypeahead){
		if(content){
			this._floorInstance = content;
		}
	};
	floorFocus$ = new Subject<string>();
	floorClick$ = new Subject<string>();

	searchPavilionSrc = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.pavilionSrcClick$.pipe(filter(() => !this._pavilionSrcInstance.isPopupOpen()));
		const inputFocus$ = this.pavilionSrcFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching pavilion with "+text);
				this.selectPavilionSrc(this.findPavilion(text))}),
			map(text => this.filterPavilion(text).slice(0, 10)),
			tap(pavilions=>console.log("pavilions src found: "+JSON.stringify(pavilions)))
		);
	}

	searchPavilionDst = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.pavilionDstClick$.pipe(filter(() => !this._pavilionDstInstance.isPopupOpen()));
		const inputFocus$ = this.pavilionDstFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching pavilion with "+text);
				this.selectPavilionDst(this.findPavilion(text))}),
			map(text => this.filterPavilion(text)),
			tap(pavilions=>console.log("pavilions dst found: "+JSON.stringify(pavilions)))
		);
	}
	pavilionFormatter = (pavilion: Pavilion) => pavilion.name;

	searchGenerationSrc = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.generationSrcClick$.pipe(filter(() => !this._generationSrcInstance.isPopupOpen()));
		const inputFocus$ = this.generationSrcFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching generation src with "+text);
				this.selectGenerationSrc(this.findGeneration(text))}),
			map(text => this.filterGeneration(text)),
			tap(generations=>console.log("generations src found: "+JSON.stringify(generations)))
		);
	}
	generationFormatter = (generation: BirdGeneration) => generation.name;

	searchFloor = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.floorClick$.pipe(filter(() => !this._floorInstance.isPopupOpen()));
		const inputFocus$ = this.floorFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching floor with "+text);
				this.selectFloor(this.findFloor(text))}),
			map(text => this.filterFloor(text)),
			tap(floors=>console.log("floors found: "+JSON.stringify(floors)))
		);
	}
	floorFormatter = (floor: Floor) => floor.name;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;
	private selectedPavilionSrc: Pavilion = null;
	private selectedPavilionDst: Pavilion = null;
	private selectedFloor: Floor = null;
	private selectedGenerationSrc: BirdGeneration = null;
	public editing:boolean = false;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}
	ngAfterViewInit(): void {
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("silos edit component: usuario no logueado");
		});
		    
    	// Init DataSource
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const pavilions$ = this.pavilionsDataSource.entitySubject.pipe(
			distinctUntilChanged()
		);
		const birdGenerations$ = this.store.pipe(
			select(selectBirdGens)
		);
		const floors$ = this.store.pipe(
			select(selectPavilionFloors)
		);
		const floorsSub = floors$.subscribe((floors)=>{
			console.log("floors sub")
			// console.log("floors: "+JSON.stringify(floors));
			this.floorsResult = floors;
			try{
				this.birdStockMovForm.get("floor").patchValue({name:""});
			}catch(error){

			}
		});
		const generationsSrcSub = birdGenerations$.subscribe((gens)=>{
			console.log("generationscrc sub")
			this.generationsResult=gens;
			try{
				this.birdStockMovForm.get("generation_src").patchValue({name: gens[0].name});
				this.selectGenerationSrc(gens[0]);
			}catch(error){

			}
		});
		const routeSub = this.activatedRoute.params.subscribe(params => {
			let urlSplited = this.router.url.split("/");
			let relUrl = urlSplited[urlSplited.length-2];
			if (relUrl == "edit"){
				this.editing = true;
			}
			const id = params.id;
			if (id && id > 0) {

				const birdStockMov$ = this.store.pipe(
					select(selectBirdStockMovById(id))
				);
				
				const sub = combineLatest([pavilions$, birdStockMov$])
					.subscribe(([pavilions, birdStockMov]) => {
						console.log("bird stock movement retrieved from store");
						if(pavilions && birdStockMov){
							this.pavilionsResult    = pavilions;
							this.loadBirdStockMov(birdStockMov);
						}
					}
				);
				this.subscriptions.push(sub);
			} else {				
				const sub = combineLatest([pavilions$])
					.subscribe(([pavilions]) => {
						if(pavilions && pavilions.length>0 ){
							this.pavilionsResult = pavilions;
							console.log("default bird stock mov");
							console.log(JSON.stringify(pavilions));
							const newBirdStockMov = new BirdStockMovement();
							newBirdStockMov.clear();
							this.loadBirdStockMov(newBirdStockMov);
						}
					}
				);
				this.subscriptions.push(sub);				
			}
		});

		this.subscriptions.push(authSubscription);
		this.subscriptions.push(routeSub);
		this.subscriptions.push(floorsSub);
		this.subscriptions.push(generationsSrcSub);

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};

		this.loadPavilions();
	}

	loadPavilions(){
		this.store.dispatch(new PavilionGetAll({}));
	}

	loadPavilionGenerations(pavilion: Pavilion){
		this.store.dispatch(new BSGetPavGens({pavilion: pavilion}));
	}

	loadPavilionFloors(pavilion:Pavilion){
		this.store.dispatch(new PavilionGetFloors({pavilion: pavilion}));
	}

	loadBirdStockMov(birdStockMov, fromService: boolean = false) {
		console.log("load bird stock mov")
		console.log(JSON.stringify(birdStockMov));
		if (!birdStockMov) {
			this.goBack('');
		}
		this.birdStockMov = {...birdStockMov};
		
		this.selectedPavilionSrc = new Pavilion();
		this.selectedPavilionSrc.clear();
		this.selectedPavilionSrc.name = birdStockMov.pavilion_src_name;
		this.selectedPavilionSrc.id = birdStockMov.pavilion_src_id;
		this.selectPavilionSrc(this.selectedPavilionSrc);

		this.selectedPavilionDst = new Pavilion();
		this.selectedPavilionDst.clear();
		this.selectedPavilionDst.name = birdStockMov.pavilion_dst_name;
		this.selectedPavilionDst.id = birdStockMov.pavilion_dst_id;
		this.selectPavilionDst(this.selectedPavilionDst);

		this.selectedGenerationSrc = new BirdGeneration();
		this.selectedGenerationSrc.clear();
		this.selectedGenerationSrc.id = birdStockMov.generation_src_id;
		this.selectedGenerationSrc.name = birdStockMov.generation_src_name;
		this.selectGenerationSrc(this.selectedGenerationSrc);

		this.selectedFloor 			= new Floor();
		this.selectedFloor.clear();
		this.selectedFloor.id =	birdStockMov.floor_id;
		this.selectedFloor.name = birdStockMov.floor_name;
		this.selectFloor(this.selectedFloor);

		this.birdStockMovId$ = of(birdStockMov.id);
		this.oldBirdStockMov = Object.assign({}, birdStockMov);
		this.initBirdStockMov();
		this.cdr.detectChanges();
  }

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init birdStockMov
	 */
	initBirdStockMov() {
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.birdStockMov.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de traslados', page: `/plant-configuration/movements` },
				{ title: 'Añadir traslado', page: `/plant-configuration/movements/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar traslado');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de traslados', page: `/plant-configuration/movements` },
			{ title: 'Editar traslado', page: `/plant-configuration/movements/edit`, queryParams: { id: this.birdStockMov.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		console.log("creating bird stock movements form");
		console.log("bird stock mov: "+JSON.stringify(this.birdStockMov));
		const amount = this.birdStockMov.amount?this.birdStockMov.amount:1;
		console.log("Editing: "+this.editing);
		console.log({name: this.birdStockMov.floor_name});
		this.birdStockMovForm = this.formBuilder.group({
			pavilion_src: [{value: this.birdStockMov.pavilion_src_name, disabled:this.editing},
				Validators.required],
			pavilion_dst: [this.birdStockMov.pavilion_dst_name,
				Validators.required],
			generation_src: [{value:{name: this.birdStockMov.generation_src_name}, disabled:this.editing},
				Validators.required],
			generation_dst: [this.birdStockMov.generation_name, [Validators.required]],
			floor: [this.birdStockMov.floor_name, Validators.required],
			amount: [amount, [Validators.required, Validators.min(1)]],
		});
		const controls = this.birdStockMovForm.controls;
		const generationDstSub = controls.generation_dst.valueChanges.pipe(
			startWith(this.birdStockMov.generation_name)
		).subscribe(x=>{
			this.birdStockMov.generation_name = x;
		});
		const amountSub = controls.amount.valueChanges.pipe(
			startWith(amount)
		).subscribe(x=>{
			this.birdStockMov.amount = x;
		});
		this.subscriptions.push(generationDstSub);
		this.subscriptions.push(amountSub);
		console.log(this.birdStockMovForm);
		// this.filteredPavilions = concat(
		// 	of(this.filterPavilion("")),
		// 	this.birdStockMovForm.controls.pavilion_dst.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterPavilion(val.toString())),
		// 		map(pavilions => pavilions.filter((pav)=>pav.type!="CRIANZA")),
		// 		tap(pavilions => {
		// 			this.selectedPavilionDst = null;
		// 			if(pavilions && pavilions.length>0){
		// 				let val = this.birdStockMovForm.controls.pavilion_dst.value;
		// 				let pavilionCoincidences = pavilions.filter((pavilion)=>pavilion.name.toLowerCase()==val.toLowerCase());
		// 				if(pavilionCoincidences.length>0){
		// 					this.selectedPavilionDst = pavilionCoincidences[0];
		// 					this.selectPavilionDst(this.selectedPavilionDst);
		// 				}
		// 			}
		// 		})
		// 	)
		// );
		// this.filteredBrePavilions = concat(
		// 	of(this.filterPavilion("")),
		// 	this.birdStockMovForm.controls.pavilion_src.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterPavilion(val.toString())),
		// 		map(pavilions => pavilions.filter((pav)=>pav.type=="CRIANZA")),
		// 		tap(pavilions => {
		// 			this.selectedPavilionSrc = null;
		// 			if(pavilions && pavilions.length>0){
		// 				let val = this.birdStockMovForm.controls.pavilion_src.value;
		// 				let pavilionCoincidences = pavilions.filter((pavilion)=>pavilion.name.toLowerCase()==val.toLowerCase());
		// 				if(pavilionCoincidences.length>0){
		// 					this.selectedPavilionSrc = pavilionCoincidences[0];
		// 					this.selectPavilionSrc(this.selectedPavilionSrc);
		// 				}
		// 			}
		// 		})
		// 	)
		// );
		// this.filteredGenerationsSrc = concat(
		// 	of(this.filterGeneration(this.birdStockMov.generation_src_name)),
		// 	this.birdStockMovForm.controls.generation_src.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterGeneration(val.toString())),
		// 		tap(generations => {
		// 			if(!this.birdStockMov.id){
		// 				this.selectedGenerationSrc = null;
		// 				if(generations && generations.length>0){
		// 					let val = this.birdStockMovForm.controls.generation_src.value;
		// 					let generationCoincidences = generations.filter((generation)=>generation.name.toLowerCase()==val.toLowerCase());
		// 					if(generationCoincidences.length>0){
		// 						this.selectedGenerationSrc = generationCoincidences[0];
		// 					}
		// 				}
		// 			}
		// 		})
		// 	)
		// );

		// this.filteredFloors = concat(
		// 	of(this.filterFloor("")),
		// 	this.birdStockMovForm.controls.floor.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterFloor(val.toString())),
		// 		tap(floors => {
		// 			this.selectedFloor = null;
		// 			if(floors && floors.length>0){
		// 				let val = this.birdStockMovForm.controls.floor.value;
		// 				let floorCoincidences = floors.filter((floor)=>floor.name.toLowerCase()==val.toLowerCase());
		// 				if(floorCoincidences.length>0){
		// 					this.selectedFloor = floorCoincidences[0];
		// 				}
		// 			}
		// 		})
		// 	)
		// );
		console.log("stock mov form created");
		this.cdr.detectChanges();
	}

	/**
	 * Filter pavilion
	 *
	 * @param val: string
	 */
	filterPavilion(val: string): Pavilion[] {
		console.log("filtering pavilions with string "+val);
		console.log("List of pavilions: " + JSON.stringify(this.pavilionsResult));
		if (this.pavilionsResult){
			return this.pavilionsResult.filter(pavilion =>
				pavilion.name.toString().toLowerCase().includes(val.toLowerCase()));
		}
    	return [];
	}

	/**
	 * Filter bird generation
	 *
	 * @param val: string
	 */
	filterGeneration(val: string): BirdGeneration[] {
		if (this.generationsResult){
			return this.generationsResult.filter(generation =>
				generation.name.toString().toLowerCase().includes(val.toLowerCase()));
		}
    	return [];
	}

	findPavilion(name:String):Pavilion{
		return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	}

	findGeneration(name:String):BirdGeneration{
		return this.generationsResult.filter(generation=>generation.name.toLowerCase() == name.toLowerCase())[0];
	}

	findFloor(name:String):Floor{
		return this.floorsResult.filter(floor=>floor.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Filter floor
	 *
	 * @param val: string
	 */
	filterFloor(val: string): Floor[] {
		if (this.floorsResult){
			return this.floorsResult.filter(floor =>
				floor.name.toString().toLowerCase().includes(val.toLowerCase()));
		}
    	return [];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/movements?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/movements', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh breeding stock
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshBirdStockMov(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/movements/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.birdStockMov = Object.assign({}, this.oldBirdStockMov);
		this.createForm();
		this.hasFormErrors = false;
		this.birdStockMovForm.markAsPristine();
		this.birdStockMovForm.markAsUntouched();
		this.birdStockMovForm.updateValueAndValidity();
		this.selectedFloor = null;
		this.selectedGenerationSrc = null;
		this.selectedPavilionDst = null;
		this.selectedPavilionSrc = null;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.birdStockMovForm.controls;
		/** check form */
		if (this.birdStockMovForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let editedBirdStockMov = this.prepareBirdStockMov();

		if (editedBirdStockMov.id > 0) {
			this.updateBirdStockMov(editedBirdStockMov, withBack);
			return;
		}

		this.addBirdStockMov(editedBirdStockMov, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareBirdStockMov(): BirdStockMovement {
		const controls = this.birdStockMovForm.controls;
		const birdStockMov = Object.assign(new BirdStockMovement(), this.birdStockMov);
		birdStockMov.id 				= this.birdStockMov.id;
		console.log("setted id to: "+birdStockMov.id);
		birdStockMov.amount 			= controls.amount.value;
		birdStockMov.pavilion_src_id 	= this.selectedPavilionSrc.id;
		birdStockMov.pavilion_dst_id 	= this.selectedPavilionDst.id;
		birdStockMov.pavilion_dst_name 	= this.selectedPavilionDst.name;
		birdStockMov.pavilion_src_name 	= this.selectedPavilionSrc.name;
		birdStockMov.floor_id 			= this.selectedFloor.id;
		birdStockMov.floor_name 		= this.selectedFloor.name;
		birdStockMov.generation_src_id 	= this.selectedGenerationSrc.id;
		birdStockMov.generation_src_name= this.selectedGenerationSrc.name;
		birdStockMov.generation_name 	= controls.generation_dst.value;
		birdStockMov.generation_id 		= this.birdStockMov.generation_id;
		birdStockMov.breeding_id		= this.birdStockMov.breeding_id;
		birdStockMov.bird_id 			= this.birdStockMov.bird_id;
		birdStockMov._createdDate 		= this.birdStockMov._createdDate;
		birdStockMov._updatedDate 		= this.birdStockMov._updatedDate;
		birdStockMov._updatedDate 		= this.typesUtilsService.getDateStringFromDate();
		birdStockMov._createdDate 		= this.birdStockMov.id > 0 ? birdStockMov._createdDate : birdStockMov._updatedDate;
		birdStockMov.write_uid 			= this.user.id;
		birdStockMov.company_id 		= this.user.company_id;
		console.log(JSON.stringify(birdStockMov))
		return birdStockMov;
	}

	/**
	 * Add BirdStockMov
	 *
	 * @param birdStockMov: BirdStockMov
	 * @param withBack: boolean
	 */
	addBirdStockMov(birdStockMov: BirdStockMovement, withBack: boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new BSAddMov({birdStockMov: birdStockMov}));
		const addMov = this.store.pipe(
			delay(1000),
			select(selectLastCreatedBirdStockMovId)
		).subscribe(newId => {
			if (!newId || newId==-1) {
				// addMov.unsubscribe();
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Nuevo traslado ha sido añadido exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshBirdStockMov(true, newId);
				// this.router.navigate(['..'])
			}
			// addMov.unsubscribe();
		});
		this.subscriptions.push(addMov);
	}

	/**
	 * Update birdStockMov
	 *
	 * @param birdStockMov: BirdStockMov
	 * @param withBack: boolean
	 */
	updateBirdStockMov(birdStockMov: BirdStockMovement, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateBirdStockMov: Update<BirdStockMovement> = {
			id: birdStockMov.id,
			changes: birdStockMov
		};

		this.store.dispatch(new BSEditMov({
			partialBirdStockMov: updateBirdStockMov,
			birdStockMov:birdStockMov
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedBirdStockMovId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
			}
			if (withBack) {
				this.goBack(birdStockMov.id);
			} else {
				const message = `Traslado ha sido editado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshBirdStockMov(false, id=this.birdStockMov.id);
			}
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Nuevo traslado';
		if (!this.birdStockMov || !this.birdStockMov.id) {
			return result;
		}

		result = `Editar traslado - ${this.birdStockMov.amount} ${this.birdStockMov.bird_type} desde ${this.birdStockMov.pavilion_src_name} hacia ${this.birdStockMov.pavilion_dst_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	selectPavilionDst(pavilion:Pavilion){
		this.selectedPavilionDst = pavilion;
		this.floorsResult = [];
		
		this.cdr.detectChanges();
		// try{
		// 	this.birdStockMovForm.get("floor").patchValue({name:""});
		// }catch(err){

		// };
		this.loadPavilionFloors(pavilion);
	}

	selectPavilionSrc(pavilion:Pavilion){
		this.generationsResult = [];
		
		this.cdr.detectChanges();
		this.selectedPavilionSrc = pavilion;
		if(!this.birdStockMov.id){
			try{
				this.birdStockMovForm.get("generation_src").patchValue({name:""});
			}catch(err){
	
			}
			if(pavilion && pavilion.id){
				this.loadPavilionGenerations(pavilion);
			}
		}
	}

	selectFloor(floor:Floor){
		this.selectedFloor = floor;
	}

	selectGenerationSrc(generation:BirdGeneration){
		this.selectedGenerationSrc = generation;
	}
}