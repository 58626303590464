import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { HylineGetPage, HylineActionTypes, HylinePageLoaded, HylineLoadDataPage, HylineDataPageLoaded, HylineEdit, HylineEdited, HylineDelete, HylineDeleted, HylineAdd, HylineAdded, HylineLoadBreDataPage, HylineBreDataPageLoaded, HylineHavDataPageLoaded, HylineLoadHavDataPage, HylineGetTypes, HylineTypesLoaded, HylineLoadEMoltDataPage, HylineEMoltDataPageLoaded, HylineLoadLMoltDataPage, HylineLMoltDataPageLoaded} from '../_actions/hyline.actions';
import { BirdService } from '../_services/bird.service';
import { PavilionService } from '../_services/pavilion.service';


@Injectable()
export class HylineEffects {

    constructor(
        private actions: Actions,
        private birdService: BirdService,
        private router: Router
    ) {}

    @Effect()
    getHylinePage: Observable<any> = this.actions.pipe(
        ofType<HylineGetPage>(HylineActionTypes.HYLINE_GET_PAGE),
        map((action: HylineGetPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylinePage(payload.page).pipe(
                map(response=>{
                    console.log("get hyline page");
                    console.log(JSON.stringify(payload));
                    console.log(JSON.stringify(response));
                    return {hylines: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylinePageLoaded(response);
        })
    );

    @Effect()
    getHylineTypes: Observable<any> = this.actions.pipe(
        ofType<HylineGetTypes>(HylineActionTypes.HYLINE_GET_TYPES),
        map((action: HylineGetTypes) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylineTypes().pipe(
                map(response=>{
                    console.log("get hyline types");
                    console.log(JSON.stringify(payload));
                    console.log(JSON.stringify(response));
                    return {hylineTypes: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineTypesLoaded(response);
        })
    );

    @Effect()
    getHylineDataPage: Observable<any> = this.actions.pipe(
        ofType<HylineLoadDataPage>(HylineActionTypes.HYLINE_LOAD_DATA_PAGE),
        map((action: HylineLoadDataPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylineDataPage(payload.hyline, payload.page).pipe(
                map(response=>{
                    console.log("get hyline data page");
                    console.log(JSON.stringify(response));
                    return {hylineData: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineDataPageLoaded(response);
        })
    );

    @Effect()
    getHylineBreDataPage: Observable<any> = this.actions.pipe(
        ofType<HylineLoadBreDataPage>(HylineActionTypes.HYLINE_LOAD_BRE_DATA_PAGE),
        map((action: HylineLoadBreDataPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylineBreedingDataPage(payload.hyline, payload.page).pipe(
                map(response=>{
                    console.log("get hyline breeding data page");
                    console.log(JSON.stringify(response));
                    return {hylineData: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineBreDataPageLoaded(response);
        })
    );

    @Effect()
    getHylineHavDataPage: Observable<any> = this.actions.pipe(
        ofType<HylineLoadHavDataPage>(HylineActionTypes.HYLINE_LOAD_HAV_DATA_PAGE),
        map((action: HylineLoadHavDataPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylineHaverstDataPage(payload.hyline, payload.page).pipe(
                map(response=>{
                    console.log("get hyline haverst data page");
                    console.log(JSON.stringify(response));
                    return {hylineData: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineHavDataPageLoaded(response);
        })
    );

    @Effect()
    getHylineEMoltDataPage: Observable<any> = this.actions.pipe(
        ofType<HylineLoadEMoltDataPage>(HylineActionTypes.HYLINE_LOAD_EMOLT_DATA_PAGE),
        map((action: HylineLoadEMoltDataPage) => action.payload),
        switchMap(payload => {
            console.log("early molt get page effect");
            return this.birdService.getHylineEarlyMoltDataPage(payload.hyline, payload.page).pipe(
                map(response=>{
                    console.log("get hyline early molt data page");
                    console.log(JSON.stringify(response));
                    return {hylineData: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineEMoltDataPageLoaded(response);
        })
    );

    @Effect()
    getHylineLMoltDataPage: Observable<any> = this.actions.pipe(
        ofType<HylineLoadLMoltDataPage>(HylineActionTypes.HYLINE_LOAD_LMOLT_DATA_PAGE),
        map((action: HylineLoadLMoltDataPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getHylineLateMoltDataPage(payload.hyline, payload.page).pipe(
                map(response=>{
                    console.log("get hyline late molt data page");
                    console.log(JSON.stringify(response));
                    return {hylineData: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new HylineLMoltDataPageLoaded(response);
        })
    );

    @Effect()
    updateHyline: Observable<any> = this.actions.pipe(
        ofType<HylineEdit>(HylineActionTypes.HYLINE_EDIT),
        map((action: HylineEdit) => action.payload),
        switchMap(payload => {
            return this.birdService.updateHyline(payload.hyline).pipe(
                map((updated)=> {return {updated: updated, hyline: payload.hyline, partialHyline:payload.partialHyline}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("edit hyline");
            return new HylineEdited(resp);
        })
    );

    @Effect()
    deleteHyline: Observable<any> = this.actions.pipe(
        ofType<HylineDelete>(HylineActionTypes.HYLINE_DELETE),
        map((action: HylineDelete) => action.payload),
        switchMap(payload => {
            return this.birdService.deleteHyline(payload.hyline).pipe(
                map(deleted=>{
                    console.log("delete hyline");
                    console.log(JSON.stringify(payload));
                    return {hyline: payload.hyline, deleted:deleted};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new HylineDeleted(response);
        })
    );

    @Effect()
    addHyline: Observable<any> = this.actions.pipe(
        ofType<HylineAdd>(HylineActionTypes.HYLINE_ADD),
        map((action: HylineAdd) => action.payload),
        switchMap(payload => {
            return this.birdService.addHyline(payload.hyline).pipe(
                map((resp)=> {return {added: resp.added, hyline: {...payload.hyline, id:resp.id}}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("add hyline");
            return new HylineAdded(resp);
        })
    );

    @Effect({dispatch:false})
    hylinePageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_PAGE_LOADED),
        tap((action:HylinePageLoaded)=>console.log("hyline page loaded"))
    );

    @Effect({dispatch:false})
    hylineDataPageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_DATA_PAGE_LOADED),
        tap((action:HylineDataPageLoaded)=>console.log("hyline data page loaded"))
    );

    @Effect({dispatch:false})
    hylineBreDataPageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_BRE_DATA_PAGE_LOADED),
        tap((action:HylineBreDataPageLoaded)=>console.log("hyline breeding data page loaded"))
    );

    @Effect({dispatch:false})
    hylineHavDataPageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_HAV_DATA_PAGE_LOADED),
        tap((action:HylineHavDataPageLoaded)=>console.log("hyline haverst data page loaded"))
    );

    @Effect({dispatch:false})
    hylineEMoltDataPageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_EMOLT_DATA_PAGE_LOADED),
        tap((action:HylineEMoltDataPageLoaded)=>console.log("hyline early molt data page loaded"))
    );

    @Effect({dispatch:false})
    hylineLMoltDataPageLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_LMOLT_DATA_PAGE_LOADED),
        tap((action:HylineLMoltDataPageLoaded)=>console.log("hyline late molt data page loaded"))
    );

    @Effect({dispatch:false})
    hylineTypesLoaded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_TYPES_LOADED),
        tap((action:HylineTypesLoaded)=>console.log("hyline types loaded"))
    );

    @Effect({dispatch:false})
    hylineEdited: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_EDITED),
        tap((action:HylineEdited)=>console.log("hyline edited"))
    );

    @Effect({dispatch:false})
    hylineDeleted: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_DELETED),
        tap((action:HylineDeleted)=>console.log("hyline deleted"))
    );

    @Effect({dispatch:false})
    hylineAdded: Observable<any> = this.actions.pipe(
        ofType(HylineActionTypes.HYLINE_ADDED),
        tap((action:HylineAdded)=>console.log("hyline added"))
    );
}