import { BaseModel } from '../../_base/crud';

export class Pavilion extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name: string;
    iiot_code: string;
    silo_ids: any;
    silo_names: any;
    type: string;
    type_id: number;
    pav_group_id : number;
    floor_ids : any;
    // floors: number; // Define la cantidad de pisos (?)
    // responsible_id: number; //Identificador de usuario del responsable
    pavilioners: string;
    company_id: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.name               = "";
        this.iiot_code          = undefined;
        this.silo_ids            = undefined;
        this.pav_group_id       = undefined;
        // this.floors             = undefined;
        this.silo_names = [];
        // this.responsible_id     = undefined;
        this.pavilioners        = "";
        this.type_id            = undefined;
        this.company_id         = undefined;
    }
}

export class PavilionType{
    id: number;
    company_id: number;
    name: string;
    clear(){
        this.id         = undefined;
        this.name       = "";
        this.company_id = undefined;
    }
}

export class Pavilioner{
    // pavilion_id: number;
    id: number;
    partner_id: number;
    pavilions: string
    name: string;
    identificator: string; // Es el grupo
    // pavilion_name: string;
    // pavilion_type: string;
    // pavilion_type_id: number;
    // pavilion_iiot_code: string;
    clear(){
        this.id             = undefined;
        this.partner_id     = undefined;
        this.pavilions      = "";
        this.identificator  = "";
        this.name           = "";
    }
}

export class Floor{
    id:number;
    name:string;
    clear(){
        this.name   = "";
        this.id     = undefined;
    }
}

export class SpecificDashboard{
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    weight_uniformity: number;
    weight: number;
    weight_min: number;
    weight_max: number;
    grain_tb: number;
    grain: number;
    diff_grain: number;
    water: number;
    water_tb: number;
    diff_water: number;
    mortality: number;
    diff_mortality: number;
    t_min: number;
    t_max: number;
    bird_type_id: number;
    bird_type_name: string;
    bird_breeding_id: number;
    age_in_weeks: number;
    company_id: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.company_id         = undefined;
        this.weight_uniformity  = undefined
        this.weight             = undefined
        this.weight_min         = undefined
        this.weight_max         = undefined
        this.grain_tb           = undefined
        this.grain              = undefined
        this.diff_grain         = undefined
        this.water              = undefined
        this.water_tb           = undefined
        this.diff_water         = undefined
        this.mortality          = undefined
        this.diff_mortality     = undefined
        this.t_min              = undefined
        this.t_max              = undefined
        this.bird_type_id       = undefined
        this.bird_type_name     = ""
        this.bird_breeding_id   = undefined
        this.age_in_weeks       = undefined
    }
}