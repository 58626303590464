// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Deads} from '../_models/deads.model';
import { DeadsActions, DeadsActionTypes } from '../_actions/deads.actions';

//deads
export interface DeadsState extends EntityState<Deads> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDeadsId: number;
    lastUpdatedDeadsId: number;
    lastDeletedDeadsId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const deadsAdapter: EntityAdapter<Deads> = createEntityAdapter<Deads>();

export const initialDeadsState: DeadsState = deadsAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDeadsId: -1,
    lastUpdatedDeadsId: -1,
    lastDeletedDeadsId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function deadsReducer(state = initialDeadsState, action: DeadsActions): DeadsState {
    switch  (action.type) {
        case DeadsActionTypes.DEADS_PAGE_LOADED:
            console.log("deads reducer load")
            console.log(JSON.stringify(action));
            try{
                return deadsAdapter.addMany(action.payload.deads, {
                    ...initialDeadsState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
            
        case DeadsActionTypes.DEADS_ADDED:
            console.log("deads reducer... added.. action: "+JSON.stringify(action));
            if(action.payload.added && action.payload.deads.id>0){
                return deadsAdapter.addOne(action.payload.deads, {
                        ...state, lastCreatedDeadsId: action.payload.deads.id
                    });
            }
            return {...state, lastCreatedDeadsId: -1}
        case DeadsActionTypes.DEADS_EDITED:
            console.log("reducer deads edited")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return deadsAdapter.updateOne(action.payload.partialDeads, 
                    {...state, lastUpdatedDeadsId: action.payload.deads.id});
            return {...state, lastUpdatedDeadsId: -1}
        case DeadsActionTypes.DEADS_DELETED:
            if(action.payload.deleted){
                return deadsAdapter.removeOne(action.payload.deads.id, 
                    {...state, lastDeletedDeadsId: action.payload.deads.id});
            }
            return {...state, lastDeletedDeadsId: -1}
        default: return state;
    }
}

export const getDeadsState = createFeatureSelector<DeadsState>('deads');
export const deadsSelectors = deadsAdapter.getSelectors();