import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { QueryParamsModel } from '../../_base/crud';

import {
    GrainConsumeStock,
    GrainDeleteStockConsumption,
    GrainDeleteStockLoad,
    GrainEditStockConsumption,
    GrainEditStockLoad,
    GrainFindStockConsumption,
    GrainFindStockLoad,
    GrainGetStockConsumptionPage,
    GrainGetStockInventoryPage,
    GrainGetStockLoadPage,
    GrainLoadStock,
    GrainStockActionTypes,
    GrainStockConsumed,
    GrainStockConsumptionDeleted,
    GrainStockConsumptionEdited,
    GrainStockConsumptionPageLoaded,
    GrainStockInventoryPageLoaded,
    GrainStockLoadDeleted,
    GrainStockLoaded,
    GrainStockLoadEdited,
    GrainStockLoadPageLoaded,
    GrainTypeActionTypes,
    GrainTypeAdd,
    GrainTypeAdded,
    GrainTypeAllLoaded,
    GrainTypeDelete,
    GrainTypeDeleted,
    GrainTypeEdit,
    GrainTypeEdited,
    GrainTypeGetAll,
    GrainTypeGetPage,
    GrainTypePageLoaded
} from '../_actions/grain-stock.actions';
import { GrainStockService, GrainTypeService } from '../_services/grain-stock.service';


@Injectable()
export class GrainTypeEffects {

    constructor(
        private actions: Actions,
        private grainTypeService: GrainTypeService,
        private router: Router,
    ) {}

    @Effect()
    add: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ADD),
        map((action: GrainTypeAdd) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.add(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("add grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeAdded({grainType: payload.grainType});
                })
            );
        })  
    );

    @Effect()
    edit: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_EDIT),
        map((action: GrainTypeEdit) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.edit(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("edit grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeEdited({partialGrainType: payload.grainType});
                })
            );
        })  
    );

    @Effect()
    delete: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_DELETE),
        map((action: GrainTypeDelete) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.delete(payload.grainType).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("delete grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return new GrainTypeDeleted({id: payload.grainType.id});
                })
            );
        })  
    );

    @Effect()
    getPage: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_GET_PAGE),
        map((action: GrainTypeGetPage) => action.payload),
        switchMap(payload => {
            return this.grainTypeService.getPage(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("get page grain type");
                    console.log(JSON.stringify(payload.grainType));
                    return {grainTypes: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainTypePageLoaded(response);
        })
    );

    @Effect()
    getAll: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_GET_ALL),
        map((action: GrainTypeGetAll) => action.payload),
        switchMap(payload => {            
            return this.grainTypeService.getAll().pipe(
                map((grainTypes)=>{ //Adecuarse a la response recibida de api real
                    console.log("get all grain types");
                    console.log(JSON.stringify(grainTypes));
                    return grainTypes;
                })
            );
        }),
        map((grainTypes)=>{
            return new GrainTypeAllLoaded({grainTypes: grainTypes});
        })
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_PAGE_LOADED),
        tap((action:GrainTypePageLoaded)=>console.log("grain type page loaded"))
    );

    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ALL_LOADED),
        tap((action:GrainTypeAllLoaded)=>console.log("grain type all loaded"))
    );

    @Effect({dispatch:false})
    added: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_ADDED),
        tap((action:GrainTypeAdded)=>console.log("grain type added"))
    );

    @Effect({dispatch:false})
    edited: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_EDITED),
        tap((action:GrainTypeEdited)=>console.log("grain type edited"))
    );

    @Effect({dispatch:false})
    deleted: Observable<any> = this.actions.pipe(
        ofType(GrainTypeActionTypes.GT_DELETED),
        tap((action:GrainTypeDeleted)=>console.log("grain type deleted"))
    );
}

@Injectable()
export class GrainStockEffects {

    constructor(
        private actions: Actions,
        private grainStockService: GrainStockService,
        private router: Router,
    ) {}

    @Effect()
    getStockInventoryPage: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_GET_STOCK_INVENTORY_PAGE),
        map((action: GrainGetStockInventoryPage) => action.payload),
        switchMap(payload => {
            return this.grainStockService.getInventory(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("get page grain stock inventory");
                    console.log(JSON.stringify(payload.grainStock));
                    return {grainStocks: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainStockInventoryPageLoaded(response);
        })
    );

    @Effect()
    loadStock: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_LOAD_STOCK),
        map((action: GrainLoadStock) => action.payload),
        switchMap(payload => {
            return this.grainStockService.load(payload.grainStock).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("load grain stock");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockLoaded({added:response.added, grainStock: {...payload.grainStock, id:response.id}});
                })
            );
        })  
    );

    @Effect()
    editStockLoad: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_EDIT_STOCK_LOAD),
        map((action: GrainEditStockLoad) => action.payload),
        switchMap(payload => {
            return this.grainStockService.updateLoad(payload.grainStock).pipe(
                map((updated)=>{ //Adecuarse a la response recibida de api real
                    console.log("edit grain stock load");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockLoadEdited({updated: updated, 
                        grainStock:payload.grainStock,
                        partialGrainStock: payload.partialGrainStock});
                })
            );
        })  
    );

    @Effect()
    deleteStockLoad: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_DELETE_STOCK_LOAD),
        map((action: GrainDeleteStockLoad) => action.payload),
        switchMap(payload => {
            return this.grainStockService.deleteLoad(payload.grainStock).pipe(
                map((deleted)=>{ //Adecuarse a la response recibida de api real
                    console.log("delete grain stock load");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockLoadDeleted({grainStock: payload.grainStock, deleted:deleted});
                })
            );
        })  
    );

    @Effect()
    getStockLoadPage: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_GET_STOCK_LOAD_PAGE),
        map((action: GrainGetStockLoadPage) => action.payload),
        switchMap(payload => {
            return this.grainStockService.getLoads(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("get page grain stock loads");
                    console.log(JSON.stringify(payload.grainStock));
                    return {grainStocks: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainStockLoadPageLoaded(response);
        })
    );

    @Effect()
    findStockLoad: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_FIND_STOCK_LOAD),
        map((action: GrainFindStockLoad) => action.payload),
        switchMap(payload => {
            return this.grainStockService.findStockLoad(payload.grainStock).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("find grain stock load");
                    console.log(JSON.stringify(payload.grainStock));
                    const queryParams = new QueryParamsModel(
                        {},
                        "asc",
                        "id",
                        0,
                        0
                      );
                    return {grainStocks: response.items, page: queryParams, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainStockLoadPageLoaded(response);
        })
    );

    @Effect()    
    consumeStock: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_CONSUME_STOCK),
        map((action: GrainConsumeStock) => action.payload),
        switchMap(payload => {
            return this.grainStockService.consume(payload.grainStock).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("consume grain stock");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockConsumed({added:response.added, grainStock: {...payload.grainStock, id:response.id}});
                })
            );
        })  
    );

    @Effect()
    editStockConsumption: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_EDIT_STOCK_CONSUMPTION),
        map((action: GrainEditStockConsumption) => action.payload),
        switchMap(payload => {
            console.log("edit grain stock consumption payload: "+JSON.stringify(payload));
            return this.grainStockService.updateConsumption(payload.grainStock).pipe(
                map((updated)=>{ //Adecuarse a la response recibida de api real
                    console.log("edit grain stock consumption");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockConsumptionEdited({updated: updated, 
                        grainStock:payload.grainStock,
                        partialGrainStock: payload.partialGrainStock});
                })
            );
        })  
    );

    @Effect()
    deleteStockConsumption: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_DELETE_STOCK_CONSUMPTION),
        map((action: GrainDeleteStockConsumption) => action.payload),
        switchMap(payload => {
            return this.grainStockService.deleteConsumption(payload.grainStock).pipe(
                map((deleted)=>{ //Adecuarse a la response recibida de api real
                    console.log("delete grain stock consumption");
                    console.log(JSON.stringify(payload.grainStock));
                    return new GrainStockConsumptionDeleted({grainStock: payload.grainStock, deleted:deleted});
                })
            );
        })  
    );

    @Effect()
    getStockConsumptionPage: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_GET_STOCK_CONSUMPTION_PAGE),
        map((action: GrainGetStockConsumptionPage) => action.payload),
        switchMap(payload => {
            return this.grainStockService.getConsumptions(payload.page).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("get page grain stock consumptions");
                    console.log(JSON.stringify(payload.grainStock));
                    return {grainStocks: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainStockConsumptionPageLoaded(response);
        })
    );

    @Effect()
    findStockConsumption: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_FIND_STOCK_CONSUMPTION),
        map((action: GrainFindStockConsumption) => action.payload),
        switchMap(payload => {
            return this.grainStockService.findStockConsumption(payload.grainStock).pipe(
                map((response)=>{ //Adecuarse a la response recibida de api real
                    console.log("find grain stock load");
                    console.log(JSON.stringify(payload.grainStock));
                    const queryParams = new QueryParamsModel(
                        {},
                        "asc",
                        "id",
                        0,
                        0
                      );
                    return {grainStocks: response.items, page: queryParams, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{
            return new GrainStockLoadPageLoaded(response);
        })
    );
    
    @Effect({dispatch:false})
    stockInventoryPageLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_INVENTORY_PAGE_LOADED),
        tap((action:GrainStockInventoryPageLoaded)=>console.log("grain stock inventory page loaded"))
    );

    @Effect({dispatch:false})
    stockLoadPageLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_LOAD_PAGE_LOADED),
        tap((action:GrainStockLoadPageLoaded)=>console.log("grain stock load page loaded"))
    );

    @Effect({dispatch:false})
    stockLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_LOADED),
        tap((action:GrainStockLoaded)=>console.log("grain stock loaded"))
    );

    @Effect({dispatch:false})
    stockLoadEdited: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_LOAD_EDITED),
        tap((action:GrainStockLoadEdited)=>console.log("grain stock load edited"))
    );

    @Effect({dispatch:false})
    stockLoadDeleted: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_LOAD_DELETED),
        tap((action:GrainStockLoadDeleted)=>console.log("grain stock load deleted"))
    );

    @Effect({dispatch:false})
    stockConsumptionPageLoaded: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_CONSUMPTION_PAGE_LOADED),
        tap((action:GrainStockConsumptionPageLoaded)=>console.log("grain stock consumption page loaded"))
    );

    @Effect({dispatch:false})
    stockConsumed: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_CONSUMED),
        tap((action:GrainStockConsumed)=>console.log("grain stock consumed"))
    );

    @Effect({dispatch:false})
    stockConsumptionEdited: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_CONSUMPTION_EDITED),
        tap((action:GrainStockConsumptionEdited)=>console.log("grain stock consumption edited"))
    );

    @Effect({dispatch:false})
    stockConsumptionDeleted: Observable<any> = this.actions.pipe(
        ofType(GrainStockActionTypes.GS_STOCK_CONSUMPTION_DELETED),
        tap((action:GrainStockConsumptionDeleted)=>console.log("grain stock consumption deleted"))
    );
}