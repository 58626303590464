// RxJS
import { delay, distinctUntilChanged } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import { 
	selectGrainStockConsumptionsInitWaitingMessage,
	selectGrainStockConsumptionsInStore,
	selectGrainStockConsumptionsPageLoading,
	selectGrainStockInventorysInitWaitingMessage,
	selectGrainStockInventorysInStore,
	selectGrainStockInventorysPageLoading,
	selectGrainStockLoadsInitWaitingMessage,
	selectGrainStockLoadsInStore,
	selectGrainStockLoadsPageLoading,
    selectGrainTypesInitWaitingMessage, 
    selectGrainTypesInStore, 
    selectGrainTypesPageLoading
} from '../_selectors/grain-stock.selectors';

export class GrainTypesDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectGrainTypesPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectGrainTypesInitWaitingMessage)
		);

		this.store.pipe(
			select(selectGrainTypesInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class GrainStockLoadDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectGrainStockLoadsPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectGrainStockLoadsInitWaitingMessage)
		);

		this.store.pipe(
			select(selectGrainStockLoadsInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class GrainStockConsumptionDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectGrainStockConsumptionsPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectGrainStockConsumptionsInitWaitingMessage)
		);

		this.store.pipe(
			select(selectGrainStockConsumptionsInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class GrainStockInventoryDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectGrainStockInventorysPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectGrainStockInventorysInitWaitingMessage)
		);

		this.store.pipe(
			select(selectGrainStockInventorysInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
	sortArray(_incomingArray: any[], _sortField: string = '', _sortOrder: string = 'asc'): any[] {

		return _incomingArray;
	}
}