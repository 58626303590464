// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { Profiles } from '../_models/profile.model';
// Models
import { QueryParamsModel } from '../../_base/crud';

export enum ProfileActionTypes {
    AllProfilesRequested = '[Profiles Module] All Profiles Requested',
    AllProfilesLoaded = '[Profiles API] All Profiles Loaded',
    //ProfileOnServerCreated = '[Edit Profile Component] Profile On Server Created',
    //ProfileCreated = '[Edit Profile Dialog] Profile Created',
    ProfileUpdated = '[Edit Profile Dialog] Profile Updated',
    //ProfileDeleted = '[Profiles List Page] Profile Deleted',
    ProfilesPageRequested = '[Profiles List Page] Profiles Page Requested',
    ProfilesPageLoaded = '[Profiles API] Profiles Page Loaded',
    ProfilesPageCancelled = '[Profiles API] Profiles Page Cancelled',
    ProfilesPageToggleLoading = '[Profiles] Profiles Page Toggle Loading',
    ProfilesActionToggleLoading = '[Profiles] Profiles Action Toggle Loading'
}

/*export class ProfileOnServerCreated implements Action {
    readonly type = ProfileActionTypes.ProfileOnServerCreated;
    constructor(public payload: { profile: Profiles }) { }
}

export class ProfileCreated implements Action {
    readonly type = ProfileActionTypes.ProfileCreated;
    constructor(public payload: { profile: Profiles }) { }
}*/
export class AllProfilesRequested implements Action {
    readonly type = ProfileActionTypes.AllProfilesRequested;
}

export class AllProfilesLoaded implements Action {
    readonly type = ProfileActionTypes.AllProfilesLoaded;
    constructor(public payload: { profiles: Profiles[] }) { }
}


export class ProfileUpdated implements Action {
    readonly type = ProfileActionTypes.ProfileUpdated;
    constructor(public payload: {
        partialProfile: Update<Profiles>,
        profile: Profiles
    }) { }
}

/*export class ProfileDeleted implements Action {
    readonly type = ProfileActionTypes.ProfileDeleted;
    constructor(public payload: { id: number }) {}
}*/

export class ProfilesPageRequested implements Action {
    readonly type = ProfileActionTypes.ProfilesPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ProfilesPageLoaded implements Action {
    readonly type = ProfileActionTypes.ProfilesPageLoaded;
    constructor(public payload: { profiles: Profiles[], totalCount: number, page: QueryParamsModel  }) { }
}


export class ProfilesPageCancelled implements Action {
    readonly type = ProfileActionTypes.ProfilesPageCancelled;
}

export class ProfilesPageToggleLoading implements Action {
    readonly type = ProfileActionTypes.ProfilesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ProfilesActionToggleLoading implements Action {
    readonly type = ProfileActionTypes.ProfilesActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ProfileActions = AllProfilesRequested 
| AllProfilesLoaded
| ProfilesPageLoaded
| ProfileUpdated
| ProfilesPageCancelled
| ProfilesPageToggleLoading
| ProfilesPageRequested
| ProfilesActionToggleLoading;
