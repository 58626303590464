import { NormalListComponent } from './inventory/normal-list/normal-list.component';
import { OmegaListComponent } from './inventory/omega-list/omega-list.component';
import { StockMovementListComponent } from './stock-movement/stock-movement-list/stock-movement-list.component';
import { StockMovementEditComponent } from './stock-movement/stock-movement-edit/stock-movement-edit.component';
import { ConsumptionListComponent } from './consumption/consumption-list/consumption-list.component';
import { RouterModule, Routes } from '@angular/router';
import { NourishmentComponent } from './nourishment.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PartialsModule } from '../../partials/partials.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatMenuModule, MatSelectModule, MatInputModule, MatTableModule, MatAutocompleteModule, MatRadioModule, MatIconModule, MatNativeDateModule, MatProgressBarModule, MatDatepickerModule, MatCardModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatProgressSpinnerModule, MatSnackBarModule, MatExpansionModule, MatTabsModule, MatTooltipModule, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService } from '../../../core/_base/crud';
import { InventoryListComponent } from './inventory/inventory-list.component';
import { ActionNotificationComponent } from '../../partials/content/crud';
import { ConsumptionEditComponent } from './consumption/consumption-edit/consumption-edit.component';
import { DeadsEffects, deadsReducer, grainStockConsumptionReducer, GrainStockEffects, grainStockInventoryReducer, grainStockLoadReducer, GrainTypeEffects, grainTypesReducer, SiloEffects, silosReducer, WaterConsumptionEffects, waterConsumptionReducer } from '../../../core/plant-configuration';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WaterConsumptionListComponent } from './water/water-consumption-list/water-consumption-list.component';
import { WaterConsumptionEditComponent } from './water/water-consumption-edit/water-consumption-edit.component';
import { DeadsListComponent } from './dead/deads-list/deads-list.component';
import { DeadsEditComponent } from './dead/deads-edit/deads-edit.component';
import { ManualListComponent } from './manual/manual-list/manual-list.component';
import { ManualEditComponent, ManualEditDialog } from './manual/manual-edit/manual-edit.component';

const routes: Routes = [
	{
		path: '',
		component: NourishmentComponent,
		children: [
			{
				path: '',
				redirectTo: 'inventory',
				pathMatch: 'full'
			},
			{
				path: 'inventory',
				component: InventoryListComponent
			},
			{
				path: 'stock-movement',
				component: StockMovementListComponent
			},
			{
				path: 'stock-movement/edit/:id',
				component: StockMovementEditComponent
			},
			{
				path: 'stock-movement/add',
				component: StockMovementEditComponent
			},
			{
				path: 'consumption',
				component: ConsumptionListComponent
			},
			{
				path: 'consumption/edit/:id',
				component: ConsumptionEditComponent
			},
			{
				path: 'consumption/add',
				component: ConsumptionEditComponent
			},
			{
				path: 'water-movements',
				component: WaterConsumptionListComponent
			},
			{
				path: 'water-movements/edit/:id',
				component: WaterConsumptionEditComponent
			},
			{
				path: 'water-movements/add',
				component: WaterConsumptionEditComponent
			},
			{
				path: 'deads-reports',
				component: DeadsListComponent
			},
			{
				path: 'deads-reports/add',
				component: DeadsEditComponent
			},
			{
				path: 'deads-reports/edit/:id',
				component: DeadsEditComponent
			},
			{
				path: 'manual',
				component: ManualListComponent
			},
			{
				path: 'manual/add',
				component: ManualEditComponent
			},
			{
				path: 'manual/edit/:id',
				component: ManualEditComponent
			},
    	]
  }
];

@NgModule({
  declarations: [
    NormalListComponent, 
    OmegaListComponent, 
    StockMovementListComponent, 
    StockMovementEditComponent, 
	ConsumptionListComponent,
	ConsumptionEditComponent,
	NourishmentComponent,
	InventoryListComponent,
	WaterConsumptionListComponent,
	WaterConsumptionEditComponent,
	DeadsListComponent,
	DeadsEditComponent,
	ManualListComponent,
	ManualEditComponent,
	ManualEditDialog
  ],
  imports: [
    CommonModule,
    HttpClientModule,
	PartialsModule,
	NgbModule,
	RouterModule.forChild(routes),
	StoreModule.forFeature('grainTypes', grainTypesReducer),
	StoreModule.forFeature('grainStockLoads', grainStockLoadReducer),
	StoreModule.forFeature('grainStockConsumptions', grainStockConsumptionReducer),
	StoreModule.forFeature('grainStockInventorys', grainStockInventoryReducer),
	EffectsModule.forFeature([GrainTypeEffects]),
	EffectsModule.forFeature([GrainStockEffects]),
	StoreModule.forFeature('silos', silosReducer),
	EffectsModule.forFeature([SiloEffects]),
	StoreModule.forFeature('waterConsumptions', waterConsumptionReducer),
	EffectsModule.forFeature([WaterConsumptionEffects]),
	StoreModule.forFeature('deads', deadsReducer),
	EffectsModule.forFeature([DeadsEffects]),
    FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
  ],
  providers: [
    InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
  ],
  entryComponents: [
		ActionNotificationComponent,
		ConsumptionListComponent,
		InventoryListComponent,
		StockMovementListComponent,
		StockMovementEditComponent,
		WaterConsumptionEditComponent,
		DeadsEditComponent,
		ManualEditDialog
  ],
})
export class NourishmentModule { }
