// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { SiloActions, SiloActionTypes } from '../_actions/silo.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Silo } from '../_models/silo.model';

// tslint:disable-next-line:no-empty-interface
export interface SiloState extends EntityState<Silo> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedSiloId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Silo> = createEntityAdapter<Silo>();

export const initialSiloState: SiloState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedSiloId: undefined,
    showInitWaitingMessage: true
});

export function silosReducer(state = initialSiloState, action: SiloActions): SiloState {
    switch  (action.type) {
        case SiloActionTypes.SILO_ADDED: return adapter.addOne(action.payload.silo, {
            ...state, lastCreatedSiloId: action.payload.silo.id
        });
        case SiloActionTypes.SILO_EDITED: return adapter.updateOne(action.payload.partialSilo, state);
        case SiloActionTypes.SILO_PAGE_LOADED: return adapter.addMany(action.payload.silos, {
            ...initialSiloState,
            totalCount: action.payload.totalCount,
            lastQuery: action.payload.page,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case SiloActionTypes.SILO_ALL_LOADED: return adapter.addMany(action.payload.silos, {
            ...initialSiloState,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case SiloActionTypes.SILO_DELETED: return adapter.removeOne(action.payload.id, state);
        // case UserActionTypes.UsersPageCancelled: return {
        //     ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        // };
        default: return state;
    }
}

export const getSiloState = createFeatureSelector<SiloState>('silos');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
