import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_BIRDS_URL} from '../../connection.data';
import { QueryParamsModel } from '../../_base/crud';
import { BirdStockMovement } from '../_models/bird-stock-mov.model';
import { BirdStock, BreedingStock } from '../_models/bird-stock.model';
import { BirdGeneration, BirdType } from '../_models/bird.model';
import { Hyline, HylineData, HylineDataType, HylineType } from '../_models/hyline.model';
import { Pavilion } from '../_models/pavilion.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class BirdService{
    constructor(private http:HttpClient){

    }

    loadBirds(birdStockMov: BirdStockMovement): Observable<any>{
        return new BehaviorSubject("done");
    }

    editBirdsLoad(birdStockMov: BirdStockMovement): Observable<any>{
        return new BehaviorSubject("done");
    }

    deleteBirdsLoad(birdStockMov: BirdStockMovement): Observable<any>{
        return new BehaviorSubject("done");
    }

    getStock(pavilion:Pavilion):Observable<any>{
        return new BehaviorSubject({
            pavilion_id: pavilion.id,
            bird_amount: 4,
            age: 3,
            floor_id: 4
        });
    }

    transfer(breeding:Pavilion, transferData:BirdStockMovement): Observable<any>{
        return new BehaviorSubject("done");
    }

    getAllBreedingStocks():Observable<BreedingStock[]>{
        const url = `${API_BIRDS_URL}/stock/breeding/all`
        return this.http.get<any>(url);
    }

    getBreedingStockPage(queryParams:QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/stock/breeding/page`
        return this.http.post<any>(url, queryParams);
    }

    loadBreedingStock(breedingStock: BreedingStock):Observable<any>{
        const url = `${API_BIRDS_URL}/stock/breeding`
        return this.http.post<any>(url, breedingStock).pipe(
            map((resp)=>{
                if(resp){
                    if(resp.status && resp.status=="error"){
                        return {...breedingStock, id:undefined};
                    }else{
                        return {...breedingStock,
                            id: resp.id,
                            breeding_id: resp.breeding_id,
                            generation_id: resp.generation_id};
                    }
                }else{
                    return {...breedingStock, id:undefined};
                }
            })
        );
    }

    updateBreedingStock(breedingStock:BreedingStock):Observable<boolean>{
        const url = `${API_BIRDS_URL}/stock/breeding/${breedingStock.id}`
        return this.http.put<any>(url, breedingStock).pipe(
            map((resp)=>resp.status=="success")
        );
    }

    deleteBreedingStock(breedingStock:BreedingStock):Observable<boolean>{
        const url = `${API_BIRDS_URL}/stock/breeding/${breedingStock.id}`
        return this.http.delete<any>(url).pipe(
            map((resp)=>resp.status=="success")
        );
    }

    getAllStockMovs():Observable<BirdStockMovement[]>{
        const url = `${API_BIRDS_URL}/stock/movement/all`
        return this.http.get<any>(url);
    }

    getStockMovsPage(queryParams:QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/stock/movement/page`
        return this.http.post<any>(url, queryParams);
    }

    addStockMov(birdStockMov: BirdStockMovement):Observable<any>{
        const url = `${API_BIRDS_URL}/stock/movement`
        return this.http.post<any>(url, birdStockMov).pipe(
            map((resp)=>{
                if(resp){
                    if(resp.status && resp.status=="error"){
                        return {...birdStockMov, id:undefined};
                    }else{
                        return {...birdStockMov,
                            id: resp.id,
                            bird_id: resp.breeding_id,
                            generation_id: resp.generation_id};
                    }
                }else{
                    return {...birdStockMov, id:undefined};
                }
            })
        );
    }

    updateStockMov(birdStockMov:BirdStockMovement):Observable<boolean>{
        const url = `${API_BIRDS_URL}/stock/movement/${birdStockMov.id}`
        return this.http.put<any>(url, birdStockMov).pipe(
            map((resp)=>resp.status=="success")
        );
    }

    deleteStockMov(birdStockMov:BirdStockMovement):Observable<boolean>{
        const url = `${API_BIRDS_URL}/stock/movement/${birdStockMov.id}`
        return this.http.delete<any>(url).pipe(
            map((resp)=>resp.status=="success")
        );
    }

    deleteStock(birdStock: BirdStock):Observable<string>{
        return new BehaviorSubject("done");
    }

    getTypes():Observable<BirdType[]>{
        const url = `${API_BIRDS_URL}/types`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getStatusTypes():Observable<BirdType[]>{
        const url = `${API_BIRDS_URL}/status`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getHylineTypes():Observable<HylineType[]>{
        const url = `${API_BIRDS_URL}/hyline/types`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getHylineDataTypes():Observable<HylineDataType[]>{
        const url = `${API_BIRDS_URL}/hyline/data/types`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    addHyline(hyline:Hyline):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline`
        return this.http.post<any>(url, hyline).pipe(
            map((resp)=>{return {added: resp.status == "success", id:resp.id}})
        );
    }

    getHylinePage(queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/page`
        return this.http.post<any>(url, queryParams)
    }

    getHylineDataPage(hyline:Hyline, queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/data/${hyline.id}`
        return this.http.post<any>(url, queryParams)
    }

    getHylineBreedingDataPage(hyline:Hyline, queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/data/breeding/${hyline.id}`
        return this.http.post<any>(url, queryParams)
    }

    getHylineHaverstDataPage(hyline:Hyline, queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/data/haverst/${hyline.id}`
        return this.http.post<any>(url, queryParams)
    }

    getHylineEarlyMoltDataPage(hyline:Hyline, queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/data/molt/early/${hyline.id}`
        return this.http.post<any>(url, queryParams)
    }

    getHylineLateMoltDataPage(hyline:Hyline, queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_BIRDS_URL}/hyline/data/molt/late/${hyline.id}`
        return this.http.post<any>(url, queryParams)
    }

    updateHyline(hyline: Hyline): Observable<boolean>{
        const url = `${API_BIRDS_URL}/hyline/${hyline.id}`
        return this.http.put<any>(url, hyline).pipe(
            map((resp)=>{
                console.log("bird service.. update hyline: "+JSON.stringify(resp));
                return resp.status == "success";
            })
        );
    }

    deleteHyline(hyline:Hyline): Observable<boolean>{
        const url = `${API_BIRDS_URL}/hyline/${hyline.id}`
        return this.http.delete<any>(url).pipe(
            map((resp)=>resp.status == "success")
        );
    }

    getGenerations():Observable<BirdGeneration[]>{
        const url = `${API_BIRDS_URL}/generation/all`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    searchGenerations(name:string, limit:number):Observable<BirdGeneration[]>{
        const url = `${API_BIRDS_URL}/generation/search`;
        const params = new HttpParams()
            .set("name", name)
            .set("limit", limit.toString());
        return this.http.get<any>(url, {params: params}).pipe(
            map((resp)=>resp.rows)
        );
    }
    getBirdBreedingDashboardData():Observable<any[]>{
        const url = `${API_BIRDS_URL}/stock/dashboard`
        return this.http.get<any>(url).pipe(
            map((resp)=>resp)
        );
    }
}