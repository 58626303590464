import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'kt-loadbirds-list',
  templateUrl: './loadbirds-list.component.html',
  styleUrls: ['./loadbirds-list.component.scss']
})
export class LoadbirdsListComponent implements OnInit{
  constructor(){}

  ngOnInit(){

  }
}