// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, debounceTime, tap, switchMapTo } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	Pavilion, 
	Pavilioner,
	PavilionsDataSource,
	selectPavilionerById,
	PavilionersDataSource,
	PavilionAssign, 
	PavilionerGetAll, 
	PavilionGetAll, 
  	PavilionUnlink
} from '../../../../../core/plant-configuration';
import { User, UsersDataSource } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-pavilioners-edit',
  templateUrl: './pavilioners-edit.component.html',
  styleUrls: ['./pavilioners-edit.component.scss']
})
export class PavilionersEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('wizard', {static: true}) el: ElementRef;

	// Public properties
	pavilioner: Pavilioner;
	pavilionerId$: Observable<number>;
	oldPavilioner: Pavilioner;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	pavilionerForm: FormGroup;
	hasFormErrors = false;
	filteredPavilioners: Observable<Pavilioner[]>;
	filteredPavilions: Observable<Pavilion[]>;
	pavilionDataSource: PavilionsDataSource;
	pavilionersDataSource: PavilionersDataSource;
	pavilionsResult: Pavilion[] = [];
	allPavilions: Pavilion[] = [];
	pavilionersResult: Pavilioner[] = [];
	selectedPavilion: Pavilion = null;
	selectedPavilioner: Pavilioner = null;
	private subscriptions: Subscription[] = [];
	private user:User = new User();
	public purpose:string = "";
	private id:number = undefined;

	// @ViewChild('pavilionerTA', {static: true}) pavilionerInstance: NgbTypeahead;
	// @ViewChild('pavilionTA', {static: true}) pavilionInstance: NgbTypeahead;
	// pavilionFocus$ = new Subject<string>();
	// pavilionClick$ = new Subject<string>();
	// pavilionerFocus$ = new Subject<string>();
	// pavilionerClick$ = new Subject<string>();

	// searchPavilion = (text$: Observable<string>)=>{
	// 	const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
	// 	const clicksWithClosedPopup$ = this.pavilionClick$.pipe(filter(() => !this.pavilionInstance.isPopupOpen()));
	// 	const inputFocus$ = this.pavilionFocus$;
	// 	return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
	// 		debounceTime(200),
	// 		tap((text:string)=>{
	// 			console.log("searching pavilion with "+text);
	// 			this.selectPavilion(this.findPavilion(text))}),
	// 		map((text:string) => this.filterPavilion(text)),
	// 		tap(pavilions=>console.log("pavilions found: "+JSON.stringify(pavilions)))
	// 	);
	// }
	// pavilionFormatter = (pavilion: Pavilion) => pavilion.name;

	// searchPavilioner = (text$: Observable<string>)=>{
	// 	const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
	// 	const clicksWithClosedPopup$ = this.pavilionerClick$.pipe(filter(() => !this.pavilionerInstance.isPopupOpen()));
	// 	const inputFocus$ = this.pavilionerFocus$;
	// 	return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
	// 		debounceTime(200),
	// 		tap((text:string)=>{
	// 			console.log("searching pavilioner with "+text);
	// 			if(this.id!=undefined)this.selectPavilioner(this.findPavilioner(text))}),
	// 		map((text:string) => this.filterPavilioner(text)),
	// 		tap(pavilioners=>console.log("pavilioners found: "+JSON.stringify(pavilioners)))

	// 	);
	// }
	// pavilionerFormatter = (pavilioner: Pavilioner) => pavilioner.name;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}
	ngAfterViewInit(){
		const pavilions$ = this.pavilionDataSource.entitySubject.pipe(distinctUntilChanged());
		const pavilioners$ = this.pavilionersDataSource.entitySubject.pipe(distinctUntilChanged());
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				let urlSplited = this.router.url.split("/");
				this.id = id;
				let relUrl = urlSplited[urlSplited.length-2];
				if(relUrl == "assign"){
					this.purpose = "assigning";
				}else if(relUrl == "unlink"){
					this.purpose = "unlinking";
				}
				// console.log("relUrl: "+relUrl);
				// console.log("purpose: "+this.purpose);
				const pavilioner$ = this.store.pipe(
					select(selectPavilionerById(id))
				);
				const sub = combineLatest([pavilioner$, pavilions$]).subscribe(
					([pavilioner, pavilions])=>{
						console.log("pavilioner: "+JSON.stringify(pavilioner));
						if(pavilions && pavilions.length>0 && pavilioner){
							// console.log("Pavilioner retrieved: "+JSON.stringify(pavilioner));
							this.allPavilions = pavilions;
							this.pavilionersResult=[pavilioner];
							if(pavilioner.pavilions){
								// console.log("filtering pavilions");
								if(this.purpose=="assigning")
									this.pavilionsResult = pavilions.filter((pavilion)=>{
										return pavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1;
									});
								else
									this.pavilionsResult = pavilions.filter((pavilion)=>pavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)>-1);
							}else if(this.purpose=="assigning")
								this.pavilionsResult = pavilions;
							// console.log("filtered pavilions: "+JSON.stringify(this.pavilionsResult));
							this.selectPavilion(this.pavilionsResult[0]);
							this.selectPavilioner(pavilioner);
							this.pavilioner = pavilioner;
							this.loadPavilioner(pavilioner);
							this.pavilionerForm.get('pavilioner').patchValue(pavilioner.id);
							this.cdr.detectChanges();
							// console.log("pavilioners edit: pavilioner: "+JSON.stringify(pavilioner));
							// console.log("pavilions: "+JSON.stringify(pavilions));
						}							
					}
				)
				this.subscriptions.push(sub);
			} else {
				let relUrl = this.router.url.split("/").pop();
				if(relUrl == "assign"){
					this.purpose = "assigning";
				}else if(relUrl == "unlink"){
					this.purpose = "unlinking";
				}
				const sub = combineLatest([pavilioners$, pavilions$]).subscribe(
					([pavilioners, pavilions])=>{
						if(pavilioners && pavilions && pavilions.length>0 && pavilioners.length>0){
							this.allPavilions = pavilions;
							this.pavilionsResult = pavilions;
							this.pavilionersResult = pavilioners;
							this.selectPavilioner(this.pavilionersResult[0]);

							if(this.selectedPavilioner.pavilions){
								// console.log("filtering pavilions");
								if(this.purpose=="assigning"){
									console.log("filtering "+JSON.stringify(this.selectedPavilioner.pavilions.split(',')));
									this.pavilionsResult = pavilions.filter((pavilion)=>{
										console.log("filtering by "+pavilion.id +".- "+pavilion.name+" "+pavilion.type);
										if(this.selectedPavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1){
											console.log("the pavilion does not appear in the list");
										}else{
											console.log("the pavilion appear in the list");
										}
										return this.selectedPavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1;
									});
								}else
									this.pavilionsResult = pavilions.filter((pavilion)=>(this.selectedPavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)>-1));
							}else if(this.purpose=="assigning")
								this.pavilionsResult = pavilions;
							
							this.selectPavilion(this.pavilionsResult[0]);
							
							this.loadPavilioner(pavilioners[0]);
							// console.log("pavilioners edit: pavilioners: "+JSON.stringify(pavilioners));
							// console.log("pavilions: "+JSON.stringify(pavilions));
						}							
					}
				)
				this.subscriptions.push(sub);
				
			}
		});
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("pavilioner edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
    
    	// Init DataSource
		this.pavilionDataSource = new PavilionsDataSource(this.store);
		this.pavilionersDataSource = new PavilionersDataSource(this.store);

		
		// this.loadPavilioner(new Pavilioner());
		
		this.loadPavilioners();
		this.loadPavilions();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		
		this.subscriptions.push(authSubscription);
	}

	loadPavilions(){
		this.store.dispatch(new PavilionGetAll({}));
	}

	loadPavilioners(){
		this.store.dispatch(new PavilionerGetAll({}));
	}

	loadPavilioner(pavilioner, fromService: boolean = false) {
		if (!pavilioner) {
			this.goBack('');
		}
		this.pavilioner = pavilioner;
		this.pavilionerId$ = of(pavilioner.id);
		this.oldPavilioner = Object.assign({}, pavilioner);

		this.initPavilioner();

		this.selectedPavilion = this.pavilionsResult[0];
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init pavilioner
	 */
	initPavilioner() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.pavilioner.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de pabelloneros', page: `/plant-configuration/pavilioner` },
				{ title: 'Asignacion de pabellonero', page: `/plant-configuration/pavilioner/assign` }
			]);
			return;
		}
		this.subheaderService.setTitle('Assign pavilioner');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de pabelloneros', page: `/plant-configuration/pavilion` },
			{ title: 'Desasignacion de pabellonero', page: `/plant-configuration/pavilioner/unlink`, queryParams: { id: this.pavilioner.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// console.log("creating pavilioner form");
		// console.log(JSON.stringify(this.selectedPavilioner));
		if(this.id && this.pavilioner){
			this.selectPavilioner(this.pavilioner);
		}
		// console.log("after");
		// console.log(JSON.stringify(this.selectedPavilioner));
		const pavilion = this.selectedPavilion!=undefined?this.selectedPavilion:new Pavilion();
		const pavilioner = this.selectedPavilioner!=undefined?this.selectedPavilioner:this.pavilioner;
		console.log("pavilioner create form: "+JSON.stringify(pavilioner));
		this.pavilionerForm = this.formBuilder.group({
			pavilion: [pavilion.id, Validators.required],
			pavilioner: [{value: pavilioner.id, disabled:this.id!=undefined}, Validators.required],
		});
		this.pavilionerForm.get('pavilioner').patchValue(pavilioner.id);
		// console.log("pavilioner form created");
		// console.log(this.pavilionerForm);
		// console.log(typeof(this.pavilionerForm));
		// console.log(JSON.stringify(this.pavilionerForm));

		// this.filteredPavilions = this.pavilionerForm.controls.pavilion.valueChanges
		// 	.pipe(
		// 		startWith(''),
		// 		tap(val=>{
		// 			console.log("val: "+val)
		// 		}),
		// 		map(val => this.filterPavilion(val.toString()))
		// 	);
		// this.filteredPavilioners = this.pavilionerForm.controls.pavilioner.valueChanges
		// 	.pipe(
		// 		startWith(''),
		// 		map(val => this.filterPavilioner(val.toString()))
		// 	);
		this.cdr.detectChanges();
	}

	/**
	 * Filter pavilion
	 *
	 * @param val: string
	 */
	filterPavilion(val: string): Pavilion[] {
		if (this.pavilionsResult){
			if(!this.selectedPavilioner){
				return this.pavilionsResult.filter(pavilion =>
					pavilion.name.toLowerCase().includes(val.toLowerCase()));
			}else if(this.purpose == "assigning"){
				return this.pavilionsResult.filter(pavilion =>{
					return pavilion.name.toLowerCase().includes(val.toLowerCase()) && 
					this.selectedPavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1
				});
			}else{
				return this.pavilionsResult.filter(pavilion =>{
					return pavilion.name.toLowerCase().includes(val.toLowerCase()) && 
					this.selectedPavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)!=-1
				});
			}
		}
		return [];
	}

	/**
	 * Filter pavilioner
	 *
	 * @param val: string
	 */
	filterPavilioner(val: string): Pavilioner[] {
		if (this.pavilionersResult){
			
			let filteredPavilioners = this.pavilionersResult.filter(pavilioner =>
				pavilioner.name.toLowerCase().includes(val.toLowerCase()));
			
			if(filteredPavilioners && filteredPavilioners.length==1){
				// console.log("filtering pavilions!");
				this.selectPavilioner(filteredPavilioners[0]);
				let pavilioner = filteredPavilioners[0];
				if(pavilioner.pavilions){
					// console.log("pavilioner.pavilions: "+pavilioner.pavilions);
					if(this.purpose=="assigning"){						
						// console.log("assigning");
						// console.log("filtering "+JSON.stringify( filteredPavilioners[0].pavilions.split(',')));	
						this.pavilionsResult = this.allPavilions.filter((pavilion)=>{
							// console.log("filtering by "+pavilion.id +".- "+pavilion.name+" "+pavilion.type);
							// if( filteredPavilioners[0].pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1){
							// 	console.log("the pavilion does not appear in the list");
							// }else{
							// 	console.log("the pavilion appear in the list");
							// }
							return pavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)==-1;
						});
					}else{
						// console.log("unlinking");
						this.pavilionsResult = this.allPavilions.filter((pavilion)=>pavilioner.pavilions.split(',').indexOf(pavilion.id +".- "+pavilion.name+" "+pavilion.type)>-1);
					}
				}else if(this.purpose == "assigning"){
					// console.log("assigning but there is not pavilioner.pavilions");
					this.pavilionsResult = this.allPavilions;			
				}
				if(this.pavilionerForm)
					this.pavilionerForm.get("pavilion").patchValue(this.pavilionsResult[0].name);
				// console.log("Pavilion list filtered: "+JSON.stringify(this.pavilionsResult));
			}
			return filteredPavilioners;
		}
		return [];
	}

	// findPavilioner(name:String):Pavilioner{
	// 	console.log(JSON.stringify(this.pavilionersResult));
	// 	let filteredPavilioners = this.pavilionersResult.filter(pavilioner =>
	// 		pavilioner.name.toLowerCase() == name.toLowerCase()
	// 	);
	// 	return filteredPavilioners[0];
	// }

	// findPavilion(name:String):Pavilion{
	// 	console.log(JSON.stringify(this.pavilionsResult));
	// 	return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	// }

	findPavilioner(id:number):Pavilioner{
		// console.log(JSON.stringify(this.pavilionersResult));
		let filteredPavilioners = this.pavilionersResult.filter(pavilioner =>
			pavilioner.id == id
		);
		return filteredPavilioners[0];
	}

	findPavilion(id:number):Pavilion{
		// console.log(JSON.stringify(this.pavilionsResult));
		return this.pavilionsResult.filter(pavilion=>pavilion.id == id)[0];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/pavilioners?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/pavilioners', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh pavilioner
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshPavilioner(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/pavilioner/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.pavilioner = Object.assign({}, this.oldPavilioner);
		this.createForm();
		this.hasFormErrors = false;
		this.pavilionerForm.markAsPristine();
		this.pavilionerForm.markAsUntouched();
		this.pavilionerForm.updateValueAndValidity();
	}

	selectPavilion(pavilion:Pavilion){
		console.log("select pavilion: "+JSON.stringify(pavilion));
		this.selectedPavilion = pavilion;
	}

	selectPavilioner(pavilioner:Pavilioner){
		console.log("select pavilioner: "+JSON.stringify(pavilioner));
		this.selectedPavilioner = pavilioner;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.pavilionerForm.controls;
		/** check form */
		if (this.pavilionerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let data = this.prepareData();

		if (this.purpose == "assigning") {
			this.assignPavilioner(data, withBack);
			return;
		}

		this.unlinkPavilioner(data, withBack);
	}

	/**
	 * Returns object for saving
	 */
	prepareData(): {pavilioner:Pavilioner, pavilion:Pavilion, writable:boolean} {
		const controls 			= this.pavilionerForm.controls;
		var pavilioner = this.selectedPavilioner;
		console.log("purpose: "+this.purpose);
		if(this.id!=undefined){
			pavilioner 			= this.selectedPavilioner;
		}
		// let pavilion 			= this.selectedPavilion;
		let pavilion 			= this.selectedPavilion;
		console.log("pavilion found: "+JSON.stringify(pavilion));
		return {pavilioner: pavilioner, pavilion: pavilion, writable: true};
	}

	/**
	 * Assign pavilion
	 *
	 * @param data: {pavilioner: Pavilioner, pavilion: Pavilion}
	 * @param withBack: boolean
	 */
	assignPavilioner(data: {pavilioner: Pavilioner, pavilion: Pavilion}, withBack: boolean = false) {
		this.loadingSubject.next(true);
		let data2 = {pavilion: {...data.pavilion}, pavilioner: {...data.pavilioner}};
		data2.pavilioner.pavilions = data.pavilioner.pavilions + data.pavilion.id +".- "+data.pavilion.name+" "+data.pavilion.type;
		this.store.dispatch(new PavilionAssign(data2)); 

		of(undefined).pipe(delay(3000)).subscribe(() => { // Remove this line
			if (withBack) {
				this.goBack(data.pavilioner.id);
			} else {
				const message = `Pabellonero ha sido asignado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshPavilioner(false);
			}
		}); // Remove this line
		// this.componentSubscriptions = this.store.pipe(
		// 	delay(1000),
		// 	select(selectLastCreatedPavilionerId)
		// ).subscribe(newId => {
		// 	if (!newId) {
		// 		return;
		// 	}

		// 	this.loadingSubject.next(false);
		// 	if (withBack) {
		// 		this.goBack(newId);
		// 	} else {
		// 		const message = `Pavilion successfully has been assigned.`;
		// 		this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
		// 		this.refreshPavilioner(true, newId);
		// 	}
		// });
	}

	/**
	 * Unlink pavilioner
	 *
	 * @param data: {pavilioner: Pavilioner, pavilion: Pavilion}
	 * @param withBack: boolean
	 */
	unlinkPavilioner(data: {pavilioner: Pavilioner, pavilion: Pavilion, writable:boolean}, withBack: boolean = false) {
		this.loadingSubject.next(true);
		let data2 = {pavilion: {...data.pavilion}, pavilioner: {...data.pavilioner}};
		data2.pavilioner.pavilions = data.pavilioner.pavilions.split(',').filter(
			(pavilionStr)=>pavilionStr.split(' ').slice(1,-1).join(' ') != 
				data.pavilion.id +".- "+data.pavilion.name+" "+data.pavilion.type).join(',');

		this.store.dispatch(new PavilionUnlink(data));

		of(undefined).pipe(delay(3000)).subscribe(() => { // Remove this line
			if (withBack) {
				this.goBack(data.pavilioner.id);
			} else {
				const message = `Pabellonero ha sido desasignado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshPavilioner(false);
			}
		}); // Remove this line
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Asignar pabellonero';
		if (this.purpose == "assigning") {
			return result;
		}

		result = `Desasignar pabellonero - ${this.pavilioner?this.pavilioner.name:''}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}