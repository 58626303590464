import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_APK_URL } from '../../connection.data';

@Injectable()
export class ApkService{
    
    constructor(private http: HttpClient){

    }

    getByDate(date: Date):Observable<any[]>{
        const url = `${API_APK_URL}/guano-by-date?date=${encodeURIComponent(formatDate(date))}`;
        return this.http.get<any[]>(url);
    }

    edit(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${API_APK_URL}/sendData`, item).pipe(
            map((response)=>response.errorMessage?'error': 'success')
        );
    }
}

function formatDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function numberTo2DigitsString(number){
    return `${number<10?0:''}${number}`;
}