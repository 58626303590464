import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, switchMap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { AlarmCfgsService } from '../_services/alarmscfg.services';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    AlarmCfgActionTypes,
    AlarmCfgActions,
    AlarmCfgCreated,
    AlarmCfgOnServerCreated,
    AlarmCfgUpdated,
    AlarmCfgsActionToggleLoading,
    AlarmCfgsPageCancelled,
    AlarmCfgsPageLoaded,
    AlarmCfgsPageRequested,
    AlarmCfgsPageToggleLoading,
    AlarmCfgsStatusUpdated,
    ManyAlarmCfgsDeleted,
    OneAlarmCfgDeleted,
    AlarmCfgsGetAll,
    AlarmCfgsAllLoaded,
    AlarmCfgUpdate
} from '../_actions/alarmscfg.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class AlarmCfgEffects {
    showPageLoadingDistpatcher = new AlarmCfgsPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new AlarmCfgsPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AlarmCfgsPageToggleLoading({ isLoading: false });

    @Effect()
    loadAlarmCfgsPage$ = this.actions$
        .pipe(
            ofType<AlarmCfgsPageRequested>(AlarmCfgActionTypes.AlarmCfgsPageRequested),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.alarmCfgsService.getPage(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AlarmCfgsPageLoaded({
                    alarmCfgs: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );
    @Effect()
    getAll: Observable<any> = this.actions$.pipe(
        ofType<AlarmCfgsGetAll>(AlarmCfgActionTypes.AlarmCfgsGetAll),
        map((action: AlarmCfgsGetAll) => action.payload),
        switchMap(payload => {
            return this.alarmCfgsService.getAllAlarmCfgs().pipe(
                map(alarmCfgs=>{
                    console.log("get all alarm cfgs");
                    console.log(JSON.stringify(alarmCfgs));
                    return {alarmCfgs: alarmCfgs};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new AlarmCfgsAllLoaded(response);
        })
    );
    @Effect()
    deleteAlarmCfg$ = this.actions$
        .pipe(
            ofType<OneAlarmCfgDeleted>(AlarmCfgActionTypes.OneAlarmCfgDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    return this.alarmCfgsService.deleteAlarmCfg(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAlarmCfgs$ = this.actions$
        .pipe(
            ofType<ManyAlarmCfgsDeleted>(AlarmCfgActionTypes.ManyAlarmCfgsDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmCfgsService.deleteAlarmCfgs(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAlarmCfgsStatus$ = this.actions$
        .pipe(
            ofType<AlarmCfgsStatusUpdated>(AlarmCfgActionTypes.AlarmCfgsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmCfgsService.updateStatusForAlarmCfg(payload.alarmCfgs, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAlarmCfg$ = this.actions$
        .pipe(
            ofType<AlarmCfgUpdate>(AlarmCfgActionTypes.AlarmCfgUpdate),
            map((action: AlarmCfgUpdate) => action.payload),
            switchMap((payload) => {
                // this.store.dispatch(this.showLoadingDistpatcher);
                console.log("update alarm cfg effect");
                return this.alarmCfgsService.updateAlarmCfg(payload.alarmCfg).pipe(
                    map(response=>{return {payload: payload, response:response}})
                );
            }),
            map((response) => {
                console.log(JSON.stringify(response));
                return new AlarmCfgUpdated({...response.payload, id: response.response.id});
            }),
        );

    @Effect()
    createAlarmCfg$ = this.actions$
        .pipe(
            ofType<AlarmCfgOnServerCreated>(AlarmCfgActionTypes.AlarmCfgOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmCfgsService.createAlarmCfg(payload.alarmCfg).pipe(
                    tap(res => {
                        this.store.dispatch(new AlarmCfgCreated({ alarmCfg: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    @Effect({dispatch:false})
    allLoaded: Observable<any> = this.actions$.pipe(
        ofType(AlarmCfgActionTypes.AlarmCfgsAllLoaded),
        tap((action:AlarmCfgsAllLoaded)=>console.log("alarm cfgs all loaded"))
    );

    @Effect({dispatch:false})
    updated: Observable<any> = this.actions$.pipe(
        ofType(AlarmCfgActionTypes.AlarmCfgUpdated),
        tap((action:AlarmCfgUpdated)=>console.log("alarm cfg updated: "))
    );
    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new AlarmCfgsPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private alarmCfgsService: AlarmCfgsService, private store: Store<AppState>) { }
}
