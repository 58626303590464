// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, tap, debounceTime } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	Pavilion, 
	Deads,
	PavilionsDataSource,
	selectDeadsById,
	DeadsDataSource,
  selectLastCreatedDeadsId,
  DeadsEdit,
  selectLastUpdatedDeadsId,
  PavilionGetAll,
  DeadsAdd,
  Floor,
  selectPavilionFloors,
  PavilionGetFloors
} from '../../../../../core/plant-configuration';
import { User, UsersDataSource } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';

@Component({
  selector: 'kt-deads-edit',
  templateUrl: './deads-edit.component.html',
  styleUrls: ['./deads-edit.component.scss']
})
export class DeadsEditComponent implements OnInit, OnDestroy {
	@ViewChild('wizard', {static: true}) el: ElementRef;
  // Public properties
	id:number=null;
	deads: Deads;
	deadsId$: Observable<number>;
	oldDeads: Deads;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	deadsForm: FormGroup;
	hasFormErrors = false;
	pavilionsResult :Pavilion[] = [];
	selectedPavilion: Pavilion = new Pavilion();
	private subscriptions: Subscription[] = [];
	private user:User = new User();
	private pavilionsDataSource: PavilionsDataSource;
	floorsResult: Floor[] = [];
	selectedFloor: Floor = null;
	private editing = false;
	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("deads edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);
		
		// Init DataSource
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const entitiesSubscription = this.pavilionsDataSource.entitySubject.pipe(
		distinctUntilChanged()
		).subscribe(res => {
		this.pavilionsResult = res;
		// this.selectPavilion(new Pavilion());
		if(res){
			if(!this.editing)
				this.selectPavilion(res[0]);
			this.cdr.detectChanges();
		}
		});

		const floors$ = this.store.pipe(
				select(selectPavilionFloors)
			);
		const floorsSub = floors$.subscribe((floors)=>{
			console.log("floors sub")
			console.log("floors: "+JSON.stringify(floors));
			this.floorsResult = floors;
			// if(!this.editing)this.selectFloor(new Floor());
			try{
				
				if(!this.editing){
					this.selectFloor(floors[0]);
					this.deadsForm.get("floor").patchValue(floors[0].id);
				}else{
					this.selectFloor(floors.find(floor=>floor.name==this.deads.floor_name));
					this.deadsForm.get("floor").patchValue(this.selectedFloor.id);
				}
			}catch(error){

			}
			this.cdr.detectChanges();
		});
		this.subscriptions.push(floorsSub);
		this.subscriptions.push(entitiesSubscription);
		this.activatedRoute.params.subscribe(params => {
			this.id = params.id;
			if (this.id && this.id > 0) {
				this.editing=true;
				// const deads$ = this.store.pipe(
				// 	select(selectDeadsById(this.id))
				// );
				// const sub = deads$.subscribe(
				// 	(deads)=>{
				// 		if(deads){
				// 			this.loadDeads(deads);
				// 		}							
				// 	}
				// )
				// this.subscriptions.push(sub);
			} else {
        var deads:Deads = new Deads();
        deads.clear();
        this.loadDeads(deads);
			}
		});
		this.loadPavilions();

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
		this.subscriptions.push(authSubscription);
  	}

  loadPavilions(){
    this.store.dispatch(new PavilionGetAll({}));
  }

  loadPavilionFloors(pavilion:Pavilion){
	  console.log(`pavilion: ${JSON.stringify(pavilion)}`);
		this.store.dispatch(new PavilionGetFloors({pavilion: pavilion}));
	}
  
	loadDeads(deads: Deads, fromService: boolean = false) {
		if (!deads) {
			this.goBack('');
		}
		this.deads = Object.assign(new Deads(), deads);
		this.deadsId$ = of(deads.id);
		this.oldDeads = Object.assign({}, deads);
		
		this.selectedPavilion = new Pavilion();
		this.selectedPavilion.clear();
		this.selectedPavilion.id = deads.pavilion_id;
		this.selectedPavilion.name = deads.pavilion_name;
		this.selectedPavilion.type = deads.pavilion_type_name;
		console.log("deads: "+JSON.stringify(this.deads));
		this.selectPavilion(this.selectedPavilion);
		this.initDeads();
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init deads
	 */
	initDeads() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.deads.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de registros de muerte', page: `/nourishment/deads-reports` },
				{ title: 'Registrar muertes', page: `/nourishment/deads-reports/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Crear consumo de agua');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de registros de muerte', page: `/nourishment/deads-reports` },
			{ title: 'Editar registro de muertes', page: `/nourishment/deads-reports/edit`, queryParams: { id: this.deads.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// console.log("creating deads form");
		// console.log(JSON.stringify(this.deads));
		this.deadsForm = this.formBuilder.group({
			pavilion: [this.selectedPavilion.id, Validators.required],
			deads: [this.deads.c_dead_bird?this.deads.c_dead_bird:0],
			deads_w: [this.deads.c_dead_bird_w?this.deads.c_dead_bird_w:0],
			deads_b: [this.deads.c_dead_bird_b?this.deads.c_dead_bird_b:0],
			floor: [this.selectedFloor?this.selectedFloor.id:-1],
		});
		const controls = this.deadsForm.controls;
    	const deadsSub = controls.deads.valueChanges.subscribe(x=>{
			this.deads.c_dead_bird = x;
		});
		const deadsWSub = controls.deads_w.valueChanges.subscribe(x=>{
			this.deads.c_dead_bird_w = x;
		});
		const deadsBSub = controls.deads_b.valueChanges.subscribe(x=>{
			this.deads.c_dead_bird_b = x;
		});
		this.subscriptions.push(deadsWSub);
		this.subscriptions.push(deadsBSub);
		this.subscriptions.push(deadsSub);
		this.cdr.detectChanges();
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/nourishment/deads-reports?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/nourishment/deads-reports', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh deads
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshDeads(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/nourishment/deads-reports/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.deads = Object.assign({}, this.oldDeads);
		this.createForm();
		this.hasFormErrors = false;
		this.deadsForm.markAsPristine();
		this.deadsForm.markAsUntouched();
		this.deadsForm.updateValueAndValidity();
	}

	selectPavilion(pavilion: Pavilion){
		console.log("selected pavilion: "+JSON.stringify(pavilion));
		this.selectedPavilion = pavilion;
		this.loadPavilionFloors(pavilion);
	}

	selectFloor(floor: Floor){
		this.selectedFloor = floor;
		console.log("selected floor: "+JSON.stringify(floor));
	}
	
	findPavilion(id: number){
		console.log(`find pavilion id ${id}`);
		return this.pavilionsResult.find(pav=>pav.id==id);
	}

  	findFloor(id:number){
    	return this.floorsResult.find(floor=>floor.id==id);
  	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.deadsForm.controls;
		/** check form */
		if (this.deadsForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let deads = this.prepareData();
		console.log("prepared deads: "+JSON.stringify(deads));
		console.log("deads: "+JSON.stringify(this.deads));
		if (deads.id > 0) {
			this.updateDeads(deads, withBack);
			return;
		}

		this.addDeads(deads, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareData(): Deads {
		const controls 			= this.deadsForm.controls;
		let deads: Deads = Object.assign(this.deads, {});
		deads.clear();
		if(this.id)
			deads.id = this.id;
		deads.pavilion_id = this.selectedPavilion.id;
		deads.pavilion_name = this.selectedPavilion.name;
		
    	// deads.c_dead_bird = controls.deads.value;
		if(this.selectedPavilion.type=='CRIANZA'){
			deads.c_dead_bird 	= controls.deads.value;
		}else{
			deads.c_dead_bird_w = controls.deads_w.value;
			deads.c_dead_bird_b = controls.deads_b.value;
			if(this.selectedFloor){
				deads.floor_id = this.selectedFloor.id;
				deads.floor_name = this.selectedFloor.name;
			}
		}
    	
		deads.company_id = this.user.company_id;
		deads.write_uid = this.user.id;
		return deads;
	}

	/**
	 * Add Deads
	 *
	 * @param deads: Deads
	 * @param withBack: boolean
	 */
	addDeads(deads:Deads, withBack:boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new DeadsAdd({deads: deads}));
		const addBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastCreatedDeadsId)
		).subscribe(newId => {
			if (!newId || newId==-1) {
				// addBreedingSub.unsubscribe();
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Registro de muertes ha sido creado exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshDeads(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update deads
	 *
	 * @param deads: Deads
	 * @param withBack: boolean
	 */
	updateDeads(deads: Deads, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateDeads: Update<Deads> = {
			id: deads.id,
			changes: deads
		};

		this.store.dispatch(new DeadsEdit({
			partialDeads: updateDeads,
			deads:deads
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedDeadsId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
      }
      if(id==deads.id){
        if (withBack) {
          this.goBack(deads.id);
        } else {
          const message = `Registro de muertes ha sido actualizado exitosamente.`;
          this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
          this.refreshDeads(false);
        }
        updateBreedingSub.unsubscribe();
      }
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Crear registro de muertes';
		if (!this.deads || !this.deads.id) {
			return result;
		}

		result = `Editar registro de muertes - ${this.deads.c_dead_bird} en ${this.deads.pavilion_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}