// Angular
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
// AppState
import { AppState } from '../../../core/reducers';
// Auth
import { Permission } from '../../../core/auth';

@Component({
  selector: 'kt-plant-configuration',
  templateUrl: './plant-configuration.component.html',
  //styleUrls: ['./plant-configuration.component.scss']
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlantConfigurationComponent implements OnInit {

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit() {
  }

}
