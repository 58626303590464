// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { ActionNotificationComponent } from '../../partials/content/crud';

// Components
import { PavilionListComponent } from './pavilion/pavilion-list/pavilion-list.component';
import { PavilionEditComponent } from './pavilion/pavilion-edit/pavilion-edit.component';
import { BreedingListComponent } from './breeding/breeding-list/breeding-list.component';
import { BreedingEditComponent } from './breeding/breeding-edit/breeding-edit.component';
import { HylineEditComponent } from './hyline/hyline-edit/hyline-edit.component'
import { HylineListComponent } from './hyline/hyline-list/hyline-list.component'
import { PavilionersEditComponent } from './pavilioners/pavilioners-edit/pavilioners-edit.component'
import { PavilionersListComponent } from './pavilioners/pavilioners-list/pavilioners-list.component'
import { LoadbirdsEditComponent } from './loadbirds/loadbirds-edit/loadbirds-edit.component'
import { LoadbirdsListComponent } from './loadbirds/loadbirds-list/loadbirds-list.component'
import { MovementsEditComponent} from './movements/movements-edit/movements-edit.component'
import { MovementsListComponent} from './movements/movements-list/movements-list.component'
import { SilosEditComponent } from './silos/silos-edit/silos-edit.component'
import { SilosListComponent } from './silos/silos-list/silos-list.component'
import { LoadbreadingEditComponent } from './loadbreading/loadbreading-edit/loadbreading-edit.component'
import { LoadbreadingListComponent } from './loadbreading/loadbreading-list/loadbreading-list.component'


// Material
import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule
} from '@angular/material';
import { PlantConfigurationComponent } from './plant-configuration.component';
import { 
	SiloEffects, 
	silosReducer, 
	pavilionsReducer, 
	PavilionEffects, 
	grainTypesReducer,
	GrainTypeEffects,
	pavilionTypesReducer,
	pavilionersReducer,
	BirdStockEffects,
	birdTypesReducer,
	birdGensReducer,
	breedingStockReducer,
	stockMovReducer,
	pavilionFloorsReducer,
	hylineReducer,
	hylineDataReducer,
	hylineTypesReducer,
	hylineDataTypeReducer,
	HylineEffects,
	hylineBreDataReducer,
	hylineHavDataReducer,
	hylineLMoltDataReducer,
	hylineEMoltDataReducer,
	grainStockConsumptionReducer,
	grainStockLoadReducer,
	GrainStockEffects,
	birdStatusTypesReducer
} from '../../../core/plant-configuration';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
	{
		path: '',
		component: PlantConfigurationComponent,
		children: [
			{
				path: '',
				redirectTo: 'pavilion',
				pathMatch: 'full'
			},
			{
				path: 'pavilion',
				component: PavilionListComponent
			},
			{
				path: 'pavilion:id',
				component: PavilionListComponent
			},
			{
				path: 'pavilion/add',
				component: PavilionEditComponent
			},
			{
				path: 'pavilion/add:id',
				component: PavilionEditComponent
			},
			{
				path: 'pavilion/edit',
				component: PavilionEditComponent
			},
			{
				path: 'pavilion/edit/:id',
				component: PavilionEditComponent
			},
			{
				path: 'breeding',
				component: BreedingListComponent
			},
			{
				path: 'breeding:id',
				component: BreedingListComponent
			},
			{
				path: 'breeding/add',
				component: BreedingEditComponent
			},
			{
				path: 'breeding/add:id',
				component: BreedingEditComponent
			},
			{
				path: 'breeding/edit',
				component: BreedingEditComponent
			},
			{
				path: 'breeding/edit/:id',
				component: BreedingEditComponent
			},
			{
				path: 'hyline',
				component: HylineListComponent
			},
			{
				path: 'hyline:id',
				component: HylineListComponent
			},
			{
				path: 'hyline/add',
				component: HylineEditComponent
			},
			{
				path: 'hyline/add/:id',
				component: HylineEditComponent
			},
			{
				path: 'hyline/edit',
				component: HylineEditComponent
			},
			{
				path: 'hyline/edit/:id',
				component: HylineEditComponent
			},
			{
				path: 'pavilioners',
				component: PavilionersListComponent
			},
			{
				path: 'pavilioners:id',
				component: PavilionersListComponent
			},
			{
				path: 'pavilioners/assign',
				component: PavilionersEditComponent
			},
			{
				path: 'pavilioners/assign/:id',
				component: PavilionersEditComponent
			},
			{
				path: 'pavilioners/unlink/:id',
				component: PavilionersEditComponent
			},
			{
				path: 'pavilioners/edit/:id',
				component: PavilionersEditComponent
			},
			{
				path: 'silos',
				component: SilosListComponent
			},
			{
				path: 'silos:id',
				component: SilosListComponent
			},
			{
				path: 'silos/add',
				component: SilosEditComponent
			},
			{
				path: 'silos/add/:id',
				component: SilosEditComponent
			},
			{
				path: 'silos/edit',
				component: SilosEditComponent
			},
			{
				path: 'silos/edit/:id',
				component: SilosEditComponent
			},
			{
				path: 'movements',
				component: MovementsListComponent
			},
			{
				path: 'movements:id',
				component: MovementsListComponent
			},
			{
				path: 'movements/add',
				component: MovementsEditComponent
			},
			{
				path: 'movements/add/:id',
				component: MovementsEditComponent
			},
			{
				path: 'movements/edit',
				component: MovementsEditComponent
			},
			{
				path: 'movements/edit/:id',
				component: MovementsEditComponent
			},
			{
				path: 'loadbirds',
				component: LoadbirdsListComponent
			},
			{
				path: 'loadbirds:id',
				component: LoadbirdsListComponent
			},
			{
				path: 'loadbirds/add',
				component: LoadbirdsEditComponent
			},
			{
				path: 'loadbirds/add/:id',
				component: LoadbirdsEditComponent
			},
			{
				path: 'loadbirds/edit',
				component: LoadbirdsEditComponent
			},
			{
				path: 'loadbirds/edit/:id',
				component: LoadbirdsEditComponent
			},
			{
				path: 'loadbreading',
				component: LoadbreadingListComponent
			},
			{
				path: 'loadbreading:id',
				component: LoadbreadingListComponent
			},
			{
				path: 'loadbreading/add',
				component: LoadbreadingEditComponent
			},
			{
				path: 'loadbreading/add/:id',
				component: LoadbreadingEditComponent
			},
			{
				path: 'loadbreading/edit',
				component: LoadbreadingEditComponent
			},
			{
				path: 'loadbreading/edit/:id',
				component: LoadbreadingEditComponent
			},

		]
	}
];


@NgModule({
	declarations: [
		PlantConfigurationComponent,
		PavilionListComponent, 
		PavilionEditComponent, 
		BreedingListComponent, 
		BreedingEditComponent,
		HylineEditComponent,
		HylineListComponent,
		PavilionersEditComponent,
		PavilionersListComponent,
		LoadbirdsEditComponent,
		LoadbirdsListComponent,
		MovementsEditComponent,
		MovementsListComponent,
		SilosEditComponent,
		SilosListComponent,
		LoadbreadingEditComponent,
		LoadbreadingListComponent
	  ],
  imports: [
    CommonModule,
    HttpClientModule,
	PartialsModule,
	RouterModule.forChild(routes),
	NgbModule,
	StoreModule.forFeature('pavilions', pavilionsReducer),
	StoreModule.forFeature('pavilionTypes', pavilionTypesReducer),
	StoreModule.forFeature('pavilioners', pavilionersReducer),
	StoreModule.forFeature('pavilionFloors', pavilionFloorsReducer),
	EffectsModule.forFeature([PavilionEffects]),
	StoreModule.forFeature('silos', silosReducer),
	EffectsModule.forFeature([SiloEffects]),
	StoreModule.forFeature('grainTypes', grainTypesReducer),
	StoreModule.forFeature('grainStockLoads', grainStockLoadReducer),
	StoreModule.forFeature('grainStockConsumptions', grainStockConsumptionReducer),
	EffectsModule.forFeature([GrainTypeEffects]),
	EffectsModule.forFeature([GrainStockEffects]),
	StoreModule.forFeature('breedingStocks', breedingStockReducer),
	StoreModule.forFeature('birdStockMovs', stockMovReducer),
	StoreModule.forFeature('birdTypes', birdTypesReducer),
	StoreModule.forFeature('birdStatusTypes', birdStatusTypesReducer),
	StoreModule.forFeature('birdGens', birdGensReducer),
	EffectsModule.forFeature([BirdStockEffects]),	
	StoreModule.forFeature('hylines', hylineReducer),
	StoreModule.forFeature('hylineDatas', hylineDataReducer),
	StoreModule.forFeature('hylineBreDatas', hylineBreDataReducer),
	StoreModule.forFeature('hylineHavDatas', hylineHavDataReducer),
	StoreModule.forFeature('hylineEMoltDatas', hylineEMoltDataReducer),
	StoreModule.forFeature('hylineLMoltDatas', hylineLMoltDataReducer),
	StoreModule.forFeature('hylineTypes', hylineTypesReducer),
	StoreModule.forFeature('hylineDataTypes', hylineDataTypeReducer),
	EffectsModule.forFeature([HylineEffects]),
    FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
  ],
  entryComponents: [
		ActionNotificationComponent,
		PavilionEditComponent,
		HylineEditComponent,
		PavilionersEditComponent,
		LoadbirdsEditComponent,
		MovementsEditComponent,
		SilosEditComponent,
		LoadbreadingEditComponent
  ],
  
})
export class PlantConfigurationModule { }
