import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, switchMap } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
// CRUD
import { QueryResultsModel, QueryParamsModel } from '../../_base/crud';
// Services
import { AlarmService } from '../_services/alarms.services';
// State
import { AppState } from '../../../core/reducers';
// Actions
import {
    AlarmActionTypes,
    AlarmActions,
    AlarmCreated,
    AlarmOnServerCreated,
    AlarmUpdated,
    AlarmsActionToggleLoading,
    AlarmsPageCancelled,
    AlarmsPageLoaded,
    AlarmsPageRequested,
    AlarmsPageToggleLoading,
    AlarmsStatusUpdated,
    ManyAlarmsDeleted,
    OneAlarmDeleted,
    AlarmsGetTypes,
    AlarmsTypesLoaded,
    AlarmsGetStatusTypes,
    AlarmsStatusTypesLoaded
} from '../_actions/alarms.actions';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class AlarmEffects {
    showPageLoadingDistpatcher = new AlarmsPageToggleLoading({ isLoading: true });
    showLoadingDistpatcher = new AlarmsPageToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new AlarmsPageToggleLoading({ isLoading: false });

    @Effect()
    loadAlarmsPage$ = this.actions$
        .pipe(
            ofType<AlarmsPageRequested>(AlarmActionTypes.AlarmsPageRequested),
            switchMap(( { payload } ) => {
                this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.alarmService.getPage(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(([result, lastQuery])=> {
                console.log("alarms page loaded... data: "+JSON.stringify(result));
                return new AlarmsPageLoaded({
                    alarms: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }),
        );
    
    @Effect()
    getTypes: Observable<any> = this.actions$.pipe(
        ofType<AlarmsGetTypes>(AlarmActionTypes.AlarmsGetTypes),
        map((action: AlarmsGetTypes) => action.payload),
        switchMap(payload => {
            return this.alarmService.getAlarmTypes().pipe(
                map(alarmTypes=>{
                    console.log("get alarm types");
                    console.log(JSON.stringify(alarmTypes));
                    return {alarmTypes: alarmTypes};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new AlarmsTypesLoaded(response);
        })
    );

    @Effect()
    getStatusTypes: Observable<any> = this.actions$.pipe(
        ofType<AlarmsGetStatusTypes>(AlarmActionTypes.AlarmsGetStatusTypes),
        map((action: AlarmsGetStatusTypes) => action.payload),
        switchMap(payload => {
            return this.alarmService.getAlarmStatusTypes().pipe(
                map(alarmStatusTypes=>{
                    console.log("get alarm status types");
                    console.log(JSON.stringify(alarmStatusTypes));
                    return {alarmStatusTypes: alarmStatusTypes};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            //console.log(JSON.stringify(response));
            return new AlarmsStatusTypesLoaded(response);
        })
    );

    @Effect()
    deleteAlarm$ = this.actions$
        .pipe(
            ofType<OneAlarmDeleted>(AlarmActionTypes.OneAlarmDeleted),
            mergeMap(( { payload } ) => {
                    this.store.dispatch(this.showLoadingDistpatcher);
                    console.log("delete alarm effects");
                    return this.alarmService.deleteAlarm(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    deleteAlarms$ = this.actions$
        .pipe(
            ofType<ManyAlarmsDeleted>(AlarmActionTypes.ManyAlarmsDeleted),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmService.deleteAlarms(payload.ids);
                }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAlarmsStatus$ = this.actions$
        .pipe(
            ofType<AlarmsStatusUpdated>(AlarmActionTypes.AlarmsStatusUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmService.updateStatusForAlarm(payload.alarms, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateAlarm$ = this.actions$
        .pipe(
            ofType<AlarmUpdated>(AlarmActionTypes.AlarmUpdated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmService.updateAlarm(payload.alarm);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    createAlarm$ = this.actions$
        .pipe(
            ofType<AlarmOnServerCreated>(AlarmActionTypes.AlarmOnServerCreated),
            mergeMap(( { payload } ) => {
                this.store.dispatch(this.showLoadingDistpatcher);
                return this.alarmService.createAlarm(payload.alarm).pipe(
                    tap(res => {
                        this.store.dispatch(new AlarmCreated({ alarm: res }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );
    
    @Effect({dispatch:false})
    typesLoaded: Observable<any> = this.actions$.pipe(
        ofType(AlarmActionTypes.AlarmsTypesLoaded),
        tap((action:AlarmsTypesLoaded)=>console.log("alarm types loaded"))
    );

    @Effect({dispatch:false})
    statusYypesLoaded: Observable<any> = this.actions$.pipe(
        ofType(AlarmActionTypes.AlarmsStatusTypesLoaded),
        tap((action:AlarmsStatusTypesLoaded)=>console.log("alarm status types loaded"))
    );

    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     const queryParams = new QueryParamsModel({});
    //     return of(new AlarmsPageRequested({ page: queryParams }));
    // });

    constructor(private actions$: Actions, private alarmService: AlarmService, private store: Store<AppState>) { }
}
