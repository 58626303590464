import { Component, OnInit, NgZone, ViewChild, destroyPlatform, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { HistorycData, PavilionHistoryService } from './../../../../core/production/index';
import { formatDate } from '@angular/common';
import { startWith, switchMap } from 'rxjs/operators';
import { BirdGeneration, BSGetPavGens, BSGetPavilionGens, Floor, Pavilion, PavilionGetFloors, PavilionService, selectBirdGens, selectPavilionFloors } from '../../../../core/plant-configuration';
import { AppState } from '../../../../core/reducers';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, of, Subscription } from 'rxjs';

@Component({
  selector: 'kt-bird-type-dashboard',
  templateUrl: './bird-type-dashboard.component.html',
  styleUrls: ['./bird-type-dashboard.component.scss']
})
export class BirdTypeDashboardComponent implements OnInit, OnDestroy {
  selectedButton = 1;
  historyc: HistorycData;
  isPavSel1: string = '1';
  isPavSel:string = '1';
  LastWeek = new Date();
  today = new Date();
  todaylabel = new Date();
  diadesde: any;
  diahasta: any;
  semanadesde: string = '';
  semanahasta: string = '';
  isPlantView: boolean = false;
  performanceData: {labels: string[], datasets:any[], maxY:number};
  accPerformanceData: {labels: string[], datasets:any[], maxY:number};
  nourishmentData: {labels: string[], datasets:any[], maxY:number};
  waterData: {labels: string[], datasets:any[], maxY:number};
  deadsData: {labels: string[], datasets:any[], maxY:number};
  accDeadsData: {labels: string[], datasets:any[], maxY:number};
  temperatureData: {labels: string[], datasets:any[], maxY:number};
  generationsResult: BirdGeneration[] = [];
  selectedBirdTypeId:number = 4;
  selectedPavilionId:number = undefined;
	private selectedGeneration: BirdGeneration = new BirdGeneration();
	private subscriptions: Subscription[] = [];
  private floorsResult: Floor[] = [];

  title: string;

  constructor(
      private store: Store<AppState>,
      private pavilionService: PavilionService,
		  private pavilionHistoryService: PavilionHistoryService,
      private cdr: ChangeDetectorRef
    ) {
    //const history = new HistorycData();
    //history.clear();
    this.historyc = new HistorycData();
    this.historyc.clear();
  }

  ngOnInit() {
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    this.historyc.starting_date = formatDate(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd', 'en');
    this.historyc.ending_date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.diadesde = new Date().setDate(new Date().getDate() - 7);
    this.diahasta = new Date();
    this.subscriptions.push(
      this.store.pipe(
        select(selectPavilionFloors)
      ).subscribe(floors=>{
        this.floorsResult = floors;
        this.refreshCycle();
      })
    );
    this.updateBirdType(4);
  }

  ngOnDestroy(){
    this.subscriptions.map((sub)=>sub.unsubscribe());
  }

  loadPavilionGenerations(id){
    const pavilion = Object.assign(new Pavilion(), {id: id});
    const sub = this.pavilionService.getPavilionGenerations(pavilion).subscribe( response=>{
      console.log("generations sub: "+JSON.stringify(response));
      this.generationsResult=response.birdGens;
      console.log("generationsResult: "+JSON.stringify(this.generationsResult));
			try{
				// this.birdStockMovForm.get("generation_src").patchValue({name: gens[0].name});
				this.selectGeneration(Object.assign(new BirdGeneration(),{id:-1}));
			}catch(error){

      }
    });
    this.subscriptions.push(sub);
  }
  emptyPlotData(){
    this.performanceData = null;
    this.deadsData = null;
    this.waterData = null;
    this.nourishmentData = null;
    this.accDeadsData = null;
    this.accPerformanceData = null;
    this.temperatureData = null;
  }

  loadFloors(pavilionId){
    this.store.dispatch(new PavilionGetFloors({pavilion: {id:pavilionId}}));
  }

  getPavilionShortName(pavilionId){
    const pavShortNames = [
      {id: 8, name: 'GL1', num: 'Av1'},
      {id: 9, name: 'GL2', num: 'Av2'},
      {id: 10, name: 'P1', num: '1'},
      {id: 11, name: 'P2', num: '2'},
      {id: 12, name: 'P3', num: '3'},
      {id: 13, name: 'P4', num: '4'},
      {id: 14, name: 'P5', num: '5'},
      {id: 15, name: 'P6', num: '6'},
      {id: 16, name: 'P7', num: '7'},
      {id: 17, name: 'P8', num: '8'},
      {id: 18, name: 'P9', num: '9'},
      {id: 19, name: 'P10', num: '10'},
      {id: 20, name: 'P11', num: '11'},
      {id: 21, name: 'P12', num: '12'},
      {id: 1, name: 'C1', num: '1'},
      {id: 2, name: 'C1C', num: '1C'},
      {id: 3, name: 'C2', num: '2'},
      {id: 4, name: 'C2C', num: '2C'},
      {id: 5, name: 'C3', num: '3'},
      {id: 6, name: 'C3C', num: '3C'},
      {id: 7, name: 'C4', num: '4'},
    ];
    
    return pavShortNames.find((pav)=>pav.id==pavilionId);
  }

  updateBirdType(id){
    this.emptyPlotData();
    this.isPlantView = false;
    this.selectedPavilionId = undefined;
    this.selectedBirdTypeId = id;
    this.refreshDay(this.diadesde, this.diahasta);
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(), 'dd/MM/yyyy', 'en') + ' | ' + formatDate(new Date(), 'hh:mm', 'en');
    //document.getElementById("TitlePavHist").title = "'\<strong>Data histórica\</strong> Pabellón P4'";
  }

  updatePavilion(id) {
    this.emptyPlotData();
    this.isPlantView = false;
    // this.historyc.pavilion_id = id;
    this.selectedPavilionId = id;
    this.selectedBirdTypeId = undefined;
    // this.refreshDay(this.diadesde, this.diahasta); // Esta funcion está siendo llamada al cargar los pisos !!
    this.loadFloors(id);//Refresca graficos cuando encuentra los pisos
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(), 'dd/MM/yyyy', 'en') + ' | ' + formatDate(new Date(), 'hh:mm', 'en');
    //document.getElementById("TitlePavHist").title = "'\<strong>Data histórica\</strong> Pabellón P4'";

  }

  floorLabelFormatter(floor_id){
    const floor = this.floorsResult.find(floor=>floor.id==floor_id);
    if(floor){
      return `${floor.name}`;
    }
    return 'Piso desconocido';
  }

  pavilionLabelFormatter(pavilion_id){
    const selectedPavShortName = this.getPavilionShortName(pavilion_id);
    if(selectedPavShortName)
      return selectedPavShortName.name;
    return "desconocido";
  }

  refreshDay(diadesde, diahasta) {
    this.diadesde = diadesde;
    this.diahasta = diahasta;
    console.log("refresh day.. diadesde: "+this.diadesde);
    console.log("selected pavilion: "+this.selectedPavilionId);
    const startingDay = formatDate(this.diadesde, 'yyyy-MM-dd', 'en-US');
    const endingDay = formatDate(this.diahasta, 'yyyy-MM-dd', 'en-US');
    this.historyc.starting_date = startingDay;
    this.historyc.ending_date = endingDay;    
    this.subscriptions.push(
      of(0).pipe(        
        switchMap(()=>{
          if(this.selectedPavilionId){
            console.log("Hay un pabellon seleccionado: "+this.selectedPavilionId);
            return combineLatest([
              this.pavilionHistoryService.get_history_pavilion_harvest_day(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_nourishment_day(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_acc_harvest_day(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_deads_day(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_acc_deads(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_water_day(this.selectedPavilionId,startingDay, endingDay),
              this.pavilionHistoryService.get_history_pavilion_temperature_day(this.selectedPavilionId, startingDay, endingDay)
            ]);
          }else{
            return combineLatest([
              this.pavilionHistoryService.get_history_pavilion_harvest(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_nourishment(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_acc_harvest(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_deads(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_acc_deads(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_water(undefined,startingDay, endingDay, undefined, this.selectedBirdTypeId),
              this.pavilionHistoryService.get_history_pavilion_temperature(undefined, startingDay, endingDay, undefined, this.selectedBirdTypeId)
            ]);
          }
        })
      ).subscribe(([performanceTable, nourishmentTable, accPerformanceTable, 
          deadsTable, accDeadsTable, waterTable, temperatureTable])=>{
          console.log("performance table: "+JSON.stringify(performanceTable));
          const performanceOptions = [{
              seriesField: 'bird_type_id',
              seriesFieldLabel: '',
              valueField: 'harvest',
              aggregate: 'sum',
              labelFormatter: (id)=>'Categoría'
            },
            {
              seriesField: 'bird_type_id',
              seriesFieldLabel: '',
              valueField: 'harvest_hyline',
              aggregate: 'sum',
              labelFormatter: (id)=>"Hyline"
            }
          ];
          const accPerformanceOptions = [
            {
              seriesField: 'bird_type_id',
              seriesFieldLabel: 'Categoría',
              valueField: 'harvest',
              aggregate: 'sum',
              labelFormatter: ()=> "Categoría"
            },
            {
              seriesField: 'bird_type_id',
              seriesFieldLabel: 'Categoría',
              valueField: 'hyline_c_harvest',
              aggregate: 'sum',
              labelFormatter: ()=>"Categoría"
            }
          ];
          const nourishmentOptions = [{
              seriesField: 'bird_type_id',
              seriesFieldLabel: '',
              valueField: 'nourishment',
              aggregate: 'sum',
              labelFormatter: (id)=>'Planta'
            },{
              seriesField: 'bird_type_id',
              seriesFieldLabel: '',
              valueField: 'nourishment_hyline',
              aggregate: 'sum',
              labelFormatter: (id)=>'Hyline'
          }];
          const waterOptions = {
            seriesField: 'pavilion_id',
            seriesFieldLabel: 'Pabellón',
            valueField: 'water',
            aggregate: 'sum',
            labelFormatter: (pavilion_id)=>this.pavilionLabelFormatter(pavilion_id)
          };
          const deadsOptions = {
            seriesField: 'pavilion_id',
            seriesFieldLabel: 'Pabellón',
            valueField: 'deads',
            aggregate: 'sum',
            labelFormatter: (pavilion_id)=>this.pavilionLabelFormatter(pavilion_id)
          };
          const accDeadsOptions = {
            seriesField: 'pavilion_id',
            seriesFieldLabel: 'Pabellón',
            valueField: 'acc_deads',
            aggregate: 'sum',
            labelFormatter: (pavilion_id)=>this.pavilionLabelFormatter(pavilion_id)
          };
          // const temperatureOptions = {
          //   seriesField: 'pavilion_id',
          //   seriesFieldLabel: 'Pabellón',
          //   valueField: 'avg',
          //   aggregate: 'none',
          //   labelFormatter: (pavilion_id)=>this.pavilionLabelFormatter(pavilion_id)
          // };
          const temperatureOptions = [
            {
              seriesFieldLabel: 'Pabellón',
              valueField: 'max',
              aggregate: 'max',
              labelFormatter: (floor_id)=>this.floorLabelFormatter(floor_id),
              color: '#d93636'
            },
            {
              seriesFieldLabel: 'Pabellón',
              valueField: 'avg',
              aggregate: 'avg',
              labelFormatter: (floor_id)=>this.floorLabelFormatter(floor_id),
              color: '#4db871'
            },
            {
              seriesFieldLabel: 'Pabellón',
              valueField: 'min',
              aggregate: 'min',
              labelFormatter: (floor_id)=>this.floorLabelFormatter(floor_id),
              color: '#d93636'
            }
          ];
          if(performanceTable && performanceTable.length>0){
            // performanceTable = performanceTable.filter(p=>p.harvest!="0");
            // this.performanceData = this.processPerformance(performanceTable);
            this.performanceData = this.processHistoricData(performanceTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "harvest",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "harvest_hyline",
              dataLabel: "huevos día ave",
              aggregate: 'avg'
            });
          }
          if(accPerformanceTable && accPerformanceTable.length>0){
            // accPerformanceTable = accPerformanceTable.filter(p=>p.harvest!="0");
            this.accPerformanceData = this.processHistoricData(accPerformanceTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "harvest",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "hyline_harvest",
              dataLabel: "huevos semana ave",
              aggregate: 'avg'
            });
            // this.accPerformanceData = this.processAccPerformance(accPerformanceTable);
          }
          if(nourishmentTable && nourishmentTable.length>0 )
            // this.nourishmentData = this.prepareDataForPlot(nourishmentTable, nourishmentOptions);
            this.nourishmentData = this.processHistoricData(nourishmentTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "nourishment",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "nourishment_hyline",
              dataLabel: "gr día ave"
            });
          if(waterTable && waterTable.length>0 )
            // this.waterData = this.prepareDataForPlot(waterTable, waterOptions);
            this.waterData = this.processHistoricData(waterTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "water",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "water_hyline",
              dataLabel: "ml día ave"
            });
          if(deadsTable && deadsTable.length>0 )
            // this.deadsData = this.prepareDataForPlot(deadsTable, deadsOptions);
            this.deadsData = this.processHistoricData(deadsTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "deads",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "hyline",
              dataLabel: "muertes",
              aggregate: 'sum'
            });
          if(accDeadsTable && accDeadsTable.length>0)
            this.accDeadsData = this.processHistoricData(accDeadsTable, {
              dateFieldKey: "write_date",
              valueFieldKey: "acc_deads",
              birdAmountFieldKey: "amount",
              hylineFieldKey: "hyline",
              dataLabel: "muertes acumuladas"
            });
            // this.accDeadsData = this.prepareDataForPlot(accDeadsTable, accDeadsOptions);
          if(temperatureTable && temperatureTable.length>0 )
            this.temperatureData = this.prepareDataForPlot(temperatureTable, temperatureOptions);
          this.cdr.detectChanges();
        })
    );
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(), 'dd/MM/yyyy', 'en') + ' | ' + formatDate(new Date(), 'hh:mm', 'en');
  }

  processHistoricData(historicData, options){
    // {name: 'id', alias: 'id'},
		// {name: 'write_date', alias: 'write_date'},
		// {name: 'harvest', alias: 'harvest'},
		// {name: 'pavilion_id', alias: 'pavilion_id'},
		// {name: 'floor_id', alias: 'floor_id'},
		// {name: 'bird_type_id', alias: 'bird_type_id'},
		// {name: 'harvest_hyline', alias: 'harvest_hyline'},
		// {foreignTable: 'bird', name: 'age_in_weeks', alias: 'age_in_weeks'},
		// {foreignTable: 'smov', name: 'amount', alias: 'amount'},
		// {name: 'generation_id', alias: 'generation_id'},
    var dateFieldKey = "write_date";
    if(options.dateFieldKey) dateFieldKey = options.dateFieldKey;
    var valueFieldKey = "none";
    if(options.valueFieldKey) valueFieldKey = options.valueFieldKey;
    var hylineFieldKey = "hyline";
    if(options.hylineFieldKey) hylineFieldKey = options.hylineFieldKey;
    var birdAmountFieldKey = "amount";
    if(options.birdAmountFieldKey) birdAmountFieldKey = options.birdAmountFieldKey;
    var dataLabel = "Huevos dia ave";
    if(options.dataLabel) dataLabel = options.dataLabel;
    var aggregate = 'weighing';
    if(options.aggregate) aggregate = options.aggregate;

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    };
    var datasetArray = [];
    var valueSerie = [];
    var hylineSerie = [];
    const uniqueDateList = historicData.map(row=>row[dateFieldKey]).filter(onlyUnique);
    // console.log(`unique date list: ${JSON.stringify(uniqueDateList)}`);
    var maxYColumn = -1;
    uniqueDateList.forEach(dateStr=>{
      const valuesInDate = historicData.filter(row=>row[dateFieldKey]==dateStr);
      const totalBirdAmount = valuesInDate.reduce((prev, curr, idx)=>prev+parseInt(curr[birdAmountFieldKey]?curr[birdAmountFieldKey]:1), 0);
      // console.log(`value in date: ${JSON.stringify(valuesInDate)}`);
      var value = 0;
      var hyline = 0;
      valuesInDate.forEach(row=>{
        var birdAmount = totalBirdAmount / valuesInDate.length;
        if(row[birdAmountFieldKey]!=null && row[birdAmountFieldKey] != undefined){
          birdAmount = parseInt(row[birdAmountFieldKey]);
        }
        if(aggregate == 'weighing'){
          value += parseFloat(row[valueFieldKey])*birdAmount/totalBirdAmount;
          hyline += parseFloat(row[hylineFieldKey])*birdAmount/totalBirdAmount;
        }else if(aggregate == 'sum'){
          value += parseFloat(row[valueFieldKey]);
          hyline += parseFloat(row[hylineFieldKey]);
        }else if(aggregate == 'avg'){
          value += parseFloat(row[valueFieldKey])/valuesInDate.length;
          hyline += parseFloat(row[hylineFieldKey])/valuesInDate.length;
        }
        
      });
      if(value > maxYColumn) maxYColumn = value;
      if(hyline > maxYColumn) maxYColumn = hyline;
      hylineSerie.push(Math.round(hyline*100)/100);
      valueSerie.push(Math.round(value*100)/100);
    });
    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#4db871').alpha(0.6).rgbString(),
      label: dataLabel,
      borderColor: Chart.helpers.color('#4db871').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: valueSerie
    });

    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#302de3').alpha(0.6).rgbString(),
      label: `Hyline`,
      borderColor: Chart.helpers.color('#302de3').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: hylineSerie
    });

    return {labels: uniqueDateList.map(date=>date.slice(0,10)), datasets: datasetArray, maxY:maxYColumn};
  }

  getComponentTitle() {
    var tittle = "\<strong>Data histórica\</strong> Pabellón " + this.isPavSel1
    return tittle
    /*let result = 'Crear pabellón';
    if (!this.pavilion || !this.pavilion.id) {
      return result;
    }

    result = `Editar pabellón - ${this.pavilion.name} ${this.pavilion.type}`;
    return result;*/
  }

  refreshWeek(semanadesde, semanahasta) {
    console.log("refresh week... semanadesde: "+semanadesde);
    this.diadesde = this.getMonday(semanadesde);
    this.diahasta = this.getSunday(semanahasta);
    this.semanadesde = this.diadesde;
    this.semanahasta = this.diahasta;
    this.refreshDay(this.diadesde, this.diahasta);
  }

  refreshCycle() {
    // console.log(JSON.stringify(this.selectedGeneration));
    this.refreshDay(this.diadesde, this.diahasta);
    document.getElementById("btnfechahora").innerHTML = formatDate(new Date(), 'dd/MM/yyyy', 'en') + ' | ' + formatDate(new Date(), 'hh:mm', 'en');
  }
  selectGeneration(generation:BirdGeneration){
		this.selectedGeneration = generation;
  }

  processPerformance(performanceData){
    // {name: 'id', alias: 'id'},
		// {name: 'write_date', alias: 'write_date'},
		// {name: 'harvest', alias: 'harvest'},
		// {name: 'pavilion_id', alias: 'pavilion_id'},
		// {name: 'floor_id', alias: 'floor_id'},
		// {name: 'bird_type_id', alias: 'bird_type_id'},
		// {name: 'harvest_hyline', alias: 'harvest_hyline'},
		// {foreignTable: 'bird', name: 'age_in_weeks', alias: 'age_in_weeks'},
		// {foreignTable: 'smov', name: 'amount', alias: 'amount'},
		// {name: 'generation_id', alias: 'generation_id'},
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    };
    var datasetArray = [];
    var harvestSerie = [];
    var hylineSerie = [];
    const uniqueDateList = performanceData.map(row=>row.write_date).filter(onlyUnique);
    console.log(`unique date list: ${JSON.stringify(uniqueDateList)}`);
    var maxYColumn = -1;
    uniqueDateList.forEach(dateStr=>{
      const harvestInDate = performanceData.filter(row=>row.write_date==dateStr);
      const totalBirdAmount = harvestInDate.reduce((prev, curr, idx)=>prev+parseInt(curr.amount), 0);
      console.log(`harvest in date: ${JSON.stringify(harvestInDate)}`);
      var harvest = 0;
      var hyline = 0;
      harvestInDate.forEach(row=>{
        harvest += parseFloat(row.harvest)*parseInt(row.amount)/totalBirdAmount;
        hyline += parseFloat(row.harvest_hyline)*parseInt(row.amount)/totalBirdAmount;
      });
      if(harvest > maxYColumn) maxYColumn = harvest;
      if(hyline > maxYColumn) maxYColumn = hyline;
      hylineSerie.push(Math.round(hyline*100)/100);
      harvestSerie.push(Math.round(harvest*100)/100);
    });
    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#4db871').alpha(0.6).rgbString(),
      label: `Huevos día ave`,
      borderColor: Chart.helpers.color('#4db871').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: harvestSerie
    });

    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#302de3').alpha(0.6).rgbString(),
      label: `Hyline día`,
      borderColor: Chart.helpers.color('#302de3').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: hylineSerie
    });

    return {labels: uniqueDateList.map(date=>date.slice(0,10)), datasets: datasetArray, maxY:maxYColumn};
  }

  processAccPerformance(accPerformanceData){
    // {name: 'id', alias: 'id'},
		// {name: 'write_date', alias: 'write_date'},
		// {name: 'harvest', alias: 'harvest'},
		// {name: 'pavilion_id', alias: 'pavilion_id'},
		// {name: 'floor_id', alias: 'floor_id'},
		// {name: 'bird_type_id', alias: 'bird_type_id'},
		// {name: 'harvest_hyline', alias: 'harvest_hyline'},
		// {name: 'age_in_weeks', alias: 'age_in_weeks'},
		// {name: 'generation_id', alias: 'generation_id'},
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    };
    var datasetArray = [];
    var harvestSerie = [];
    var hylineSerie = [];
    const uniqueDateList = accPerformanceData.map(row=>row.write_date).filter(onlyUnique);
    // console.log(`unique date list: ${JSON.stringify(uniqueDateList)}`);
    var maxYColumn = -1;
    uniqueDateList.forEach(dateStr=>{
      const harvestInDate = accPerformanceData.filter(row=>row.write_date==dateStr);
      const totalBirdAmount = harvestInDate.reduce((prev, curr, idx)=>prev+parseInt(curr.amount), 0);
      console.log(`harvest in date: ${JSON.stringify(harvestInDate)}`);
      var harvest = 0;
      var hyline = 0;
      harvestInDate.forEach(row=>{
        var amount = totalBirdAmount / harvestInDate.length;
        if(row.amount){
          amount = parseInt(row.amount);
        }
        harvest += parseFloat(row.harvest)*amount/totalBirdAmount;
        hyline += parseFloat(row.hyline_harvest)*amount/totalBirdAmount;
      });
      console.log(`harvest: ${harvest}`);
      console.log(`hyline harvest: ${hyline}`);
      if(harvest > maxYColumn) maxYColumn = harvest;
      if(hyline > maxYColumn) maxYColumn = hyline;
      hylineSerie.push(Math.round(hyline*100)/100);
      harvestSerie.push(Math.round(harvest*100)/100);
    });
    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#4db871').alpha(0.6).rgbString(),
      label: `Huevos semana ave`,
      borderColor: Chart.helpers.color('#4db871').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: harvestSerie
    });

    datasetArray.push({
      fill: false,
      pointHoverRadius: 2,
      pointHoverBorderWidth: 4,
      pointBorderColor: Chart.helpers.color('#302de3').alpha(0.6).rgbString(),
      label: `Hyline semana`,
      borderColor: Chart.helpers.color('#302de3').alpha(1).rgbString(),
      borderWidth: 2,
      lineTension: 0.4,
      data: hylineSerie
    });

    return {labels: uniqueDateList.map(date=>date.slice(0,10)), datasets: datasetArray, maxY:maxYColumn};
  }

  prepareDataForPlot(table, options){
    var dateList = new Array();
    var maxYcolumn = 0;
    var datasetArray = new Array();
    // console.log("temperature res: "+JSON.stringify(res));
    dateList = table.map(row => formatDate(row.write_date.slice(0,10), 'dd-MMM', 'en-US'));

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    };

    var uniqueDate = dateList.filter(onlyUnique);
    var optionsArray = [];
    if (Array.isArray(options))
      optionsArray = options;
    else
      optionsArray = [options];
    
    optionsArray.forEach(options=>{
      var field = options.seriesField;
      var fieldLabel = options.seriesFieldLabel;
      var valueField = options.valueField;
      const labelFormatter = options.labelFormatter?options.labelFormatter:field_id=>`${fieldLabel} ${field_id}`;
      const aggregate = options.aggregate?options.aggregate:'none';
  
      var fieldList = [];
      if(field)
        fieldList = table.map(row => row[field]).filter(onlyUnique);
  
      var series = [];
      var nvalues = [];
      for(let i = 0; i<fieldList.length || (fieldList.length==0 && i<1); i++){
        series.push(new Array(uniqueDate.length).fill(0.0));
        nvalues.push( new Array(uniqueDate.length).fill(0));
      }
  
      table.forEach(row=>{
        
        const date = formatDate(row.write_date.slice(0,10), 'dd-MMM', 'en-US');			 	
        const dateIdx = uniqueDate.findIndex(d=>d==date);
        var fieldIdx = 0;
        if(field){
          const field_id = row[field];
          fieldIdx = fieldList.findIndex(d=>d==field_id);
        }
        
        const value = parseFloat(row[valueField]);

        if(aggregate=='none')
          series[fieldIdx][dateIdx] = value;
        else if(aggregate=='sum' || aggregate=='avg'){
          series[fieldIdx][dateIdx] += value;
        }else if(aggregate=='min'){
          if(nvalues[fieldIdx][dateIdx]==0) series[fieldIdx][dateIdx] = value;
          series[fieldIdx][dateIdx] = series[fieldIdx][dateIdx]>value?value:series[fieldIdx][dateIdx];
        }else if(aggregate=='max'){
          if(nvalues[fieldIdx][dateIdx]==0) series[fieldIdx][dateIdx] = value;
          series[fieldIdx][dateIdx] = series[fieldIdx][dateIdx]<value?value:series[fieldIdx][dateIdx];
        }
        nvalues[fieldIdx][dateIdx] += 1;
  
        if(series[fieldIdx][dateIdx] > maxYcolumn && aggregate!='avg'){
          maxYcolumn = series[fieldIdx][dateIdx];
        }
      });
      nvalues.forEach((row, idx)=>{
        row.forEach((value, idx2) => {
          if(aggregate=='avg')
            series[idx][idx2] /= value;
          if(series[idx][idx2] > maxYcolumn){
            maxYcolumn = series[idx][idx2];
          }
        });
      });
      // console.log("series: "+JSON.stringify(series));
      var datasetLabels = [];
      if(field)
        datasetLabels = fieldList.map(labelFormatter);
      else 
        datasetLabels = series[0].map((val)=>'');
      // console.log("field list: "+JSON.stringify(fieldList));
      // console.log("series: "+JSON.stringify(series));
      series.forEach((serie, idx)=> {
        const color = options.color? options.color: '#4db871';
        datasetArray.push({
          fill: false,
          pointHoverRadius: 2,
          pointHoverBorderWidth: 4,
          pointBorderColor: Chart.helpers.color(color).alpha(0.7).rgbString(),
          label: `${datasetLabels[idx]}`,
          borderColor: Chart.helpers.color(color).alpha(1).rgbString(),
          borderWidth: 2,
          data: serie
        });
      });
    });
    
    // console.log("datsets: "+JSON.stringify(datasetArray));
    // console.log("unique dates: "+JSON.stringify(uniqueDate));
    return {labels: uniqueDate, datasets: datasetArray, maxY:maxYcolumn};
  }
  
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getSunday(d){
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() + (6 - day) + (day==0?-6:1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
}