import { BaseModel } from '../../_base/crud';

export class Deads extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    company_id: number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type_id: number;
    pavilion_type_name: string;
    floor_id: number;
    floor_name: string;
    c_dead_bird: number;
    c_dead_bird_b: number;
    c_dead_bird_w: number;
    clear(){
        this.id                     = undefined;
        this.create_uid             = undefined;
        this.write_date             = undefined;
        this.write_uid              = undefined;
        this.create_date            = undefined;
        this.c_dead_bird   = 0;
        this.company_id             = undefined;
        this.pavilion_id            = undefined;
        this.pavilion_name          = "";
        this.pavilion_type_id       = undefined;
        this.pavilion_type_name     = "";
        this.c_dead_bird_b            = 0
        this.c_dead_bird_w            = 0
        this.floor_id            = undefined;
        this.floor_name          = "";
    }
}