// RxJS
import { delay, distinctUntilChanged } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
import { selectBirdStockMovsInitWaitingMessage, selectBirdStockMovsInStore, selectBirdStockMovsPageLoading, selectBreedingStocksInitWaitingMessage, selectBreedingStocksInStore, selectBreedingStocksPageLoading } from '../_selectors/bird-stock.selectors';
// Selectors

export class BreedingStocksDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectBreedingStocksPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectBreedingStocksInitWaitingMessage)
		);

		this.store.pipe(
			select(selectBreedingStocksInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}

export class BirdStockMovsDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();
		this.loading$ = this.store.pipe(
			select(selectBirdStockMovsPageLoading)
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectBirdStockMovsInitWaitingMessage)
		);

		this.store.pipe(
			select(selectBirdStockMovsInStore)
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}