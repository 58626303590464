import { Component, OnInit,NgZone,ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { 
  BirdGeneration, 
  BSGetPavGens, 
  Pavilion, 
  PavilionGetAll, 
  PavilionGetSPDashPage, 
  PavilionsDataSource, 
  selectBirdGens, 
  selectSpecificDashboardsPageLastQuery, 
  SpecificDashboard, 
  SpecificDashboardsDataSource } from '../../../../../core/plant-configuration';

import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { BehaviorSubject, merge, Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, MatSort } from '@angular/material';
import { QueryParamsModel } from '../../../../../core/_base/crud';

interface Ciclo {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'kt-historyc-dashboard',
  templateUrl: './historyc-dashboard.component.html',
  styleUrls: ['./historyc-dashboard.component.scss']
})
export class HistorycDashboardComponent implements OnInit, OnDestroy {
  isPavSel:string = '1';
  LastWeek = new Date();
  today = new Date();
  todaylabel = new Date();
  diadesde:string = '';
  diahasta:string = '';
  semanadesde:string = '';
  semanahasta:string = '';
  title:string;
  pavilionsDataSource: PavilionsDataSource;
  pavilionsResult: Pavilion[];
  generationsResult: BirdGeneration[];
  filteredGenerations: BirdGeneration[];
  generationFilterInput: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private selectedPavilion: Pavilion = null;
	private inputGeneration: BirdGeneration = null;
	private selectedGeneration: BirdGeneration = null;
  private subscriptions: Subscription[] = [];
  dataSource: SpecificDashboardsDataSource;
  displayedColumns = ['Categoria', 'Edad', 'Uniformidad', 'Peso real', 
    'Peso MIN', 'Peso MAX', 'Alimentación real (g)', 'Alimentación tb (g)',
    'Dif Alimentación', 'Agua', 'Agua tb', 'Dif Agua', 'Mortalidad',
    'Mortalidad tb', 'Dif Mortalidad', 'T MIN °C', 'T MAX °C'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  genSearchFieldText:string;
  lastQuery: QueryParamsModel;
  specificDashboardsResult: SpecificDashboard[] = [];
  //parentProperty = "I come from parent";
  //todaysDataTime = '';

  // ciclos: Ciclo[] = [
  //   {value: '1', viewValue: 'ciclo 1'},
  //   {value: '2', viewValue: 'ciclo 2'},
  //   {value: '3', viewValue: 'ciclo 3'}
  // ];


  constructor(private ngZone: NgZone,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal
    ) {
    //const history = new HistorycData();
    //history.clear();
    // this.historyc = new HistorycData();
    // this.historyc.clear();
   }

  ngOnInit() {
    // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    // this.remoteHistory.inicializarData(1);
    // this.remoteHistory3.inicializarData(1);
    // this.remoteHistory4.inicializarData(1);
    // this.remoteHistory2.inicializarData(1);
    // this.remoteHistory1.inicializarData(1);
    // this.remoteHistory5.inicializarData(1);
    // this.remoteHistory6.inicializarData(1);

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadSpecificDashboards())
    )
    .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Init DataSource
    this.dataSource = new SpecificDashboardsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      distinctUntilChanged()
    ).subscribe(res => {

      // this.specificDashboardsResult = res;
      this.specificDashboardsResult = res.sort((a, b) => (a.age_in_weeks > b.age_in_weeks) ? 1 : -1)
      console.log(`result specific dashboard: ${JSON.stringify(this.specificDashboardsResult)}`);
    });
    this.subscriptions.push(entitiesSubscription);
    const lastQuerySubscription = this.store.pipe(select(selectSpecificDashboardsPageLastQuery)).subscribe(res => this.lastQuery = res);
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);

    this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const pavilions$ = this.pavilionsDataSource.entitySubject.pipe(
			distinctUntilChanged()
		);
		const birdGenerations$ = this.store.pipe(
			select(selectBirdGens)
		);
		const generationsSub = birdGenerations$.subscribe((gens)=>{
			console.log("generation sub: "+JSON.stringify(gens));
      this.generationsResult = gens;
      this.genSearchFieldText = "";
      this.generationFilterInput.next("");
			try{
				// this.birdStockMovForm.get("generation_src").patchValue({name: gens[0].name});
        this.selectGeneration(gens[0]);
        this.loadSpecificDashboards();
			}catch(error){

      }
      console.log(`result specific dashboard: ${JSON.stringify(this.specificDashboardsResult)}`);
      this.cdr.detectChanges();
    });

    const genInputSub = this.generationFilterInput.pipe(
      debounceTime(250)
    ).subscribe(
      (text)=>this.filteredGenerations = this.generationsResult.filter((gen)=>gen.name.toString().toLowerCase().includes(text.toLowerCase()))
    );
    
    const sub = pavilions$.subscribe((pavilions) => {
        if(pavilions && pavilions.length>0 ){
          this.pavilionsResult = pavilions.filter(pav=>pav.type.toLowerCase()=='crianza');
          console.log("pavilions: ");
          console.log(JSON.stringify(pavilions));
          if(this.pavilionsResult.length>0){
            this.selectPavilion(this.pavilionsResult[0]);
          }
        }
      }
    );
    this.subscriptions.push(sub);
    this.subscriptions.push(generationsSub);
    this.subscriptions.push(genInputSub);
    this.loadPavilions();
    //this.loadSpecificDashboards();
  }

  /**
 * Load Specific Dashboard List
 */
loadSpecificDashboards() {
  if(!(this.selectedGeneration && this.selectedGeneration.id)){
    return;
  }
  const queryParams = new QueryParamsModel(
    {generation_id: this.selectedGeneration.id},
    this.sort.direction,
    this.sort.active,
    this.paginator.pageIndex,
    this.paginator.pageSize
  );
  
  // Call request from server
  console.log("loardSpecificDashboards of pavilion "+JSON.stringify(this.selectedPavilion));
  if(this.selectedPavilion && this.selectedPavilion.id)
    this.store.dispatch(new PavilionGetSPDashPage({ pavilion:this.selectedPavilion, page: queryParams }));
}

  updatePavilion(id){
      // this.historyc.pavilion_id = id
      this.isPavSel = id;
      this.specificDashboardsResult = [];
      this.dataSource.hasItems = false;
      this.dataSource.entitySubject.next([]);
      this.cdr.detectChanges();
      this.selectPavilion(this.findPavilion("Crianza "+id));
      
      // switch (Number(id)) {
      //     case 1:
      //       this.isPavSel = '1';    
      //       break;
      //       case 2:
      //         this.isPavSel = '1C';    
      //         break;
      //         case 3:
      //           this.isPavSel = '2';    
      //           break;
      //           case 3:
      //             this.isPavSel = '2C';    
      //             break;
      //             case 5:
      //               this.isPavSel = '3';    
      //               break;
      //               case 6:
      //                 this.isPavSel = '3C';    
      //                 break;
      //                 case 7:
      //                   this.isPavSel = '4';    
      //                   break;
                        
      //   default:
      //     this.isPavSel = '1';
      //     break;
      // }
      
      // this.historyc.starting_date = formatDate(new Date().setDate(new Date().getDate()-19), 'yyyy-MM-dd', 'en');
      // this.historyc.ending_date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
      // this.remoteHistory.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory3.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory4.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory2.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory1.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory5.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
      // this.remoteHistory6.changeFromPavilion(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date); 
      // this.historyc.starting_week = '';
      // this.historyc.ending_week = '';
      // this.historyc.starting_date = '';
      // this.historyc.ending_date = '';
      // this.diahasta = '';this.diadesde = '';this.semanadesde = '';this.semanahasta = ''; 
      // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
      //document.getElementById("TitlePavHist").title = "'\<strong>Data histórica\</strong> Pabellón P4'";
    }
  refreshDay(diadesde,diahasta){
    //console.log(diadesde,diahasta);
    // this.historyc.starting_week='';
    // this.historyc.ending_week='';
    // this.historyc.starting_date = formatDate(diadesde, 'yyyy-MM-dd', 'en-US');
    // this.historyc.ending_date = formatDate(diahasta, 'yyyy-MM-dd', 'en-US');
    // this.remoteHistory.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory3.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory4.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory2.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory1.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory5.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.remoteHistory6.changeFromDay(this.historyc.pavilion_id,this.historyc.starting_date,this.historyc.ending_date);
    // this.semanadesde = '';this.semanahasta = '';
    // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
  }
  /**
	 * On destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

  loadPavilions(){
    console.log("dispatching pavilion get all")
		this.store.dispatch(new PavilionGetAll({}));
	}

  loadPavilionGenerations(pavilion: Pavilion){
		this.store.dispatch(new BSGetPavGens({pavilion: pavilion}));
  }

  findPavilion(name:String):Pavilion{
		return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	}

	findGeneration(name:String):BirdGeneration{
		return this.generationsResult.filter(generation=>generation.name.toLowerCase() == name.toLowerCase())[0];
  }
  
  openSm(content) {
    this.modalService.open(content, { size: 'sm', centered: true, scrollable: true });
  }

  confirmGeneration(){
    this.selectedGeneration = this.inputGeneration;
  }
  
  getComponentTitle() {
    const generation = this.selectedGeneration?this.selectedGeneration.name:"";
    var tittle = "\<strong>Data histórica\</strong> Crianza "+this.isPavSel+" "+generation;
    return tittle
		/*let result = 'Crear pabellón';
		if (!this.pavilion || !this.pavilion.id) {
			return result;
		}

		result = `Editar pabellón - ${this.pavilion.name} ${this.pavilion.type}`;
		return result;*/
	}

  refreshWeek(semanadesde,semanahasta){
    // this.historyc.starting_date = '';
    // this.historyc.ending_date = '';
    // this.historyc.starting_week = formatDate(semanadesde, 'yyyy-MM-dd', 'en-US');
    // this.historyc.ending_week = formatDate(semanahasta, 'yyyy-MM-dd', 'en-US');
    // this.remoteHistory.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory3.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory4.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory2.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory1.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory5.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.remoteHistory6.changeFromWeek(this.historyc.pavilion_id,this.historyc.starting_week,this.historyc.ending_week);
    // this.diahasta = '';this.diadesde = ''
    // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
  }

  refreshCycle(){
    // this.historyc.starting_week = '';
    // this.historyc.ending_week = '';
    // this.historyc.starting_date = '';
    // this.historyc.ending_date = '';
    // //console.log(ciclo);
    // this.remoteHistory.changeFromCycle(1,'','');
    // this.remoteHistory3.changeFromCycle(1,'','');
    // this.remoteHistory4.changeFromCycle(1,'','');
    // this.remoteHistory2.changeFromCycle(1,'','');
    // this.remoteHistory1.changeFromCycle(1,'','');
    // this.remoteHistory5.changeFromCycle(1,'','');
    // this.remoteHistory6.changeFromCycle(1,'','');
    // this.diahasta = '';this.diadesde = '';this.semanadesde = '';this.semanahasta = ''; 
    // // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
  }

  selectPavilion(pavilion:Pavilion){
    this.generationsResult = [];
    this.selectedGeneration = null;
		
    this.selectedPavilion = pavilion;
    console.log("selected pavilion: "+JSON.stringify(pavilion));
    if(pavilion && pavilion.id){

      this.loadPavilionGenerations(pavilion);
    }
	}

	selectGeneration(generation:BirdGeneration){
		this.selectedGeneration = generation;
  }
  
  setInputGeneration(generation:BirdGeneration){
		this.inputGeneration = generation;
	}
}
