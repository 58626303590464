// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, concat, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, debounceTime, tap, filter } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import {switchMap, take} from 'rxjs/operators'
import { Dictionary, Update } from '@ngrx/entity';
import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// Services and Models
import { 
	BirdType,
	birdTypesLoaded,
	BreedingStock,
	BSGetBirdTypes,
	BSLoadBreeding,
	BSUpdateBreeding,
	Pavilion,
	PavilionGetAll,
	PavilionsDataSource,
	selectBirdTypes,
	selectBreedingStockById,
	selectLastCreatedBreedingStockId,
	selectLastUpdatedBreedingStockId,
} from '../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { selectBreedingStocksError } from '../../../../../core/plant-configuration/_selectors/bird-stock.selectors';

@Component({
  selector: 'kt-loadbreading-edit',
  templateUrl: './loadbreading-edit.component.html',
  styleUrls: ['./loadbreading-edit.component.scss']
})
export class LoadbreadingEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('wizard', {static: true}) el: ElementRef;
	// Public properties
	breedingStock: BreedingStock;
	breedingStockId$: Observable<number>;
	oldBreedingStock: BreedingStock;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	breedingStockForm: FormGroup;
	hasFormErrors = false;
	filteredPavilions: Observable<Pavilion[]>
	filteredBirdTypes: Observable<BirdType[]>
	
  	pavilionsDataSource: PavilionsDataSource;
	pavilionsResult: Pavilion[];
	birdTypesResult: BirdType[];
	private subscriptions: Subscription[] = [];
	private user:User = new User();

	_pavilionInstance: NgbTypeahead;
	@ViewChild('pavilionTA', {static: false}) set pavilionInstance(content: NgbTypeahead){
		if(content){
			this._pavilionInstance = content;
		}
	};
	pavilionFocus$ = new Subject<string>();
	pavilionClick$ = new Subject<string>();
	error = null;

	searchPavilion = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.pavilionClick$.pipe(filter(() => !this._pavilionInstance.isPopupOpen()));
		const inputFocus$ = this.pavilionFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching pavilion with "+text);
				this.selectPavilion(this.findPavilion(text))}),
			map(text => this.filterPavilion(text)),
			tap(pavilions=>console.log("pavilions found: "+JSON.stringify(pavilions)))
		);
	}
	pavilionFormatter = (pavilion: Pavilion) => pavilion.name;

	_birdTypeInstance: NgbTypeahead;
	@ViewChild('birdTypeTA', {static: false}) set birdTypeInstance(content: NgbTypeahead){
		if(content){
			this._birdTypeInstance = content;
		}
	};
	birdTypeFocus$ = new Subject<string>();
	birdTypeClick$ = new Subject<string>();

	searchBirdType = (text$: Observable<string>)=>{
		const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
		const clicksWithClosedPopup$ = this.birdTypeClick$.pipe(filter(() => !this._birdTypeInstance.isPopupOpen()));
		const inputFocus$ = this.birdTypeFocus$;
		return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
			tap(text=>{
				console.log("searching birdType with "+text);
				this.selectBirdType(this.findBirdType(text))}),
			map(text => this.filterBirdType(text)),
			tap(birdTypes=>console.log("birdTypes found: "+JSON.stringify(birdTypes)))

		);
	}
	birdTypeFormatter = (birdType: BirdType) => birdType.name;

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;
	private selectedPavilion: Pavilion = null;
	private selectedBirdType: BirdType = null;
	public editing:boolean = false;
	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef) {
	}

	ngAfterViewInit(): void {
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("loadbreeding edit component: usuario no logueado");
		});
		    
    	// Init DataSource
		this.pavilionsDataSource = new PavilionsDataSource(this.store);
		const pavilions$ = this.pavilionsDataSource.entitySubject.pipe(
			distinctUntilChanged()
		);
		const birdTypes$ = this.store.pipe(
			select(selectBirdTypes)
		);
		const birdTypesLoaded$ = this.store.pipe(
			select(birdTypesLoaded)
		);
		const routeSub = this.activatedRoute.params.subscribe(params => {
			let urlSplited = this.router.url.split("/");
			let relUrl = urlSplited[urlSplited.length-2];
			if (relUrl == "edit"){
				this.editing = true;
			}
			const id = params.id;
			if (id && id > 0) {

				const breedingStock$ = this.store.pipe(
					select(selectBreedingStockById(id))
				);
				
				const sub = combineLatest([pavilions$, birdTypesLoaded$, birdTypes$, breedingStock$])
					.subscribe(([pavilions, birdTypesLoaded, birdTypes, breedingStock]) => {
						console.log("breeding stock retrieved from store");
						if(pavilions && birdTypesLoaded && birdTypes && breedingStock){				
							this.pavilionsResult = pavilions.filter((pav)=>pav.type=="CRIANZA");
							this.birdTypesResult = birdTypes;
							this.loadBreedingStock(breedingStock);
						}
					}
				);
				this.subscriptions.push(sub);
			} else {				
				const sub = combineLatest([pavilions$, birdTypesLoaded$, birdTypes$])
					.subscribe(([pavilions, birdTypesLoaded, birdTypes]) => {
						if(pavilions && birdTypesLoaded && birdTypes && pavilions.length>0 && birdTypes.length>0){				
							this.pavilionsResult = pavilions.filter((pav)=>pav.type=="CRIANZA");
							this.birdTypesResult = birdTypes;
							console.log("default breeding stock");
							const newBreedingStock = new BreedingStock();
							newBreedingStock.clear();
							this.loadBreedingStock(newBreedingStock);
						}
					}
				);
				this.subscriptions.push(sub);				
			}
		});

		this.subscriptions.push(authSubscription);
		this.subscriptions.push(routeSub);

		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});

		this.loadPavilions();
		this.loadBirdTypes();
	}

	loadPavilions(){
		this.store.dispatch(new PavilionGetAll({}));
	}

	loadBirdTypes(){
		this.store.dispatch(new BSGetBirdTypes({}));
	}

	loadBreedingStock(breedingStock, fromService: boolean = false) {
		if (!breedingStock) {
			this.goBack('');
		}
		this.breedingStock = {...breedingStock};
		
		this.selectedPavilion = new Pavilion();
		this.selectedPavilion.clear();
		this.selectedPavilion.name = breedingStock.pavilion_name;
		this.selectedPavilion.id = breedingStock.pavilion_id;
		this.selectedPavilion.type_id = breedingStock.pavilion_type_id;
		this.selectedPavilion.type = breedingStock.pavilion_type;

		this.selectedBirdType = new BirdType();
		this.selectedBirdType.name = breedingStock.bird_type;
		this.selectedBirdType.id = breedingStock.bird_type_id;

		this.breedingStockId$ = of(breedingStock.id);
		this.oldBreedingStock = Object.assign({}, breedingStock);
		this.initBreedingStock();
		// if (fromService) {
		this.cdr.detectChanges();
		// }
  }

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init breedingStock
	 */
	initBreedingStock() {
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.breedingStock.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: 'Listado de cargas de crianza', page: `/plant-configuration/loadbreading` },
				{ title: 'Nueva carga de crianza', page: `/plant-configuration/loadbreading/add` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar carga de crianza');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Listado de cargas de crianza', page: `/plant-configuration/loadbreading` },
			{ title: 'Editar carga de crianza', page: `/plant-configuration/loadbreading/edit`, queryParams: { id: this.breedingStock.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		console.log("creating breeding form");
		const age = this.breedingStock.age_in_weeks?this.breedingStock.age_in_weeks:1;
		const generation = this.breedingStock.generation_name;
		const amount = this.breedingStock.bird_amount?this.breedingStock.bird_amount:1;
		// const c_amount = this.breedingStock.c_bird_amount?this.breedingStock.c_bird_amount:1;
		this.breedingStockForm = this.formBuilder.group({
			pavilion: [{name:this.selectedPavilion.name}, Validators.required],
			birdType: [{name: this.selectedBirdType.name, disabled:this.editing},
				Validators.required],
			age: [{value: age,disabled:this.editing},
				[Validators.required, Validators.min(1), Validators.max(90)]],
			generation: [generation, Validators.required],
			amount: [{value: amount, disabled: this.editing}, 
				[Validators.required, Validators.min(1)]],
			// c_amount: [{value: c_amount, disabled:this.editing},
			// 	[Validators.required, Validators.min(1)]],
		});
		this.breedingStock.age_in_weeks = age;
		this.breedingStock.bird_amount = amount;
		this.breedingStock.generation_name = generation;
		const controls = this.breedingStockForm.controls;
		const ageSub = controls.age.valueChanges.pipe(
			startWith(age)
		).subscribe(x=>{
			this.breedingStock.age_in_weeks = x;
		});
		const generationSub = controls.generation.valueChanges.pipe(
			startWith(generation)
		).subscribe(x=>{
			this.breedingStock.generation_name = x;
		});
		const amountSub = controls.amount.valueChanges.pipe(
			startWith(amount)
		).subscribe(x=>{
			this.breedingStock.bird_amount = x;
		});
		this.subscriptions.push(ageSub);
		this.subscriptions.push(generationSub);
		this.subscriptions.push(amountSub);
		// console.log(this.breedingStockForm);
		// this.filteredPavilions = concat(
		// 	of(this.filterPavilion(this.selectedPavilion.name)),
		// 	this.breedingStockForm.controls.pavilion.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterPavilion(val.toString()))
		// 	)
		// );
		// this.filteredBirdTypes = concat(
		// 	of(this.filterBirdType(this.selectedBirdType.name)),
		// 	this.breedingStockForm.controls.birdType.valueChanges.pipe(
		// 		startWith(''),
		// 		map(val => this.filterBirdType(val.toString()))
		// 	)
		// );	
		console.log("breeding form created");
		this.cdr.detectChanges();
	}

	/**
	 * Filter pavilion
	 *
	 * @param val: string
	 */
	filterPavilion(val: string): Pavilion[] {
		this.selectedPavilion = null;
		if (this.pavilionsResult){
			let pavilionList = this.pavilionsResult.filter(pavilion =>
				pavilion.name.toString().toLowerCase().includes(val.toLowerCase()));
			if(pavilionList.length>=1){
				let pavilionCoincidences = pavilionList.filter(pavilion=>pavilion.name.toLowerCase() == val.toLowerCase());
				if(pavilionCoincidences.length>0){
					this.selectedPavilion = pavilionCoincidences[0];
				}
			}
			return pavilionList;
		}
    	return [];
	}

	/**
	 * Filter bird type
	 *
	 * @param val: string
	 */
	filterBirdType(val: string): BirdType[] {
		this.selectedBirdType = null;
		if (this.birdTypesResult){
			let birdTypeList = this.birdTypesResult.filter(birdType =>
				birdType.name.toString().toLowerCase().includes(val.toLowerCase()));
			if(birdTypeList.length>=1){
				let birdTypeCoincidences = birdTypeList.filter(birdType=>birdType.name.toLowerCase() == val.toLowerCase());
				if(birdTypeCoincidences.length>0){
					this.selectedBirdType = birdTypeCoincidences[0];
				}
			}
			return birdTypeList;
		}
    	return [];
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `/plant-configuration/loadbreading?id=${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl('/plant-configuration/loadbreading', { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh breeding stock
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshBreedingStock(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `/plant-configuration/loadbreading/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.breedingStock = Object.assign({}, this.oldBreedingStock);
		this.createForm();
		this.hasFormErrors = false;
		this.breedingStockForm.markAsPristine();
		this.breedingStockForm.markAsUntouched();
		this.breedingStockForm.updateValueAndValidity();
		this.selectedBirdType = null;
		this.selectedPavilion = null;
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.breedingStockForm.controls;
		/** check form */
		if (this.breedingStockForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let editedBreedingStock = this.prepareBreedingStock();

		if (editedBreedingStock.id > 0) {
			this.updateBreedingStock(editedBreedingStock, withBack);
			return;
		}

		this.addBreedingStock(editedBreedingStock, true);
	}

	/**
	 * Returns object for saving
	 */
	prepareBreedingStock(): BreedingStock {
		const controls = this.breedingStockForm.controls;
		const breedingStock = Object.assign(new BreedingStock(), this.breedingStock);
		breedingStock.id = this.breedingStock.id;
		breedingStock.pavilion_id = this.selectedPavilion.id;
		breedingStock.bird_type_id = this.selectedBirdType.id;
		breedingStock.generation_name = controls.generation.value;
		breedingStock.generation_id = this.breedingStock.generation_id;
		breedingStock.breeding_id = this.breedingStock.breeding_id;
		breedingStock.bird_amount = controls.amount.value;
		breedingStock.pavilion_name = this.selectedPavilion.name;
		breedingStock.pavilion_type = this.selectedPavilion.type;
		// breedingStock.c_bird_amount = controls.c_amount.value;
		breedingStock.age_in_weeks = controls.age.value;
		breedingStock._createdDate = this.breedingStock._createdDate;
		breedingStock._updatedDate = this.breedingStock._updatedDate;
		breedingStock._updatedDate = this.typesUtilsService.getDateStringFromDate();
		breedingStock._createdDate = this.breedingStock.id > 0 ? breedingStock._createdDate : breedingStock._updatedDate;
		breedingStock.write_uid = this.user.id;
		breedingStock.company_id = this.user.company_id;
		console.log(JSON.stringify(breedingStock))
		return breedingStock;
	}

	findPavilion(name:String):Pavilion{
		console.log(JSON.stringify(this.pavilionsResult));
		return this.pavilionsResult.filter(pavilion=>pavilion.name.toLowerCase() == name.toLowerCase())[0];
	}

	findBirdType(name:String):BirdType{
		console.log(JSON.stringify(this.birdTypesResult));
		return this.birdTypesResult.filter(birdType=>birdType.name.toLowerCase() == name.toLowerCase())[0];
	}

	/**
	 * Add BreedingStock
	 *
	 * @param breedingStock: BreedingStock
	 * @param withBack: boolean
	 */
	addBreedingStock(breedingStock: BreedingStock, withBack: boolean = false) {
		this.loadingSubject.next(true);
		
		this.store.dispatch(new BSLoadBreeding({breedingStock: breedingStock}));
		const addBreedingSub = this.store.pipe(
			delay(100),
			select(selectLastCreatedBreedingStockId)
		).subscribe((newId: any) => {
			console.log(newId);
			
			if (!newId || newId==-1) {
				// addBreedingSub.unsubscribe();
				return;
			}

			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Nueva carga de crianza ha sido añadida.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshBreedingStock(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});

		
		this.subscriptions.push(
			this.store.pipe(
				select(selectBreedingStocksError),
				filter(error=>error!=null),
				take(1)
			).subscribe(error=>{
				console.log("error: ");console.dir(error);
				if(error){
					this.loadingSubject.next(false);
					const message = error.error;
					this.error = message;
					addBreedingSub.unsubscribe();
					this.cdr.detectChanges();
				}
			})
		);
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update breedingStock
	 *
	 * @param breedingStock: BreedingStock
	 * @param withBack: boolean
	 */
	updateBreedingStock(breedingStock: BreedingStock, withBack: boolean = false) {
		this.loadingSubject.next(true);

		const updateBreedingStock: Update<BreedingStock> = {
			id: breedingStock.id,
			changes: breedingStock
		};

		this.store.dispatch(new BSUpdateBreeding({
			partialBreedingStock: updateBreedingStock,
			breedingStock:breedingStock
		}));

		const updateBreedingSub = this.store.pipe(
			delay(1000),
			select(selectLastUpdatedBreedingStockId)
		).subscribe((id) => { // Remove this line
			if (!id || id==-1) {
				// updateBreedingSub.unsubscribe();
				return;
			}
			if (withBack) {
				this.goBack(breedingStock.id);
			} else {
				const message = `Carga de crianza ha sido actualizada.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
				this.refreshBreedingStock(false);
			}
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = 'Nueva carga de crianza';
		if (!this.breedingStock || !this.breedingStock.id) {
			return result;
		}

		result = `Editar carga de crianza - ${this.breedingStock.bird_amount} ${this.breedingStock.bird_type} en ${this.breedingStock.pavilion_name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}

	selectPavilion(pavilion:Pavilion){
		this.selectedPavilion = pavilion;
	}

	selectBirdType(birdType:BirdType){
		this.selectedBirdType = birdType;
	}
}