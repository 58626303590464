
// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AlarmActions, AlarmActionTypes } from '../_actions/alarms.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Alarm, AlarmStatus, AlarmType } from '../_models/alarm.model';

export interface AlarmsState extends EntityState<Alarm> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastQuery: QueryParamsModel;
    lastCreatedAlarmId: number;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Alarm> = createEntityAdapter<Alarm>();

export const initialAlarmsState: AlarmsState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedAlarmId: undefined,
    showInitWaitingMessage: true
});

export function AlarmsReducer(state = initialAlarmsState, action: AlarmActions): AlarmsState {
    switch  (action.type) {
        case AlarmActionTypes.AlarmsPageToggleLoading: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedAlarmId: undefined
        };
        case AlarmActionTypes.AlarmsActionToggleLoading: return {
            ...state, actionsloading: action.payload.isLoading
        };
        case AlarmActionTypes.AlarmOnServerCreated: return {
            ...state
        };
        case AlarmActionTypes.AlarmCreated: return adapter.addOne(action.payload.alarm, {
             ...state, lastCreatedAlarmId: action.payload.alarm.id
        });
        case AlarmActionTypes.AlarmUpdated: return adapter.updateOne(action.payload.partialAlarm, state);
        // case AlarmActionTypes.AlarmsStatusUpdated: {
        //     const _partialAlarms: Update<Alarm>[] = [];
        //     for (let i = 0; i < action.payload.alarms.length; i++) {
        //         _partialAlarms.push({
		// 		    id: action.payload.alarms[i].id,
		// 		    changes: {
        //                 status: action.payload.status
        //             }
		// 	    });
        //     }
        //     return adapter.updateMany(_partialAlarms, state);
        // }
        case AlarmActionTypes.OneAlarmDeleted: return adapter.removeOne(action.payload.id, state);
        case AlarmActionTypes.ManyAlarmsDeleted: return adapter.removeMany(action.payload.ids, state);
        case AlarmActionTypes.AlarmsPageCancelled: return {
            ...state, listLoading: false, lastQuery: new QueryParamsModel({})
        };
        case AlarmActionTypes.AlarmsPageLoaded:
            console.log("AlarmsReducer: alarms page loaded");
            console.log(JSON.stringify(action));
            if(action.payload == undefined){
                return state;
            }
            try{
                return adapter.addMany(action.payload.alarms, {
                    ...initialAlarmsState,
                    totalCount: action.payload.totalCount,
                    listLoading: false,
                    lastQuery: action.payload.page,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
        default: return state;
    }
}

export const getAlarmState = createFeatureSelector<Alarm>('alarms');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();


//ALARM TYPES
export interface AlarmTypeState{
    alarmTypes:AlarmType[],
    alarmTypesLoaded:boolean;
}

export const initialAlarmTypeState: AlarmTypeState = {
    alarmTypes: undefined,
    alarmTypesLoaded: false
}

export function alarmTypesReducer(state = initialAlarmTypeState, action: AlarmActions):AlarmTypeState{
    switch(action.type){
        case AlarmActionTypes.AlarmsTypesLoaded:
            console.log("alarm types reducer: alarm types loaded");
            console.log(JSON.stringify(action));
            return {
                alarmTypesLoaded: true,
                alarmTypes: action.payload.alarmTypes
            };
        default:
            return state;
    }
}

export const getAlarmTypeState = createFeatureSelector<Alarm>('alarmTypes');


//ALARM STATUS TYPES
export interface AlarmStatusTypeState{
    alarmStatusTypes:AlarmStatus[],
    alarmStatusTypesLoaded:boolean;
}

export const initialAlarmStatusTypeState: AlarmStatusTypeState = {
    alarmStatusTypes: undefined,
    alarmStatusTypesLoaded: false
}

export function alarmStatusTypesReducer(state = initialAlarmStatusTypeState, action: AlarmActions):AlarmStatusTypeState{
    switch(action.type){
        case AlarmActionTypes.AlarmsStatusTypesLoaded:
            return {
                alarmStatusTypesLoaded: true,
                alarmStatusTypes: action.payload.alarmStatusTypes
            };
        default:
            return state;
    }
}

export const getAlarmStatusType = createFeatureSelector<Alarm>('alarmStatusTypes');