// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import { 
  GrainType,
  GrainTypeGetAll,
  GrainTypesDataSource,
  PavilionGetTypes,
  PavilionType,
  selectPavilionTypes,
  selectSilosPageLastQuery,
  Silo, 
  SiloDelete, 
  SiloEdit, 
  SiloGetPage, 
  SilosDataSource 
} from '../../../../../core/plant-configuration';

@Component({
  selector: 'kt-silos-list',
  templateUrl: './silos-list.component.html',
  styleUrls: ['./silos-list.component.scss']
})
export class SilosListComponent implements OnInit, OnDestroy {

  // Table fields
  dataSource: SilosDataSource;
  displayedColumns = ['select', 'silo.id', 'name', 'pavilion', 'pavilion type', 'grain_type', '% reorder', 'capacity', 'actions'];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  filterGrainType = '';
  filterPavilionType = '';
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<Silo>(true, []);
  silosResult: Silo[] = [];
  grainTypesResult: GrainType[] = [];
  pavilionTypesResult: PavilionType[];
  grainTypesDataSource: GrainTypesDataSource;
  private subscriptions: Subscription[] = [];

  /**
   * Component constructor
   *
   * @param dialog: MatDialog
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param subheaderService: SubheaderService
   * @param layoutUtilsService: LayoutUtilsService
   * @param store: Store<AppState>
   */
  constructor(public dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private subheaderService: SubheaderService,
              private layoutUtilsService: LayoutUtilsService,
              private store: Store<AppState>) { }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadSilosList())
    )
    .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadSilosList();
      })
    )
    .subscribe();
    this.subscriptions.push(searchSubscription);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Silos');

    const pavTypes$ = this.store.pipe(
      select(selectPavilionTypes)
    );
    const pavTypeSub = pavTypes$.subscribe(
      (pavTypes)=>{
        console.log("pavilion types loaded!");
        if(pavTypes && pavTypes.length>0){
          this.pavilionTypesResult = pavTypes;
          this.filterPavilionType = '';
        }
      }
    );

    // Init DataSource
    this.dataSource = new SilosDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.silosResult = res;
    });
    this.subscriptions.push(entitiesSubscription);
    const lastQuerySubscription = this.store.pipe(select(selectSilosPageLastQuery)).subscribe(res => this.lastQuery = res);
    // Load last query from store
    this.subscriptions.push(lastQuerySubscription);

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        this.restoreState(this.lastQuery, +params.id);
      }

      // First load
      of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
        this.loadSilosList();
      }); // Remove this line, just loading imitation
    });

    this.grainTypesDataSource = new GrainTypesDataSource(this.store);
    const grainTypeSubscription = this.grainTypesDataSource.entitySubject.pipe(
			distinctUntilChanged()
		).subscribe(res => {
      this.grainTypesResult = res;
    });

    this.subscriptions.push(routeSubscription);
    this.subscriptions.push(entitiesSubscription);
    this.subscriptions.push(grainTypeSubscription);
    this.subscriptions.push(pavTypeSub);    

    this.loadGrainTypes();
    this.loadPavilionTypes();
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  loadGrainTypes(){
    this.store.dispatch(new GrainTypeGetAll({}));    
  }

  loadPavilionTypes(){
    this.store.dispatch(new PavilionGetTypes({}));    
  }

  /**
   * Load Silos List
   */
  loadSilosList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    console.log("loadSiloList");
    this.store.dispatch(new SiloGetPage({ page: queryParams }));
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterGrainType && this.filterGrainType.length > 0) {
      filter.grainTypeId = +this.filterGrainType;
    }

    if (this.filterPavilionType && this.filterPavilionType.length > 0) {
      filter.pavilionTypeId = +this.filterPavilionType;
    }

    if(searchText && searchText.length > 0){
      filter.name = searchText;
      filter.pavilion = searchText;
    }
    return filter;
  }

  /**
   * Restore state
   *
   * @param queryParams: QueryParamsModel
   * @param id: number
   */
  restoreState(queryParams: QueryParamsModel, id: number) {

    if (!queryParams.filter) {
      return;
    }

    if ('grainType' in queryParams.filter) {
      this.filterGrainType = queryParams.filter.condition.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  /** ACTIONS */
  /**
   * Delete silo
   *
   * @param _item: Silo
   */
  deleteSilo(_item: Silo) {
    const _title = 'Eliminar silo';
    const _description = 'Esta usted seguro de que desea eliminar permanentemente este silo?';
    const _waitDesciption = 'El silo se esta eliminando...';
    const _deleteMessage = `El silo ha sido eliminado`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.store.dispatch(new SiloDelete({silo:{ id: _item.id }}));
      this.loadSilosList();
      this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    });
  }

  /**
   * Delete silos
   */
  deleteSilos() {
    // const _title = 'Products Delete';
    // const _description = 'Are you sure to permanently delete selected products?';
    // const _waitDesciption = 'Products are deleting...';
    // const _deleteMessage = 'Selected products have been deleted';

    // const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    // dialogRef.afterClosed().subscribe(res => {
    // 	if (!res) {
    // 		return;
    // 	}

    // 	const idsForDeletion: number[] = [];
    // 	// tslint:disable-next-line:prefer-for-of
    // 	for (let i = 0; i < this.selection.selected.length; i++) {
    // 		idsForDeletion.push(this.selection.selected[i].id);
    // 	}
    // 	this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
    // 	this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    // 	this.selection.clear();
    // });
  }

  /**
   * Fetch selected silos
   */
  fetchSilos() {
    // tslint:disable-next-line:prefer-const
    let messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.name} ${elem.grain_type} ${elem.capacity}`,
        id: elem.id,
      });
    });
    this.layoutUtilsService.fetchElements(messages);
  }

  /**
   * Update status dialog
   */
  updateStatusForSilos() {
    // const _title = 'Update status for selected silos';
    // const _updateMessage = 'Status has been updated for selected silos';
    // const _statuses = [{ value: 0, text: 'Selling' }, { value: 1, text: 'Sold' }];
    // const _messages = [];

    // this.selection.selected.forEach(elem => {
    //   _messages.push({
    //     text: `${elem.name} ${elem.grain_type} ${elem.capacity}`,
    //     id: elem.id,
    //   });
    // });

    // const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
    // dialogRef.afterClosed().subscribe(res => {
    //   if (!res) {
    //     this.selection.clear();
    //     return;
    //   }

    //   this.store.dispatch(new SiloEdit({
    //     silo: this.selection.selected
    //   }));

    //   this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
    //   this.selection.clear();
    // });
  }

  /**
   * Redirect to edit page
   *
   * @param id: any
   */
  editSilo(id) {
    this.router.navigate(['../silos/edit', id], { relativeTo: this.activatedRoute });
  }

  createSilo() {
    this.router.navigateByUrl('/plant-configuration/silos/add');
  }

  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.silosResult.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.silosResult.forEach(row => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns status string
   *
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Selling';
      case 1:
        return 'Sold';
    }
    return '';
  }

  /**
   * Returns CSS Class by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 0:
        return 'success';
      case 1:
        return 'metal';
    }
    return '';
  }

  /**
   * Rerurns condition string
   *
   * @param condition: number
   */
  getItemConditionString(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'New';
      case 1:
        return 'Used';
    }
    return '';
  }

  /**
   * Returns CSS Class by condition
   *
   * @param condition: number
   */
  getItemCssClassByCondition(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'accent';
      case 1:
        return 'primary';
    }
    return '';
  }

}