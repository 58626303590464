import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {API_WATER_CONSUMPTION_URL} from '../../connection.data';
import { QueryParamsModel } from '../../_base/crud';
import { WaterConsumption } from '../_models/water-consumption.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class WaterConsumptionService{
    constructor(private http:HttpClient){

    }

    add(waterConsumption: WaterConsumption): Observable<any>{
        const url = `${API_WATER_CONSUMPTION_URL}/`
        return this.http.post<any>(url, waterConsumption).pipe(
            map(response=>{return{id:response.id, added:response.status=="success"}})
        );
    }

    edit(waterConsumption: WaterConsumption): Observable<any>{
        const url = `${API_WATER_CONSUMPTION_URL}/${waterConsumption.id}`
        return this.http.put<any>(url, waterConsumption).pipe(
            map(response=>response.status=="success")
        );
    }

    delete(waterConsumption: WaterConsumption): Observable<any>{
        const url = `${API_WATER_CONSUMPTION_URL}/${waterConsumption.id}`
        return this.http.delete<any>(url).pipe(
            map(response=>response.status=="success")
        );
    }

    getPage(queryParams: QueryParamsModel):Observable<any>{
        const url = `${API_WATER_CONSUMPTION_URL}/page`
        return this.http.post<any>(url, queryParams);
    }    
}