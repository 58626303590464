// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { AlarmsState } from '../_reducers/alarm.reducers';
import { Alarm, AlarmStatus, AlarmType } from '../_models/alarm.model';

export const selectAlarmsState = createFeatureSelector<AlarmsState>('alarms');

export const selectAlarmById = (AlarmId: number) => createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.entities[AlarmId]
);

export const selectAlarmsPageLoading = createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.listLoading
);

export const selectAlarmsActionLoading = createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.actionsloading
);

export const selectAlarmsPageLastQuery = createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.lastQuery
);

export const selectLastCreatedAlarmId = createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.lastCreatedAlarmId
);

export const selectAlarmsInitWaitingMessage = createSelector(
    selectAlarmsState,
    alarmsState => alarmsState.showInitWaitingMessage
);

export const selectAlarmsInStore = createSelector(
    selectAlarmsState,
    alarmsState => {
        const items: Alarm[] = [];
        each(alarmsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Alarm[] = httpExtension.sortArray(items, alarmsState.lastQuery.sortField, alarmsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, alarmsState.totalCount, '');
    }
);

export const selectHasAlarmsInStore = createSelector(
    selectAlarmsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//ALARM TYPES
export const selectAlarmTypesState = state => state.alarmTypes;

export const alarmTypesLoaded = createSelector(
    selectAlarmTypesState,
    alarmTypesState => alarmTypesState.alarmTypesLoaded
)

export const selectAlarmTypes = createSelector(
    selectAlarmTypesState,
    alarmTypesState => {
        const items: AlarmType[] = [];
        each(alarmTypesState.alarmTypes, element => {
            items.push(element);
        });
        return items;
    }
)

//ALARM STATUS TYPES
export const selectAlarmStatusTypesState = state => state.alarmStatusTypes;

export const alarmStatusTypesLoaded = createSelector(
    selectAlarmStatusTypesState,
    alarmStatusTypesState => alarmStatusTypesState.alarmStatusTypesLoaded
)

export const selectAlarmStatusTypes = createSelector(
    selectAlarmStatusTypesState,
    alarmStatusTypesState => {
        const items: AlarmStatus[] = [];
        each(alarmStatusTypesState.alarmStatusTypes, element => {
            items.push(element);
        });
        return items;
    }
)