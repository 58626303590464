import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BehaviorSubject, fromEvent, merge, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
// import { BaseDataSource } from 'src/app/core/_base/crud';
import { PavilionHistoryService } from '../../../../core/production';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Pavilion, PavilionService } from '../../../../core/plant-configuration';

@Component({
  selector: 'kt-full-report-list',
  templateUrl: './full-report-list.component.html',
  styleUrls: ['./full-report-list.component.scss']
})
export class FullReportListComponent implements OnInit, OnDestroy, AfterViewInit {
  st: Date;
  et: Date;
  private lastSt: Date;
  private lastEt: Date;
  datasource: MatTableDataSource<any[]>;
  displayedColumns: string[] = [];
  private subscriptions: Subscription[] = [];
  reportData: any[] = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
	@ViewChild('sort1', {static: true}) sort: MatSort;
  pavilionList: Pavilion[];

  constructor(
    private pavilionHistoryService: PavilionHistoryService,
    private pavilionService: PavilionService
  ) { }

  ngOnInit() {
    this.st = new Date();
    this.et = new Date();
    this.st.setDate(this.st.getDate()-7);
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionService.getAll())
      ).subscribe(pavilionList=>{
        this.pavilionList = pavilionList.filter(pav=>pav.type_id!=2);
      })
    )
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngAfterViewInit() {
    this.changePeriod();
  }

  changePeriod(){
    this.lastEt = this.et;
    this.lastSt = this.st;
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionHistoryService.get_harvest_per_pavilion_in_period(this.st, this.et))
      ).subscribe((harvestList)=>{
        // console.log(`harvest list: ${JSON.stringify(harvestList)}`);
        // console.log("harvest list: ");console.dir(harvestList);
        const reportTable = [];
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        const uniqueDates = harvestList.map(row=>row.write_date).filter(onlyUnique);
        uniqueDates.forEach(date=>{
          const harvestInDate = harvestList.filter(row=>row.write_date == date);
          const row = {ts: new Date(date)};
          this.pavilionList.forEach(pavilion=>{
            const shortPavName = pavilion.name.toLowerCase().replace('pabellón ', 'P').toUpperCase();
            row[shortPavName] = 0;
          });
          var totalPerDay = 0;
          harvestInDate.forEach(hrow=>{
            const shortPavName = hrow.pavilion_name.toLowerCase().replace('pabellón ', 'P').toUpperCase();
            row[shortPavName] = parseInt(hrow.total_harvest);
            totalPerDay += row[shortPavName];

            // if(shortPavName == 'P3' && date=='2021-09-14T03:00:00.000Z'){
            //   console.log("harvest: "+hrow.total_harvest);
            // }
          });
          row["Total"] = totalPerDay;
          reportTable.push(row);
        });
        // console.log("report table: ");console.dir(reportTable);
        // console.log(`report table: ${JSON.stringify(reportTable)}`);
        if(reportTable.length>0){
          this.reportData = reportTable;
          this.displayedColumns = Object.keys(reportTable[0]);
          this.datasource = new MatTableDataSource(reportTable);
          this.datasource.paginator = this.paginator;
          this.datasource.sort = this.sort;
          this.paginator.pageIndex = 0;
        }
      })
    )
  }

  exportByPavilion(){
    console.log("Exportar por pabellón");
    new AngularCsv(this.reportData.map(row=>Object.assign(Object.assign({},row), {ts: formatDateDay(row.ts)})), 'My Report', {
      headers: this.displayedColumns
    });
  }

  exportByFloor(){
    console.log("Exportar por piso");
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionHistoryService.get_harvest_per_floor_in_period(this.lastSt, this.lastEt))
      ).subscribe((harvestList)=>{
        console.log(`harvest List: ${JSON.stringify(harvestList)}`);
        const reportTable = [];
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }
        const uniqueDates = harvestList.map(row=>row.write_date).filter(onlyUnique);
        uniqueDates.forEach(date=>{
          const harvestInDate = harvestList.filter(row=>row.write_date == date);
          const uniqueFloors = harvestInDate.map((row)=>row.floor_name).filter(onlyUnique);
          console.log(`harvest in ${date}: ${JSON.stringify(harvestInDate)}`);
          var totalHarvestList = {};
          this.pavilionList.forEach(pavilion=>{
            const shortPavName = pavilion.name.toLowerCase().replace('pabellón ', 'P').toUpperCase(); 
            totalHarvestList[`${shortPavName}-cat`] = "";
            totalHarvestList[shortPavName] = 0;           
          });
          uniqueFloors.forEach(floor=>{
            const harvestInFloorDate = harvestInDate.filter(row=>row.floor_name == floor);
            // console.log(`floor harvest list: ${JSON.stringify(harvestInFloorDate)}`);
            const row = {ts: formatDateDay(new Date(date)), floor: floor};
            this.pavilionList.forEach(pavilion=>{
              const shortPavName = pavilion.name.toLowerCase().replace('pabellón ', 'P').toUpperCase();
              row[`${shortPavName}-cat`] = '';
              row[shortPavName] = 0;    
            });
            harvestInFloorDate.forEach(harvest=>{
              const shortPavName = harvest.pavilion_name.toLowerCase().replace('pabellón ', 'P').toUpperCase();
              row[shortPavName] = parseInt(harvest.total_harvest);
              row[`${shortPavName}-cat`] = harvest.bird_type_name;
              if(shortPavName=="P1") console.log(`before harvest p1 floor ${floor}: ${row[shortPavName]} | total pavilion: ${totalHarvestList[shortPavName]}`);
              totalHarvestList[shortPavName] += row[shortPavName];
              if(shortPavName=="P1") console.log(`harvest p1 floor ${floor}: ${row[shortPavName]} | total pavilion: ${totalHarvestList[shortPavName]}`);
              // console.log(`total harvest list: ${JSON.stringify(totalHarvestList)}`)
            });
            reportTable.push(row);
            // console.log(`row: ${JSON.stringify(row)}`);
          });
          const row = Object.assign({ts: formatDateDay(new Date(date)), floor: 'Total pabellón'}, totalHarvestList);
          reportTable.push(row);
        });
        if(reportTable.length>0)
          new AngularCsv(reportTable, 'My Report', {
            headers: Object.keys(reportTable[0])
          });
      })
    );
  }
}

// function formatDate(date:Date){
//   const year = date.getFullYear();
//   const month = numberTo2DigitsString(date.getMonth() +1);
//   const day = numberTo2DigitsString(date.getDate());
//   const hours = numberTo2DigitsString(date.getHours());
//   const minutes = numberTo2DigitsString(date.getMinutes());
//   const seconds = numberTo2DigitsString(date.getSeconds());
//   return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
// }

function formatDateDay(date:Date){
  const year = date.getFullYear();
  const monthList = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
  const month = date.getMonth();
  const day = numberTo2DigitsString(date.getDate());
  return `${year}-${monthList[month]}-${day}`;
}

function numberTo2DigitsString(number){
  return `${number<10?0:''}${number}`;
}