// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { GrainStockConsumptionState, GrainStockInventoryState, GrainStockLoadState, GrainTypeState } from '../_reducers/grain-stock.reducers';
import { GrainStock, GrainType } from '../_models/grain-stock.model';

export const selectGrainTypesState = createFeatureSelector<GrainTypeState>('grainTypes');

export const selectGrainTypeById = (grainTypeId: number) => createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.entities[grainTypeId]
);

export const selectGrainTypesPageLoading = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectGrainTypesState,
//     customersState => customersState.actionsloading
// );

export const selectGrainTypesPageLastQuery = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.lastQuery
);

export const selectLastCreatedGrainTypeId = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.lastCreatedGrainTypeId
);

export const selectGrainTypesInitWaitingMessage = createSelector(
    selectGrainTypesState,
    grainTypesState => grainTypesState.showInitWaitingMessage
);

export const selectGrainTypesInStore = createSelector(
    selectGrainTypesState,
    grainTypesState => {
        const items: GrainType[] = [];
        each(grainTypesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GrainType[] = httpExtension.sortArray(items, grainTypesState.lastQuery.sortField, grainTypesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, grainTypesState.totalCount, '');
    }
);

export const selectHasGrainTypesInStore = createSelector(
    selectGrainTypesInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//GRAIN STOCK LOAD State
export const selectGrainStockLoadsState = createFeatureSelector<GrainStockLoadState>('grainStockLoads');

export const selectGrainStockLoadById = (grainStockLoadId: number) => createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.entities[grainStockLoadId]
);

export const selectGrainStockLoadsPageLoading = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.listLoading
);

export const selectGrainStockLoadsPageLastQuery = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.lastQuery
);

export const selectLastCreatedGrainStockLoadId = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.lastCreatedGrainStockId
);

export const selectLastUpdatedGrainStockLoadId = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.lastUpdatedGrainStockId
);

export const selectLastDeletedGrainStockLoadId = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.lastDeletedGrainStockId
);

export const selectGrainStockLoadsInitWaitingMessage = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => grainStockLoadsState.showInitWaitingMessage
);

export const selectGrainStockLoadsInStore = createSelector(
    selectGrainStockLoadsState,
    grainStockLoadsState => {
        const items: GrainStock[] = [];
        each(grainStockLoadsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GrainStock[] = httpExtension.sortArray(items, grainStockLoadsState.lastQuery.sortField, grainStockLoadsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, grainStockLoadsState.totalCount, '');
    }
);

export const selectHasGrainStockLoadsInStore = createSelector(
    selectGrainStockLoadsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//GRAIN STOCK CONSUMPTION State
export const selectGrainStockConsumptionsState = createFeatureSelector<GrainStockConsumptionState>('grainStockConsumptions');

export const selectGrainStockConsumptionById = (grainStockConsumptionId: number) => createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.entities[grainStockConsumptionId]
);

export const selectGrainStockConsumptionsPageLoading = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.listLoading
);

export const selectGrainStockConsumptionsPageLastQuery = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.lastQuery
);

export const selectLastCreatedGrainStockConsumptionId = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.lastCreatedGrainStockId
);

export const selectLastUpdatedGrainStockConsumptionId = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.lastUpdatedGrainStockId
);

export const selectLastDeletedGrainStockConsumptionId = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.lastDeletedGrainStockId
);

export const selectGrainStockConsumptionsInitWaitingMessage = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => grainStockConsumptionsState.showInitWaitingMessage
);

export const selectGrainStockConsumptionsInStore = createSelector(
    selectGrainStockConsumptionsState,
    grainStockConsumptionsState => {
        const items: GrainStock[] = [];
        each(grainStockConsumptionsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GrainStock[] = httpExtension.sortArray(items, grainStockConsumptionsState.lastQuery.sortField, grainStockConsumptionsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, grainStockConsumptionsState.totalCount, '');
    }
);

export const selectHasGrainStockConsumptionsInStore = createSelector(
    selectGrainStockConsumptionsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//GRAIN STOCK INVENTORY State
export const selectGrainStockInventorysState = createFeatureSelector<GrainStockInventoryState>('grainStockInventorys');

export const selectGrainStockInventoryById = (grainStockInventoryId: number) => createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.entities[grainStockInventoryId]
);

export const selectGrainStockInventorysPageLoading = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.listLoading
);

export const selectGrainStockInventorysPageLastQuery = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.lastQuery
);

export const selectLastCreatedGrainStockInventoryId = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.lastCreatedGrainStockId
);

export const selectLastUpdatedGrainStockInventoryId = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.lastUpdatedGrainStockId
);

export const selectLastDeletedGrainStockInventoryId = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.lastDeletedGrainStockId
);

export const selectGrainStockInventorysInitWaitingMessage = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => grainStockInventorysState.showInitWaitingMessage
);

export const selectGrainStockInventorysInStore = createSelector(
    selectGrainStockInventorysState,
    grainStockInventorysState => {
        const items: GrainStock[] = [];
        each(grainStockInventorysState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: GrainStock[] = httpExtension.sortArray(items, grainStockInventorysState.lastQuery.sortField, grainStockInventorysState.lastQuery.sortOrder);
        return new QueryResultsModel(result, grainStockInventorysState.totalCount, '');
    }
);

export const selectHasGrainStockInventorysInStore = createSelector(
    selectGrainStockInventorysInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);