// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { Deads} from '../_models/deads.model';
import { DeadsState } from '../_reducers/deads.reducers';

// State
export const selectDeadsState = createFeatureSelector<DeadsState>('deads');

export const selectDeadsById = (deadsId: number) => createSelector(
    selectDeadsState,
    deadsState => deadsState.entities[deadsId]
);

export const selectDeadsPageLoading = createSelector(
    selectDeadsState,
    deadsState => deadsState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectDeadsState,
//     customersState => customersState.actionsloading
// );

export const selectDeadsPageLastQuery = createSelector(
    selectDeadsState,
    deadsState => deadsState.lastQuery
);

export const selectLastCreatedDeadsId = createSelector(
    selectDeadsState,
    deadsState => deadsState.lastCreatedDeadsId
);

export const selectLastUpdatedDeadsId = createSelector(
    selectDeadsState,
    deadsState => deadsState.lastUpdatedDeadsId
);

export const selectLastDeletedDeadsId = createSelector(
    selectDeadsState,
    deadsState => deadsState.lastDeletedDeadsId
);

export const selectDeadsInitWaitingMessage = createSelector(
    selectDeadsState,
    deadsState => deadsState.showInitWaitingMessage
);

export const selectDeadsInStore = createSelector(
    selectDeadsState,
    deadsState => {
        const items: Deads[] = [];
        each(deadsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Deads[] = httpExtension.sortArray(items, deadsState.lastQuery.sortField, deadsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, deadsState.totalCount, '');
    }
);

export const selectHasDeadsInStore = createSelector(
    selectDeadsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);