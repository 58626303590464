import { BaseModel } from '../../_base/crud';
export class Profiles extends BaseModel {
    id: number;
    create_uid: number;
    create_date: any;
    write_uid:string;
    write_date:string;
    name:string;
    identificator:string;
    description:string;
    clear(): void {
        this.id = undefined;
        this.create_uid = undefined;
        this.write_uid = undefined;
        this.create_date = '';
        this.write_date = '';
        this.name = '';
        this.identificator = '';
        this.description = '';
    }
}