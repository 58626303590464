import { Action } from '@ngrx/store';

export enum WaterConsumptionActionTypes {
    WATER_CONSUMPTION_EDIT = '[WATER_CONSUMPTION] WaterConsumptionEdit',
    WATER_CONSUMPTION_EDITED = '[WATER_CONSUMPTION] WaterConsumptionEdited',
    WATER_CONSUMPTION_ADD = '[WATER_CONSUMPTION] WaterConsumptionAdd',
    WATER_CONSUMPTION_ADDED = '[WATER_CONSUMPTION] WaterConsumptionAdded',
    WATER_CONSUMPTION_DELETE = '[WATER_CONSUMPTION] WaterConsumptionDelete',
    WATER_CONSUMPTION_DELETED = '[WATER_CONSUMPTION] WaterConsumptionDeleted',
    WATER_CONSUMPTION_GET_PAGE = '[WATER_CONSUMPTION] WaterConsumptionGetPage',
    WATER_CONSUMPTION_PAGE_LOADED = '[WATER_CONSUMPTION] WaterConsumptionPageLoaded'
}

export class WaterConsumptionAdd implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_ADD;
    constructor(public payload: any) {}
}

export class WaterConsumptionEdit implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_EDIT;
    constructor(public payload: any) {}
}

export class WaterConsumptionGetPage implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_GET_PAGE;
    constructor(public payload: any) {}
}

export class WaterConsumptionPageLoaded implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class WaterConsumptionAdded implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_ADDED;
    constructor(public payload: any) {}
}

export class WaterConsumptionEdited implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_EDITED;
    constructor(public payload: any) {}
}

export class WaterConsumptionDelete implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_DELETE;
    constructor(public payload: any) {}
}

export class WaterConsumptionDeleted implements Action {
    readonly type = WaterConsumptionActionTypes.WATER_CONSUMPTION_DELETED;
    constructor(public payload: any) {}
}

export type WaterConsumptionActions =
                | WaterConsumptionAdd
                | WaterConsumptionEdit
                | WaterConsumptionGetPage
                | WaterConsumptionPageLoaded
                | WaterConsumptionAdded
                | WaterConsumptionDeleted
                | WaterConsumptionDelete 
                | WaterConsumptionEdited;