import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable} from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DeadsActionTypes, DeadsAdd, DeadsAdded, DeadsDelete, DeadsDeleted, DeadsEdit, DeadsEdited, DeadsGetPage, DeadsPageLoaded } from '../_actions/deads.actions';
import { DeadsService } from '../_services/deads.service';

@Injectable()
export class DeadsEffects {

    constructor(
        private actions: Actions,
        private deadsService: DeadsService
    ) {}

    @Effect()
    getDeadsPage: Observable<any> = this.actions.pipe(
        ofType<DeadsGetPage>(DeadsActionTypes.DEADS_GET_PAGE),
        map((action: DeadsGetPage) => action.payload),
        switchMap(payload => {
            return this.deadsService.getPage(payload.page).pipe(
                map(response=>{
                    console.log("get deads page");
                    console.log(JSON.stringify(payload));
                    console.log(JSON.stringify(response));
                    return {deads: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real
            return new DeadsPageLoaded(response);
        })
    );

    @Effect()
    updateDeads: Observable<any> = this.actions.pipe(
        ofType<DeadsEdit>(DeadsActionTypes.DEADS_EDIT),
        map((action: DeadsEdit) => action.payload),
        switchMap(payload => {
            return this.deadsService.edit(payload.deads).pipe(
                map((updated)=> {return {updated: updated, deads: payload.deads, partialDeads:payload.partialDeads}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("edit deads");
            return new DeadsEdited(resp);
        })
    );

    @Effect()
    deleteDeads: Observable<any> = this.actions.pipe(
        ofType<DeadsDelete>(DeadsActionTypes.DEADS_DELETE),
        map((action: DeadsDelete) => action.payload),
        switchMap(payload => {
            return this.deadsService.delete(payload.deads).pipe(
                map(deleted=>{
                    console.log("delete deads");
                    console.log(JSON.stringify(payload));
                    return {deads: payload.deads, deleted:deleted};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new DeadsDeleted(response);
        })
    );

    @Effect()
    addDeads: Observable<any> = this.actions.pipe(
        ofType<DeadsAdd>(DeadsActionTypes.DEADS_ADD),
        map((action: DeadsAdd) => action.payload),
        switchMap(payload => {
            return this.deadsService.add(payload.deads).pipe(
                map((resp)=> {return {added: resp.added, deads: {...payload.deads, id:resp.id}}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            console.log("add deads");
            return new DeadsAdded(resp);
        })
    );

    @Effect({dispatch:false})
    deadsPageLoaded: Observable<any> = this.actions.pipe(
        ofType(DeadsActionTypes.DEADS_PAGE_LOADED),
        tap((action:DeadsPageLoaded)=>console.log("deads page loaded"))
    );

    @Effect({dispatch:false})
    deadsEdited: Observable<any> = this.actions.pipe(
        ofType(DeadsActionTypes.DEADS_EDITED),
        tap((action:DeadsEdited)=>console.log("deads edited"))
    );

    @Effect({dispatch:false})
    deadsDeleted: Observable<any> = this.actions.pipe(
        ofType(DeadsActionTypes.DEADS_DELETED),
        tap((action:DeadsDeleted)=>console.log("deads deleted"))
    );

    @Effect({dispatch:false})
    deadsAdded: Observable<any> = this.actions.pipe(
        ofType(DeadsActionTypes.DEADS_ADDED),
        tap((action:DeadsAdded)=>console.log("deads added"))
    );
}