import { Action } from '@ngrx/store';

export enum GrainTypeActionTypes {
    GT_ADD = '[GT] GrainTypeAdd',
    GT_EDIT = '[GT] GrainTypeEdit',
    GT_GET_PAGE = '[GT] GrainTypeGetPage',
    GT_DELETE = '[GT] GrainTypeDelete',
    GT_ADDED = '[GT] GrainTypeAdded',
    GT_EDITED = '[GT] GrainTypeEdited',
    GT_DELETED = '[GT] GrainTypeDeleted',
    GT_PAGE_LOADED = '[GT] GrainTypePageLoaded',
    GT_GET_ALL = '[GT] GrainTypeGetAll',
    GT_ALL_LOADED = '[GT] GrainTypeAllLoaded',
}

export enum GrainStockActionTypes {
    GS_LOAD_STOCK = '[GS] GrainLoadStock',
    GS_STOCK_LOADED = '[GS] GrainStockLoaded',
    GS_CONSUME_STOCK = '[GS] GrainConsumeStock',
    GS_STOCK_CONSUMED = '[GS] GrainStockConsumed',
    GS_EDIT_STOCK_LOAD = '[GS] GrainEditStockLoad',
    GS_EDIT_STOCK_CONSUMPTION = '[GS] GrainEditStockConsumption',
    GS_STOCK_LOAD_EDITED = '[GS] GrainStockLoadEdited',
    GS_STOCK_CONSUMPTION_EDITED = '[GS] GrainStockConsumptionEdited',
    GS_DELETE_STOCK_LOAD = '[GS] GrainDeleteStockLoad',
    GS_DELETE_STOCK_CONSUMPTION = '[GS] GrainDeleteStockConsumption',
    GS_STOCK_LOAD_DELETED = '[GS] GrainStockLoadDeleted',
    GS_STOCK_CONSUMPTION_DELETED = '[GS] GrainStockConsumptionDeleted',
    GS_GET_STOCK_CONSUMPTION_PAGE = '[GS] GrainGetStockConsumptionPage',
    GS_GET_STOCK_LOAD_PAGE = '[GS] GrainGetStockLoadPage',
    GS_GET_STOCK_INVENTORY_PAGE = '[GS] GrainGetStockInventoryPage',
    GS_STOCK_CONSUMPTION_PAGE_LOADED = '[GS] GrainStockConsumptionPageLoaded',
    GS_STOCK_LOAD_PAGE_LOADED = '[GS] GrainStockLoadPageLoaded',
    GS_STOCK_INVENTORY_PAGE_LOADED = '[GS] GrainStockInventoryPageLoaded',
    GS_FIND_STOCK_LOAD = '[GS] GrainFindStockLoad',
    GS_FIND_STOCK_CONSUMPTION = '[GS] GrainFindStockConsumption'
}

export class GrainTypeAdd implements Action {
    readonly type = GrainTypeActionTypes.GT_ADD;
    constructor(public payload: any) {}
}

export class GrainTypeEdit implements Action {
    readonly type = GrainTypeActionTypes.GT_EDIT;
    constructor(public payload: any) {}
}

export class GrainTypeDelete implements Action {
    readonly type = GrainTypeActionTypes.GT_DELETE;
    constructor(public payload: any) {}
}

export class GrainTypeGetPage implements Action {
    readonly type = GrainTypeActionTypes.GT_GET_PAGE;
    constructor(public payload: any) {}
}

export class GrainTypeGetAll implements Action {
    readonly type = GrainTypeActionTypes.GT_GET_ALL;
    constructor(public payload: any) {}
}

export class GrainTypeAdded implements Action {
    readonly type = GrainTypeActionTypes.GT_ADDED;
    constructor(public payload: any) {}
}

export class GrainTypeEdited implements Action {
    readonly type = GrainTypeActionTypes.GT_EDITED;
    constructor(public payload: any) {}
}

export class GrainTypeDeleted implements Action {
    readonly type = GrainTypeActionTypes.GT_DELETED;
    constructor(public payload: any) {}
}

export class GrainTypePageLoaded implements Action {
    readonly type = GrainTypeActionTypes.GT_PAGE_LOADED;
    constructor(public payload: any) {}
}

export class GrainTypeAllLoaded implements Action {
    readonly type = GrainTypeActionTypes.GT_ALL_LOADED;
    constructor(public payload: any) {}
}

export class GrainLoadStock implements Action {
    readonly type = GrainStockActionTypes.GS_LOAD_STOCK;
    constructor(public payload: any) {}
}
export class GrainConsumeStock implements Action {
    readonly type = GrainStockActionTypes.GS_CONSUME_STOCK;
    constructor(public payload: any) {}
}
export class GrainEditStockLoad implements Action {
    readonly type = GrainStockActionTypes.GS_EDIT_STOCK_LOAD;
    constructor(public payload: any) {}
}
export class GrainEditStockConsumption implements Action {
    readonly type = GrainStockActionTypes.GS_EDIT_STOCK_CONSUMPTION;
    constructor(public payload: any) {}
}
export class GrainDeleteStockLoad implements Action {
    readonly type = GrainStockActionTypes.GS_DELETE_STOCK_LOAD;
    constructor(public payload: any) {}
}
export class GrainDeleteStockConsumption implements Action {
    readonly type = GrainStockActionTypes.GS_DELETE_STOCK_CONSUMPTION;
    constructor(public payload: any) {}
}
export class GrainGetStockConsumptionPage implements Action {
    readonly type = GrainStockActionTypes.GS_GET_STOCK_CONSUMPTION_PAGE;
    constructor(public payload: any) {}
}
export class GrainGetStockLoadPage implements Action {
    readonly type = GrainStockActionTypes.GS_GET_STOCK_LOAD_PAGE;
    constructor(public payload: any) {}
}
export class GrainGetStockInventoryPage implements Action {
    readonly type = GrainStockActionTypes.GS_GET_STOCK_INVENTORY_PAGE;
    constructor(public payload: any) {}
}
export class GrainStockLoaded implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_LOADED;
    constructor(public payload: any) {}
}
export class GrainStockConsumed implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_CONSUMED;
    constructor(public payload: any) {}
}
export class GrainStockLoadEdited implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_LOAD_EDITED;
    constructor(public payload: any) {}
}
export class GrainStockConsumptionEdited implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_CONSUMPTION_EDITED;    
    constructor(public payload: any) {}
}
export class GrainStockConsumptionDeleted implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_CONSUMPTION_DELETED;
    constructor(public payload: any) {}
}
export class GrainStockLoadDeleted implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_LOAD_DELETED;
    constructor(public payload: any) {}
}
export class GrainStockLoadPageLoaded implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_LOAD_PAGE_LOADED;
    constructor(public payload: any) {}
}
export class GrainStockConsumptionPageLoaded implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_CONSUMPTION_PAGE_LOADED;
    constructor(public payload: any) {}
}
export class GrainStockInventoryPageLoaded implements Action {
    readonly type = GrainStockActionTypes.GS_STOCK_INVENTORY_PAGE_LOADED;
    constructor(public payload: any) {}
}
export class GrainFindStockConsumption implements Action {
    readonly type = GrainStockActionTypes.GS_FIND_STOCK_CONSUMPTION;
    constructor(public payload: any) {}
}
export class GrainFindStockLoad implements Action {
    readonly type = GrainStockActionTypes.GS_FIND_STOCK_LOAD;
    constructor(public payload: any) {}
}
export type GrainTypeActions =
                | GrainTypeAdd
                | GrainTypeAdded
                | GrainTypeDelete
                | GrainTypeDeleted
                | GrainTypeEdit
                | GrainTypeEdited
                | GrainTypeGetPage
                | GrainTypePageLoaded
                | GrainTypeGetAll
                | GrainTypeAllLoaded;
export type GrainStockActions = 
                | GrainLoadStock
                | GrainStockLoaded
                | GrainConsumeStock
                | GrainStockConsumed
                | GrainEditStockLoad
                | GrainStockLoadEdited
                | GrainDeleteStockLoad
                | GrainStockLoadDeleted
                | GrainGetStockLoadPage
                | GrainStockLoadPageLoaded
                | GrainEditStockConsumption
                | GrainStockConsumptionEdited
                | GrainDeleteStockConsumption
                | GrainStockConsumptionDeleted
                | GrainGetStockConsumptionPage
                | GrainStockConsumptionPageLoaded
                | GrainGetStockInventoryPage
                | GrainStockInventoryPageLoaded
                | GrainFindStockConsumption
                | GrainFindStockLoad;