import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef } from '@angular/core';
import {OnLinePavilionData} from '../../../../core/production/_models/OnLinePavliionData'
import {PavilionService} from '../../../../core/plant-configuration/_services/pavilion.service'
import {PavilionRealtimeService} from '../../../../core/production/_services/pavilionRealtime.service'
import { interval, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { formatDate } from '@angular/common';
@Component({
  selector: 'kt-guano-dashboard',
  templateUrl: './guano-dashboard.component.html',
  styleUrls: ['./guano-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuanoDashboardComponent implements OnInit {  
  pavilionList : OnLinePavilionData[] = [];
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  constructor(
    private pavilionService : PavilionService,
    private pavilionRealtimeService : PavilionRealtimeService,
    private cd: ChangeDetectorRef,
    ) {
    var iiot = -1;
    var cnt = 0;
    for (let index = 8; index < 22; index++) {
      var pavilion : OnLinePavilionData = new OnLinePavilionData();
      pavilion.clear();
      pavilion.iiot_code = (iiot++).toString();
      pavilion.id = index;
      pavilion.floor_data = []
      pavilion.cnt = cnt;
      if (pavilion.iiot_code == '-1')
        pavilion.iiot_code = "GL1"
      if (pavilion.iiot_code == '0')
        pavilion.iiot_code = "GL2"
      this.pavilionList.push(pavilion);
    }
    //console.table(this.pavilionList);
  }

  ngOnInit() {
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    
    this.pavilionService.getAllPavFloors().subscribe(res => {
      res.forEach(element => {
        var pavilion = this.pavilionList.find(e => e.id == element.pavilion_id)
        if (pavilion)
          pavilion.floor_data.push({
            floor_name : element.floor_name, 
            floor_id : element.floor_id,
            guano_control_0 : 1,
            guano_control_1 : 1,
            guano_control_2 : 1,
          })
      })
    })
    //const source = interval(10000);      //EVERY 10 s
    //this.subscription = source.subscribe(val => this.getGuanoStatus());
    this.getGuanoStatus();
  }
  ngOnDestroy() {
    // For method 1
    this.subscription && this.subscription.unsubscribe();
    this.subscriptions.map(sub=>sub.unsubscribe());
  }
  getGuanoStatus(){
    //console.table('#############################')
    //console.table(this.pavilionList);
    //console.table('#############################')
    this.pavilionRealtimeService.production_guano_control().subscribe(res => {
      console.table(res)
      res.forEach(element => {
        var pavilion = this.pavilionList.find(e => e.id == element.pavilion_id)
        if (pavilion){
          var guano = pavilion.floor_data.find(e => e.floor_id == element.floor_id)
          if (guano){            
            if (element.cnt == 0)
              guano.guano_control_0 =  element.guano_status_id;
            else if (element.cnt == 1)
              guano.guano_control_1 =  element.guano_status_id;
            else if (element.cnt == 2)
              guano.guano_control_2 =  element.guano_status_id;  
            
            this.cd.markForCheck();
          }
        }
      });
    })
  }
  getItemCssClassBySign(item,item2,date){
    //console.log('GETTING: %s',date);
    //console.table(item)
    //console.table(item2)
   // console.table(this.pavilionList);
    if (date == 'today'){
      //console.log('HERE1');
      var pavilion = this.pavilionList.find(e => e.id == item.id)
      if (pavilion){
        //console.log('HERE2');
        var guano = pavilion.floor_data.find(e => e.floor_id == item2.floor_id)
        if (guano){
          //console.log('today: '+guano.guano_control_0);
          if (guano.guano_control_0 == 1)
            return 'rojo'
          else if (guano.guano_control_0 == 2)
            return 'amarillo'
          else if (guano.guano_control_0 == 3)
            return 'verde'
          else 
            return 'rojo'  
        }
      }
    }else if (date == 'yesterday'){
       //console.log('HERE1');
      var pavilion = this.pavilionList.find(e => e.id == item.id)
      if (pavilion){
        //console.log('HERE2');
        var guano = pavilion.floor_data.find(e => e.floor_id == item2.floor_id)
        if (guano){
          //console.log('HERE3');
          //console.log('yesterday: '+guano.guano_control_1);
          if (guano.guano_control_1 == 1)
            return 'rojo'
          else if (guano.guano_control_1 == 2)
            return 'amarillo'
          else if (guano.guano_control_1 == 3)
            return 'verde'
          else 
            return 'rojo'  
        }
      }
    } else if (date == 'yesterday_plus_1'){
      var pavilion = this.pavilionList.find(e => e.id == item.id)
      if (pavilion){
        var guano = pavilion.floor_data.find(e => e.floor_id == item2.floor_id)
        if (guano){
          //console.log('yesterday_plus_1: '+guano.guano_control_2);
          if (guano.guano_control_2 == 1)
            return 'rojo'
          else if (guano.guano_control_2 == 2)
            return 'amarillo'
          else if (guano.guano_control_2 == 3)
            return 'verde'
          else 
            return 'rojo'  
        }
      }
    }
  }

}
