import { Component, OnInit,ViewChild,OnDestroy,ChangeDetectorRef } from '@angular/core';
import {realTimeTemperaturecomponent} from '../../../../partials/content/widgets/realtimeTemperature/realTimeTemperature.component';
import {OnLinePavilionData} from '../../../../../core/production/_models/OnLinePavliionData'
import { interval, Subscription } from 'rxjs';
import { EventMqttService } from '../../../../../core/production/_services/EventMqttService';
import { IMqttMessage } from "ngx-mqtt";
import {formatDate } from '@angular/common';
import {PavilionRealtimeService} from '../../../../../core/production/_services/pavilionRealtime.service';
import {PavilionService} from '../../../../../core/plant-configuration/_services/pavilion.service'
import { startWith } from 'rxjs/operators';
@Component({
  selector: 'kt-realtime-dashboard-pav1',
  templateUrl: './realtime-dashboard-pav1.component.html',
  styleUrls: ['./realtime-dashboard-pav1.component.scss']
})
export class RealtimeDashboardPav1Component implements OnInit,OnDestroy {
  @ViewChild(realTimeTemperaturecomponent, { static: true }) remoteHistory: realTimeTemperaturecomponent;
  private subscriptions: Subscription[] = [];
  c_pavilion : OnLinePavilionData;
  private acumID: string = 'ACUM';
  private totID: string = 'TOT';
  constructor(
    private readonly eventMqtt: EventMqttService,
    private pavilionRealtimeService: PavilionRealtimeService,
    private pavilionService : PavilionService,
    private cd: ChangeDetectorRef,
  ) {
    this.subscribeToTopic();
    this.c_pavilion = new OnLinePavilionData();
    this.c_pavilion.clear();
    this.c_pavilion.pavilion_id = 10;
    this.c_pavilion.iiot_code = '1';
    this.pavilionService.getPavilionFloors(undefined,this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;
      result.forEach(element => {
        this.c_pavilion.floor_data.push({
          floor_id : element.id,
          c_bird_type_name : '',
          pavilion_harvest_state : 'R',
          totharvest : 0
        })
      });
    })
   }

   ngOnInit() {
    this.remoteHistory.inicializarConID(this.c_pavilion.pavilion_id);
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    this.pavilionRealtimeService.pavilion_realtime_bird_by_floor(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;      
      if (result.rows){
        result.rows.forEach(element => {
          this.c_pavilion.age_in_weeks = this.c_pavilion.age_in_weeks + element.age_in_weeks + ', '
          this.c_pavilion.c_bird_amount = this.c_pavilion.c_bird_amount + element.c_bird_amount;
          var pav_data = this.c_pavilion.floor_data.find(e => e.floor_id == element.floor_id) 
          console.log('pav data');
          console.dir(pav_data);
          if (pav_data){
            pav_data.c_bird_type_name = element.bird_type_name;         
            pav_data.bird_amount = element.c_bird_amount;
          }              
          this.cd.markForCheck();
        });
      }
    });
    //GLOBAL DATA
    this.pavilionRealtimeService.pavilion_realtime_silo_by_pavilion(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;      
      if (result.rows.length > 0){        
        result.rows.forEach(element => {
          this.c_pavilion.grain_name = this.c_pavilion.grain_name + element.grain_name + ', '
          this.c_pavilion.c_grain_amount = Number(this.c_pavilion.c_grain_amount) + Number(element.c_grain_amount)
        });
      }
    });
    this.pavilionRealtimeService.pavilion_manual_daily_data(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;
      if (result.rows.length > 0){
        if (result.rows[0].pavilion_type_id = 1){
          this.c_pavilion.water_amount = (result.rows[0].water_amount/this.c_pavilion.c_bird_amount)*1000000;
          this.c_pavilion.nourishment_total = Number(result.rows[0].c_grain_regular);this.c_pavilion.nourishment_omega = Number(result.rows[0].c_grain_omega)
          this.c_pavilion.nourishment = Number(Number(result.rows[0].c_grain_regular+result.rows[0].c_grain_omega)/this.c_pavilion.c_bird_amount)*1000;
        }
      }
    });
    this.pavilionRealtimeService.pavilion_tot_deads(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;
      if (result.rows.length > 0){
        this.c_pavilion.c_dead_bird = result.rows[0].c_dead_bird;
            if (result.rows[0].c_dead_bird > 0 && this.c_pavilion.c_bird_amount)
              this.c_pavilion.p_dead_bird = (result.rows[0].c_dead_bird/this.c_pavilion.c_bird_amount)*100;
      }
    })
    this.pavilionRealtimeService.pavilion_realtime_temperature(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;
      if (result.rows.length > 0){
        this.c_pavilion.t_min = result.rows[0].temp_min;
        this.c_pavilion.t_max = result.rows[0].temp_max;
        this.c_pavilion.t_curr = result.rows[0].temp_curr;
      }
    });
    this.pavilionRealtimeService.production_last_pavilion_harvest_by_id(this.c_pavilion.pavilion_id).subscribe(res => {
      var result: any; result = res;
      //console.table(result)
      if (result.rows.length > 0){
        this.c_pavilion.totharvest = result.rows[0].harvest_tot;
        result.rows.forEach(element => {
          var floor = this.c_pavilion.floor_data.find(e => e.floor_id == element.floor_id)  
            if (element.harvest > 0){
              floor.totharvest = element.harvest;
              floor.pavilion_harvest_state = 'Y'
            }              
        });
        this.cd.markForCheck();
      }
    });
  }
  private subscribeToTopic() {
    this.subscriptions.push(this.eventMqtt.topic(this.acumID)
    .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        if (this.c_pavilion.iiot_code == item.Galpon){
           var floor = this.c_pavilion.floor_data.find(e => e.floor_id == Number(item.Piso))
           if (floor){
            if (item.Acumulado > 0){
              floor.pavilion_harvest_state = 'Y';
              if (floor.totharvest == item.Acumulado)
                floor.pavilion_harvest_state = 'G';     //THIS NEEDS TO BE COMPARED WITH THE PROM FROM DB
            }
            else
              floor.pavilion_harvest_state = 'R'
            floor.totharvest = item.Acumulado
            this.cd.markForCheck();
           }
        }
        document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    this.subscriptions.push(this.eventMqtt.topic(this.totID)
    .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());        
        if (item.Galpon == this.c_pavilion.iiot_code){          
          this.c_pavilion.totharvest = Number(item.Total);
        }        
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }
  getItemCssClassBySign(floor_id): string {
    var pav_data = this.c_pavilion.floor_data.find(e => e.floor_id == floor_id) 
    if (pav_data){
      if (pav_data.pavilion_harvest_state == 'R')
        return 'red-sign'
      else if (pav_data.pavilion_harvest_state == 'Y')
        return 'yellow-sign'
      else if (pav_data.pavilion_harvest_state == 'G')
        return 'green-sign'
      else
        return 'red-sign'
    }else
      return 'red-sign'
  }
}
