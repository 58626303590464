import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { API_SILOS_URL} from '../../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { Silo } from '../_models/silo.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class SiloService{
    constructor(private http: HttpClient){

    }

    getAll():Observable<Silo[]>{
        const url = `${API_SILOS_URL}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_SILOS_URL}/page`;
        return this.http.post<QueryResultsModel>(url, queryParams).pipe(
            tap((result)=>console.log(`${API_SILOS_URL}/page : `+JSON.stringify(result)))
        )
    }

    add(silo:Silo):Observable<number>{
        return this.http.post<any>(API_SILOS_URL, silo).pipe(
            map((response)=> response.id)
        );
    }

    edit(silo:Silo):Observable<string>{
        return this.http.put<any>(API_SILOS_URL, silo).pipe(
            map((response)=>response.status)
        );
    }

    delete(silo:Silo):Observable<string>{
        return this.http.delete<any>(`${API_SILOS_URL}/${silo.id}`).pipe(
            map((response)=>response.status)
        );
    }
}