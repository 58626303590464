import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-alarms-dashboard',
  templateUrl: 'alarms-dashboard.component.html',
  styleUrls: ['./alarms-dashboard.component.scss']
})
export class AlarmsDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
