// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {EventMqttService} from '../../../core/production/_services/EventMqttService'
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import { HttpUtilsService, TypesUtilsService, InterceptService, LayoutUtilsService} from '../../../core/_base/crud';
// Shared
import { 
	ActionNotificationComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
 } from '../../partials/content/crud';

 // Components
import {RealtimeDashboardComponent} from './realtime/realtime-dashboard/realtime-dashboard.component'
import { RealtimeDashboardAv1Component } from './realtime/realtime-dashboard-av1/realtime-dashboard-av1.component';
import { RealtimeDashboardAv2Component } from './realtime/realtime-dashboard-av2/realtime-dashboard-av2.component';
import { RealtimeDashboardPav1Component } from './realtime/realtime-dashboard-pav1/realtime-dashboard-pav1.component';
import { RealtimeDashboardPav2Component } from './realtime/realtime-dashboard-pav2/realtime-dashboard-pav2.component';
import { RealtimeDashboardPav3Component } from './realtime/realtime-dashboard-pav3/realtime-dashboard-pav3.component';
import { RealtimeDashboardPav4Component } from './realtime/realtime-dashboard-pav4/realtime-dashboard-pav4.component';
import { RealtimeDashboardPav5Component } from './realtime/realtime-dashboard-pav5/realtime-dashboard-pav5.component';
import { RealtimeDashboardPav6Component } from './realtime/realtime-dashboard-pav6/realtime-dashboard-pav6.component';
import { RealtimeDashboardPav7Component } from './realtime/realtime-dashboard-pav7/realtime-dashboard-pav7.component';
import { RealtimeDashboardPav8Component } from './realtime/realtime-dashboard-pav8/realtime-dashboard-pav8.component';
import { RealtimeDashboardPav10Component } from './realtime/realtime-dashboard-pav10/realtime-dashboard-pav10.component';
import { RealtimeDashboardPav11Component } from './realtime/realtime-dashboard-pav11/realtime-dashboard-pav11.component';
import { RealtimeDashboardPav12Component } from './realtime/realtime-dashboard-pav12/realtime-dashboard-pav12.component';
import {HistorycDashboardComponent} from './historyc/historyc-dashboard/historyc-dashboard.component'
// Material

import {
	MatInputModule,
	MatPaginatorModule,
	MatProgressSpinnerModule,
	MatSortModule,
	MatTableModule,
	MatSelectModule,
	MatMenuModule,
	MatProgressBarModule,
	MatButtonModule,
	MatCheckboxModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatCardModule,
	MatRadioModule,
	MatIconModule,
	MatDatepickerModule,
	MatExpansionModule,
	MatAutocompleteModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
	MatSnackBarModule,
	MatTooltipModule,
} from '@angular/material';
import { ProductionComponent } from './production.component';
import { RealtimeDashboardPav9Component } from './realtime/realtime-dashboard-pav9/realtime-dashboard-pav9.component';
import { realTimeTemperaturecomponent } from '../../partials/content/widgets/realtimeTemperature/realTimeTemperature.component';
import { 
	pavilionsReducer, 
	pavilionTypesReducer, 
	pavilionersReducer, 
	pavilionFloorsReducer, 
	PavilionEffects,
	breedingStockReducer, 
	stockMovReducer, 
	birdTypesReducer, 
	birdStatusTypesReducer, 
	birdGensReducer, 
	BirdStockEffects, 
	hylineReducer, 
	hylineDataReducer, 
	hylineBreDataReducer, 
	hylineHavDataReducer, 
	hylineEMoltDataReducer, 
	hylineLMoltDataReducer, 
	hylineTypesReducer, 
	hylineDataTypeReducer, 
	HylineEffects 
} from '../../../core/plant-configuration';


const routes: Routes = [
	{
		path: '',
		component: ProductionComponent,
		children: [
			{
				path: '',
				redirectTo: 'realtime',
				pathMatch: 'full'
			},
			{
				path: 'realtime',
				component: RealtimeDashboardComponent
			},			
			{
				path: 'realtime-dashboard-av1',
				component: RealtimeDashboardPav3Component
			},
			{
				path: 'realtime-dashboard-av2',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav1',
				component: RealtimeDashboardPav3Component
			},
			{
				path: 'realtime-dashboard-pav2',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav3',
				component: RealtimeDashboardPav3Component
			},
			{
				path: 'realtime-dashboard-pav4',
				component: RealtimeDashboardPav3Component
			},
			{
				path: 'realtime-dashboard-pav5',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav6',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav7',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav8',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav9',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav10',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav11',
				component: RealtimeDashboardPav3Component
      },
      {
				path: 'realtime-dashboard-pav12',
				component: RealtimeDashboardPav3Component
      },
	  {
				path: 'history-production-pavilion',
				component: HistorycDashboardComponent
	  },
	  
		]
	}
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
		PartialsModule,
		RouterModule.forChild(routes),
		//StoreModule.forFeature('alarms', AlarmsReducer),
		//EffectsModule.forFeature([AlarmEffects]),
		//StoreModule.forFeature('alarmscfg', AlarmCfgsReducer),
		//EffectsModule.forFeature([AlarmCfgEffects]),
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forChild(),
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
    	MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatExpansionModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		StoreModule.forFeature('pavilions', pavilionsReducer),
		StoreModule.forFeature('pavilionTypes', pavilionTypesReducer),
		StoreModule.forFeature('pavilioners', pavilionersReducer),
		StoreModule.forFeature('pavilionFloors', pavilionFloorsReducer),
		EffectsModule.forFeature([PavilionEffects]),
		StoreModule.forFeature('breedingStocks', breedingStockReducer),
		StoreModule.forFeature('birdStockMovs', stockMovReducer),
		StoreModule.forFeature('birdTypes', birdTypesReducer),
		StoreModule.forFeature('birdStatusTypes', birdStatusTypesReducer),
		StoreModule.forFeature('birdGens', birdGensReducer),
		EffectsModule.forFeature([BirdStockEffects]),	
		StoreModule.forFeature('hylines', hylineReducer),
		StoreModule.forFeature('hylineDatas', hylineDataReducer),
		StoreModule.forFeature('hylineBreDatas', hylineBreDataReducer),
		StoreModule.forFeature('hylineHavDatas', hylineHavDataReducer),
		StoreModule.forFeature('hylineEMoltDatas', hylineEMoltDataReducer),
		StoreModule.forFeature('hylineLMoltDatas', hylineLMoltDataReducer),
		StoreModule.forFeature('hylineTypes', hylineTypesReducer),
		StoreModule.forFeature('hylineDataTypes', hylineDataTypeReducer),
		EffectsModule.forFeature([HylineEffects]),
  ],
  providers: [
		InterceptService,
		{
        	provide: HTTP_INTERCEPTORS,
       	 	useClass: InterceptService,
			multi: true
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'kt-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService,
		EventMqttService
  ],
  entryComponents: [
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent
	],
  declarations: [
    ProductionComponent,
    RealtimeDashboardComponent,
    RealtimeDashboardAv1Component, 
    RealtimeDashboardAv2Component, 
    RealtimeDashboardPav1Component, 
    RealtimeDashboardPav2Component, 
    RealtimeDashboardPav3Component, 
    RealtimeDashboardPav4Component, 
    RealtimeDashboardPav5Component, 
    RealtimeDashboardPav6Component, 
    RealtimeDashboardPav7Component,
    RealtimeDashboardPav8Component,
    RealtimeDashboardPav10Component,
    RealtimeDashboardPav11Component,
    RealtimeDashboardPav12Component,
	RealtimeDashboardPav9Component,
	HistorycDashboardComponent,
	realTimeTemperaturecomponent
	//RealTimePavilionGL1
],
  
})
export class ProductionModule { }
