import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription,Observable, interval, BehaviorSubject, of } from 'rxjs';
import { EventMqttService } from '../../../../core/production/_services/EventMqttService';
import { PavilionRealtimeService } from '../../../../core/production/_services/pavilionRealtime.service';
import { IMqttMessage } from "ngx-mqtt";
import { VelocityData } from '../../../../core/production/_models/VelocityData'
//import { throwToolbarMixedModesError } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { resolve } from 'dns';
import {formatDate } from '@angular/common';
import { User } from '../../../../core/auth';
import { selectAuthState } from '../../../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../../../core/reducers';
import { select, Store } from '@ngrx/store';
import { filter, startWith, take, switchMap, map } from 'rxjs/operators';
import { PavilionService } from '../../../../core/plant-configuration';

@Component({
  selector: 'kt-velocity-dashboard',
  templateUrl: './velocity-dashboard.component.html',
  styleUrls: ['./velocity-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VelocityDashboardComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('chart1ValueNeedle', {static: false}) chart1ValueNeedleRef: ElementRef;
	@ViewChild('chart2ValueNeedle', {static: false}) chart2ValueNeedleRef: ElementRef;
	@ViewChild('chart3ValueNeedle', {static: false}) chart3ValueNeedleRef: ElementRef;
	@ViewChild('chart4ValueNeedle', {static: false}) chart4ValueNeedleRef: ElementRef;

  private velID: string;
  private acumID: string;
  private totID : string;
  private actID : string;
  subscription: Subscription;
  // public pavilionDatas: VelocityData[] = [];
  private pavilion_harvest = [];
	private subscriptions: Subscription[] = [];
	currentUser: User = new User();


  chart1: any;
  chart2: any;
  chart3: any;
  chart4: any;
  chart1Value: any;
  chart2Value: any;
  chart3Value: any;
  chart4Value: any;

  total_harvest: Number = 0;
  tmp_harvest: number = 0;
  cfg_velocity: number = 0;
  vel_max: number = 108;
 private needleRangeFactor = (180 * 1)/120;

 pavVelObj: any[] = [];

  constructor(
    private readonly eventMqtt: EventMqttService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private pavilionRealtimeService: PavilionRealtimeService,
    private pavilionService: PavilionService,
	private renderer: Renderer2
  ) {
    // var iiot = -1;
    this.subscriptions.push(
      of(null).pipe(
        switchMap(()=>this.pavilionService.getAll()),
        map((pavilionList)=>pavilionList.filter(pav=>Number(pav.iiot_code)<15 && pav.iiot_code!=null))
      ).subscribe(pavilionList=>{
        console.log("pavilion list: ");console.dir(pavilionList);
        pavilionList = pavilionList.sort((pav1:any, pav2:any)=>pav1.id-pav2.id);
        for (let pavilion of pavilionList) {
          var vel = new VelocityData();
          vel.clear();
          vel.pavilion_id = pavilion.id;
          vel.working_floor = 'S/C';
          // if (iiot == -1){
          //   vel.iiot_code = 14;iiot++;
          //   this.pavilion_harvest.push(
          //     {
          //       pavilion_id : pavilion.id,
          //       iiot_code : 14,
          //       harvest : 0,
          //       working : false
          //     }
          //   )
          // } else if (iiot == 0){
          //   vel.iiot_code = 13;iiot++;
          //   this.pavilion_harvest.push(
          //     {
          //       pavilion_id : pavilion.id,
          //       iiot_code : 13,
          //       harvest : 0,
          //       working : false
          //     }
          //   )
          // } else {
          //   vel.iiot_code = iiot++;
          //   this.pavilion_harvest.push(
          //     {
          //       pavilion_id : pavilion.id,
          //       iiot_code : iiot,
          //       harvest : 0,
          //       working : false,
          //       working_floor : 'N/A',
          //     }
          //   )
          // }
          this.pavilion_harvest.push(
            {
              pavilion_id : pavilion.id,
              iiot_code : pavilion.iiot_code,
              harvest : 0,
              working : false,
              working_floor : 'N/A',
              pavilion_name: pavilion.name
            }
          )
          vel.iiot_code = Number(pavilion.iiot_code);
          vel.pavilion_name = pavilion.name;
          // this.pavilionDatas.push(vel)
          this.pavVelObj[vel.iiot_code] = vel;
        }
        console.log("pavilion_harvest initialized: ");console.dir(this.pavilion_harvest);
        // console.log("pavilionDatas initialized: ");console.dir(this.pavilionDatas);
        console.log("pav vel object initialized: ");console.dir(this.pavVelObj)

        this.subscribeToTopic();
        this.getVelocity();
        this.callLoops();
      })
    )
    
    this.velID = 'VEL';
    this.acumID = 'ACUM';
    this.totID = 'TOT';
    this.actID = 'ACT';
  }
  ngAfterViewInit(): void {    
  }

  timer;
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscriptions.forEach(sub=>sub.unsubscribe());
    //TODO:
    /**
     *
     * Para poder actualizar este dashboard al inicio seria ideal poder tener la data de la base de datos
     * y llenar en un inicio el dashboard de velocidad con datos.
     *
     */



    //this.callLoops();
    //timer = Observable.timer(0, 1000);
  }
  callLoops(){
    this.subscriptions.push(
      this.pavilionRealtimeService.latestHarvestPerPavilion().subscribe(res => {
        console.log("last pavilion harvest response:");console.dir(res);
        var rsp:any;rsp=res;
        console.log("listado de pabellones: ");console.dir(this.pavilion_harvest)
        rsp.forEach(element => {
          var pav_data = this.pavilion_harvest.find(e => e.iiot_code == element.iiot_code)
          if(pav_data!=undefined){
            pav_data.harvest = Number(element.harvest)
          }else{
            console.log("no se encuentra pabellon ", element.iiot_code);
            console.dir(element);
          }
        });
        const perPavilion = {};
        rsp.forEach(row=>{
          if(perPavilion[row.iiot_code] == undefined)perPavilion[`${row.iiot_code}`] = 0;
          console.log("acum harvest ", row.harvest, " for pavilion ", row.iiot_code)
          perPavilion[row.iiot_code] += Number(row.harvest);
        })
        console.log("harveset per pavilion http req: ");console.dir(perPavilion);
        this.updateTotalHarvest();
        this.cd.detectChanges();
        console.log("total harvest by http req: ", this.total_harvest);
      })
    );
  }
  updateTotalHarvest(){
    this.total_harvest = this.pavilion_harvest.reduce((prev, curr, idx)=>prev+Number(curr.harvest), 0)/1000;
  }
  getVelocity() {
    this.pavilionRealtimeService.get_velocity().subscribe(res => {
      console.log("get velocity response: ");console.dir(res);
      //console.table(res);
      var result: any; result = res;
      this.cfg_velocity = Number(result[0].gral_velo);
      //console.log(this.cfg_velocity);
    });
    this.getVelBrief();
    this.get_prom_velocity();
  }

  getVelBrief(){
    this.pavilionRealtimeService.get_velocity_brief().subscribe((res:any) => {
      console.log("get velocity brief response: ");console.dir(res);
      this.setTroVel(res.velocidad_instantanea_trans_oriente);
      this.setTrpVel(res.velocidad_instantanea_trans_poniente);
      this.setTotVel(res.velocidad_instantanea_cosecha);
      //console.table(res);
      // var result: any; result = res;
      // this.cfg_velocity = Number(result[0].gral_velo);
      //console.log(this.cfg_velocity);
    });
  }

  get_prom_velocity(){
    this.pavilionRealtimeService.get_prom_vel().subscribe(res => {
      console.log("get prom velocity reseponse: ");console.dir(res);
      const vel = res[0].gral_vel_prom;
      this.setPromVel(vel);
    });
  }

  ngOnInit() {

    this.currentUser.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.currentUser = auth.user;
			else
				console.log("shift edit component: usuario no logueado");
		});
		this.subscriptions.push(authSubscription);

    // document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    this.subscriptions.push(interval(1000*60).pipe(startWith(0)).subscribe(()=>{
      document.getElementById("btnfechahora").innerHTML = formatDate(new Date(),'dd/MM/yyyy','en') +' | ' + formatDate(new Date(),'hh:mm','en');
    }));
    // this.InicializarData(0, 0);
  }

  setPromVel(vel){
    console.log("set prom vel to ", vel)
    this.chart4Value = Number(vel) / 1000
	  this.renderer.setStyle(this.chart4ValueNeedleRef.nativeElement, 'transform', `rotate(${this.chart4Value * this.needleRangeFactor}deg)`);
    // this.chart4.data.datasets[0].data[1] = this.chart3Value / this.vel_max;
    // var velAux2 = Number(vel) / 1000;
    // this.chart4.update();
    // this.upatePromVelChart();
    // this.InicializarData(2, velAux2);
    // this.chart4Value = velAux2;
    this.cd.detectChanges();
  }

  setTotVel(vel){
    this.chart3Value = Number(vel) / 1000;
		this.renderer.setStyle(this.chart3ValueNeedleRef.nativeElement, 'transform', `rotate(${this.chart3Value * this.needleRangeFactor}deg)`);
    // this.chart3.data.datasets[0].data[1] = this.chart3Value / this.vel_max;
    // var velAux1 = Number(vel) / 1000;
    // this.chart3.update();
    // this.updateTotVelChart();
    // this.InicializarData(1, velAux1);
    this.get_prom_velocity();
    // this.chart3Value = velAux1;
    this.cd.detectChanges();
  }

  setTroVel(vel){
    this.chart2Value = Number(vel) / 1000
    this.renderer.setStyle(this.chart2ValueNeedleRef.nativeElement, 'transform', `rotate(${this.chart2Value * this.needleRangeFactor}deg)`);
    // this.chart2.data.datasets[0].data[1] = this.chart3Value / this.vel_max;
    // var velAux2 = Number(vel) / 1000;
    // this.chart2.update();
    // this.updateTroVelChart();
    // this.InicializarData(3, velAux3);
    // this.chart2Value = velAux2;
    this.cd.detectChanges();
  }

  setTrpVel(vel){
    this.chart1Value = Number(vel) / 1000;
		this.renderer.setStyle(this.chart1ValueNeedleRef.nativeElement, 'transform', `rotate(${this.chart1Value * this.needleRangeFactor}deg)`);
    // this.chart1.data.datasets[0].data[1] = this.chart3Value / this.vel_max;
    // var velAux1 = Number(vel) / 1000;
    // this.chart1.update();
    // this.updateTrpVelChart();
    // this.chart1Value = velAux1;
    // this.InicializarData(4, velAux4);
    this.cd.detectChanges();
  }

  private subscribeToTopic() {
    var tmp: any = [];
    this.subscription = this.eventMqtt.topic(this.velID).subscribe((data: IMqttMessage) => {
      let item = JSON.parse(data.payload.toString());
      console.log('mqtt - VEL');console.dir(item);
      if (item.Galpon == 'TOT') {
        this.setTotVel(item.Velocidad)
      }else if (item.Galpon == 'TRO') {
        this.setTroVel(item.Velocidad)
      } else if (item.Galpon == 'TRP') {
        this.setTrpVel(item.Velocidad)
      }
      // var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)
      var pav_data = this.pavVelObj[item.Galpon]
      if (pav_data) {
        if (item.Modulador == "1")
          pav_data.pavilion_velocity_mode = 'A'
        else
          pav_data.pavilion_velocity_mode = 'M'
        pav_data.velocity = item.Velocidad
        this.cd.markForCheck();
      }

      this.cd.markForCheck();
      //var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)

    });
    this.subscriptions.push(this.subscription);
    /*this.subscription = this.eventMqtt.topic(this.acumID)
      .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        //console.log('ACUM',item)
        //var pav_data = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)
        //pav_data.harvest = Number(pav_data.harvest) + Number(item.Acumulado);


        if (item.Galpon == "1" && item.Piso == "1")
          this.tmp_harvest = 0;

        this.tmp_harvest = Number(this.tmp_harvest) + Number(item.Acumulado);

        if (item.Galpon == "14" && item.Piso == "1") {
          this.total_harvest = Number(this.tmp_harvest) / 1000;
          //this.total_harvest = Math.round(this.total_harvest).toFixed(1);
        }

        this.cd.markForCheck();
      });*/
      this.subscription = this.eventMqtt.topic(this.actID)
      .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        console.log('ACT');console.dir(item);
        try {
          var pav_data = this.pavilion_harvest.find(e => e.iiot_code == item.Galpon)
          if (pav_data){
            if ("1" == item.Estado)
              pav_data.working = true
            else
              pav_data.working = false

            this.cd.markForCheck();
            //pav_data.harvest = Number(item.Total)
          }
          // var pav_data2 = this.pavilionDatas.find(e => e.iiot_code == item.Galpon)
          var pav_data2 = this.pavVelObj[item.Galpon]
          if (pav_data2){
            if (item.Piso_Cosecha != "0")
              pav_data2.working_floor = item.Piso_Cosecha;
            this.cd.markForCheck();
          }

        } catch (error) {
          console.log(error);
        }
      });
      this.subscriptions.push(this.subscription);
      this.subscription = this.eventMqtt.topic(this.totID)
      .subscribe((data: IMqttMessage) => {
        let item = JSON.parse(data.payload.toString());
        //console.log('TOT',item)
        try {
          var pav_data = this.pavilion_harvest.find(e => e.iiot_code == item.Galpon)
          if (pav_data){
            pav_data.harvest = Number(item.Total)
            console.log("mqtt - pabellon ", item.Galpon, " con cosecha: ", item.Total)
          }else{
            console.log("mqtt - no se encuentra pabellon ",item.Galpon)
          }
        } catch (error) {
          console.log(error);
        }

        // if (item.Galpon == "1")
        //   this.tmp_harvest = 0;
        // this.tmp_harvest = Number(this.tmp_harvest) + Number(item.Total);

        // if (item.Galpon == "14")
        //   this.total_harvest = Number(this.tmp_harvest) / 1000;
        this.updateTotalHarvest();
        this.cd.markForCheck();
      });

      this.subscriptions.push(this.subscription);

  }
  first_time = true;
  getItemCssClassByColour(pav_id): string {
    //console.log('Entering getItemCssClassByColour: ' +pav_id);
    var pav_data = this.pavilion_harvest.find(e => e.iiot_code == pav_id)
    if (pav_data){
      if (pav_data.working)
        return 'azul'
      else
        return 'gray'
    }else{
      return 'gray'
    }
  }
  // Modal Dialog set velocity
  openDialog(): void {
    const dialogRef = this.dialog.open(SetVelocityDialog, {
      width: '350px !important',
      height: 'auto !important',
      data: { settedVelocity: this.cfg_velocity }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined || result == null) {
        result = this.cfg_velocity;
        //console.log('Dialogo ajustar velocidad cerrado', result);

      } else {
        var error = false;
        var tmp_vel: number = result;
        if (tmp_vel > this.vel_max && tmp_vel < 0)
          error = true
        if (this.cfg_velocity == tmp_vel)
          error = true;
        if (!error) {
          //console.log('Dialogo ajustar velocidad cerrado', tmp_vel);
          this.pavilionRealtimeService.set_velocity(1, tmp_vel).subscribe(res => {
            var resp: any; resp = res;
            //console.table(resp);
            this.cfg_velocity = tmp_vel;
          });
        }
      }
    });
  }
  public InicializarData(auxNum, VelAux) {
    if (auxNum == 0) {
      this.chart1 = new Chart("canvas1", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TRP"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, 1],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
      this.chart1Value = 0;
      // doughnut chart2
      this.chart2 = new Chart("canvas2", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TRO"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, 1],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
      this.chart2Value = 0;
      // doughnut chart3
      this.chart3 = new Chart("canvas3", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TOT"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, 1],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
      this.chart3Value = 0;
      // doughnut chart4
      this.chart4 = new Chart("canvas4", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TOT"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, 1],
              backgroundColor: ["#ffffff", "#f1495e"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
      this.chart4Value = 0;
    }
    if (auxNum == 1) {
      this.chart3.destroy();
      this.chart3 = new Chart("canvas3", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TOT"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, VelAux],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
      //this.chart3Value = 0;
    }
    if (auxNum == 2) {
      this.chart4.destroy();
      this.chart4 = new Chart("canvas4", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TOT"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, VelAux],
              backgroundColor: ["#ffffff", "#f1495e"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
    }
    if (auxNum == 3) {
      this.chart2.destroy();
      this.chart2 = new Chart("canvas2", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TRO"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, VelAux],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
    }
    if (auxNum == 4) {
      this.chart1.destroy();
      this.chart1 = new Chart("canvas1", {
        type: "doughnut",
        data: {
          labels: ["TIME", "TRP"],
          datasets: [
            {
              borderWidth: "0px",
              borderColor: "#e6e3e3",
              data: [100, VelAux],
              backgroundColor: ["#ffffff", "#27dd78"],
              fill: false
            }
          ]
        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      });
    }
  }

}

@Component({
  selector: 'set-velocity-dialog',
  templateUrl: 'set-velocity-dialog.html',
})
export class SetVelocityDialog {
  settedVelocity:number;
  cfg_velocity
  constructor(
    public dialogRef: MatDialogRef<SetVelocityDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    var error = true;
    this.dialogRef.close();
  }

}
