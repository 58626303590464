// Angular
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, BehaviorSubject } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Models
import { AlarmCfgModel } from '../_models/alarmcfg.model';

//const API_AlarmCfgS_URL = 'api/AlarmCfgs';

import {BASE_URL,API_PERMISSION_URL,API_ALARMS_URL} from '../../connection.data';
import { map } from 'rxjs/operators';

// Real REST API
@Injectable()
export class AlarmCfgsService {
	lastFilter$: BehaviorSubject<QueryParamsModel> = new BehaviorSubject(new QueryParamsModel({}, 'asc', '', 0, 10));

	constructor(private http: HttpClient,
		           private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new AlarmCfg to the server
	createAlarmCfg(alarmCfg): Observable<AlarmCfgModel> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<AlarmCfgModel>(API_ALARMS_URL+"/cfgs", alarmCfg, { headers: httpHeaders });
	}

	// READ
	getAllAlarmCfgs(): Observable<AlarmCfgModel[]> {
		return this.http.get<any>(API_ALARMS_URL+"/cfgs").pipe(
			map(resp=>resp.rows),
			map((results)=>{
				results.forEach((cfg, index)=>{
					switch(cfg.alarm_type_name){
						case "PABELLONES-TEMPERATURA":
						console.log("temperatura!")
						results[index].info = `Min: ${cfg.temp_low_value}°C. Medio: ${cfg.temp_medium_value}°C. `
							+ `Max: ${cfg.temp_high_value}°C.`;
						break
						case "PABELLONES-COSECHA":        
						//NO SE CUMPLE LA META
						results[index].info = `Min: ${cfg.harvest_data_low}. Medio: ${cfg.harvest_data_medium}. `
							+ `Max: ${cfg.harvest_data_high}`;
						break
						case "PABELLONES-MOD-VELOCIDAD":
						results[index].info = `Velocidad ${cfg.is_vel_manual?'manual':'automática'}`;
						break
						case "PABELLONES-INVENTARIO-ALIMENTO":
						results[index].info = `Min: ${cfg.grain_low} Kg. Medio: ${cfg.grain_medium} Kg. `
							+ `Max: ${cfg.grain_high} Kg.`;
						break
						case "PABELLONES-CONTROL-GUANO":
						results[index].info = `Control guanol ${cfg.guano_control?'automático':'manual'}`;
						break
						case "CRIANZA-INGRESO-DATA":
						//Tiempo para ingresar la data manual ha sido superado
						results[index].info = `Hora limite: ${cfg.input_manual_data_hour}:${cfg.input_manual_data_min}.`
						// results[index].info = `Min: ${cfg.manual_data_breeding_low}. Medio: ${cfg.manual_data_breeding_medium}. `
						//   + `Max: ${cfg.manual_data_breeding_high}`;
						break
						case "COSECHA-HUEVOS":
						//Solo mostrar white y color
						results[index].info = `Blancos esperados: ${cfg.white_egg_weekly_harvest_setup}. Color esperados: ${cfg.color_egg_weekly_harvest_setup}.`
						break
						case "PABELLONES-COSECHA-MOD":
						//Is harvest.. mostrar checkbox
						results[index].info = cfg.is_harvest?'Cosecha automática':'Cosecha manual';
						break;
						default:
						break
					}
				});
				return results;
			})
		);
	}

	getPage(query: QueryParamsModel):Observable<QueryResultsModel>{
		return this.http.post<any>(API_ALARMS_URL+"/cfgs/page", query).pipe(
			map((results)=>{
				if(results==undefined || results.items==undefined || results.items.length==0){
					return results;
				}
				results.items.forEach((cfg, index)=>{
					switch(cfg.alarm_type_name){
						case "PABELLONES-TEMPERATURA":
						console.log("temperatura!")
						results.items[index].info = `Min: ${cfg.temp_low_value}°C. Medio: ${cfg.temp_medium_value}°C. `
							+ `Max: ${cfg.temp_high_value}°C.`;
						break
						case "PABELLONES-COSECHA":        
						//NO SE CUMPLE LA META
						results.items[index].info = `Min: ${cfg.harvest_data_low}. Medio: ${cfg.harvest_data_medium}. `
							+ `Max: ${cfg.harvest_data_high}`;
						break
						case "PABELLONES-MOD-VELOCIDAD":
						results.items[index].info = `Velocidad manual: ${cfg.is_vel_manual}`;
						break
						case "PABELLONES-INVENTARIO-ALIMENTO":
						results.items[index].info = `Min: ${cfg.grain_low} Kg. Medio: ${cfg.grain_medium} Kg. `
							+ `Max: ${cfg.grain_high} Kg.`;
						break
						case "PABELLONES-CONTROL-GUANO":
						break
						case "CRIANZA-INGRESO-DATA":
						//Tiempo para ingresar la data manual ha sido superado
						results.items[index].info = `Hora limite: ${cfg.input_manual_data_hour}:${cfg.input_manual_data_min}.`
						// results.items[index].info = `Min: ${cfg.manual_data_breeding_low}. Medio: ${cfg.manual_data_breeding_medium}. `
						//   + `Max: ${cfg.manual_data_breeding_high}`;
						break
						case "COSECHA-HUEVOS":
						//Solo mostrar white y color
						results.items[index].info = `Blancos esperados: ${cfg.white_egg_weekly_harvest_setup}. Color esperados: ${cfg.color_egg_weekly_harvest_setup}.`
						break
						case "PABELLONES-COSECHA-MOD":
						//Is harvest.. mostrar checkbox
						results.items[index].info = cfg.is_harvest?'Cosecha automática':'Cosecha manual';
						break;
						default:
						break
					}
				});
				return results;
			})
		);
	}

	getAlarmCfgById(alarmCfgId: number): Observable<AlarmCfgModel> {
		return this.http.get<AlarmCfgModel>(API_ALARMS_URL + `/${alarmCfgId}`);
	}

	// Server should return filtered/sorted result
	/*findAlarmCfgs(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
			// Note: Add headers if needed (tokens/bearer)
			const httpHeaders = this.httpUtils.getHTTPHeaders();
			const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

			const url = API_ALARMS_URL + '/findAlarmCfgs';
			return this.http.post<QueryResultsModel>(url, {
				headers: httpHeaders,
				params:  httpParams
			});
	}*/
	findAlarmCfgs(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		const url = `${API_ALARMS_URL}/findAlarmCfgs`;
		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'application/json');
		return this.http.post<QueryResultsModel>(url, queryParams, { headers: httpHeaders});
	  }

	// UPDATE => PUT: update the AlarmCfg on the server
	updateAlarmCfg(alarmCfg: AlarmCfgModel): Observable<any> {
		// Note: Add headers if needed (tokens/bearer)
		console.log("alarm cfg service update alarmcfg")
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_ALARMS_URL+`/cfgs/${alarmCfg.id}`, alarmCfg, { headers: httpHeaders });
	}

	// UPDATE Status
	// Comment this when you start work with real server
	// This code imitates server calls
	updateStatusForAlarmCfg(alarmCfgs: AlarmCfgModel[], status: number): Observable<any> {
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = {
			AlarmCfgsForUpdate: alarmCfgs,
			newStatus: status
		};
		const url = API_ALARMS_URL + '/updateStatus';
		return this.http.put(url, body, { headers: httpHeaders });
	}

	// DELETE => delete the AlarmCfg from the server
	deleteAlarmCfg(alarmCfgId: number): Observable<AlarmCfgModel> {
		const url = `${API_ALARMS_URL}/cfgs/${alarmCfgId}`;
		return this.http.delete<AlarmCfgModel>(url);
	}

	deleteAlarmCfgs(ids: number[] = []): Observable<any> {
		const url = API_ALARMS_URL + '/delete';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { alarmCfgIdsForDelete: ids };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders} );
	}
}
