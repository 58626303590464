export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: [				
				{
					title: 'Producción',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Tiempo Real',
							submenu : [
								{
									title: 'General',
									page: '/production/realtime'
								},
								{
									title: 'Pabellón GL1',
									page: '/production/realtime-dashboard-av1'
								},
								{
									title: 'Pabellón GL2',
									page: '/production/realtime-dashboard-av2'
								},
								{
									title: 'Pabellón 1',
									page: '/production/realtime-dashboard-pav1'
								},
								{
									title: 'Pabellón 2',
									page: '/production/realtime-dashboard-pav2'
								},
								{
									title: 'Pabellón 3',
									page: '/production/realtime-dashboard-pav3'
								},
								{
									title: 'Pabellón 4',
									page: '/production/realtime-dashboard-pav4'
								},
								{
									title: 'Pabellón 5',
									page: '/production/realtime-dashboard-pav5'
								},
								{
									title: 'Pabellón 6',
									page: '/production/realtime-dashboard-pav6'
								},
								{
									title: 'Pabellón 7',
									page: '/production/realtime-dashboard-pav7'
								},
								{
									title: 'Pabellón 8',
									page: '/production/realtime-dashboard-pav8'
								},
								{
									title: 'Pabellón 9',
									page: '/production/realtime-dashboard-pav9'
								},
								{
									title: 'Pabellón 10',
									page: '/production/realtime-dashboard-pav10'
								},
								{
									title: 'Pabellón 11',
									page: '/production/realtime-dashboard-pav11'
								},
								{
									title: 'Pabellón 12',
									page: '/production/realtime-dashboard-pav12'
								}
							]
						},
						{
							title: 'Data Hístorica',
							page: '/production/hystoric',
							submenu : [
								{
									title: 'General',
									page: '/production/history-production-pavilion'
								},
							]
						}
					]
				},
				{
					title: 'Crianza',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'General',
							page: '/breeding/realtime',
						},
						{
							title: 'Dashboard específico',
							page: '/breeding/specific',
						}
					]
				},
				{
					title: 'Aplicación Móvil',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Alimento',
							submenu : [
                                {
                                    title: 'Inventario',
                                    page: '/nourishment/inventory'
                                },
                                {
                                    title: 'Movimientos de Inventario',
                                    page: '/nourishment/stock-movement'
                                },
                                {
                                    title: 'Consumo',
                                    page: '/nourishment/consumption'
                                }
							]
						},
						{
							title: 'Manual',
							page: '/nourishment/manual',
						}
					],  
				},
				{
					title: 'Control Guano',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Estado Guano',
							page: '/guanocontrol/guanocontrol'
						}
					]
				},
				{
					title: 'Control de velocidad',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Velocidad',
							page: '/velocity/velocity'
						},
						{
							title: 'Cosecha en línea',
							page: '/online-harvest/online-harvest'
						},
					]
				},
				{
					title: 'Tipo de aves',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'General',
							page: '/bird-type/bird-type-dashboard'
						}					
					]
				},
				{
					title: 'Configuración de planta',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Pabellones',
							page: '/plant-configuration/pavilion'
						},
						{
							title: 'Asignar Pabelloneros',
							page: '/plant-configuration/pavilioners'
						},
						{
							title: 'Cargar crianza',
							page: '/plant-configuration/loadbreading'
						},
						{
							title: 'Traslados',
							page: '/plant-configuration/movements'
						},
						{
							title: 'Silos',
							page: '/plant-configuration/silos'
						},
						{
							title: 'Hy-line',
							page: '/plant-configuration/hyline'
						},					

					]
				},
				/*{
					title: 'Alarmas',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'General',
							page: '/alarms/alarmsdashboard'
						},
						{
							title: 'Alarmas',
							page: '/alarms/alarms'
						},
						{
							title: 'Configuración de alarmas',
							page: '/alarms/alarmscfg'
						},					
					]
				},*/
				/*{
					title: 'Data cruda',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Data cruda',
							page: '/historydata'
						},						
					]
				},*/
				/*{
					title: 'Reportes',
					root : true,
					alignment: 'left',
					page: '/dashboard',
					translate: 'MENU.DASHBOARD',
				},*/
				{
					title: 'Reportes',
					root : true,
					alignment: 'left',
					toggle: 'click',
					page: '/reports/full-report-list',
					/*submenu: [
						{
							title: 'General',
							page: '/bird-type/bird-type-dashboard'
						}					
					]*/
				},
				{
					title: 'Administración',
					root : true,
					alignment: 'left',
					toggle: 'click',
					submenu: [
						{
							title: 'Usuarios',
							page: '/user-management/users'
						},
						{
							title: 'Perfiles',
							page: '/user-management/roles'
						}
					]
				}
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
