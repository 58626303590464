import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-breeding',
  templateUrl: './breeding.component.html',
  styleUrls: ['./breeding.component.scss']
})
export class BreedingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
