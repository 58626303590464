// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay, filter, map } from 'rxjs/operators';
import { combineLatest, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  pavilionTypesLoaded, 
  selectPavilionTypes, 
  PavilionType, 
  PavilionGetTypes,
  GrainType,
  GrainStock,
  GrainStockLoadDataSource,
  GrainTypesDataSource,
  selectGrainStockLoadsPageLastQuery,
  GrainTypeGetAll,
  GrainGetStockLoadPage,
  GrainDeleteStockLoad,
  selectLastDeletedGrainStockLoadId,
  GrainStockService
} from '../../../../../core/plant-configuration';


// Table with EDIT item in new page
// ARTICLE for table with sort/filter/paginator
// https://blog.angular-university.io/angular-material-data-table/
// https://v5.material.angular.io/components/table/overview
// https://v5.material.angular.io/components/sort/overview
// https://v5.material.angular.io/components/table/overview#sorting
// https://www.youtube.com/watch?v=NSt9CI3BXv4
@Component({
  selector: 'kt-stock-movement-list',
  templateUrl: './stock-movement-list.component.html',
  styleUrls: ['./stock-movement-list.component.scss']
})
export class StockMovementListComponent implements OnInit, OnDestroy {

  // Table fields
dataSource: GrainStockLoadDataSource;
displayedColumns = ['select', 'id', 'silo.name', 'pav.name', 'ptype.name', 'gtype.name', 'silo.capacity',
 'grain_amount', 'silo.c_grain_amount', 'write_date', 'actions'];
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild('sort1', {static: true}) sort: MatSort;
// Filter fields
@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
filterPavType = '';
filterGrainType = '';
lastQuery: QueryParamsModel;
// Selection
selection = new SelectionModel<GrainStock>(true, []);
grainStocksResult: GrainStock[] = [];
grainTypesResult: GrainType[] = [];
pavTypesResult: PavilionType[] = [];
private subscriptions: Subscription[] = [];
  grainTypesDataSource: GrainTypesDataSource;

/**
 * Component constructor
 *
 * @param dialog: MatDialog
 * @param activatedRoute: ActivatedRoute
 * @param router: Router
 * @param subheaderService: SubheaderService
 * @param layoutUtilsService: LayoutUtilsService
 * @param store: Store<AppState>
 */
constructor(public dialog: MatDialog,
             private activatedRoute: ActivatedRoute,
             private router: Router,
             private subheaderService: SubheaderService,
             private layoutUtilsService: LayoutUtilsService,
             private store: Store<AppState>,
             private grainStockService: GrainStockService) { }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
  // If the user changes the sort order, reset back to the first page.
  const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  this.subscriptions.push(sortSubscription);

  /* Data load will be triggered in two cases:
  - when a pagination event occurs => this.paginator.page
  - when a sort event occurs => this.sort.sortChange
  **/
  const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
    tap(() => this.loadGrainStockList())
  )
  .subscribe();
  this.subscriptions.push(paginatorSubscriptions);

  // Filtration, bind to searchInput
  const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    debounceTime(150),
    distinctUntilChanged(),
    tap(() => {
      this.paginator.pageIndex = 0;
      this.loadGrainStockList();
    })
  )
  .subscribe();
  this.subscriptions.push(searchSubscription);

  //Pavilion types observers
  const pavTypesLoaded$ = this.store.pipe(
    select(pavilionTypesLoaded),
    filter((pavTypesLoaded)=>pavTypesLoaded)
  );
  const pavTypes$ = this.store.pipe(
    select(selectPavilionTypes)
  );
  const pavTypeSub = combineLatest([pavTypesLoaded$, pavTypes$]).subscribe(
    ([pavTypesLoaded, pavTypes])=>{
      console.log("pavilion types loaded!");
      if(pavTypesLoaded && pavTypes && pavTypes.length>0){
        this.pavTypesResult = pavTypes;
        this.filterPavType = '';
      }
    }
  );

  //Grain types observers
  this.grainTypesDataSource = new GrainTypesDataSource(this.store);
  const grainTypeSubscription = this.grainTypesDataSource.entitySubject.pipe(
    distinctUntilChanged()
  ).subscribe(res => {
    this.grainTypesResult = res;
    
    this.filterGrainType = '';
  });

  // Set title to page breadCrumbs
  this.subheaderService.setTitle('Grain Stocks');

  // Init DataSource
  this.dataSource = new GrainStockLoadDataSource(this.store);
  const entitiesSubscription = this.dataSource.entitySubject.pipe(
    skip(1),
    distinctUntilChanged()
  ).subscribe(res => {
    this.grainStocksResult = res;
    // if (this.sort.direction.toLowerCase() == 'desc'){
    //   this.grainStocksResult = res.reverse();
    // }
  });
  
  const lastQuerySubscription = this.store.pipe(select(selectGrainStockLoadsPageLastQuery)).subscribe(res => this.lastQuery = res);
  // Load last query from store
  this.subscriptions.push(lastQuerySubscription);

  // Read from URL itemId, for restore previous state
  const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
    if (params.id) {
      this.restoreState(this.lastQuery, +params.id);
    }

    // First load
    const firstLoadSub = of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadGrainStockList();
    }); // Remove this line, just loading imitation
    this.subscriptions.push(firstLoadSub);
  });

  this.subscriptions.push(entitiesSubscription);
  this.subscriptions.push(routeSubscription);
  this.subscriptions.push(pavTypeSub);
  this.subscriptions.push(grainTypeSubscription);

  this.loadPavilionTypes();
  this.loadGrainTypes();
}

/**
 * On Destroy
 */
ngOnDestroy() {
  this.subscriptions.forEach(el => el.unsubscribe());
}

loadPavilionTypes(){
  this.store.dispatch(new PavilionGetTypes({}));
}

loadGrainTypes(){
  this.store.dispatch(new GrainTypeGetAll({}));
}

/**
 * Load GrainStock List
 */
loadGrainStockList() {
  this.selection.clear();
  const queryParams = new QueryParamsModel(
    this.filterConfiguration(),
    this.sort.direction,
    this.sort.active,
    this.paginator.pageIndex,
    this.paginator.pageSize
  );
  // Call request from server
  // console.log("loadGrainStocksList");
  // this.store.dispatch(new GrainGetStockLoadPage({ page: queryParams }));
  this.selection.clear();
  this.grainStockService.getLoads(queryParams).subscribe(res=>{
    this.grainStocksResult = res.items;
    this.dataSource.entitySubject.next(res.items);
    this.dataSource.paginatorTotalSubject.next(res.totalCount);
  })
}

/**
 * Returns object for filter
 */
filterConfiguration(): any {
  const filter: any = {};
  const searchText: string = this.searchInput.nativeElement.value;

  if(searchText != ''){
    filter.silo = searchText;
    filter.pavilion = searchText;
  }

  if(this.filterPavType!=''){
    filter.pavilion_type_id = this.filterPavType;
  }

  if(this.filterGrainType!=''){
    filter.grain_type_id = this.filterGrainType;
  }

  return filter;
}

/**
 * Restore state
 *
 * @param queryParams: QueryParamsModel
 * @param id: number
 */
restoreState(queryParams: QueryParamsModel, id: number) {

  if (!queryParams.filter) {
    return;
  }

  if ('pavilionType' in queryParams.filter) {
    this.filterPavType = queryParams.filter.status.toString();
  }

  if ('grainType' in queryParams.filter) {
    this.filterGrainType = queryParams.filter.status.toString();
  }
}

/** ACTIONS */
/**
 * Delete bird stock register
 *
 * @param _item: GrainStock
 */
deleteGrainStock(_item: GrainStock) {
  const _title = 'Eliminar movimiento de inventario';
  const _description = 'Esta usted seguro de que desea eliminar permanentemente este movimiento de inventario?';
  const _waitDesciption = 'El movimiento de inventario se esta eliminando...';
  const _deleteMessage = `El movimiento de inventario ha sido eliminado`;

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  const dialogSub = dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    this.store.dispatch(new GrainDeleteStockLoad({ grainStock: _item}));
    const deleteSub = this.store.pipe(
      select(selectLastDeletedGrainStockLoadId)
    ).subscribe((id)=>{
      if(id == _item.id){
        this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        this.loadGrainStockList();
        deleteSub.unsubscribe();
      }
    });
    this.subscriptions.push(deleteSub);  
  });
  this.subscriptions.push(dialogSub);
}

/**
 * Delete pavilions
 */
deletePavilions() {
  // const _title = 'Products Delete';
  // const _description = 'Are you sure to permanently delete selected products?';
  // const _waitDesciption = 'Products are deleting...';
  // const _deleteMessage = 'Selected products have been deleted';

  // const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  // dialogRef.afterClosed().subscribe(res => {
  // 	if (!res) {
  // 		return;
  // 	}

  // 	const idsForDeletion: number[] = [];
  // 	// tslint:disable-next-line:prefer-for-of
  // 	for (let i = 0; i < this.selection.selected.length; i++) {
  // 		idsForDeletion.push(this.selection.selected[i].id);
  // 	}
  // 	this.store.dispatch(new ManyProductsDeleted({ ids: idsForDeletion }));
  // 	this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
  // 	this.selection.clear();
  // });
}

/**
 * Fetch selected products
 */
fetchGrainStocks() {
  // tslint:disable-next-line:prefer-const
  let messages = [];
  this.selection.selected.forEach(elem => {
    messages.push({
      text: `${elem.grain_type} in ${elem.silo_name}`,
      id: elem.id,
    });
  });
  this.layoutUtilsService.fetchElements(messages);
}

/**
 * Update status dialog
 */
// updateStatusForPavilions() {
//   const _title = 'Update status for selected bird stocks';
//   const _updateMessage = 'Status has been updated for selected bird stocks';
//   const _statuses = [{ value: 0, text: 'Selling' }, { value: 1, text: 'Sold' }];
//   const _messages = [];

//   this.selection.selected.forEach(elem => {
//     _messages.push({
//       text: `${elem.grain_type} in ${elem.silo_name}`,
//       id: elem.id,
//     });
//   });

//   const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
//   const dialogSub = dialogRef.afterClosed().subscribe(res => {
//     if (!res) {
//       this.selection.clear();
//       return;
//     }

//     // this.store.dispatch(new BSEdit({
//     //   birdStocks: this.selection.selected
//     // }));

//     this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
//     this.selection.clear();
//   });
//   this.subscriptions.push(dialogSub);
// }

/**
 * Redirect to edit page
 *
 * @param id: any
 */
editGrainStock(id) {
  this.router.navigate(['../stock-movement/edit', id], { relativeTo: this.activatedRoute });
}

createGrainStock() {
  this.router.navigate(['../stock-movement/add'], { relativeTo: this.activatedRoute });
}

/**
 * Check all rows are selected
 */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.grainStocksResult.length;
  return numSelected === numRows;
}

/**
 * Selects all rows if they are not all selected; otherwise clear selection
 */
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
  } else {
    this.grainStocksResult.forEach(row => this.selection.select(row));
  }
}

/* UI */
/**
 * Returns status string
 *
 * @param status: number
 */
getItemStatusString(status: number = 0): string {
  switch (status) {
    case 0:
      return 'Selling';
    case 1:
      return 'Sold';
  }
  return '';
}

/**
 * Returns CSS Class by status
 *
 * @param status: number
 */
getItemCssClassByStatus(status: number = 0): string {
  switch (status) {
    case 0:
      return 'success';
    case 1:
      return 'metal';
  }
  return '';
}

/**
 * Rerurns condition string
 *
 * @param condition: number
 */
getItemConditionString(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'New';
    case 1:
      return 'Used';
  }
  return '';
}

/**
 * Returns CSS Class by condition
 *
 * @param condition: number
 */
getItemCssClassByCondition(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'accent';
    case 1:
      return 'primary';
  }
  return '';
}

}