// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
import { Hyline, HylineData, HylineDataType, HylineType } from '../_models/hyline.model';
import { HylineBreDataState, HylineDataState, HylineEMoltDataState, HylineHavDataState, HylineLMoltDataState, HylineState } from '../_reducers/hyline.reducer';

// State
export const selectHylinesState = createFeatureSelector<HylineState>('hylines');

export const selectHylineById = (hylineId: number) => createSelector(
    selectHylinesState,
    hylinesState => hylinesState.entities[hylineId]
);

export const selectHylinesPageLoading = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylinesState,
//     customersState => customersState.actionsloading
// );

export const selectHylinesPageLastQuery = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.lastQuery
);

export const selectLastCreatedHylineId = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.lastCreatedHylineId
);

export const selectLastUpdatedHylineId = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.lastUpdatedHylineId
);

export const selectLastDeletedHylineId = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.lastDeletedHylineId
);

export const selectHylinesInitWaitingMessage = createSelector(
    selectHylinesState,
    hylinesState => hylinesState.showInitWaitingMessage
);

export const selectHylinesInStore = createSelector(
    selectHylinesState,
    hylinesState => {
        const items: Hyline[] = [];
        each(hylinesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: Hyline[] = httpExtension.sortArray(items, hylinesState.lastQuery.sortField, hylinesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylinesState.totalCount, '');
    }
);

export const selectHasHylinesInStore = createSelector(
    selectHylinesInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE DATA State
export const selectHylineDatasState = createFeatureSelector<HylineDataState>('hylineDatas');

export const selectHylineDataById = (hylineDataId: number) => createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.entities[hylineDataId]
);

export const selectHylineDatasPageLoading = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylineDatasState,
//     customersState => customersState.actionsloading
// );

export const selectHylineDatasPageLastQuery = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.lastQuery
);

export const selectLastCreatedHylineDataId = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.lastCreatedHylineDataId
);

export const selectLastUpdatedHylineDataId = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.lastUpdatedHylineDataId
);

export const selectLastDeletedHylineDataId = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.lastDeletedHylineDataId
);

export const selectHylineDatasInitWaitingMessage = createSelector(
    selectHylineDatasState,
    hylineDatasState => hylineDatasState.showInitWaitingMessage
);

export const selectHylineDatasInStore = createSelector(
    selectHylineDatasState,
    hylineDatasState => {
        const items: HylineData[] = [];
        each(hylineDatasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HylineData[] = httpExtension.sortArray(items, hylineDatasState.lastQuery.sortField, hylineDatasState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylineDatasState.totalCount, '');
    }
);

export const selectHasHylineDatasInStore = createSelector(
    selectHylineDatasInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE BREEDING DATA State
export const selectHylineBreDatasState = createFeatureSelector<HylineBreDataState>('hylineBreDatas');

export const selectHylineBreDataById = (hylineBreDataId: number) => createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.entities[hylineBreDataId]
);

export const selectHylineBreDatasPageLoading = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylineBreDatasState,
//     customersState => customersState.actionsloading
// );

export const selectHylineBreDatasPageLastQuery = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.lastQuery
);

export const selectLastCreatedHylineBreDataId = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.lastCreatedHylineDataId
);

export const selectLastUpdatedHylineBreDataId = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.lastUpdatedHylineDataId
);

export const selectLastDeletedHylineBreDataId = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.lastDeletedHylineDataId
);

export const selectHylineBreDatasInitWaitingMessage = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => hylineBreDatasState.showInitWaitingMessage
);

export const selectHylineBreDatasInStore = createSelector(
    selectHylineBreDatasState,
    hylineBreDatasState => {
        const items: HylineData[] = [];
        each(hylineBreDatasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HylineData[] = httpExtension.sortArray(items, hylineBreDatasState.lastQuery.sortField, hylineBreDatasState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylineBreDatasState.totalCount, '');
    }
);

export const selectHasHylineBreDatasInStore = createSelector(
    selectHylineBreDatasInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE DATA State
export const selectHylineHavDatasState = createFeatureSelector<HylineHavDataState>('hylineHavDatas');

export const selectHylineHavDataById = (hylineHavDataId: number) => createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.entities[hylineHavDataId]
);

export const selectHylineHavDatasPageLoading = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylineHavDatasState,
//     customersState => customersState.actionsloading
// );

export const selectHylineHavDatasPageLastQuery = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.lastQuery
);

export const selectLastCreatedHylineHavDataId = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.lastCreatedHylineDataId
);

export const selectLastUpdatedHylineHavDataId = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.lastUpdatedHylineDataId
);

export const selectLastDeletedHylineHavDataId = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.lastDeletedHylineDataId
);

export const selectHylineHavDatasInitWaitingMessage = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => hylineHavDatasState.showInitWaitingMessage
);

export const selectHylineHavDatasInStore = createSelector(
    selectHylineHavDatasState,
    hylineHavDatasState => {
        const items: HylineData[] = [];
        each(hylineHavDatasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HylineData[] = httpExtension.sortArray(items, hylineHavDatasState.lastQuery.sortField, hylineHavDatasState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylineHavDatasState.totalCount, '');
    }
);

export const selectHasHylineHavDatasInStore = createSelector(
    selectHylineHavDatasInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE EARLY MOLT DATA State
export const selectHylineEMoltDatasState = createFeatureSelector<HylineEMoltDataState>('hylineEMoltDatas');

export const selectHylineEMoltDataById = (hylineEMoltDataId: number) => createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.entities[hylineEMoltDataId]
);

export const selectHylineEMoltDatasPageLoading = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylineEMoltDatasState,
//     customersState => customersState.actionsloading
// );

export const selectHylineEMoltDatasPageLastQuery = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.lastQuery
);

export const selectLastCreatedHylineEMoltDataId = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.lastCreatedHylineDataId
);

export const selectLastUpdatedHylineEMoltDataId = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.lastUpdatedHylineDataId
);

export const selectLastDeletedHylineEMoltDataId = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.lastDeletedHylineDataId
);

export const selectHylineEMoltDatasInitWaitingMessage = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => hylineEMoltDatasState.showInitWaitingMessage
);

export const selectHylineEMoltDatasInStore = createSelector(
    selectHylineEMoltDatasState,
    hylineEMoltDatasState => {
        const items: HylineData[] = [];
        each(hylineEMoltDatasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HylineData[] = httpExtension.sortArray(items, hylineEMoltDatasState.lastQuery.sortField, hylineEMoltDatasState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylineEMoltDatasState.totalCount, '');
    }
);

export const selectHasHylineEMoltDatasInStore = createSelector(
    selectHylineEMoltDatasInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE LATE MOLT DATA State
export const selectHylineLMoltDatasState = createFeatureSelector<HylineLMoltDataState>('hylineLMoltDatas');

export const selectHylineLMoltDataById = (hylineLMoltDataId: number) => createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.entities[hylineLMoltDataId]
);

export const selectHylineLMoltDatasPageLoading = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.listLoading
);

// export const selectProductsActionLoading = createSelector(
//     selectHylineLMoltDatasState,
//     customersState => customersState.actionsloading
// );

export const selectHylineLMoltDatasPageLastQuery = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.lastQuery
);

export const selectLastCreatedHylineLMoltDataId = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.lastCreatedHylineDataId
);

export const selectLastUpdatedHylineLMoltDataId = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.lastUpdatedHylineDataId
);

export const selectLastDeletedHylineLMoltDataId = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.lastDeletedHylineDataId
);

export const selectHylineLMoltDatasInitWaitingMessage = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => hylineLMoltDatasState.showInitWaitingMessage
);

export const selectHylineLMoltDatasInStore = createSelector(
    selectHylineLMoltDatasState,
    hylineLMoltDatasState => {
        const items: HylineData[] = [];
        each(hylineLMoltDatasState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: HylineData[] = httpExtension.sortArray(items, hylineLMoltDatasState.lastQuery.sortField, hylineLMoltDatasState.lastQuery.sortOrder);
        return new QueryResultsModel(result, hylineLMoltDatasState.totalCount, '');
    }
);

export const selectHasHylineLMoltDatasInStore = createSelector(
    selectHylineLMoltDatasInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);

//HYLINE TYPES
export const selectHylineTypesState = state => state.hylineTypes;

export const hylineTypesLoaded = createSelector(
    selectHylineTypesState,
    hylineTypesState => hylineTypesState.hylineTypesLoaded
)

export const selectHylineTypes = createSelector(
    selectHylineTypesState,
    hylineTypesState => {
        const items: HylineType[] = [];
        each(hylineTypesState.hylineTypes, element => {
            items.push(element);
        });
        return items;
    }
)

//BIRD DATA TYPES
export const selectHylineDataTypesState = state => state.hylineDataTypes;

export const hylineDataTypesLoaded = createSelector(
    selectHylineDataTypesState,
    hylineDataTypesState => hylineDataTypesState.hylineDataTypesLoaded
)

export const selectHylineDataTypes = createSelector(
    selectHylineDataTypesState,
    hylineDataTypesState => {
        const items: HylineDataType[] = [];
        each(hylineDataTypesState.hylineDataTypes, element => {
            items.push(element);
        });
        return items;
    }
)