// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { BirdStockActions, BirdStockActionTypes } from '../_actions/bird-stock.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { Hyline, HylineData, HylineDataType, HylineType } from '../_models/hyline.model';
import { HylineActions, HylineActionTypes } from '../_actions/hyline.actions';

//hyline
export interface HylineState extends EntityState<Hyline> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineId: number;
    lastUpdatedHylineId: number;
    lastDeletedHylineId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const hylineAdapter: EntityAdapter<Hyline> = createEntityAdapter<Hyline>();

export const initialHylineState: HylineState = hylineAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineId: -1,
    lastUpdatedHylineId: -1,
    lastDeletedHylineId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineReducer(state = initialHylineState, action: HylineActions): HylineState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_PAGE_LOADED:
            console.log("hyline reducer load")
            console.log(JSON.stringify(action));
            try{
                return hylineAdapter.addMany(action.payload.hylines, {
                    ...initialHylineState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
            
        case HylineActionTypes.HYLINE_ADDED:
            console.log("hyline reducer... added.. action: "+JSON.stringify(action));
            if(action.payload.added && action.payload.hyline.id>0){
                return hylineAdapter.addOne(action.payload.hyline, {
                        ...state, lastCreatedHylineId: action.payload.hyline.id
                    });
            }
            return {...state, lastCreatedHylineId: -1}
        case HylineActionTypes.HYLINE_EDITED:
            console.log("reducer hyline edited")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return hylineAdapter.updateOne(action.payload.partialHyline, 
                    {...state, lastUpdatedHylineId: action.payload.hyline.id});
            return {...state, lastUpdatedHylineId: -1}
        case HylineActionTypes.HYLINE_DELETED:
            if(action.payload.deleted){
                return hylineAdapter.removeOne(action.payload.hyline.id, 
                    {...state, lastDeletedHylineId: action.payload.hyline.id});
            }
            return {...state, lastDeletedHylineId: -1}
        default: return state;
    }
}

//hyline data
export interface HylineDataState extends EntityState<HylineData> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineDataId: number;
    lastUpdatedHylineDataId: number;
    lastDeletedHylineDataId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const hdataAdapter: EntityAdapter<HylineData> = createEntityAdapter<HylineData>();

export const initialHylineDataState: HylineDataState = hdataAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineDataId: -1,
    lastUpdatedHylineDataId: -1,
    lastDeletedHylineDataId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineDataReducer(state = initialHylineDataState, action: HylineActions): HylineDataState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_DATA_PAGE_LOADED:
            return hdataAdapter.addMany(action.payload.hylineData, {
                    ...initialHylineDataState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

//hyline breeding data
export interface HylineBreDataState extends EntityState<HylineData> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineDataId: number;
    lastUpdatedHylineDataId: number;
    lastDeletedHylineDataId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const hdataBreAdapter: EntityAdapter<HylineData> = createEntityAdapter<HylineData>();

export const initialHylineBreDataState: HylineBreDataState = hdataBreAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineDataId: -1,
    lastUpdatedHylineDataId: -1,
    lastDeletedHylineDataId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineBreDataReducer(state = initialHylineBreDataState, action: HylineActions): HylineBreDataState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_BRE_DATA_PAGE_LOADED:
            console.log("hyline bre data page loaded REDUCER")
            console.log("action: "+JSON.stringify(action));
            return hdataBreAdapter.addMany(action.payload.hylineData, {
                    ...initialHylineBreDataState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

//hyline haverst data
export interface HylineHavDataState extends EntityState<HylineData> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineDataId: number;
    lastUpdatedHylineDataId: number;
    lastDeletedHylineDataId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const hdataHavAdapter: EntityAdapter<HylineData> = createEntityAdapter<HylineData>();

export const initialHylineHavDataState: HylineHavDataState = hdataHavAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineDataId: -1,
    lastUpdatedHylineDataId: -1,
    lastDeletedHylineDataId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineHavDataReducer(state = initialHylineHavDataState, action: HylineActions): HylineHavDataState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_HAV_DATA_PAGE_LOADED:
            console.log("hyline hav data page loaded REDUCER")
            console.log("action: "+JSON.stringify(action));
            return hdataHavAdapter.addMany(action.payload.hylineData, {
                    ...initialHylineHavDataState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

//hyline early molt data
export interface HylineEMoltDataState extends EntityState<HylineData> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineDataId: number;
    lastUpdatedHylineDataId: number;
    lastDeletedHylineDataId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const hdataEMoltAdapter: EntityAdapter<HylineData> = createEntityAdapter<HylineData>();

export const initialHylineEMoltDataState: HylineEMoltDataState = hdataEMoltAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineDataId: -1,
    lastUpdatedHylineDataId: -1,
    lastDeletedHylineDataId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineEMoltDataReducer(state = initialHylineEMoltDataState, action: HylineActions): HylineEMoltDataState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_EMOLT_DATA_PAGE_LOADED:
            console.log("hyline early molt data page loaded REDUCER")
            console.log("action: "+JSON.stringify(action));
            return hdataEMoltAdapter.addMany(action.payload.hylineData, {
                    ...initialHylineEMoltDataState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

//hyline late molt data
export interface HylineLMoltDataState extends EntityState<HylineData> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedHylineDataId: number;
    lastUpdatedHylineDataId: number;
    lastDeletedHylineDataId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const hdataLMoltAdapter: EntityAdapter<HylineData> = createEntityAdapter<HylineData>();

export const initialHylineLMoltDataState: HylineLMoltDataState = hdataLMoltAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedHylineDataId: -1,
    lastUpdatedHylineDataId: -1,
    lastDeletedHylineDataId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function hylineLMoltDataReducer(state = initialHylineLMoltDataState, action: HylineActions): HylineLMoltDataState {
    switch  (action.type) {
        case HylineActionTypes.HYLINE_LMOLT_DATA_PAGE_LOADED:
            console.log("hyline late molt data page loaded REDUCER")
            console.log("action: "+JSON.stringify(action));
            return hdataLMoltAdapter.addMany(action.payload.hylineData, {
                    ...initialHylineLMoltDataState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

export const getHylineState = createFeatureSelector<HylineState>('hylines');
export const getHylineDataState = createFeatureSelector<HylineDataState>('hylineDatas');
export const getHylineBreDataState = createFeatureSelector<HylineDataState>('hylineBreDatas');
export const getHylineHavDataState = createFeatureSelector<HylineDataState>('hylineHavDatas');
export const getHylineEMoltDataState = createFeatureSelector<HylineDataState>('hylineEMoltDatas');
export const getHylineLMoltDataState = createFeatureSelector<HylineDataState>('hylineLMoltDatas');

export const hylineSelectors = hylineAdapter.getSelectors();
export const hylineDataSelectors = hdataAdapter.getSelectors();
export const hylineBreDataSelectors = hdataBreAdapter.getSelectors();
export const hylineHavDataSelectors = hdataHavAdapter.getSelectors();
export const hylineEMoltDataSelectors = hdataEMoltAdapter.getSelectors();
export const hylineLMoltDataSelectors = hdataLMoltAdapter.getSelectors();


//Hyline TYPES
export interface HylineTypeState{
    hylineTypes:HylineType[],
    hylineTypesLoaded:boolean;
}

export const initialHylineTypeState: HylineTypeState = {
    hylineTypes: undefined,
    hylineTypesLoaded: false
}

export function hylineTypesReducer(state = initialHylineTypeState, action: HylineActions):HylineTypeState{
    switch(action.type){
        case HylineActionTypes.HYLINE_TYPES_LOADED:
            return {
                hylineTypesLoaded: true,
                hylineTypes: action.payload.hylineTypes
            };
        default:
            return state;
    }
}

//Hyline data TYPES
export interface HylineDataTypeState{
    hylineDataType:HylineDataType[],
    hylineDataTypeLoaded:boolean;
}

export const initialHylineDataTypeState: HylineDataTypeState = {
    hylineDataType: undefined,
    hylineDataTypeLoaded: false
}

export function hylineDataTypeReducer(state = initialHylineDataTypeState, action: HylineActions):HylineDataTypeState{
    switch(action.type){
        case HylineActionTypes.HYLINE_DATA_TYPES_LOADED:
            return {
                hylineDataTypeLoaded: true,
                hylineDataType: action.payload.hylineTypes
            };
        default:
            return state;
    }
}