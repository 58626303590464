import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-omega-list',
  templateUrl: './omega-list.component.html',
  styleUrls: ['./omega-list.component.scss']
})
export class OmegaListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
