import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../auth';
import {BASE_URL, API_PRODUCTION_URL} from '../../connection.data';
import {QueryParamsModel, QueryResultsModel} from '../../_base/crud';
import {HttpClientModule} from '@angular/common/http';
//import { Pavilion, Pavilioner } from '../_models/pavilion.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class PavilionHistoryService{

    constructor(private http: HttpClient){

    }
    get_history_pavilion_harvest_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_harvest_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_harvest_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_harvest_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_acc_harvest_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_acc_harvest_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_nourishment_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_nourishment_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_temperature_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_temperature_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_water_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_water_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_day(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_day?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_week(pavilion_id,starting_date,ending_date): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_week?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_history_pavilion_deads_generation(pavilion_id,starting_date,ending_date,generation_id): Observable<any[]>{
        const pavilionQuery = pavilion_id?`pavilion_id=${pavilion_id}`: '';
        const url = `${API_PRODUCTION_URL}/history_pavilion_deads_generation?${pavilionQuery}&starting_date=${starting_date}&ending_date=${ending_date}&generation_id=${generation_id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_production_realtime_total_bird_by_type(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_type`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
            //map((resp)=>resp)
        );
    }
    get_production_realtime_total_bird_by_pavilion(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_pavilion`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_plant_history_harvest(starting_date:string, ending_date:string): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_harvest_plant?starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_plant_history_water(starting_date:string, ending_date: string): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_water_plant?starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_plant_history_nourishment(starting_date:string, ending_date:string): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_nourishment_plant?starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_plant_history_deads(starting_date:string, ending_date:string): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_deads?starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_plant_history_temperature(starting_date:string, ending_date:string): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/history_temperature?starting_date=${starting_date}&ending_date=${ending_date}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_acc_deads(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_deads_accumulated?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_harvest(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_harvest?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        console.log(url);
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_acc_harvest(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_harvest_accumulated?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_water(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_water?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        console.log(url);
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_deads(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_deads?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        console.log(url);
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_temperature(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_temperature?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_history_pavilion_nourishment(pavilion_id:number, starting_date:string, ending_date:string, generation_id:number=undefined, bird_type_id:number=undefined): Observable<any[]>{
        var url = `${API_PRODUCTION_URL}/history_nourishment?starting_date=${starting_date}&ending_date=${ending_date}`;
        if(generation_id){
            url += `&generation_id=${generation_id}`;
        }
        if(pavilion_id){
            url += `&pavilion_id=${pavilion_id}`;
        }
        if(bird_type_id){
            url += `&bird_type_id=${bird_type_id}`;
        }
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_harvest_per_pavilion_in_period(st:Date, et:Date): Observable<any[]>{
        encodeURIComponent
        var url = `${API_PRODUCTION_URL}/harvest_per_pavilion_in_period?st=${encodeURIComponent(formatDate(st))}&et=${encodeURIComponent(formatDate(et))}`;

        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    get_harvest_per_floor_in_period(st:Date, et:Date): Observable<any[]>{
        encodeURIComponent
        var url = `${API_PRODUCTION_URL}/harvest_per_floor_in_period?st=${encodeURIComponent(formatDate(st))}&et=${encodeURIComponent(formatDate(et))}`;

        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    /*getAll():Observable<Pavilion[]>{       
        const url = `${API_PAVILIONS_URL}/all`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    get_production_realtime_total_bird_by_pavilion(): Observable<any[]>{
        const url = `${API_PRODUCTION_URL}/production_realtime_total_bird_by_pavilion`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }*/

    update_pavilion_history(date:Date, pavilionId:number, operationList:string[]){
        var url = `${API_PRODUCTION_URL}/update_pavilion_history`;

        return this.http.put<any>(url, {
            date: formatDay(date),
            pavilion_id: pavilionId,
            operation_type: operationList
        }).pipe(
            map((resp)=>resp.status=="success")
        );
    }
}

function formatDate(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    const hours = numberTo2DigitsString(date.getHours());
    const minutes = numberTo2DigitsString(date.getMinutes());
    const seconds = numberTo2DigitsString(date.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function formatDay(date:Date){
    const year = date.getFullYear();
    const month = numberTo2DigitsString(date.getMonth() +1);
    const day = numberTo2DigitsString(date.getDate());
    return `${year}-${month}-${day}`;
}

function numberTo2DigitsString(number){
    return `${number<10?0:''}${number}`;
}