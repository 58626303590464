import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BirdStockActionTypes, BSAddMov, BSAddTransfer, BSBirdGensLoaded, BSBirdStatusTypesLoaded, BSBirdTypesLoaded, BSBreedingDeleted, BSBreedingLoaded, BSBreedingPageLoaded, BSBreedingUpdated, BSDeleteBreeding, BSDeleteMov, BSEditMov, BSFailure, BSGetBirdGens, BSGetBirdStatusTypes, BSGetBirdTypes, BSGetBreedingPage, BSGetMovsPage, BSGetPavGens, BSGetPavilionGens, BSLoadBreeding, BSMovAdded, BSMovDeleted, BSMovEdited, BSMovsPageLoaded, BSPavGensLoaded, BSSearchBirdGens, BSTransferAdded, BSUpdateBreeding } from '../_actions/bird-stock.actions';
import { BirdService } from '../_services/bird.service';
import { PavilionService } from '../_services/pavilion.service';


@Injectable()
export class BirdStockEffects {

    constructor(
        private actions: Actions,
        private birdService: BirdService,
        private router: Router,
        private pavilionService: PavilionService
    ) {}

    @Effect()
    getBreedingStockPage: Observable<any> = this.actions.pipe(
        ofType<BSGetBreedingPage>(BirdStockActionTypes.BS_GET_BREEDING_PAGE),
        map((action: BSGetBreedingPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getBreedingStockPage(payload.page).pipe(
                map(response=>{
                    console.log("get page bird stock");
                    console.log(JSON.stringify(payload));
                    return {breedingStocks: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBreedingPageLoaded(response);
        })
    );

    @Effect()
    loadNewBreedingStock: Observable<any> = this.actions.pipe(
        ofType<BSLoadBreeding>(BirdStockActionTypes.BS_LOAD_BREEDING),
        map((action: BSLoadBreeding) => action.payload),
        switchMap(payload => {
            return this.birdService.loadBreedingStock(payload.breedingStock);
        }),
        map((breedingStock)=>{ //Adecuarse a la response recibida de api real         
            console.log(JSON.stringify(breedingStock));
            return new BSBreedingLoaded({breedingStock: breedingStock});
        }),
        catchError(error=>{
            console.log("bird stock effect - catch error: ");console.dir(error);
            return of(new BSFailure(error))
        })
    );

    @Effect()
    updateBreedingStock: Observable<any> = this.actions.pipe(
        ofType<BSUpdateBreeding>(BirdStockActionTypes.BS_UPDATE_BREEDING),
        map((action: BSUpdateBreeding) => action.payload),
        switchMap(payload => {
            return this.birdService.updateBreedingStock(payload.breedingStock).pipe(
                map((updated)=> {return {updated: updated, breedingStock: payload.breedingStock, partialBreedingStock:payload.partialBreedingStock}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            return new BSBreedingUpdated(resp);
        })
    );

    @Effect()
    getBirdStockMovsPage: Observable<any> = this.actions.pipe(
        ofType<BSGetMovsPage>(BirdStockActionTypes.BS_GET_MOVS_PAGE),
        map((action: BSGetMovsPage) => action.payload),
        switchMap(payload => {
            return this.birdService.getStockMovsPage(payload.page).pipe(
                map(response=>{
                    console.log("get page bird stock movements payload:");
                    console.dir(payload);
                    return {birdStockMovs: response.items, page: payload.page, totalCount: response.totalCount};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log("bird stock movs page: "); console.dir(response);
            return new BSMovsPageLoaded(response);
        })
    );

    @Effect()
    addStockMovement: Observable<any> = this.actions.pipe(
        ofType<BSAddMov>(BirdStockActionTypes.BS_ADD_MOV),
        map((action: BSAddMov) => action.payload),
        switchMap(payload => {
            return this.birdService.addStockMov(payload.birdStockMov);
        }),
        map((birdStockMov)=>{ //Adecuarse a la response recibida de api real         
            console.log(JSON.stringify(birdStockMov));
            return new BSMovAdded({birdStockMov: birdStockMov});
        })
    );

    @Effect()
    updateStockMovement: Observable<any> = this.actions.pipe(
        ofType<BSEditMov>(BirdStockActionTypes.BS_EDIT_MOV),
        map((action: BSEditMov) => action.payload),
        switchMap(payload => {
            return this.birdService.updateStockMov(payload.birdStockMov).pipe(
                map((updated)=> {return {updated: updated, birdStockMov: payload.birdStockMov, partialBirdStockMov:payload.partialBirdStockMov}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            return new BSMovEdited(resp);
        })
    );

    @Effect()
    deleteStockMovement: Observable<any> = this.actions.pipe(
        ofType<BSDeleteMov>(BirdStockActionTypes.BS_DELETE_MOV),
        map((action: BSDeleteMov) => action.payload),
        switchMap(payload => {
            return this.birdService.deleteStockMov(payload.birdStockMov).pipe(
                map((deleted)=> {return {deleted: deleted, birdStockMov: payload.birdStockMov}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            return new BSMovDeleted(resp);
        })
    );

    @Effect()
    deleteBreedingStock: Observable<any> = this.actions.pipe(
        ofType<BSDeleteBreeding>(BirdStockActionTypes.BS_DELETE_BREEDING),
        map((action: BSDeleteBreeding) => action.payload),
        switchMap(payload => {
            return this.birdService.deleteBreedingStock(payload.breedingStock).pipe(
                map((deleted)=> {return {deleted: deleted, breedingStock: payload.breedingStock}})
            );
        }),
        map((resp)=>{ //Adecuarse a la response recibida de api real
            return new BSBreedingDeleted(resp);
        })
    );

    @Effect()
    getBirdTypes: Observable<any> = this.actions.pipe(
        ofType<BSGetBirdTypes>(BirdStockActionTypes.BS_GET_BIRD_TYPES),
        map((action: BSGetBirdTypes) => action.payload),
        switchMap(payload => {
            return this.birdService.getTypes().pipe(
                map(response=>{
                    console.log("get bird types");
                    console.log(JSON.stringify(payload));
                    return {birdTypes: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdTypesLoaded(response);
        })
    );

    @Effect()
    getBirdStatusTypes: Observable<any> = this.actions.pipe(
        ofType<BSGetBirdStatusTypes>(BirdStockActionTypes.BS_GET_BIRD_STATUS_TYPES),
        map((action: BSGetBirdStatusTypes) => action.payload),
        switchMap(payload => {
            return this.birdService.getStatusTypes().pipe(
                map(response=>{
                    console.log("get bird status types");
                    console.log(JSON.stringify(payload));
                    return {birdStatusTypes: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdStatusTypesLoaded(response);
        })
    );

    @Effect()
    getBreedingBirdGens: Observable<any> = this.actions.pipe(
        ofType<BSGetPavGens>(BirdStockActionTypes.BS_GET_PAV_GENS),
        map((action: BSGetPavGens) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getBreedingGenerations(payload.pavilion).pipe(
                map(response=>{
                    console.log("get breeding bird gens");
                    console.log(JSON.stringify(payload));
                    return {birdGens: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdGensLoaded(response);
        })
    );

    @Effect()
    getPavilionBirdGens: Observable<any> = this.actions.pipe(
        ofType<BSGetPavilionGens>(BirdStockActionTypes.BS_GET_PAVILION_GENS),
        map((action: BSGetPavilionGens) => action.payload),
        switchMap(payload => {
            return this.pavilionService.getPavilionGenerations(payload.pavilion).pipe(
                map(response=>{
                    console.log("get pavilion bird gens");
                    console.log(JSON.stringify(payload));
                    return response;
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdGensLoaded(response);
        })
    );

    @Effect()
    getBirdGens: Observable<any> = this.actions.pipe(
        ofType<BSGetBirdGens>(BirdStockActionTypes.BS_GET_BIRD_GENS),
        map((action: BSGetBirdGens) => action.payload),
        switchMap(payload => {
            return this.birdService.getGenerations().pipe(
                map(response=>{
                    console.log("get bird gens");
                    console.log(JSON.stringify(payload));
                    return {birdGens: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdGensLoaded(response);
        })
    );

    @Effect()
    searchBirdGens: Observable<any> = this.actions.pipe(
        ofType<BSSearchBirdGens>(BirdStockActionTypes.BS_SEARCH_BIRD_GENS),
        map((action: BSSearchBirdGens) => action.payload),
        switchMap(payload => {
            return this.birdService.searchGenerations(payload.name, payload.limit).pipe(
                map(response=>{
                    console.log("search bird gens");
                    console.log(JSON.stringify(payload));
                    return {birdGens: response};
                })
            );
        }),
        map((response)=>{ //Adecuarse a la response recibida de api real            
            console.log(JSON.stringify(response));
            return new BSBirdGensLoaded(response);
        })
    );


    // @Effect()
    // pavLoadBirds: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_LOAD_BIRDS),
    //     map((action: BSPavLoadBirds) => action.payload),
    //     switchMap(payload => {
    //         return this.birdStockService.loadBirds(payload.birdStockMov).pipe(
    //             map((response)=>{ //Adecuarse a la response recibida de api real
    //                 console.log("load birds");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSPavBirdsLoaded({birdStockMov: payload.birdStockMov});
    //             })
    //         );
    //     })  
    // );

    // @Effect()
    // pavEditLoad: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_EDIT_LOAD),
    //     map((action: BSPavEditLoad) => action.payload),
    //     switchMap(payload => {
    //         return this.birdStockService.editBirdsLoad(payload.birdStockMov).pipe(
    //             map((response)=>{ //Adecuarse a la response recibida de api real
    //                 console.log("edit birds load");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSPavLoadEdited({partialBSM: payload.birdStockMov});
    //             })
    //         );
    //     })  
    // );

    // @Effect()
    // pavDeleteLoad: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_DELETE_LOAD),
    //     map((action: BSPavDeleteLoad) => action.payload),
    //     switchMap(payload => {
    //         return this.birdStockService.deleteBirdsLoad(payload.birdStockMov).pipe(
    //             map((response)=>{ //Adecuarse a la response recibida de api real
    //                 console.log("delete birds load");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSPavLoadDeleted({id: payload.birdStockMov.id});
    //             })
    //         );
    //     })  
    // );

    // @Effect()
    // pavLoadLoadPage: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_LOAD_LOAD_PAGE),
    //     map((action: BSPavLoadLoadPage) => action.payload),
    //     switchMap(payload => {
    //         return this.birdStockService.getPage(payload.page).pipe(
    //             map((birdStockMovs)=>{ //Adecuarse a la response recibida de api real
    //                 console.log("get page birds load");
    //                 console.log(JSON.stringify(payload.page));
    //                 return new BSPavLoadPageLoaded({birdStockMovs: birdStockMovs, page: payload.page});
    //             })
    //         );
    //     })  
    // );

    // @Effect()
    // breLoadBirds: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_BIRDS),
    //     map((action:BSBreLoadBirds)=>action.payload),
    //     switchMap(payload=>{
    //         return this.birdStockService.loadBirds(payload.birdStockMov).pipe(
    //             map((response)=>{
    //                 console.log("breeding load birds");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSBreBirdsLoaded({birdStockMov: payload.birdStockMov});
    //             })
    //         )
    //     })
    // );

    // @Effect()
    // breEditLoad: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_EDIT_LOAD),
    //     map((action:BSBreEditLoad)=>action.payload),
    //     switchMap(payload=>{
    //         return this.birdStockService.editBirdsLoad(payload.birdStockMov).pipe(
    //             map((response)=>{
    //                 console.log("breeding edit birds load");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSBreLoadEdited({partialBSM: payload.birdStockMov});
    //             })
    //         )
    //     })
    // );

    // @Effect()
    // breDeleteLoad: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_BIRDS),
    //     map((action:BSBreDeleteLoad)=>action.payload),
    //     switchMap(payload=>{
    //         return this.birdStockService.deleteBirdsLoad(payload.birdStockMov).pipe(
    //             map((response)=>{
    //                 console.log("breeding delete birds load");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSBreLoadDeleted({id: payload.birdStockMov.id});
    //             })
    //         )
    //     })
    // );

    // @Effect()
    // breLoadLoadPage: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_LOAD_PAGE),
    //     map((action:BSBreLoadLoadPage)=>action.payload),
    //     switchMap(payload=>{
    //         return this.birdStockService.loadBirds(payload.birdStockMov).pipe(
    //             map((birdStockMovs)=>{
    //                 console.log("breeding load load page");
    //                 console.log(JSON.stringify(payload.birdStockMov));
    //                 return new BSBreLoadPageLoaded({birdStockMovs: birdStockMovs});
    //             })
    //         )
    //     })
    // );

    @Effect()
    addTransfer: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_ADD_TRANSFER),
        map((action:BSAddTransfer)=>action.payload),
        switchMap(payload=>{
            return this.birdService.transfer(payload.pavilion, payload.birdStockMov).pipe(
                map((response)=>{
                    console.log("transfer birds to pavilion");
                    console.log(JSON.stringify(payload));
                    return new BSTransferAdded({});
                })
            )
        })
    );
    
    // @Effect({dispatch:false})
    // pavBirdsLoaded: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_BIRDS_LOADED),
    //     tap((action:BSPavBirdsLoaded)=>console.log("pavilion birds loaded"))
    // );

    // @Effect({dispatch:false})
    // pavLoadEdited: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_LOAD_EDITED),
    //     tap((action:BSPavLoadEdited)=>console.log("pavilion birds load edited"))
    // );

    // @Effect({dispatch:false})
    // pavLoadDeleted: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_LOAD_DELETED),
    //     tap((action:BSPavLoadDeleted)=>console.log("pavilion birds load deleted"))
    // );

    // @Effect({dispatch:false})
    // pavLoadPageLoaded: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_PAV_LOAD_PAGE_LOADED),
    //     tap((action:BSPavLoadPageLoaded)=>console.log("pavilion birds load page loaded"))
    // );

    // @Effect({dispatch:false})
    // breBirdsLoaded: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_BIRDS_LOADED),
    //     tap((action:BSBreBirdsLoaded)=>console.log("breeding birds loaded"))
    // );

    // @Effect({dispatch:false})
    // breLoadEdited: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_EDITED),
    //     tap((action:BSBreLoadEdited)=>console.log("breeding birds load edited"))
    // );

    // @Effect({dispatch:false})
    // breLoadDeleted: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_DELETED),
    //     tap((action:BSBreLoadDeleted)=>console.log("breeding birds load deleted"))
    // );

    // @Effect({dispatch:false})
    // breLoadPageLoaded: Observable<any> = this.actions.pipe(
    //     ofType(BirdStockActionTypes.BS_BRE_LOAD_PAGE_LOADED),
    //     tap((action:BSBreLoadPageLoaded)=>console.log("breeding birds load page loaded"))
    // );

    @Effect({dispatch:false})
    transferAdded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_TRANSFER_ADDED),
        tap((action:BSTransferAdded)=>console.log("birds transfer deleted"))
    );

    @Effect({dispatch:false})
    pageLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BREEDING_PAGE_LOADED),
        tap((action:BSBreedingPageLoaded)=>console.log("bird breeding stock page loaded"))
    );

    @Effect({dispatch:false})
    birdTypesLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BIRD_TYPES_LOADED),
        tap((action:BSBirdTypesLoaded)=>console.log("bird types loaded"))
    );

    @Effect({dispatch:false})
    birdStatusTypesLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BIRD_STATUS_TYPES_LOADED),
        tap((action:BSBirdStatusTypesLoaded)=>console.log("bird status types loaded"))
    );

    @Effect({dispatch:false})
    birdGensLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BIRD_GENS_LOADED),
        tap((action:BSBirdGensLoaded)=>console.log("bird gens loaded"))
    );

    @Effect({dispatch:false})
    pavilionBirdGensLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_PAV_GENS_LOADED),
        tap((action:BSPavGensLoaded)=>console.log("pavilion bird gens loaded"))
    );

    @Effect({dispatch:false})
    newBreedingStockLoaded: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BREEDING_LOADED),
        tap((action:BSBreedingLoaded)=>console.log("new breeding stock loaded (?)"))
    );
    
    @Effect({dispatch:false})
    breedingStockUpdated: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BREEDING_UPDATED),
        tap((action:BSBreedingUpdated)=>console.log("breeding stock updated"))
    );

    @Effect({dispatch:false})
    breedingStockDeleted: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_BREEDING_DELETED),
        tap((action:BSBreedingDeleted)=>console.log("breeding stock deleted"))
    );

    @Effect({dispatch:false})
    stockMovementDeleted: Observable<any> = this.actions.pipe(
        ofType(BirdStockActionTypes.BS_MOV_DELETED),
        tap((action:BSMovDeleted)=>console.log("bird stock movement deleted"))
    );
}