// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},

	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'user-management',
				loadChildren: () => import('./views/pages/user-management/user-management.module').then(m => m.UserManagementModule)
			},
			{
				path: 'production',
				loadChildren: () => import('./views/pages/production/production.module').then(m => m.ProductionModule)
			},
			{
				path: 'breeding',
				loadChildren: () => import('./views/pages/breeding/breeding.module').then(m => m.BreedingModule)
			},
			{
				path: 'nourishment',
				loadChildren: () => import('./views/pages/nourishment/nourishment.module').then(m => m.NourishmentModule)
			},
			{
				path: 'velocity',
				loadChildren: () => import('./views/pages/velocity/velocity.module').then(m => m.VelocityModule)
			},
			{
				path: 'online-harvest',
				loadChildren: () => import('./views/pages/online-harvest/online-harvest.module').then(m => m.OnlineHarvestModule)
			},
			{
				path: 'guanocontrol',
				loadChildren: () => import('./views/pages/guanocontrol/guanocontrol.module').then(m => m.GuanocontrolModule)
			},
			{
				path: 'alarms',
				loadChildren: () => import('./views/pages/alarms/alarms.module').then(m => m.AlarmsModule)
			},
			{
				path: 'bird-type',
				loadChildren: () => import('./views/pages/bird-type/bird-type.module').then(m => m.BirdTypeModule)
			},
			{
				path: 'plant-configuration',
				loadChildren: () => import('./views/pages/plant-configuration/plant-configuration.module').then(m => m.PlantConfigurationModule)
			},
			{
				path: 'reports',
				loadChildren: () => import('./views/pages/reports/reports.module').then(m => m.ReportsModule)
			},
			{
				path: 'error/403',
				component: ErrorPageComponent,
				data: {
					type: 'error-v6',
					code: 403,
					title: '403... Access forbidden',
					desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
				}
			},
			{path: 'error/:type', component: ErrorPageComponent},
			{path: '', redirectTo: 'production', pathMatch: 'full'},
			{path: '**', redirectTo: 'production', pathMatch: 'full'}
		]
	},

	{path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
