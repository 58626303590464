// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import { GrainStockActions, GrainStockActionTypes, GrainTypeActions, GrainTypeActionTypes } from '../_actions/grain-stock.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { GrainStock, GrainType } from '../_models/grain-stock.model';

// tslint:disable-next-line:no-empty-interface
export interface GrainTypeState extends EntityState<GrainType> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGrainTypeId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<GrainType> = createEntityAdapter<GrainType>();

export const initialGrainTypeState: GrainTypeState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedGrainTypeId: undefined,
    showInitWaitingMessage: true
});

export function grainTypesReducer(state = initialGrainTypeState, action: GrainTypeActions): GrainTypeState {
    switch  (action.type) {
        case GrainTypeActionTypes.GT_ADDED: return adapter.addOne(action.payload.grainType, {
            ...state, lastCreatedGrainTypeId: action.payload.grainType.id
        });
        case GrainTypeActionTypes.GT_EDITED: return adapter.updateOne(action.payload.partialGrainType, state);
        case GrainTypeActionTypes.GT_PAGE_LOADED: return adapter.addMany(action.payload.grainTypes, {
            ...initialGrainTypeState,
            totalCount: action.payload.totalCount,
            lastQuery: action.payload.page,
            listLoading: false,
            showInitWaitingMessage: false
        });
        case GrainTypeActionTypes.GT_ALL_LOADED:
            return adapter.addMany(action.payload.grainTypes, {
                ...initialGrainTypeState,
                listLoading: false,
                showInitWaitingMessage: false
            });
        case GrainTypeActionTypes.GT_DELETED: return adapter.removeOne(action.payload.id, state);

        default: return state;
    }
}

//GrainStock Load
export interface GrainStockLoadState extends EntityState<GrainStock> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGrainStockId: number;
    lastUpdatedGrainStockId: number;
    lastDeletedGrainStockId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const grainStockLoadAdapter: EntityAdapter<GrainStock> = createEntityAdapter<GrainStock>();

export const initialGrainStockLoadState: GrainStockLoadState = grainStockLoadAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGrainStockId: -1,
    lastUpdatedGrainStockId: -1,
    lastDeletedGrainStockId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function grainStockLoadReducer(state = initialGrainStockLoadState, action: GrainStockActions): GrainStockLoadState {
    switch  (action.type) {
        case GrainStockActionTypes.GS_STOCK_LOAD_PAGE_LOADED:
            console.log("grainStock load reducer page loaded")
            console.log(JSON.stringify(action));
            return grainStockLoadAdapter.addMany(action.payload.grainStocks, {
                    ...initialGrainStockLoadState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        case GrainStockActionTypes.GS_STOCK_LOADED:
            console.log("grainStock load reducer... added.. action: "+JSON.stringify(action));
            if(action.payload.added && action.payload.grainStock.id>0){
                return grainStockLoadAdapter.addOne(action.payload.grainStock, {
                        ...state, lastCreatedGrainStockId: action.payload.grainStock.id
                    });
            }
            return {...state, lastCreatedGrainStockId: -1}
        case GrainStockActionTypes.GS_STOCK_LOAD_EDITED:
            console.log("reducer grainStock load edited")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return grainStockLoadAdapter.updateOne(action.payload.partialGrainStock, 
                    {...state, lastUpdatedGrainStockId: action.payload.grainStock.id});
            return {...state, lastUpdatedGrainStockId: -1}
        case GrainStockActionTypes.GS_STOCK_LOAD_DELETED:
            if(action.payload.deleted){
                return grainStockLoadAdapter.removeOne(action.payload.grainStock.id, 
                    {...state, lastDeletedGrainStockId: action.payload.grainStock.id});
            }
            return {...state, lastDeletedGrainStockId: -1}
        default: return state;
    }
}

//GrainStock Consumption
export interface GrainStockConsumptionState extends EntityState<GrainStock> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGrainStockId: number;
    lastUpdatedGrainStockId: number;
    lastDeletedGrainStockId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const grainStockConsumptionAdapter: EntityAdapter<GrainStock> = createEntityAdapter<GrainStock>();

export const initialGrainStockConsumptionState: GrainStockConsumptionState = grainStockConsumptionAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGrainStockId: -1,
    lastUpdatedGrainStockId: -1,
    lastDeletedGrainStockId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function grainStockConsumptionReducer(state = initialGrainStockConsumptionState, action: GrainStockActions): GrainStockConsumptionState {
    switch  (action.type) {
        case GrainStockActionTypes.GS_STOCK_CONSUMPTION_PAGE_LOADED:
            console.log("grainStock consumption reducer page loaded")
            console.log(JSON.stringify(action));
            return grainStockConsumptionAdapter.addMany(action.payload.grainStocks, {
                    ...initialGrainStockConsumptionState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        case GrainStockActionTypes.GS_STOCK_CONSUMED:
            console.log("grainStock consumption reducer... added.. action: "+JSON.stringify(action));
            if(action.payload.added && action.payload.grainStock.id>0){
                return grainStockConsumptionAdapter.addOne(action.payload.grainStock, {
                        ...state, lastCreatedGrainStockId: action.payload.grainStock.id
                    });
            }
            return {...state, lastCreatedGrainStockId: -1}
        case GrainStockActionTypes.GS_STOCK_CONSUMPTION_EDITED:
            console.log("reducer grainStock consumption edited")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return grainStockConsumptionAdapter.updateOne(action.payload.partialGrainStock, 
                    {...state, lastUpdatedGrainStockId: action.payload.grainStock.id});
            return {...state, lastUpdatedGrainStockId: -1}
        case GrainStockActionTypes.GS_STOCK_CONSUMPTION_DELETED:
            if(action.payload.deleted){
                return grainStockConsumptionAdapter.removeOne(action.payload.grainStock.id, 
                    {...state, lastDeletedGrainStockId: action.payload.grainStock.id});
            }
            return {...state, lastDeletedGrainStockId: -1}
        default: return state;
    }
}

//GrainStock Inventory
export interface GrainStockInventoryState extends EntityState<GrainStock> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedGrainStockId: number;
    lastUpdatedGrainStockId: number;
    lastDeletedGrainStockId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const grainStockInventoryAdapter: EntityAdapter<GrainStock> = createEntityAdapter<GrainStock>();

export const initialGrainStockInventoryState: GrainStockInventoryState = grainStockInventoryAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedGrainStockId: -1,
    lastUpdatedGrainStockId: -1,
    lastDeletedGrainStockId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function grainStockInventoryReducer(state = initialGrainStockInventoryState, action: GrainStockActions): GrainStockInventoryState {
    switch  (action.type) {
        case GrainStockActionTypes.GS_STOCK_INVENTORY_PAGE_LOADED:
            console.log("grainStock inventory reducer page loaded")
            console.log(JSON.stringify(action));
            return grainStockInventoryAdapter.addMany(action.payload.grainStocks, {
                    ...initialGrainStockInventoryState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
        default: return state;
    }
}

export const getGrainTypeState = createFeatureSelector<GrainTypeState>('grainTypes');
export const getGrainStockLoadState = createFeatureSelector<GrainStockLoadState>('grainStockLoads');
export const getGrainStockConsumptionState = createFeatureSelector<GrainStockConsumptionState>('grainStockConsumptions');
export const getGrainStockInventoryState = createFeatureSelector<GrainStockInventoryState>('grainStockInventorys');

export const grainTypeSelectors = adapter.getSelectors();
export const grainStockLoadSelectors = grainStockLoadAdapter.getSelectors();
export const grainStockConsumptionSelectors = grainStockConsumptionAdapter.getSelectors();
export const grainStockInventorySelectors = grainStockInventoryAdapter.getSelectors();
