import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';

@Injectable()
export class BasicService{
    baseUrl:string = '';
    
    constructor(protected http: HttpClient){

    }

    getAll():Observable<any[]>{
        const url = `${this.baseUrl}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getById(itemId: number):Observable<any>{
        const url = `${this.baseUrl}/${itemId}`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows[0];
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${this.baseUrl}/page`;
        console.log(url);
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    add(item:any):Observable<number>{
        return this.http.post<any>(this.baseUrl, item).pipe(
            map((response)=> response.id)
        );
    }

    edit(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/${item.id}`, item).pipe(
            map((response)=>response.status)
        );
    }

    delete(item:any):Observable<string>{
        return this.http.delete<any>(`${this.baseUrl}/${item.id}`).pipe(
            map((response)=>response.status)
        );
    }
}