// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Input, Output, EventEmitter, ContentChild, TemplateRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
// Material
import { MatDialog } from '@angular/material';
// RxJS
import { Observable, BehaviorSubject, Subscription, of, combineLatest, Subject, merge } from 'rxjs';
import { map, startWith, delay, first, distinctUntilChanged, filter, tap, debounceTime, switchMap, take } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
import { Dictionary, Update } from '@ngrx/entity';
// import { AppState } from '../../../../../core/reducers';
// Layout
import { SubheaderService, LayoutConfigService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from '../../../../../core/_base/crud';
// import { Item } from '../../../../../../../core/plant-configuration';
import { User } from '../../../../../core/auth';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../../../../core/reducers';

@Component({
  selector: 'kt-simple-edit',
  templateUrl: './simple-edit.component.html',
  styleUrls: ['./simple-edit.component.scss']
})
export class SimpleEditComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('wizard', {static: true}) el: ElementRef;
	@Input() summaryTemplate: TemplateRef<any>;

    @Input() name: string;
    @Input() pluralName: string;
    @Input() route: string;
    @Input() itemObs: Subject<any>;
    @Input() addObs: Subject<any>;
    @Input() editObs: Subject<any>;
    @Input() itemForm: FormGroup;
	@Input() stepList: any[];
	@Input() data: any = {};
    @Output() editEvent = new EventEmitter<any>();
    @Output() addEvent = new EventEmitter<any>();
    @Output() getEvent = new EventEmitter<any>();


	// Public properties
	item: any;
	itemId$: Observable<number>;
	oldItem: any;
	selectedTab = 0;
	loadingSubject = new BehaviorSubject<boolean>(true);
	loading$: Observable<boolean>;
	// itemForm: FormGroup;
	hasFormErrors = false;
	// itemsDataSource: ItemsDataSource;
	itemsResult: any[] = [];
    
	private subscriptions: Subscription[] = [];
    private user:User = new User();

	// Private password
	private componentSubscriptions: Subscription;
	// sticky portlet header margin
	private headerMargin: number;

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 * @param activatedRoute: ActivatedRoute
	 * @param router: Router
	 * @param typesUtilsService: TypesUtilsService
	 * @param formBuilder: FormBuilder
	 * @param dialog: MatDialog
	 * @param subheaderService: SubheaderService
	 * @param layoutUtilsService: SubheaderService
	 * @param layoutConfigService: LayoutConfigService
	 * @param cdr: ChangeDetectorRef
	 */
	constructor(
		private store: Store<AppState>,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private typesUtilsService: TypesUtilsService,
		private formBuilder: FormBuilder,
		public dialog: MatDialog,
		private subheaderService: SubheaderService,
		private layoutUtilsService: LayoutUtilsService,
		private layoutConfigService: LayoutConfigService,
		private cdr: ChangeDetectorRef,
		) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {

		this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("item edit component: usuario no logueado");
		});

		this.loading$ = this.loadingSubject.asObservable();
		this.loadingSubject.next(true);

		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			if (id && id > 0) {
				this.getEvent.emit(id);
				const sub = this.itemObs.subscribe((item)=>{
					if(item){
                        this.item = item;
                        this.loadItem(item);
                    }
				});
				this.subscriptions.push(sub);
			} else {
                var item:any = {};
                this.loadItem(item);
			}
		});
        // this.loadItems();
        
		
		this.subscriptions.push(authSubscription);
	}

	ngAfterViewInit(){
		// sticky portlet header
		window.onload = () => {
			const style = getComputedStyle(document.getElementById('kt_header'));
			this.headerMargin = parseInt(style.height, 0);
		};
		const wizard = new KTWizard(this.el.nativeElement, {
			startStep: 1
		});

		// Validation before going to next page
		wizard.on('beforeNext', (wizardObj) => {
			// https://angular.io/guide/forms
			// https://angular.io/guide/form-validation

			// validate the form and use below function to stop the wizard's step
			// wizardObj.stop();
		});

		// Change event
		wizard.on('change', () => {
			setTimeout(() => {
				KTUtil.scrollTop();
			}, 500);
		});
	}


	loadItem(item: any, fromService: boolean = false) {
		if (!item) {
			this.goBack('');
		}
		console.log("loading item: "+JSON.stringify(item));
		this.item = Object.assign({}, item);
		console.log("item loaded: "+JSON.stringify(this.item));
		this.itemId$ = of(item.id);
		this.oldItem = Object.assign({}, item);

		this.initItem();
		
		if (fromService) {
			this.cdr.detectChanges();
		}
  	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();			
		}
		this.subscriptions.forEach((sub)=>sub.unsubscribe());
	}

	/**
	 * Init item
	 */
	initItem() {
		// console.log("create form");
		this.createForm();
		this.loadingSubject.next(false);
		if (!this.item.id) {
			this.subheaderService.setBreadcrumbs([
				{ title: `Listado de ${this.pluralName}`, page: `${this.route}` },
				{ title: `Crear ${this.name}`, page: `${this.route}/add` }
			]);
			return;
		}
		this.subheaderService.setTitle(`Crear ${this.name}`);
		this.subheaderService.setBreadcrumbs([
			{ title: `Listado de ${this.name}`, page: `${this.route}` },
			{ title: `Editar ${this.name}`, page: `${this.route}/edit`, queryParams: { id: this.item.id } }
		]);
	}

	/**
	 * Create form
	 */
	createForm() {
		// console.log("creating item form");
		// console.log(JSON.stringify(this.item));
		// this.itemForm = this.formBuilder.group(this.itemFormOptions);
		// console.log(`itemFormOptions: ${JSON.stringify(this.itemFormOptions)}`);
		// const controls = this.itemForm.controls;
		// const nameSub = controls.name.valueChanges.subscribe(x=>{
		// 	this.item.name = x;
        // });
        // const iiotSub = controls.iiot_code.valueChanges.subscribe(x=>{
		// 	this.item.iiot_code = x;
		// });
		// const velSub = controls.velMax.valueChanges.subscribe(x=>{
		// 	this.item.vel_max = x;
		// });
		// const dataSub = controls.dataConversion.valueChanges.subscribe(x=>{
		// 	this.item.data_conversion = x;
		// });
		// const posSub = controls.pos.valueChanges.subscribe(x=>{
		// 	this.item.vel_max = x;
		// });
		// this.subscriptions.push(nameSub);
		// this.subscriptions.push(iiotSub);
		// this.subscriptions.push(velSub);
		// this.subscriptions.push(dataSub);
		// this.subscriptions.push(posSub);
		
		Object.keys(this.item).forEach(key=>{
			if(this.itemForm.controls[key] && this.item[key]){
				this.itemForm.controls[key].patchValue(this.item[key]);				
			}
		});
		Object.keys(this.itemForm.controls).forEach(key=>{
			this.subscriptions.push(
				this.itemForm.controls[key].valueChanges.subscribe(x=>{
					this.item[key] = x;
				})
			);
		})
		this.cdr.detectChanges();
	}

	/**
	 * Go back to the list
	 *
	 * @param id: any
	 */
	goBack(id) {
		this.loadingSubject.next(false);
		const url = `${this.route}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	goBackWithoutId() {
		this.router.navigateByUrl(`${this.route}`, { relativeTo: this.activatedRoute });
	}

	/**
	 * Refresh item
	 *
	 * @param isNew: boolean
	 * @param id: number
	 */
	refreshItem(isNew: boolean = false, id = 0) {
		this.loadingSubject.next(false);
		let url = this.router.url;
		if (!isNew) {
			this.router.navigate([url], { relativeTo: this.activatedRoute });
			return;
		}

		url = `${this.route}/edit/${id}`;
		this.router.navigateByUrl(url, { relativeTo: this.activatedRoute });
	}

	/**
	 * Reset
	 */
	reset() {
		this.item = Object.assign({}, this.oldItem);
		this.createForm();
		this.hasFormErrors = false;
		this.itemForm.markAsPristine();
		this.itemForm.markAsUntouched();
		this.itemForm.updateValueAndValidity();
	}

	/**
	 * Save data
	 *
	 * @param withBack: boolean
	 */
	onSumbit(withBack: boolean = false) {
		this.hasFormErrors = false;
		const controls = this.itemForm.controls;
		/** check form */
		if (this.itemForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			this.getFormValidationErrors();
			this.hasFormErrors = true;
			this.selectedTab = 0;
			return;
		}

		// tslint:disable-next-line:prefer-const
		let item = this.prepareData();

		if (item.id > 0) {
			this.updateItem(item, withBack);
			return;
		}

		this.addItem(item, true);
	}

	getFormValidationErrors() {
		Object.keys(this.itemForm.controls).forEach(key => {	  
			const controlErrors: ValidationErrors = this.itemForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
				});
			}
		});
	}

    //Debiese ir esto en el componente padre
	// /**
	//  * Returns object for saving
	//  */
	prepareData(): any {
		const controls 			= this.itemForm.controls;
		let item = Object.assign({}, this.item);
        for(let key of Object.keys(controls)){
            item[key] = controls[key].value;
        }
		return item;
	}

	/**
	 * Add Item
	 *
	 * @param item: Item
	 * @param withBack: boolean
	 */
	addItem(item:any, withBack:boolean = false) {
		this.loadingSubject.next(true);
		
		this.addEvent.emit(item);
		const addBreedingSub = this.addObs.pipe(
            delay(250),
            take(1),
		).subscribe(newId => {
			if (!newId || newId==-1 ) {
				// addBreedingSub.unsubscribe();
				return;
			}
            
			this.loadingSubject.next(false);
			if (withBack) {
				this.goBack(newId);
			} else {
				const message = `Máquina ha sido creada exitosamente.`;
				this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
				this.refreshItem(true, newId);
			}
			// addBreedingSub.unsubscribe();
		});
		this.subscriptions.push(addBreedingSub);
	}

	/**
	 * Update item
	 *
	 * @param item: Item
	 * @param withBack: boolean
	 */
	updateItem(item: any, withBack: boolean = false) {
		this.loadingSubject.next(true);

        this.editEvent.emit(item);
		const updateBreedingSub = this.editObs.pipe(
			delay(250),
			take(1)
		).subscribe((id) => { // Remove this line
			if (!id || id!=item.id) {
				// updateBreedingSub.unsubscribe();
				return;
			}
			
			if (withBack) {
				this.goBack(item.id);
			} else {
                if(id!=item.id){
                    const message = `Registro de ${this.name} no ha podido ser modificado.`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
                }else{
                    const message = `Registro de ${this.name} ha sido modificado exitosamente.`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
                }
				// this.refreshItem(false);
				this.goBackWithoutId();
			}
			// updateBreedingSub.unsubscribe();
		}); // Remove this line
		this.subscriptions.push(updateBreedingSub);
	}

	/**
	 * Returns component title
	 */
	getComponentTitle() {
		let result = `Crear ${this.name}`;
		if (!this.item || !this.item.id) {
			return result;
		}

		result = `Editar ${this.name}`;
		return result;
	}

	/**
	 * Close alert
	 *
	 * @param $event
	 */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}
}