// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { WaterConsumption} from '../_models/water-consumption.model';
import { WaterConsumptionActions, WaterConsumptionActionTypes } from '../_actions/water-consumption.actions';

//waterConsumption
export interface WaterConsumptionState extends EntityState<WaterConsumption> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedWaterConsumptionId: number;
    lastUpdatedWaterConsumptionId: number;
    lastDeletedWaterConsumptionId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}
export const waterConsumptionAdapter: EntityAdapter<WaterConsumption> = createEntityAdapter<WaterConsumption>();

export const initialWaterConsumptionState: WaterConsumptionState = waterConsumptionAdapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedWaterConsumptionId: -1,
    lastUpdatedWaterConsumptionId: -1,
    lastDeletedWaterConsumptionId: -1,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function waterConsumptionReducer(state = initialWaterConsumptionState, action: WaterConsumptionActions): WaterConsumptionState {
    switch  (action.type) {
        case WaterConsumptionActionTypes.WATER_CONSUMPTION_PAGE_LOADED:
            console.log("waterConsumption reducer load")
            console.log(JSON.stringify(action));
            try{
                return waterConsumptionAdapter.addMany(action.payload.waterConsumptions, {
                    ...initialWaterConsumptionState,
                    totalCount: action.payload.totalCount,
                    lastQuery: action.payload.page,
                    listLoading: false,
                    showInitWaitingMessage: false
                });
            }catch(error){
                console.log(error);
                return state;
            }
            
        case WaterConsumptionActionTypes.WATER_CONSUMPTION_ADDED:
            console.log("waterConsumption reducer... added.. action: "+JSON.stringify(action));
            if(action.payload.added && action.payload.waterConsumption.id>0){
                return waterConsumptionAdapter.addOne(action.payload.waterConsumption, {
                        ...state, lastCreatedWaterConsumptionId: action.payload.waterConsumption.id
                    });
            }
            return {...state, lastCreatedWaterConsumptionId: -1}
        case WaterConsumptionActionTypes.WATER_CONSUMPTION_EDITED:
            console.log("reducer waterConsumption edited")
            console.log(JSON.stringify(action));
            if(action.payload.updated)
                return waterConsumptionAdapter.updateOne(action.payload.partialWaterConsumption, 
                    {...state, lastUpdatedWaterConsumptionId: action.payload.waterConsumption.id});
            return {...state, lastUpdatedWaterConsumptionId: -1}
        case WaterConsumptionActionTypes.WATER_CONSUMPTION_DELETED:
            if(action.payload.deleted){
                return waterConsumptionAdapter.removeOne(action.payload.waterConsumption.id, 
                    {...state, lastDeletedWaterConsumptionId: action.payload.waterConsumption.id});
            }
            return {...state, lastDeletedWaterConsumptionId: -1}
        default: return state;
    }
}

export const getWaterConsumptionState = createFeatureSelector<WaterConsumptionState>('waterConsumptions');
export const waterConsumptionSelectors = waterConsumptionAdapter.getSelectors();