import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
import { ManualService, Manual, PavilionService, Pavilion, ApkService, SiloService, Silo} from '../../../../../core/plant-configuration';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../core/_base/layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../../../core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { PavilionHistoryService } from '../../../../../core/production';

@Component({
  selector: 'kt-manual-edit',
  templateUrl: './manual-edit.component.html',
  styleUrls: ['./manual-edit.component.scss']
})
export class ManualEditComponent implements OnInit, OnDestroy, AfterViewInit {
    itemObs = new Subject<Manual[]>();
    editObs = new Subject<number>();
    addObs = new Subject<number>();
    isBreeding = false;
    private itemPavilionFormOptions = {
        pavilion_id: [{value: -1, disabled: true}, Validators.required],
        m_harvest: [0, Validators.required],
        r_grain_regular: [0, Validators.required],
        r_grain_omega: [0, Validators.required],
        water_amount: [0, Validators.required],
        c_grain_regular: [0, Validators.required],
        c_grain_omega: [0, Validators.required],
        c_dead_bird_b: [0, Validators.required],
        c_dead_bird_w: [0, Validators.required],
    };
    private itemBreedingFormOptions = {
        pavilion_id: [{value: -1, disabled: true}, Validators.required],
        water_amount: [0, Validators.required],
        c_grain: [0, Validators.required],
        c_dead_bird: [0, Validators.required],
        t_min: [0, Validators.required],
        t_max: [0, Validators.required],
        avg_weight: [0, Validators.required],
        p_uni_weight: [0, Validators.required],
    };
    private pavilionList:any[] = [];
    itemPavilionForm = this.formBuilder.group(this.itemPavilionFormOptions);
    itemBreedingForm = this.formBuilder.group(this.itemBreedingFormOptions);

    dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    pavilionChanged$: BehaviorSubject<number> = new BehaviorSubject(-1);
    private pavilionStepList: any[] = [
        {icon: "flaticon-interface-7", title: "Define el pabellón", subTitle: "Selecciona el pabellón", form: [
            [
                {name: "pavilion_id", label: "Pabellón", type: "list", optionList: [
                    {value: -1, text: 'Seleccione un pabellón'}
                ]},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros de producción", subTitle: "Ingrese parámetros de producción", form: [
            [
                {name: "m_harvest", label: "Cosecha manual", type: "number", min: 0},
                {name: "r_grain_regular", label: "Recepción de grano regular", type: "number", min: 0},
                
            ],[
                {name: "r_grain_omega", label: "Recepción de grano omega", type: "number", min: 0},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros de insumo", subTitle: "Ingrese parámetros de insumo", form: [
            [
                {name: "water_amount", label: "Medidor de agua", type: "number", min: 0},
                {name: "c_grain_regular", label: "Consumo de grano regular", type: "number", min: 0},
                
            ],[
                {name: "c_grain_omega", label: "Consumo de grano omega", type: "number", min: 0},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Cantidad de muertes", subTitle: "Ingrese las muertes de aves", form: [
            [
                {name: "c_dead_bird_b", label: "Muertes brown", type: "number", min: 0},
                {name: "c_dead_bird_w", label: "Muertes white", type: "number", min: 0},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Control de guano", subTitle: "Defina los niveles de control de guano", form: [
            [

            ]
        ]},
    ];
    private breedingStepList: any[] = [
        {icon: "flaticon-interface-7", title: "Define el pabellón", subTitle: "Selecciona el pabellón", form: [
            [
                {name: "pavilion_id", label: "Pabellón", type: "list", optionList: [
                    {value: -1, text: 'Seleccione un pabellón'}
                ]},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros de insumo", subTitle: "Ingrese parámetros de insumo", form: [
            [
                {name: "water_amount", label: "Medidor de agua", type: "number", min: 0},
                {name: "c_grain", label: "Consumo de grano", type: "number", min: 0},                
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros de muertes", subTitle: "Ingrese cantidad de muertes", form: [
            [
                {name: "c_dead_bird", label: "Muertes", type: "number", min: 0},           
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros ambientales", subTitle: "Ingrese parámetros de temperatura", form: [
            [
                {name: "t_max", label: "Temperatura máxima", type: "number", min: -50},
                {name: "t_min", label: "Temperatura mínima", type: "number", min: -50},
            ]
        ]},
        {icon: "flaticon-interface-7", title: "Parámetros semanales", subTitle: "Ingrese parámetros de peso", form: [
            [
                {name: "avg_weight", label: "Peso promedio", type: "number", min: 0},
                {name: "p_uni_weight", label: "Peso uniforma promedio", type: "number", min: 0},
            ]
        ]},
    ]
    data:any = {};

    private subscriptions: Subscription[] = [];
    private guanoControlList: any[];

    private user:User = new User();
    siloList: Silo[];
    oldItem: any;

    constructor(
        private manualService: ManualService,
        private pavilionHistoryService: PavilionHistoryService,
        public dialog: MatDialog,
        private pavilionService: PavilionService,
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef,
        private apkService: ApkService,
        private store: Store<AppState>,
        private siloService: SiloService,
    ){}

    ngOnInit(){
        this.user.clear();

		const authSubscription = this.store.pipe(
			select(selectAuthState),
		).subscribe((auth) => {
			// console.log("auth state: "+JSON.stringify(auth));
			if(auth.isUserLoaded)
				this.user = auth.user;
			else
				console.log("edit manual daily data: usuario no logueado");
		});
        this.subscriptions.push(authSubscription);

        // this.subscriptions.push(of(null).pipe(
        //     switchMap(()=>this.pavilionService.getAll()),
        //     tap(pavilions=>{
        //         this.pavilionList = pavilions.map(pav=>Object.assign({floorList: []}, pav));
        //     }),
        //     switchMap((pavilions)=> combineLatest(pavilions.map(pav=>this.pavilionService.getPavilionFloors(pav))))
        // ).subscribe(floors=>{
        //     for(let i = 0; i<this.pavilionList.length; i+=1){
        //         this.pavilionList[i].floorList = floors[i];
        //     }
        //     console.log(JSON.stringify(this.pavilionList));
        //     this.data.pavilionList = this.pavilionList;
        //     this.updateStepList();
        // }));     
        
        // this.subscriptions.push(of(null).pipe(
        //     switchMap(()=>this.apkService.getByDate(new Date())),
        // ).subscribe(guanoControlList=>{
        //     this.guanoControlList = guanoControlList;
        // })); 

        this.subscriptions.push(
            of(null).pipe(                
                switchMap(()=>this.siloService.getAll()),
                tap((siloList)=>{
                    this.siloList = siloList;
                }),
                switchMap(()=>this.pavilionService.getAll()),
                tap(pavilions=>{
                    this.pavilionList = pavilions.map(pav=>Object.assign({floorList: []}, pav));
                }),
                switchMap((pavilions)=> combineLatest(pavilions.map(pav=>this.pavilionService.getPavilionFloors(pav)))),
            ).subscribe(floors=>{
                for(let i = 0; i<this.pavilionList.length; i+=1){
                    this.pavilionList[i].floorList = floors[i];
                }
                // console.log(JSON.stringify(this.pavilionList));
                this.data.pavilionList = this.pavilionList;
                this.updateStepList();
                this.dataLoaded$.next(true);
            })
        )
    }

    updateStepList(){
        this.pavilionStepList[0].form[0][0].optionList = [{value: -1, text: 'Seleccione un pabellón'}];
        this.pavilionStepList[0].form[0][0].optionList = this.pavilionStepList[0].form[0][0].optionList.concat(this.pavilionList.map(pav=>{
            return {value:pav.id, text: pav.name};
        }));
        this.breedingStepList[0].form[0][0].optionList = [{value: -1, text: 'Seleccione un pabellón'}];
        this.breedingStepList[0].form[0][0].optionList = this.breedingStepList[0].form[0][0].optionList.concat(this.pavilionList.map(pav=>{
            return {value:pav.id, text: pav.name};
        }));
        this.cdr.detectChanges();
    }

    pavilionChanged(pavilion){
        const siloListByPav = this.siloList.filter(silo=>silo.pavilion_id == pavilion.id);
        const grainTypeIdList = siloListByPav.map(silo=>silo.grain_type_id);
        console.log("pavilion step list:");
        console.dir(this.pavilionStepList);
        if(grainTypeIdList.includes(1)){
            this.pavilionStepList[1].form[0][1].hidden = false;
            this.pavilionStepList[2].form[0][1].hidden = false;
        }else{
            this.pavilionStepList[1].form[0][1].hidden = true;
            this.pavilionStepList[2].form[0][1].hidden = true;
        }
        if(grainTypeIdList.includes(2)){
            this.pavilionStepList[1].form[1][0].hidden = false;
            this.pavilionStepList[2].form[1][0].hidden = false;
        }else{
            this.pavilionStepList[1].form[1][0].hidden = true;
            this.pavilionStepList[2].form[1][0].hidden = true;
        }
        // const silo = this.siloList.find(silo=>pavilion.id==silo.pavilion_id);
        // if(silo){
        //     if(silo.grain_type_id == 1){ // NORMAL
        //         this.pavilionStepList[1].form[0][1].hidden = false;
        //         this.pavilionStepList[1].form[1][0].hidden = true;
        //         this.pavilionStepList[2].form[0][1].hidden = false;
        //         this.pavilionStepList[2].form[1][0].hidden = true;
        //     }else{ //OMEGA
        //         this.pavilionStepList[1].form[0][1].hidden = true;
        //         this.pavilionStepList[1].form[1][0].hidden = false;
        //         this.pavilionStepList[2].form[0][1].hidden = true;
        //         this.pavilionStepList[2].form[1][0].hidden = false;
        //     }            
        // }
    }

    private prevPavilion = null;

    ngAfterViewInit(){
        // this.subscriptions.push(
        //     this.dataLoaded$.pipe(
        //         filter((loaded)=>loaded==true),
        //         take(1),
        //         switchMap(()=>this.itemPavilionForm.controls.pavilion_id.valueChanges)
        //     ).subscribe(pavilion_id=>{
        //         console.log(`pavilion form: changed to pavilion  ${JSON.stringify(pavilion_id)}`);
        //         const pavilion = this.pavilionList.find(pav=>pav.id==pavilion_id);
        //         console.log(JSON.stringify(pavilion));
        //         if(this.prevPavilion){
        //             this.prevPavilion.floorList.forEach(floor=>{
        //                 this.itemPavilionForm.removeControl(floor.name.replace(' ', '-'));
        //             });
        //         }
        //         if(pavilion.type == "CRIANZA"){
        //             this.isBreeding = true;
        //             this.itemBreedingForm.controls.pavilion_id.patchValue(pavilion.id);
        //         }else{
        //             this.isBreeding = false;

        //             pavilion.floorList.forEach(floor=>{
        //                 // this.itemFormOptions[floor.name.replace(' ', '-')] = ["high", Validators.required];
        //                 this.itemPavilionForm.addControl(floor.name.replace(' ', '-'), new FormControl("high", Validators.required))
        //             });
        //             // this.itemForm = this.formBuilder.group(this.itemFormOptions);
        //             this.cdr.detectChanges();
        //             this.pavilionStepList[4].form = [pavilion.floorList.map(floor=>{
        //                 return {
        //                     name: floor.name.replace(' ', '-'),
        //                     label: floor.name,
        //                     type: "radio",
        //                     optionList: [
        //                         {value: "high", text: "Alto"},
        //                         {value: "medium", text: "Medio"},
        //                         {value: "low", text: "Bajo"},
        //                     ]
        //                 };
        //             })];
                    
        //         }
        //         this.prevPavilion = pavilion;
        //         this.updateStepList();
        //     })
        // );

        this.subscriptions.push(
            this.dataLoaded$.pipe(
                filter((loaded)=>loaded==true),
                take(1),
                switchMap(()=> this.pavilionChanged$),
                filter(pavilion_id=>pavilion_id!=-1)
            ).subscribe(pavilion_id=>{
                const pavilion = this.pavilionList.find(pav=>pav.id==pavilion_id);
                this.pavilionChanged(pavilion);
                if(this.isBreeding){                    
                    console.log(`breedingform: changed to pavilion  ${JSON.stringify(pavilion_id)}`);
                    if(pavilion.type == "CRIANZA"){
                        this.isBreeding = true;
                    }else{
                        this.isBreeding = false;
                        this.itemPavilionForm.controls.pavilion_id.patchValue(pavilion.id);
                    }
                }else{
                    if(this.prevPavilion){
                        this.prevPavilion.floorList.forEach(floor=>{
                            this.itemPavilionForm.removeControl(floor.name.replace(' ', '-'));
                        });
                    }
                    if(pavilion.type == "CRIANZA"){
                        this.isBreeding = true;
                        this.itemBreedingForm.controls.pavilion_id.patchValue(pavilion.id);
                    }else{
                        this.isBreeding = false;
    
                        pavilion.floorList.forEach(floor=>{
                            // this.itemFormOptions[floor.name.replace(' ', '-')] = ["high", Validators.required];
                            this.itemPavilionForm.addControl(floor.name.replace(' ', '-'), new FormControl(null, Validators.required))
                        });
                        // this.itemForm = this.formBuilder.group(this.itemFormOptions);
                        this.cdr.detectChanges();
                        this.pavilionStepList[4].form = [pavilion.floorList.map(floor=>{
                            const guanoConv = ["high", "medium", "low"]

                            const guanoControl = this.guanoControlList.find(guano=>guano.pavilion_id == pavilion.id && guano.floor_id == floor.id);
                            if(guanoControl){
                                console.log("guano control: ");
                                console.dir(guanoControl);
                                const level = guanoConv[parseInt(guanoControl.guano_status_id)-1];
                                console.log(`setted floor ${floor.name} guano control to ${level}`);
                                this.itemPavilionForm.controls[floor.name.replace(' ', '-')].patchValue(level);
                            }else{
                                console.log("No se ha encontrado registro guano control");
                            }
                            
                            return {
                                name: floor.name.replace(' ', '-'),
                                label: floor.name,
                                type: "radio",
                                optionList: [
                                    {value: "high", text: "Alto"},
                                    {value: "medium", text: "Medio"},
                                    {value: "low", text: "Bajo"},
                                ]
                            };
                        })];
                        this.cdr.detectChanges();                  
                    }
                }
                this.updateStepList();
                this.prevPavilion = pavilion;
            })
        );

        this.subscriptions.push(
            this.itemBreedingForm.controls.pavilion_id.valueChanges.subscribe((pavilion_id)=>{
                this.pavilionChanged$.next(pavilion_id);
            })
        );

        this.subscriptions.push(
            this.itemPavilionForm.controls.pavilion_id.valueChanges.subscribe((pavilion_id)=>{
                this.pavilionChanged$.next(pavilion_id);
            })
        );
    }

    ngOnDestroy(){
        this.subscriptions.forEach(sub=>sub.unsubscribe());
    }

    onItemLoaded(item){
        // if(item.pavilion_type_name == "CRIANZA"){
        //     this.isBreeding = true;            
        // }else{
        //     this.isBreeding = false;
        // }
        this.oldItem = item;
        this.pavilionChanged$.next(item.pavilion_id);
    }

    getPavilionById(pavilionId){
        return this.pavilionList.find(pav=>pav.id == pavilionId);
    }

    getItem(itemId: number){
        this.subscriptions.push(
            this.dataLoaded$.pipe(
                filter(loaded=>loaded==true),
                switchMap(()=>this.manualService.getById(itemId)),
                switchMap((item)=>combineLatest([of(item), this.apkService.getByDate(new Date(item.create_date))])),
            ).subscribe(([item, guanoControlList])=>{
                this.guanoControlList = guanoControlList;
                if(this.prevPavilion){
                    item.pavilion_id = this.prevPavilion.id;
                    item.pavilion_name = this.prevPavilion.name;
                    item.pavilion_type_id = this.prevPavilion.type_id;
                    item.pavilion_type_name = this.prevPavilion.type;
                }
                const pavilion = this.pavilionList.find(pav=>pav.id==item.pavilion_id);
                pavilion.floorList.forEach(floor=>{
                    // const guanoConv = {high: 1, medium: 2, low: 3};
                    const guanoConv = ["high", "medium", "low"]

                    const guanoControl = this.guanoControlList.find(guano=>guano.pavilion_id == pavilion.id && guano.floor_id == floor.id);
                    if(guanoControl){
                        // console.log("guano control: ");
                        // console.dir(guanoControl);
                        const level = guanoConv[parseInt(guanoControl.guano_status_id)-1];
                        // this.itemPavilionForm.controls[floor.name.replace(' ', '-')].patchValue(level);
                        // console.log(`setted floor ${floor.name} guano control to ${level}`);
                        item[floor.name.replace(' ', '-')] = level;
                    }else{
                        console.log("No se ha encontrado registro guano control");
                    }
                })
                this.onItemLoaded(item);
                this.itemObs.next(item);
            })
        )
    }

    addItem(item: any){
        // this.subscriptions.push(
        //     of(null).pipe(
        //         switchMap(()=>this.manualService.add(item))
        //     ).subscribe(newId=>{
        //         this.addObs.next(newId);
        //     })
        // )
    }

    editItem(item: any){
        this.subscriptions.push(
            of(null).pipe(
                switchMap(()=>{
                    // if(item.status_id==1){
                        //list_guano : {id: number, guano_status_id: number}
                        //week_id: number
                    item.uid = this.user.id;
                    const guanoConv = {high: 1, medium: 2, low: 3};
                    item.week_id = item.manual_weekly_data_id;
                    const pavilion = this.pavilionList.find(pav=>pav.id==item.pavilion_id);
                    var list_guano = [];

                    // console.log("pavilion:");
                    // console.dir(pavilion);

                    // console.log("guano control list");
                    // console.dir(this.guanoControlList);

                    pavilion.floorList.forEach(floor=>{
                        const guanoLevel = item[floor.name.replace(' ', '-')];
                        const guanoControl = this.guanoControlList.find(guano=>guano.pavilion_id == pavilion.id && guano.floor_id == floor.id);
                        if(guanoControl){
                            list_guano.push({
                                id: guanoControl.id,
                                guano_status_id: guanoConv[guanoLevel]
                            });
                        }else{
                            console.log("No se ha encontrado registro guano control");
                        }
                    });
                    // console.log("list guano");
                    // console.dir(list_guano);
                    
                    item.list_guano = list_guano;
                    console.log("item to send: ");
                    console.dir(item);
                    return this.manualService.edit(item);
                    // }
                    // return of("error");
                    // return this.manualService.edit(item)
                }),
                map(status=>status=="success"),
                switchMap((manualEdited)=>{
                    var statusObs:Observable<string> = of("Ha fallado la edición de la tarea diaria");
                    if(manualEdited){
                        statusObs = of("Operación exitosa");
                        // const pavilion = this.pavilionList.find(pav=>pav.id==item.pavilion_id);
                        const date = new Date(item.create_date);
                        var minDate = new Date();
                        minDate.setHours(0);minDate.setMinutes(0);minDate.setSeconds(0);minDate.setMilliseconds(0);
                        minDate.setDate(minDate.getDate()-7);
                        var maxDate = new Date();
                        maxDate.setHours(23);maxDate.setMinutes(59);maxDate.setSeconds(59);maxDate.setMilliseconds(999);
                        maxDate.setDate(maxDate.getDate()-1);
                        console.log("date time: "+date.getTime());
                        console.log("min date: "+minDate.getTime())
                        if(date.getTime() < minDate.getTime()){
                            statusObs = of("Tarea manual editada. Historico no pudo ser actualizado debido a que la fecha de la tarea manual es anterior a 7 dias.")
                        }else if(item.pavilion_type_id == 1 && date.getTime() <= maxDate.getTime()){//PABELLON
                            console.log("DEBIESE ACTUALIZAR HISTORICO")
                            var operationList = [];
                            if(this.oldItem.c_dead_bird_b == null)this.oldItem.c_dead_bird_b = 0;
                            if(this.oldItem.c_dead_bird_w == null)this.oldItem.c_dead_bird_w = 0;
                            if(this.oldItem.c_grain_omega == null)this.oldItem.c_grain_omega = 0;
                            if(this.oldItem.c_grain_regular == null)this.oldItem.c_grain_regular = 0;
                            if(this.oldItem.water_amount == null)this.oldItem.water_amount = 0;
                            if(this.oldItem.m_harvest == null)this.oldItem.m_harvest = 0;
                            if(this.oldItem.c_dead_bird_b != item.c_dead_bird_b ||
                                this.oldItem.c_dead_bird_w != item.c_dead_bird_w){
                                operationList.push("deads");
                            }
                            if(this.oldItem.c_grain_omega != item.c_grain_omega ||
                                this.oldItem.c_grain_regular != item.c_grain_regular){
                                operationList.push("nourishment");
                                console.log("old item: ");console.dir(this.oldItem);
                                console.log("new item: ");console.dir(item);
                            }
                            if(this.oldItem.water_amount != item.water_amount){
                                operationList.push("water");
                            }
                            if(this.oldItem.m_harvest != item.m_harvest){
                                operationList.push("harvest");
                            }
                            console.log("operationlist: ");console.dir(operationList);
                            if(operationList.length>0){
                                statusObs = this.pavilionHistoryService.update_pavilion_history(
                                    date,
                                    item.pavilion_id,
                                    operationList
                                ).pipe(
                                    map(historyUpdated=>historyUpdated?"Operación exitosa":"Tarea manual editada. Ha fallado la actualización de la historia")
                                );
                            }
                        }                        
                    }             
                    return combineLatest([of(manualEdited), statusObs])
                }),
                switchMap(([manualEdited, status])=>{                
                    const dialogRef = this.dialog.open(ManualEditDialog, {
                        width: '400px',
                        hasBackdrop: true,
                        data       : {
                            status : status
                        }
                    });

                    return combineLatest([
                        of(manualEdited),
                        dialogRef.afterClosed()
                    ]);
                    
                    // .subscribe(res => {
                    //     if (!res) {
                    //         return;
                    //     }
                    
                    //     this.store.dispatch(new OneAlarmDeleted({ id: _item.id }));
                    //     this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    // });
                    
                })
            ).subscribe(([manualEdited,res])=>{
                // console.log("dialog response: ");console.dir(res);
                if(manualEdited) this.editObs.next(item.id);
                else this.editObs.next(-1);
            })
        )
    }
}

@Component({
    selector: 'manual-edit-dialog',
    templateUrl: 'manual-edit-dialog.html',
})
export class ManualEditDialog {
    constructor( @Inject(MAT_DIALOG_DATA) public data: any ) {
    }
}