import { BaseModel } from '../../_base/crud';

export class Silo extends BaseModel {
    id: number;
    create_uid: number;
    write_uid: number;
    write_date: any;
    create_date: any;
    name: string;
    capacity: number;
    p_reorder: number; //Porcentaje para volver a ordenar !
    grain_type_id: number;
    grain_type: string;
    company_id: number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type_id: number;
    pavilion_type_name: string;
    c_grain_amount: number;
    clear(){
        this.id                 = undefined;
        this.create_uid         = undefined;
        this.write_date         = undefined;
        this.write_uid          = undefined;
        this.create_date        = undefined;
        this.name               = "";
        this.capacity           = undefined;
        this.p_reorder          = undefined;
        this.grain_type_id      = undefined;
        this.grain_type         = "";
        this.company_id         = undefined;
        this.pavilion_id        = undefined;
        this.pavilion_name      = "";
        this.pavilion_type_id   = undefined;
        this.pavilion_type_name = "";
        this.c_grain_amount     = undefined
    }
}