// NGRX
import { Action } from '@ngrx/store';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { AlarmCfgModel } from '../_models/alarmcfg.model';
import { Update } from '@ngrx/entity';

export enum AlarmCfgActionTypes {
    AlarmCfgOnServerCreated = '[Edit AlarmCfg Component] AlarmCfg On Server Created',
    AlarmCfgCreated = '[Edit AlarmCfg Component] AlarmCfg Created',
    AlarmCfgUpdate = '[Edit AlarmCfg Component] AlarmCfg Update',
    AlarmCfgUpdated = '[Edit AlarmCfg Component] AlarmCfg Updated',
    AlarmCfgsStatusUpdated = '[AlarmCfgs List Page] AlarmCfgs Status Updated',
    OneAlarmCfgDeleted = '[AlarmCfgs List Page] One AlarmCfg Deleted',
    ManyAlarmCfgsDeleted = '[AlarmCfgs List Page] Many Selected AlarmCfgs Deleted',
    AlarmCfgsPageRequested = '[AlarmCfgs List Page] AlarmCfgs Page Requested',
    AlarmCfgsPageLoaded = '[AlarmCfgs API] AlarmCfgs Page Loaded',
    AlarmCfgsPageCancelled = '[AlarmCfgs API] AlarmCfgs Page Cancelled',
    AlarmCfgsPageToggleLoading = '[AlarmCfgs] AlarmCfgs Page Toggle Loading',
    AlarmCfgsActionToggleLoading = '[AlarmCfgs] AlarmCfgs Action Toggle Loading',
    AlarmCfgsGetAll = '[AlarmCfgs] AlarmCfgs get all',
    AlarmCfgsAllLoaded = '[AlarmCfgs] AlarmCfgs all loaded'
}

export class AlarmCfgOnServerCreated implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgOnServerCreated;
    constructor(public payload:  any) { }
}

export class AlarmCfgCreated implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgCreated;
    constructor(public payload: { alarmCfg: AlarmCfgModel }) { }
}

export class AlarmCfgUpdated implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgUpdated;
    constructor(public payload: any) { }
}

export class AlarmCfgUpdate implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgUpdate;
    constructor(public payload: any) { }
}

export class AlarmCfgsStatusUpdated implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsStatusUpdated;
    constructor(public payload: {
        alarmCfgs: AlarmCfgModel[],
        status: number
    }) { }
}

export class OneAlarmCfgDeleted implements Action {
    readonly type = AlarmCfgActionTypes.OneAlarmCfgDeleted;
    constructor(public payload: { id: number }) {}
}

export class ManyAlarmCfgsDeleted implements Action {
    readonly type = AlarmCfgActionTypes.ManyAlarmCfgsDeleted;
    constructor(public payload: { ids: number[] }) {}
}

export class AlarmCfgsPageRequested implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class AlarmCfgsPageLoaded implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsPageLoaded;
    constructor(public payload: { alarmCfgs: AlarmCfgModel[], totalCount: number, page: QueryParamsModel }) { }
}

export class AlarmCfgsPageCancelled implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsPageCancelled;
}

export class AlarmCfgsPageToggleLoading implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AlarmCfgsActionToggleLoading implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}
export class AlarmCfgsGetAll implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsGetAll;
    constructor(public payload: any) { }
}

export class AlarmCfgsAllLoaded implements Action {
    readonly type = AlarmCfgActionTypes.AlarmCfgsAllLoaded;
    constructor(public payload: any) { }
}

export type AlarmCfgActions = AlarmCfgOnServerCreated
| AlarmCfgCreated
| AlarmCfgUpdated
| AlarmCfgUpdate
| AlarmCfgsStatusUpdated
| OneAlarmCfgDeleted
| ManyAlarmCfgsDeleted
| AlarmCfgsPageRequested
| AlarmCfgsPageLoaded
| AlarmCfgsPageCancelled
| AlarmCfgsPageToggleLoading
| AlarmCfgsActionToggleLoading
| AlarmCfgsGetAll
| AlarmCfgsAllLoaded;