import { AnonymousSubject } from 'rxjs/internal/Subject';
import { BaseModel } from '../../_base/crud';

export class AlarmCfgModel extends BaseModel {
	id: number;
    write_date : any;
    create_date : any;
    write_uid : number;
    alarm_type_id : number;
    alarm_type_name: string;
    company_id : number;
    temp_low_value : number;
    temp_medium_value: number;
    temp_high_value : number;
    is_harvest : boolean;
    white_egg_weekly_harvest_setup : number;
    color_egg_weekly_harvest_setup : number;
    harvest_data_low : any;
    harvest_data_medium : any;
    harvest_data_high : any;
    is_vel_manual : any;
    grain_low : number;
    grain_medium : number;
    grain_high : number;
    pavilion_id: number;
    pavilion_name: string;
    pavilion_type_id: number;
    pavilion_type_name: string;
    input_manual_data_min: number;
    input_manual_data_hour: number;
    guano_control: any;
    floor_id: number;
    floor_name: string;
    info: string;
	clear() {
        this.id = undefined;
        this.write_uid = undefined;
        this.write_date = '';        
        this.alarm_type_id = undefined;
        this.company_id = undefined;
        this.alarm_type_name = "";
        this.temp_high_value = undefined;
        this.temp_low_value = undefined;
        this.temp_medium_value = undefined;
        this.is_harvest = false;
        this.white_egg_weekly_harvest_setup = undefined;
        this.color_egg_weekly_harvest_setup = undefined;
        this.harvest_data_high = undefined;
        this.harvest_data_medium = undefined;
        this.harvest_data_low = undefined;
        this.is_vel_manual = false;
        this.grain_high = undefined;
        this.grain_medium = undefined;
        this.grain_low = undefined;
        this.pavilion_id = undefined;
        this.pavilion_name = "";
        this.pavilion_type_id = undefined;
        this.pavilion_type_name = "";
        this.input_manual_data_hour = undefined;
        this.input_manual_data_min = undefined;
        this.info = "";
        this.guano_control = "";
        this.floor_id = 0;
        this.floor_name = "";
	}
}
