// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatPaginator, MatSort, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// RXJS
import { debounceTime, distinctUntilChanged, tap, skip, delay } from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
// NGRX
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// UI
import { SubheaderService } from '../../../../../core/_base/layout';
// CRUD
import { LayoutUtilsService, MessageType, QueryParamsModel } from '../../../../../core/_base/crud';
// Services and Models
import {
  AlarmCfgModel,
  AlarmsCfgDataSource,
  AlarmCfgsPageRequested,
  OneAlarmCfgDeleted,
  ManyAlarmCfgsDeleted,
  AlarmCfgsStatusUpdated,
  selectAlarmCfgsPageLastQuery,
  AlarmType,
  selectAlarmTypes,
  AlarmsGetTypes,
  AlarmCfgUpdate

} from '../../../../../core/alarms'
import { 
  PavilionGetTypes, 
  PavilionType, 
  selectPavilionTypes 
} from '../../../../../core/plant-configuration';
import { Update } from '@ngrx/entity';
import { selectAuthState } from '../../../../../core/auth/_selectors/auth.selectors';
import { User } from '../../../../../core/auth';
@Component({
  selector: 'kt-alarmscfg-list',
  templateUrl: './alarmscfg-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
  //styleUrls: ['./alarmscfg-list.component.scss']
})
export class AlarmscfgListComponent implements OnInit {

// Table fields
dataSource: AlarmsCfgDataSource;
displayedColumns = ['select', 'create_date', 'pavilion_name', 'floor_name', 
  'info', 'vel_mode_name', 'guano_control', 'harvest', 'alarm_type_name', 'actions'];
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild('sort1', {static: true}) sort: MatSort;
// Filter fields
@ViewChild('searchInput', {static: true}) searchInput: ElementRef;
filterPavilionType = '';
filterAlarmType = '';
lastQuery: QueryParamsModel;
// Selection
selection = new SelectionModel<AlarmCfgModel>(true, []);
alarmsResult: AlarmCfgModel[] = [];
pavilionTypesResult: PavilionType[] = [];
alarmTypesResult: AlarmType[] = [];
private subscriptions: Subscription[] = [];
page_length;
first_load = true;

private user: User = new User();
/**
 * Component constructor
 *
 * @param dialog: MatDialog
 * @param activatedRoute: ActivatedRoute
 * @param router: Router
 * @param subheaderService: SubheaderService
 * @param layoutUtilsService: LayoutUtilsService
 * @param store: Store<AppState>
 */
constructor(public dialog: MatDialog,
             private activatedRoute: ActivatedRoute,
             private router: Router,
             private subheaderService: SubheaderService,
             private layoutUtilsService: LayoutUtilsService,
             private store: Store<AppState>,
             private cdr: ChangeDetectorRef
             ) { }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
ngOnInit() {
  // If the user changes the sort order, reset back to the first page.
  const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  this.subscriptions.push(sortSubscription);

  this.user.clear();

  const authSubscription = this.store.pipe(
    select(selectAuthState),
  ).subscribe((auth) => {
    // console.log("auth state: "+JSON.stringify(auth));
    if(auth.isUserLoaded)
      this.user = auth.user;
    else
      console.log("hyline list component: usuario no logueado");
  });
  this.subscriptions.push(authSubscription);

  /* Data load will be triggered in two cases:
  - when a pagination event occurs => this.paginator.page
  - when a sort event occurs => this.sort.sortChange
  **/
  const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
    tap(() => this.loadAlarmCfgsList())
  )
  .subscribe();
  this.subscriptions.push(paginatorSubscriptions);

  // Filtration, bind to searchInput
  const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
    debounceTime(150),
    distinctUntilChanged(),
    tap(() => {
      this.paginator.pageIndex = 0;
      this.loadAlarmCfgsList();
    })
  )
  .subscribe();
  this.subscriptions.push(searchSubscription);

  // Set title to page breadCrumbs
  this.subheaderService.setTitle('Listado de alarmas');

  const pavTypes$ = this.store.pipe(
    select(selectPavilionTypes)
  );
  const alarmTypes$ = this.store.pipe(
    select(selectAlarmTypes)
  );

  const pavTypesSub = pavTypes$.subscribe(pavilionTypes=>{
    this.pavilionTypesResult = pavilionTypes;
  });

  const alarmTypesSub = alarmTypes$.subscribe(alarmTypes=>{
    console.log("alarm types retrieved: "+JSON.stringify(alarmTypes));
    this.alarmTypesResult = alarmTypes;
  });

  // Init DataSource
  this.dataSource = new AlarmsCfgDataSource(this.store);
  const entitiesSubscription = this.dataSource.entitySubject.pipe(
    skip(1),
    distinctUntilChanged()
  ).subscribe(res => {
    let cfgTemplate = new AlarmCfgModel();
    cfgTemplate.clear();
    cfgTemplate.info = "nothing";
    this.alarmsResult = res;
    if (res){				
      if (res.length!=0){
        if (this.first_load){
          this.page_length = res.length
          this.cdr.detectChanges();
          this.first_load = false;	
        }
        // this.alarmsResult.forEach((alarmCfg, index)=>{
        //   const cfg = this.alarmsResult[index];
        //   console.log("cfg found: "+JSON.stringify(cfg));
        //   this.alarmsResult[index] = Object.assign(cfgTemplate, this.alarmsResult[index]);
        //   switch(cfg.alarm_type_name){
        //     case "PABELLONES-TEMPERATURA":
        //       console.log("temperatura!")
        //       this.alarmsResult[index].info = `Min: ${cfg.temp_low_value}°C. Medio: ${cfg.temp_medium_value}°C. `
        //         + `Max: ${cfg.temp_high_value}°C.`;
        //       break
        //     case "PABELLONES-COSECHA":        
        //       //NO SE CUMPLE LA META
        //       this.alarmsResult[index].info = `Min: ${cfg.harvest_data_low}. Medio: ${cfg.harvest_data_medium}. `
        //         + `Max: ${cfg.harvest_data_high}`;
        //       break
        //     case "PABELLONES-MOD-VELOCIDAD":
        //       this.alarmsResult[index].info = `Velocidad manual: ${cfg.is_vel_manual}`;
        //       break
        //     case "PABELLONES-INVENTARIO-ALIMENTO":
        //       this.alarmsResult[index].info = `Min: ${cfg.grain_low} Kg. Medio: ${cfg.grain_medium} Kg. `
        //         + `Max: ${cfg.grain_high} Kg.`;
        //       break
        //     case "PABELLONES-CONTROL-GUANO":
        //       break
        //     case "CRIANZA-INGRESO-DATA":
        //       //Tiempo para ingresar la data manual ha sido superado
        //       this.alarmsResult[index].info = `Hora limite: ${cfg.input_manual_data_hour}:${cfg.input_manual_data_min}.`
        //       // this.alarmsResult[index].info = `Min: ${cfg.manual_data_breeding_low}. Medio: ${cfg.manual_data_breeding_medium}. `
        //       //   + `Max: ${cfg.manual_data_breeding_high}`;
        //       break
        //     case "COSECHA-HUEVOS":
        //       //Solo mostrar white y color
        //       this.alarmsResult[index].info = `Blancos esperados: ${cfg.white_egg_weekly_harvest_setup}. Color esperados: ${cfg.color_egg_weekly_harvest_setup}.`
        //       break
        //     case "PABELLONES-COSECHA-MOD":
        //       //Is harvest.. mostrar checkbox
        //       this.alarmsResult[index].info = cfg.is_harvest?'Cosecha automática':'Cosecha manual';
        //       break;
        //     default:
        //       break
        //   }
        //   console.log("index: "+index);
        //   console.log("After: "+JSON.stringify(this.alarmsResult[index]));
        // });
      }
    }	
  });
  this.subscriptions.push(entitiesSubscription);
  const lastQuerySubscription = this.store.pipe(select(selectAlarmCfgsPageLastQuery)).subscribe(res => this.lastQuery = res);
  // Load last query from store
  this.subscriptions.push(lastQuerySubscription);

  // Read from URL itemId, for restore previous state
  const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
    if (params.id) {
      this.restoreState(this.lastQuery, +params.id);
    }

    // First load
    of(undefined).pipe(delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadAlarmCfgsList();
    }); // Remove this line, just loading imitation
  });
  this.subscriptions.push(routeSubscription);

  this.loadAlarmTypes();
  this.loadPavilionTypes();
}

/**
 * On Destroy
 */
ngOnDestroy() {
  this.subscriptions.forEach(el => el.unsubscribe());
}

loadAlarmTypes(){
  this.store.dispatch(new AlarmsGetTypes({}));
}

loadPavilionTypes(){
  this.store.dispatch(new PavilionGetTypes({}));
}

/**
 * Load AlarmCfgs List
 */
loadAlarmCfgsList() {
  this.selection.clear();
  const queryParams = new QueryParamsModel(
    this.filterConfiguration(),
    this.sort.direction,
    this.sort.active,
    this.paginator.pageIndex,
    this.paginator.pageSize
  );
  // Call request from server
  this.store.dispatch(new AlarmCfgsPageRequested({ page: queryParams }));
  this.selection.clear();
}

/**
 * Returns object for filter
 */
filterConfiguration(): any {
  const filter: any = {};
  const searchText: string = this.searchInput.nativeElement.value;

  if (this.filterAlarmType) {
    filter.alarm_type_id = this.filterAlarmType;
  }

  if (this.filterPavilionType) {
    filter.pavilion_type_id = this.filterPavilionType;
  }

  filter.pavilion = searchText;
  return filter;
}

/**
 * Restore state
 *
 * @param queryParams: QueryParamsModel
 * @param id: number
 */
restoreState(queryParams: QueryParamsModel, id: number) {

  if (!queryParams.filter) {
    return;
  }

  if ('alarm_type_id' in queryParams.filter) {
    this.filterAlarmType = queryParams.filter.alarm_type_id.toString();
  }

  if ('pavilion_type_id' in queryParams.filter) {
    this.filterPavilionType = queryParams.filter.pavilion_type_id.toString();
  }

  if (queryParams.filter.pavilion) {
    this.searchInput.nativeElement.value = queryParams.filter.pavilion;
  }
}

/** ACTIONS */
/**
 * Delete AlarmCfg
 *
 * @param _item: AlarmCfgModel
 */
deleteAlarmCfg(_item: AlarmCfgModel) {
  const _title = 'Eliminar configuración de alarma';
  const _description = 'Está usted seguro de que desea eliminar esta configuración de alarma?';
  const _waitDesciption = 'La configuración de alarma está siendo eliminada...';
  const _deleteMessage = `La configuración de alarma ha sido eliminada`;

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    this.store.dispatch(new OneAlarmCfgDeleted({ id: _item.id }));
    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
  });
}

/**
 * Delete AlarmCfgs
 */
deleteAlarmCfgs() {
  const _title = 'Eliminar configuraciones de alarma';
  const _description = 'Está usted seguro de que desea eliminar las configuraciones de alarma seleccionadas?';
  const _waitDesciption = 'Las configuraciones de alarma están siendo eliminadas...';
  const _deleteMessage = 'Las configuraciones de alarma seleccionadas han sido eliminadas';

  const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      return;
    }

    const idsForDeletion: number[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selection.selected.length; i++) {
      idsForDeletion.push(this.selection.selected[i].id);
    }
    this.store.dispatch(new ManyAlarmCfgsDeleted({ ids: idsForDeletion }));
    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    this.selection.clear();
  });
}

/**
 * Fetch selected AlarmCfgs
 */
fetchAlarmCfgs() {
  // tslint:disable-next-line:prefer-const
  let messages = [];
  this.selection.selected.forEach(elem => {
    messages.push({
      text: `${elem.alarm_type_name}`,
      id: elem.id,
      status: elem.pavilion_name
    });
  });
  this.layoutUtilsService.fetchElements(messages);
}

/**
 * Update status dialog
 */
updateStatusForAlarmCfgs() {
  const _title = 'Actualizar estado para las configuraciones de alarma seleccionadas';
  const _updateMessage = 'El estado de las configuraciones de alarma ha sido actualizado';
  const _statuses = [{ value: 0, text: 'Selling' }, { value: 1, text: 'Sold' }];
  const _messages = [];

  this.selection.selected.forEach(elem => {
    _messages.push({
      text: `${elem.alarm_type_name}`,
      id: elem.id,
      status: elem.pavilion_name,
      statusTitle: elem.alarm_type_name,
      statusCssClass: this.getItemCssClassByStatus(0)
    });
  });

  const dialogRef = this.layoutUtilsService.updateStatusForEntities(_title, _statuses, _messages);
  dialogRef.afterClosed().subscribe(res => {
    if (!res) {
      this.selection.clear();
      return;
    }

    //this.store.dispatch(new AlarmCfgsStatusUpdated({
    //  status: +res,
    //  alarms: this.selection.selected
    //}));

    this.layoutUtilsService.showActionNotification(_updateMessage, MessageType.Update);
    this.selection.clear();
  });
}

updateAlarmCfgHarvestMode(alarmCfg:AlarmCfgModel){
  console.log(JSON.stringify(alarmCfg));
  console.log("changed status");
  const calarmCfg = {...this.alarmsResult.find((h)=>h.id == alarmCfg.id)};
  console.log("found alarmCfg: "+JSON.stringify(calarmCfg));
  
  calarmCfg.is_harvest = !calarmCfg.is_harvest;
  calarmCfg.write_uid = this.user.id;
  const updateAlarmCfg: Update<AlarmCfgModel> = {
    id: alarmCfg.id,
    changes: calarmCfg
  };
  this.store.dispatch(new AlarmCfgUpdate({alarmCfg:calarmCfg, partialAlarmCfg:updateAlarmCfg}));
}

updateAlarmCfgVelMode(alarmCfg:AlarmCfgModel){
  console.log(JSON.stringify(alarmCfg));
  console.log("changed status");
  const calarmCfg = {...this.alarmsResult.find((h)=>h.id == alarmCfg.id)};
  console.log("found alarmCfg: "+JSON.stringify(calarmCfg));
  
  calarmCfg.is_vel_manual = !calarmCfg.is_vel_manual;
  calarmCfg.write_uid = this.user.id;
  const updateAlarmCfg: Update<AlarmCfgModel> = {
    id: alarmCfg.id,
    changes: calarmCfg
  };
  this.store.dispatch(new AlarmCfgUpdate({alarmCfg:calarmCfg, partialAlarmCfg:updateAlarmCfg}));
}

updateAlarmCfgGuanoMode(alarmCfg:AlarmCfgModel){
  console.log(JSON.stringify(alarmCfg));
  console.log("changed status");
  const calarmCfg = {...this.alarmsResult.find((h)=>h.id == alarmCfg.id)};
  console.log("found alarmCfg: "+JSON.stringify(calarmCfg));
  
  calarmCfg.guano_control = !calarmCfg.guano_control;
  calarmCfg.write_uid = this.user.id;
  const updateAlarmCfg: Update<AlarmCfgModel> = {
    id: alarmCfg.id,
    changes: calarmCfg
  };
  this.store.dispatch(new AlarmCfgUpdate({alarmCfg:calarmCfg, partialAlarmCfg:updateAlarmCfg}));
}

/**
 * Redirect to edit page
 *
 * @param id: any
 */
editAlarmCfg(id) {
  this.router.navigate(['../alarmscfg/edit', id], { relativeTo: this.activatedRoute });
}

createAlarmCfg() {
 this.router.navigate(['../alarmscfg/add'], { relativeTo: this.activatedRoute });
}

/**
 * Check all rows are selected
 */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.alarmsResult.length;
  return numSelected === numRows;
}

/**
 * Selects all rows if they are not all selected; otherwise clear selection
 */
masterToggle() {
  if (this.isAllSelected()) {
    this.selection.clear();
  } else {
    this.alarmsResult.forEach(row => this.selection.select(row));
  }
}

/* UI */
/**
 * Returns status string
 *
 * @param status: number
 */
getItemStatusString(status: number = 0): string {
  switch (status) {
    case 0:
      return 'Selling';
    case 1:
      return 'Sold';
  }
  return '';
}

/**
 * Returns CSS Class by status
 *
 * @param status: number
 */
getItemCssClassByStatus(status: number = 0): string {
  switch (status) {
    case 0:
      return 'success';
    case 1:
      return 'metal';
  }
  return '';
}

/**
 * Rerurns condition string
 *
 * @param condition: number
 */
getItemConditionString(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'New';
    case 1:
      return 'Used';
  }
  return '';
}

/**
 * Returns CSS Class by condition
 *
 * @param condition: number
 */
getItemCssClassByCondition(condition: number = 0): string {
  switch (condition) {
    case 0:
      return 'accent';
    case 1:
      return 'primary';
  }
  return '';
}
}
