import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../auth';
import {BASE_URL, API_PAVILIONS_URL} from '../../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { BirdGeneration } from '../_models/bird.model';
import { Floor, Pavilion, Pavilioner } from '../_models/pavilion.model';
//const API_USERS_URL = 'api/users';
//const API_PERMISSION_URL = 'api/permissions';
//const API_ROLES_URL = 'api/roles';

//const BASE_URL = "http://localhost:3000"                //INTERNAL DEV
//const BASE_URL = "http://ir4hub.cl:45016"                //LAB DEV

@Injectable()
export class PavilionService{
    constructor(private http: HttpClient){

    }

    getAll():Observable<Pavilion[]>{       
        const url = `${API_PAVILIONS_URL}/all`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_PAVILIONS_URL}/page`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    getSpecificDashboardPage(pavilion:Pavilion, queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_PAVILIONS_URL}/breeding/dashboard/page/${pavilion.id}`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    getTypes():Observable<any[]>{
        const url = `${API_PAVILIONS_URL}/types`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );   
    }

    add(pavilion:Pavilion):Observable<number>{
        const url = `${API_PAVILIONS_URL}`;
        return this.http.post<any>(url,pavilion).pipe(
            map((response)=>response.id)
        );
    }

    edit(pavilion:Pavilion):Observable<boolean>{
        const url = `${API_PAVILIONS_URL}`;
        return this.http.put<any>(url, pavilion).pipe(
            map((resp)=> resp.status=="success")
        )
    }

    delete(pavilion:Pavilion):Observable<boolean>{
        const url = `${API_PAVILIONS_URL}/${pavilion.id}`;
        return this.http.delete<any>(url).pipe(
            map((resp)=>resp.status=="success")
        );
    }

    assignPavilioner(pavilioner:Pavilioner, pavilion:Pavilion):Observable<any>{
        const url = `${API_PAVILIONS_URL}/pavilioner/assign`;
        return this.http.post<any>(url, {
            partner_id: pavilioner.partner_id, 
            pavilion_id: pavilion.id
        });
    }

    unlinkPavilioner(pavilioner:Pavilioner, pavilion:Pavilion):Observable<boolean>{
        const url = `${API_PAVILIONS_URL}/pavilioner/unlink`;
        const params = new HttpParams()
            .set("partner_id", pavilioner.partner_id.toString())
            .set("pavilion_id", pavilion.id.toString());
        return this.http.delete<any>(url, {params: params}).pipe(
            map((resp)=>resp.status=='success')
        );
    }

    getPavilionersPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${API_PAVILIONS_URL}/pavilioner/page`;
        return this.http.post<QueryResultsModel>(url, queryParams)
    }

    getAllPavilioners():Observable<Pavilioner[]>{
        const url = `${API_PAVILIONS_URL}/pavilioner/all`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    getAllPavFloors():Observable<any[]>{
        const url = `${API_PAVILIONS_URL}/getAllPavFloors`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getPavilionFloors(pavilion:Pavilion,pavilion_id=0):Observable<Floor[]>{
        if (pavilion){
            const url = `${API_PAVILIONS_URL}/floors/${pavilion.id}`;
            return this.http.get<any>(url).pipe(
                map((resp)=>resp.rows)
            );
        }else{
            const url = `${API_PAVILIONS_URL}/floors/${pavilion_id}`;
            return this.http.get<any>(url).pipe(
                map((resp)=>resp.rows)
            );
        }
        
    }

    getAllFloors():Observable<Floor[]>{
        const url = `${API_PAVILIONS_URL}/pavilion_floors`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }
    getAllSilos():Observable<Floor[]>{
        const url = `${API_PAVILIONS_URL}/getAllSilos`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getBreedingGenerations(pavilion:Pavilion):Observable<BirdGeneration[]>{
        const url = `${API_PAVILIONS_URL}/breeding/generations/${pavilion.id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>resp.rows)
        );
    }

    getPavilionGenerations(pavilion:Pavilion):Observable<any>{
        const url = `${API_PAVILIONS_URL}/generations/${pavilion.id}`;
        return this.http.get<any>(url).pipe(
            map((resp)=>{return {birdGens: resp.rows, active: resp.active}})
        );
    }
}